import type { TOC } from '@ember/component/template-only';
import ReviewsCard from 'smily-admin-ui/components/reviews/card';
import ReviewsListItem from 'smily-admin-ui/components/reviews/list-item';
import ReviewsListItemCompact from 'smily-admin-ui/components/reviews/list-item-compact';
import type HostReviewModel from 'smily-admin-ui/models/host-review';
import type ReviewModel from 'smily-admin-ui/models/review';

const ReviewsItem: TOC<{
  Args: {
    review: ReviewModel | HostReviewModel;
    smallScreen?: boolean;
    compact?: boolean;
  };
}> = <template>
  {{#if @smallScreen}}
    <ReviewsCard @model={{@review}} />
  {{else}}
    {{#if @compact}}
      <ReviewsListItemCompact @review={{@review}} />
    {{else}}
      <ReviewsListItem @model={{@review}} />
    {{/if}}
  {{/if}}
</template>;

export default ReviewsItem;
