import type { TOC } from '@ember/component/template-only';
import { guidFor } from '@ember/object/internals';
import t from 'ember-intl/helpers/t';
import FormTextarea from 'ember-smily-base/components/form/textarea';
import LoadingButton from 'ember-smily-base/components/loading-button';
import type { Changeset } from 'ember-smily-base/utils/model';
import not from 'ember-truth-helpers/helpers/not';
import InboxAddAttachment from 'smily-admin-ui/components/inbox/add-attachment';
import InboxComposeAttachments from 'smily-admin-ui/components/inbox/compose/attachments';
import InboxComposeHeader from 'smily-admin-ui/components/inbox/compose/header';
import InboxDraftWithAi from 'smily-admin-ui/components/inbox/draft-with-ai';
import InboxSavedReplies from 'smily-admin-ui/components/inbox/saved-replies';
import InboxSendButton from 'smily-admin-ui/components/inbox/send-button';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type { Visibility } from 'smily-admin-ui/models/inbox-message';
import { asAttachments } from 'smily-admin-ui/models/inbox-message';

export interface InboxComposeAreaSignature {
  Args: {
    changeset: Changeset;
    isAddingAttachments: boolean;
    send: () => void;
    draftWithAi: () => void;
    addAttachment: (event: Event) => Promise<void>;
    deleteAttachment: (attachment: InboxAttachmentModel) => Promise<void>;
    changeVisibility: (visibility: Visibility) => void;
  };
}

const InboxComposeAreaDesktop: TOC<InboxComposeAreaSignature> = <template>
  <div class='p-2 pb-3 border-top d-flex flex-column align-items-start gap-3'>
    <InboxComposeHeader
      @changeset={{@changeset}}
      @changeVisibility={{@changeVisibility}}
    />

    {{#let (asAttachments @changeset.attachments) as |attachments|}}
      {{#if attachments.length}}
        <InboxComposeAttachments
          @attachments={{attachments}}
          @deleteAttachment={{@deleteAttachment}}
        />
      {{/if}}
    {{/let}}

    <div class='w-100 d-flex flex-column'>
      <FormTextarea
        @model={{@changeset}}
        @modelGuid={{guidFor @changeset._content}}
        @property='content'
        @renderLabel={{false}}
        @placeholder={{if
          @changeset.isNote
          (t 'inbox.write_a_note')
          (t 'inbox.write_a_reply')
        }}
        @height='80px'
        @inputClass='message-composer-input {{if @changeset.isNote "note"}}'
      />

      <div class='d-flex justify-content-end gap-2'>
        {{#if @changeset.isNote}}
          <LoadingButton
            @action={{@send}}
            @label={{t 'inbox.save_note'}}
            @disabled={{not @changeset.content}}
            class='btn btn-primary btn-sm'
          />
        {{else}}
          <InboxSavedReplies @changeset={{@changeset}} />

          <InboxAddAttachment
            @isAddingAttachments={{@isAddingAttachments}}
            @onChange={{@addAttachment}}
          />

          <InboxDraftWithAi @draftWithAi={{@draftWithAi}} />

          <InboxSendButton
            @changeset={{@changeset}}
            @small={{true}}
            @send={{@send}}
          />
        {{/if}}
      </div>
    </div>
  </div>
</template>;

export default InboxComposeAreaDesktop;
