import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import AnnotatedCard from 'ember-smily-base/components/annotated-card';
import Form from 'ember-smily-base/components/form';
import LoadingButton from 'ember-smily-base/components/loading-button';
import { createChangeset } from 'ember-smily-base/utils/model';
import type ReviewsHostAutomationCommentModel from 'smily-admin-ui/models/reviews-host-automation-comment';
import { CHARACTER_LIMIT } from 'smily-admin-ui/models/reviews-host-automation-comment';

export default class ReviewsAutomatedCommentForm extends Component<{
  Element: HTMLUListElement;
  Args: {
    comment: ReviewsHostAutomationCommentModel;
    isNew?: boolean;
  };
}> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;

  commentChangeset = createChangeset(this.args.comment);

  saveComment = task({ drop: true }, async () => {
    await this.commentChangeset.save();

    const translationKey = this.args.isNew
      ? 'reviews.automation.public_comment.added'
      : 'reviews.automation.public_comment.updated';

    this.router.transitionTo('reviews.host.automation.index');
    this.notify.success(this.intl.t(translationKey));
  });

  deleteComment = task({ drop: true }, async () => {
    await this.args.comment.destroyRecord();

    this.router.transitionTo('reviews.host.automation.index');
    this.notify.success(
      this.intl.t('reviews.automation.public_comment.deleted'),
    );
  });

  <template>
    <div class='p-3 d-flex flex-column'>
      <AnnotatedCard
        @title={{if
          @isNew
          (t 'reviews.automation.public_comment.title')
          (t 'breadcrumbs.reviews.host.automation.comment')
        }}
        @subtitle={{t
          'reviews.automation.public_comment.subtitle'
          htmlSafe=true
        }}
        class='remove-annotation-padding'
      >
        <Form
          @model={{this.commentChangeset}}
          @onSubmit={{perform this.saveComment}}
          class='p-3'
          as |F|
        >
          <div class='d-flex flex-column gap-1'>
            <F.Textarea
              @property='content'
              @characterLimit={{CHARACTER_LIMIT}}
              @required={{true}}
            />
          </div>

          <div class='d-flex justify-content-between gap-3'>
            {{#if @isNew}}
              <div></div>
            {{else}}
              <LoadingButton
                @label={{t 'reviews.automation.public_comment.delete_comment'}}
                @action={{perform this.deleteComment}}
                @needsConfirmation={{true}}
                class='btn btn-danger'
              />
            {{/if}}

            <F.Submit>
              {{if
                @isNew
                (t 'reviews.automation.public_comment.create_comment')
                (t 'common.save_changes')
              }}
            </F.Submit>
          </div>
        </Form>
      </AnnotatedCard>
    </div>
  </template>
}
