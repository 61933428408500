import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

export default class AppsAppRoute extends Route {
  @service coreLinks!: CoreLinksService;

  beforeModel(transition: Transition): void {
    const { app_id: id } = transition.to.params;
    this.coreLinks.redirect('apps', id);
  }
}
