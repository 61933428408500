import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type AccountModel from 'smily-admin-ui/models/account';
import type AccountingPaymentModel from 'smily-admin-ui/models/accounting-payment';
import type SystemBillingDetailModel from 'smily-admin-ui/models/system-billing-detail';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type ExpectedSuspensionReason = 'outstanding_payments';

export default class SubscriptionModel extends BaseModel {
  @attr cardName?: string;
  @attr cardType?: string;
  @attr cardNumber?: string;
  @attr cardExpiration?: string;
  @attr aliasId?: string;
  @attr('object', { readOnly: true }) expectedSuspension?: {
    date: Date;
    reason: ExpectedSuspensionReason;
  };

  @belongsTo('account', {
    async: false,
    inverse: 'subscription',
    readOnly: true,
  })
  account!: AccountModel;

  @belongsTo('system-billing-detail', {
    async: false,
    inverse: null,
    readOnly: true,
  })
  systemBillingDetail!: SystemBillingDetailModel;

  @hasMany('accounting-payment', {
    async: false,
    readOnly: true,
    inverse: null,
  })
  outstandingPayments!: SyncHasMany<AccountingPaymentModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subscription: SubscriptionModel;
  }
}
