import { service } from '@ember/service';
import type { Snapshot } from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type StoreService from 'ember-smily-base/services/store';
import { BaseAdapter } from 'ember-smily-base/utils/adapter';
import { getShortLocale } from 'ember-smily-base/utils/intl';
import type { Error as JSONAPIError } from 'ember-smily-base/utils/json-api';
import type { Model, ModelName } from 'ember-smily-base/utils/store';
import { serializeQuery } from 'ember-smily-base/utils/store';
import config from 'smily-admin-ui/config/environment';
import type SessionService from 'smily-admin-ui/services/session-service';
import { close as closeSentry, setContext } from 'smily-admin-ui/utils/sentry';

interface SnapshotWithInclude extends Snapshot {
  include?: string;
}

export interface AjaxSettings {
  url: string;
  method: string;
  type: string;
  credentials?: string;
}

const {
  API: { HOST, NAMESPACE },
} = config;
const INDEXED_OBJECT_RE = /%5[Bb]\d?%5[Dd]/g;

function getModelNameFromType<MN extends ModelName>(type: Model<MN>) {
  return (
    type as unknown as {
      modelName: ModelName;
    }
  ).modelName as string;
}

function transformSnapshot(
  store: StoreService,
  modelName: string,
  snapshot: SnapshotWithInclude,
) {
  if (!snapshot.include) {
    return;
  }

  const { include } = snapshot;
  const serialized = serializeQuery(store, modelName, { include });

  snapshot.include = serialized.include;
}

function generateSentryContext(errors: JSONAPIError[]) {
  return Object.fromEntries(
    errors.map((error) => [error.source!.pointer, error.code || error.detail]),
  );
}

export default class ApplicationAdapter extends BaseAdapter {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service session!: SessionService;

  host = HOST;
  namespace = NAMESPACE;

  get headers() {
    return {
      ...this.session.authorizationHeader,
      'Accept-Language': this.intl.primaryLocale,
      'X-Current-Locale': getShortLocale(this.intl.primaryLocale).toLowerCase(),
    } as Record<string, string>;
  }

  query<MN extends ModelName>(
    store: StoreService,
    type: Model<MN>,
    query: Record<string, unknown>,
  ) {
    const modelName = getModelNameFromType(type);
    const serializedQuery = serializeQuery(store, modelName, query);

    return super.query(store, type, serializedQuery);
  }

  findRecord<MN extends ModelName>(
    store: StoreService,
    type: Model<MN>,
    id: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    snapshot: any,
  ) {
    const modelName = getModelNameFromType(type);

    transformSnapshot(store, modelName, snapshot);

    return super.findRecord(store, type, id, snapshot);
  }

  handleResponse(
    status: number,
    headers: Record<string, unknown>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    payload: any,
    requestData: Record<string, unknown>,
  ): ReturnType<BaseAdapter['handleResponse']> {
    if (status === 304) {
      throw new Error(`Ember Data Request ${requestData.url} returned a 304`);
    }

    if (
      status === 401 ||
      payload?.code === 'UnauthorizedError' ||
      payload?.errors?.[0]?.status === '401'
    ) {
      closeSentry();
      this.session.invalidate();
      return super.handleResponse(status, headers, payload, requestData);
    }

    if (status && String(status).startsWith('4')) {
      if (status === 422) {
        const errors = payload?.errors && generateSentryContext(payload.errors);
        setContext('Unprocessable entry errors', errors);
      } else {
        this.notify.error(this.intl.t('internals.errors.default'));
      }
    }

    let parsedPayload = payload;

    try {
      parsedPayload = JSON.parse(payload);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return super.handleResponse(status, headers, parsedPayload, requestData);
    }
  }

  ajaxOptions(
    url: string,
    method: string,
    options: AjaxSettings,
  ): AjaxSettings {
    const origOptions = super.ajaxOptions(url, method, options) as AjaxSettings;

    origOptions.url = origOptions.url.replace(INDEXED_OBJECT_RE, '%5B%5D');

    return origOptions;
  }

  declare urlPrefix: () => string;
}
