import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type CacheService from 'smily-admin-ui/services/cache';
import type { InboxCounterProperty } from 'smily-admin-ui/services/cache';

export interface InboxSidebarSubmenuCategory {
  key: string;
  query: Record<string, unknown>;
  count: InboxCounterProperty;
}

interface InboxSidebarSubmenuItemSignature {
  Element: HTMLLIElement;
  Args: {
    category: InboxSidebarSubmenuCategory;
  };
}

export default class InboxSidebarSubmenuItem extends Component<InboxSidebarSubmenuItemSignature> {
  @service cache!: CacheService;

  get badge() {
    const count = this.cache[this.args.category.count];

    if (!count) {
      return '';
    }

    return count > 1000 ? '1000 +' : count.toString();
  }

  <template>
    <li class='nav-item' ...attributes>
      <LinkTo @route='inbox' @query={{@category.query}} class='nav-link'>
        <div class='nav-title'>
          {{optionLabel @category.key 'inbox.sidebar'}}
        </div>

        {{#if this.badge}}
          <div class='nav-meta'>
            {{this.badge}}
          </div>
        {{/if}}
      </LinkTo>
    </li>
  </template>
}
