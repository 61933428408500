import { concat } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { LinkTo } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BsTooltip from 'ember-bootstrap/components/bs-tooltip';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import ExternalLink from 'ember-smily-base/components/external-link';
import Form from 'ember-smily-base/components/form';
import type MetricsService from 'ember-smily-base/services/metrics';
import type { AnyComponent } from 'ember-smily-base/utils/glimmer';
import { getShortLocale } from 'ember-smily-base/utils/intl';
import { createChangeset } from 'ember-smily-base/utils/model';
import or from 'ember-truth-helpers/helpers/or';
import SignupCdiscountHeader from 'smily-admin-ui/components/signup/cdiscount-header';
import SignupHelper from 'smily-admin-ui/components/signup/helper';
import SignupMaevaHeader from 'smily-admin-ui/components/signup/maeva-header';
import SignupSkisetHeader from 'smily-admin-ui/components/signup/skiset-header';
import UnauthenticatedScreen from 'smily-admin-ui/components/unauthenticated-screen';
import config from 'smily-admin-ui/config/environment';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';

const BRANDED_CODES = ['CDISCOUNT', 'MAEVA', 'SKISET'];
const BRAND_HEADER_COMPONENTS: { [index: string]: AnyComponent } = {
  CDISCOUNT: SignupCdiscountHeader,
  MAEVA: SignupMaevaHeader,
  SKISET: SignupSkisetHeader,
};

interface SignupLayoutSignature {
  Args: {
    onboarding: OnboardingModel;
    isFree?: boolean;
    isBusiness?: boolean;
    isManager?: boolean;
    airbnbError?: string;
  };
}

export default class SignupLayout extends Component<SignupLayoutSignature> {
  @service intl!: IntlService;
  @service metrics!: MetricsService;
  @service router!: RouterService;

  onboardingChangeset = createChangeset(this.args.onboarding);

  get privacyUrl(): string {
    const [locale] = this.intl.primaryLocale.split('-');
    return new URL(`${locale}/privacy-policy`, config.API.HOST).toString();
  }

  get airbnbSignupUrl(): string {
    const url = new URL(config.APP.AIRBNB_SIGNUP_URL);
    const locale = getShortLocale(this.intl.primaryLocale).toLowerCase();
    const { referralCode } = this.args.onboarding;

    url.searchParams.append('locale', locale);

    if (referralCode) {
      url.searchParams.append('referralCode', referralCode);
    }

    return url.toString();
  }

  get isFrench() {
    return this.intl.primaryLocale === 'fr-fr';
  }

  get moreInfoUrl(): string {
    return this.isFrench
      ? 'https://manual.smily.com/fr/importer-mes-informations-depuis-airbnb'
      : 'https://manual.smily.com/en/import-my-information-from-airbnb';
  }

  get isBranded(): boolean {
    return (
      !!this.args.onboarding.referralCode &&
      BRANDED_CODES.includes(this.args.onboarding.referralCode)
    );
  }

  get needsExtraInfo(): boolean {
    return (
      this.isFrench &&
      !!this.args.onboarding.referralCode &&
      ['CDISCOUNT', 'MAEVA'].includes(this.args.onboarding.referralCode)
    );
  }

  get extraTopbarInfo(): string | undefined {
    return this.needsExtraInfo
      ? this.intl.t(
          `onboarding.branded_signup.${(
            this.args.onboarding.referralCode as string
          ).toLowerCase()}.reach_us_at`,
        )
      : undefined;
  }

  get extraFootnote(): string | undefined {
    return this.needsExtraInfo
      ? this.intl.t(
          `onboarding.branded_signup.${(
            this.args.onboarding.referralCode as string
          ).toLowerCase()}.reach_us_at_footnote`,
        )
      : undefined;
  }

  get brandHeaderComponent() {
    return this.isBranded
      ? BRAND_HEADER_COMPONENTS[this.args.onboarding.referralCode as string]!
      : null;
  }

  @action
  maybeAutofocus(element: HTMLElement): void {
    if (this.args.onboarding.firstName) {
      (element.querySelector('input') as HTMLElement).focus();
    }
  }

  @action
  async signup(): Promise<void> {
    this.args.onboarding.locale = getShortLocale(
      this.intl.primaryLocale,
    ).toLowerCase();

    await this.onboardingChangeset.save({
      adapterOptions: {
        isFree: this.args.isFree,
        isBusiness: this.args.isBusiness,
        isManager: this.args.isManager,
      },
    });

    this.router.transitionTo('confirm-email', {
      queryParams: {
        email: this.args.onboarding.email,
        backPath: this.router.currentRouteName,
      },
    });
  }

  <template>
    <div class='vh-100 d-flex flex-column bg-white'>
      <UnauthenticatedScreen @extraTopbarInfo={{this.extraTopbarInfo}}>
        <div class='flex-1 overflow-auto'>
          <div
            class='min-h-100 d-flex align-items-center justify-content-center p-3
              {{if
                this.isBranded
                (concat
                  "is-branded brand-" (lowercase @onboarding.referralCode)
                )
              }}
              '
          >
            <div
              class='signup-container p-3 p-lg-5 bg-white
                {{if this.isBranded "border rounded"}}'
            >
              {{#if this.isBranded}}
                <this.brandHeaderComponent
                  @brand={{lowercase @onboarding.referralCode}}
                  data-test-brand-header
                />
              {{/if}}

              {{#if @isFree}}
                <h2 class='mb-2'>
                  {{t 'onboarding.signup.old.title'}}
                </h2>

                <p>
                  {{t 'onboarding.signup.old.subtitle.1'}}
                  <br />
                  {{t 'onboarding.signup.old.subtitle.2'}}
                </p>
              {{else}}
                <div class='d-flex flex-column gap-3'>
                  <h2>
                    {{t 'onboarding.signup.title'}}
                  </h2>

                  <h3>
                    {{t 'onboarding.signup.you_are_an_owner'}}
                  </h3>

                  <div>
                    {{t 'onboarding.signup.get_set_up'}}
                  </div>

                  <div class='d-flex flex-column gap-2'>
                    <SignupHelper
                      @label={{t 'onboarding.signup.airbnb_button'}}
                    />

                    <a
                      href={{this.airbnbSignupUrl}}
                      class='btn btn-airbnb'
                      type='button'
                    >
                      {{t 'onboarding.signup.airbnb_button'}}
                    </a>

                    {{#if @airbnbError}}
                      <div class='invalid-feedback d-block'>
                        {{t (concat 'internals.errors.airbnb.' @airbnbError)}}
                      </div>
                    {{/if}}

                    <div class='text-11'>
                      {{t 'onboarding.signup.airbnb_helper'}}
                    </div>

                    <ExternalLink
                      @label={{t 'onboarding.signup.more_info'}}
                      @url={{this.moreInfoUrl}}
                    />
                  </div>
                </div>

                <div class='d-flex align-items-center my-1'>
                  <hr class='w-100' />

                  <div class='mx-2'>
                    {{t 'common.or'}}
                  </div>

                  <hr class='w-100' />
                </div>
              {{/if}}

              <Form
                @model={{this.onboardingChangeset}}
                @gap={{2}}
                @onSubmit={{this.signup}}
                as |F|
              >
                {{#unless @isFree}}
                  <div>
                    {{t 'onboarding.signup.have_no_listings'}}
                  </div>
                {{/unless}}

                <div class='d-flex flex-column gap-0'>
                  {{#if (or @isBusiness @isManager)}}
                    <F.Input
                      @property='companyName'
                      @autocomplete='organization'
                    />

                    <div class='d-flex gap-2'>
                      {{#if this.isFrench}}
                        <F.Input
                          @property='lastName'
                          @autocomplete='family-name'
                        />

                        <F.Input
                          @property='firstName'
                          @autocomplete='given-name'
                          @required={{true}}
                        />
                      {{else}}
                        <F.Input
                          @property='firstName'
                          @autocomplete='given-name'
                          @required={{true}}
                        />

                        <F.Input
                          @property='lastName'
                          @autocomplete='family-name'
                        />
                      {{/if}}
                    </div>
                  {{else}}
                    <F.Input
                      @property='firstName'
                      @autocomplete='given-name'
                      @required={{true}}
                    />
                  {{/if}}

                  <F.Phone
                    {{didInsert this.maybeAutofocus}}
                    @property='phoneField'
                    @required={{true}}
                  />

                  <F.Input
                    @property='email'
                    @type='email'
                    @autocomplete='email'
                    @required={{true}}
                  />

                  <div>
                    <BsTooltip>
                      {{t 'onboarding.signup.password_requirements'}}
                    </BsTooltip>

                    <F.Input
                      @property='password'
                      @type='password'
                      @autocomplete='current-password'
                      @required={{true}}
                    />
                  </div>
                </div>

                <SignupHelper @label={{t 'onboarding.signup.get_started'}} />

                <div class='d-flex flex-column gap-3'>
                  <F.Submit @label={{t 'onboarding.signup.get_started'}} />

                  <hr class='w-100' />

                  <div
                    class='d-flex align-items-center justify-content-between'
                  >
                    <div>
                      {{t 'onboarding.signup.already_have_account'}}
                    </div>

                    <LinkTo
                      @route='auth.login'
                      class='btn btn-secondary btn-sm'
                    >
                      {{t 'onboarding.signup.login'}}
                    </LinkTo>
                  </div>
                </div>
              </Form>

              {{#if this.extraFootnote}}
                <div class='mt-3 text-11'>
                  {{this.extraFootnote}}
                </div>
              {{/if}}
            </div>
          </div>
        </div>
      </UnauthenticatedScreen>
    </div>
  </template>
}
