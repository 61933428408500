import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class AccountCompanyAbility extends Ability<'account-company'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'account-company');
  }
}
