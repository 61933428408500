import type { Snapshot } from '@ember-data/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';

interface Options {
  new?: boolean;
  relationships?: boolean;
  null?: boolean;
  undefined?: boolean;
}

export default function trim(
  snapshot: Snapshot,
  json: ResponseWithData<ResourceObject>,
  options?: Options,
) {
  const opts = {
    new: true,
    relationships: false,
    null: true,
    undefined: true,
    ...options,
  };

  if (!opts.new || (snapshot as unknown as { isNew: boolean }).isNew) {
    json.data.attributes = Object.fromEntries(
      Object.entries(json.data.attributes!).filter(([_, value]) => {
        if (opts.null && value === null) {
          return false;
        }

        if (opts.undefined && value === undefined) {
          return false;
        }

        return true;
      }),
    );

    if (opts.relationships) {
      json.data.relationships = Object.fromEntries(
        Object.entries(json.data.relationships || {}).filter(([_, value]) => {
          if (opts.null && value.data === null) {
            return false;
          }

          if (opts.undefined && value.data === undefined) {
            return false;
          }

          return true;
        }),
      );
    }
  }
}
