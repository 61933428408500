import { defineProperty } from '@ember/object';
import { getOwner } from '@ember/application';
import { camelize } from '@ember/string';
import config from 'smily-admin-ui/config/environment';

import type ReleaseAbility from 'smily-admin-ui/abilities/release';
import type SessionService from 'smily-admin-ui/services/session-service';

export default function generateReleaseAbilities(
  context: ReleaseAbility,
): void {
  const session = getOwner(context).lookup('service:session') as SessionService;
  const releases = [
    ...(session.enabledReleaseToggles || []),
    ...config.APP.releaseToggles,
  ];

  releases.forEach((release) => {
    const abilityName = camelize(`can-access-${release}`);
    defineProperty(context, abilityName, {
      value: true,
      enumerable: true,
    });
  });
}
