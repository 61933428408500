import type { ModelFor } from 'ember-smily-base/utils/routing';
import type TasksTaskRoute from 'smily-admin-ui/routes/tasks/task';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class TasksTaskMangeRoute extends BaseRoute {
  checkAbilities = true;
  requiredRouteAbility = 'access tasks task manage route';

  model() {
    return this.modelFor('tasks.task') as ModelFor<TasksTaskRoute>;
  }
}
