import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

// these routes should be accessible without the feature flag on as they are
// links from other screens
const PASSTHROUGH = ['rentals.rental.taxes'];

export default class RentalsRentalRoute extends BaseRoute {
  @service abilities!: AbilitiesService;
  @service coreLinks!: CoreLinksService;
  @service store!: StoreService;

  get title() {
    return this.currentModel?.name ?? super.title;
  }

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (
      !PASSTHROUGH.includes(transition.to.name) &&
      !this.abilities.can('access rentals feature')
    ) {
      transition.abort();
      const { rental_id: id } = transition.to.find(
        ({ name }) => name === 'rentals.rental',
      )!.params;
      this.coreLinks.redirect('rentalsEdit', id);
    }
  }

  model({ rental_id: id }: { rental_id: string }) {
    return this.store.findRecord('rental', id, {
      ...this.store.generateQuery('rental'),
      reload: true,
    });
  }
}
