import type IntlService from 'ember-intl/services/intl';
import type { SelectOption } from 'ember-smily-base/utils/select';
import type { TimeUnitDisplay } from 'smily-admin-ui/utils/intl';
import type { TimeUnit } from 'smily-admin-ui/utils/tasks';
import { TIME_UNITS } from 'smily-admin-ui/utils/tasks';

export default function getTimeUnitOptions(
  intl: IntlService,
  count: string,
  unitDisplay: TimeUnitDisplay = 'long',
) {
  const safeCount = count || '10';

  return TIME_UNITS.map((unit) => {
    const unitWithCount = intl.formatNumber(Number(safeCount), {
      style: 'unit',
      unit,
      unitDisplay,
    });
    const label = unitWithCount.replace(safeCount, '').trim();

    return {
      label,
      value: unit,
    } as SelectOption<TimeUnit>;
  });
}
