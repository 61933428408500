import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import t from 'ember-intl/helpers/t';
import BulkEditEditComponentsBase from 'ember-smily-base/components/bulk-edit/edit-components/-base';
import Select from 'ember-smily-base/components/select/simple';
import { getPropertyLabel } from 'ember-smily-base/utils/intl';
import type { SelectOption } from 'ember-smily-base/utils/select';
import { allowNumbersOnly } from 'smily-admin-ui/utils/dom';
import { serializeUnitDataToMinutes } from 'smily-admin-ui/utils/intl';
import type { TimeUnit } from 'smily-admin-ui/utils/tasks';
import { getTimeUnitOptions } from 'smily-admin-ui/utils/tasks';

export default class BulkEditEditComponentsDuration extends BulkEditEditComponentsBase<number> {
  @tracked value = '';
  @tracked unit: SelectOption<TimeUnit> = this.options.at(0)!;

  get options() {
    return getTimeUnitOptions(this.intl, `${this.args.value ?? ''}`);
  }

  @action
  setValue(event: Event) {
    allowNumbersOnly(event);

    const { value } = event.target as HTMLInputElement;
    const minutes = serializeUnitDataToMinutes(value, this.unit.value);

    this.value = value;
    this.args.changeValue(minutes);
  }

  @action
  setUnit(option: SelectOption<TimeUnit>) {
    const minutes = serializeUnitDataToMinutes(this.value, option.value);

    this.unit = option;
    this.args.changeValue(minutes);
  }

  <template>
    <div class='smily-form-field'>
      <div class='form-label-container'>
        <label for={{this.guid}} class='form-label'>
          {{getPropertyLabel this.intl @modelName @propertyName}}
        </label>
      </div>

      <div class='form-control-container d-flex gap-2'>
        <input
          {{on 'input' this.setValue}}
          value={{this.value}}
          id={{this.guid}}
          class='flex-1 form-control'
          required
          placeholder={{this.placeholder}}
        />

        <div class='flex-1'>
          <Select
            @value={{this.unit}}
            @options={{this.options}}
            @displayField='label'
            @ariaLabel={{t 'internals.fields.unit'}}
            @onChange={{this.setUnit}}
          />
        </div>
      </div>
    </div>
  </template>
}
