import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type TaskManagementAttachmentModel from 'smily-admin-ui/models/task-management-attachment';
import type { TaskManagementAttachmentFile } from 'smily-admin-ui/models/task-management-attachment';
import type TaskManagementTaskModel from 'smily-admin-ui/models/task-management-task';
import type UserModel from 'smily-admin-ui/models/user';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class TaskManagementTaskCommentModel extends BaseModel {
  @attr content!: string;
  @attr('date', { readOnly: true }) createdAt!: Date;
  @attr('array', { readOnly: true, defaultValue: () => [] })
  newAttachmentFiles!: TaskManagementAttachmentFile[];

  @belongsTo('task-management-task', { async: false, inverse: 'comments' })
  task!: TaskManagementTaskModel;
  @belongsTo('user', { async: false, inverse: null })
  createdBy!: UserModel;

  @hasMany('task-management-attachment', {
    readOnly: true,
    async: false,
    inverse: null,
  })
  attachments!: SyncHasMany<TaskManagementAttachmentModel>;

  get attachmentSrc() {
    return this.attachments.at(0)?.file.original;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'task-management-task-comment': TaskManagementTaskCommentModel;
  }
}
