import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type MediaService from 'ember-responsive';
import { isMobile } from 'ember-smily-base/utils/application';
import type RentalModel from 'smily-admin-ui/models/rental';

interface BookingsFormTaxesSectionSubtitleSignature {
  Args: {
    rental: RentalModel;
  };
}

export default class BookingsFormTaxesSectionSubtitle extends Component<BookingsFormTaxesSectionSubtitleSignature> {
  @service media!: MediaService;

  <template>
    <div class='smily-form-section-subtitle'>
      {{#if (isMobile this.media)}}
        {{t 'bookings.taxes_subtitle.text_mobile'}}
      {{else}}
        {{t 'bookings.taxes_subtitle.text'}}

        <LinkTo @route='rentals.rental.taxes' @model={{@rental}}>
          {{t 'bookings.taxes_subtitle.link'}}
        </LinkTo>
      {{/if}}
    </div>
  </template>
}
