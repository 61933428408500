import { attr, belongsTo } from '@ember-data/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type TaxTemplateModel from 'smily-admin-ui/models/tax-template';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class BookingTaxModel extends BaseModel {
  @attr name!: string;
  @attr('number') amount!: number;
  @attr('number') percentage!: number;
  @attr included!: boolean;
  @attr('date', { readOnly: true }) createdAt!: Date;
  @attr('date', { readOnly: true }) updatedAt!: Date;

  @belongsTo('booking', { async: false, inverse: 'bookingTaxes' })
  booking!: BookingModel;
  @belongsTo('tax-template', { async: false, inverse: null })
  taxTemplate!: TaxTemplateModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-tax': BookingTaxModel;
  }
}
