import { tracked } from '@glimmer/tracking';

export type ReviewCriterionSerialized = {
  rating?: number;
  comment?: string;
};

export default class ReviewCriterion {
  @tracked rating;
  @tracked comment;

  constructor(rating?: number, comment?: string) {
    this.rating = rating;
    this.comment = comment;
  }

  get hasRating(): boolean {
    return this.rating !== undefined;
  }

  serialize(): ReviewCriterionSerialized {
    const { rating, comment } = this;
    return { rating, comment };
  }
}
