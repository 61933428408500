import type { TOC } from '@ember/component/template-only';
import BsProgress from 'ember-bootstrap/components/bs-progress';
import t from 'ember-intl/helpers/t';
import RentalsCompletionLabel from 'smily-admin-ui/components/rentals/completion-label';
import type RentalModel from 'smily-admin-ui/models/rental';

const RentalsProgress: TOC<{
  Element: HTMLDivElement;
  Args: {
    rental: RentalModel;
  };
}> = <template>
  <div class='w-100 d-flex flex-column gap-1' ...attributes>
    <RentalsCompletionLabel @rental={{@rental}} class='font-weight-semibold' />

    <BsProgress class='smily-progress' as |progress|>
      <progress.bar
        @value={{@rental.completionPercentage}}
        class={{if @rental.isListed 'bg-success' 'bg-warning'}}
      />
    </BsProgress>

    {{#unless @rental.isListed}}
      <div class='text-11'>
        {{t 'rentals.completion_promo.listing'}}
      </div>
    {{/unless}}
  </div>
</template>;

export default RentalsProgress;
