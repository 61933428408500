import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import IconText from 'ember-smily-base/components/icon-text';
import LoadingButton from 'ember-smily-base/components/loading-button';

const InboxDraftWithAi: TOC<{
  Args: {
    draftWithAi: () => void;
    compact?: boolean;
  };
}> = <template>
  <LoadingButton
    @action={{@draftWithAi}}
    @iconOnly={{@compact}}
    class='btn btn-secondary
      {{if @compact "px-0 border-0 text-20 lh-1" "btn-sm"}}'
  >
    <IconText
      @icon='sparkles'
      @iconClass='text-purple'
      @text={{t 'reviews.draft_with_ai'}}
      @hideText={{@compact}}
    />
  </LoadingButton>
</template>;

export default InboxDraftWithAi;
