import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import Component from '@glimmer/component';

interface OnboardingWizardLayoutSignature {
  Element: HTMLDivElement;
  Blocks: {
    content: [];
    helper: [];
  };
}

export default class OnboardingWizardLayout extends Component<OnboardingWizardLayoutSignature> {
  setHeight(element: HTMLElement): void {
    const content = element.closest<HTMLElement>('.main-content');

    if (content && content.clientHeight > element.clientHeight) {
      element.style.height = '100%';
    }
  }

  <template>
    <div
      {{didInsert this.setHeight}}
      class='d-flex flex-column flex-lg-row'
      ...attributes
    >
      <div class='flex-lg-1 bg-white p-3 p-lg-6'>
        {{yield to='content'}}
      </div>

      <aside class='flex-lg-1 p-3 p-lg-6'>
        {{yield to='helper'}}
      </aside>
    </div>
  </template>
}
