import { defineProperty } from '@ember/object';
import { getOwner } from '@ember/application';
import { camelize } from '@ember/string';

import type FeatureAbility from 'smily-admin-ui/abilities/feature';
import type SessionService from 'smily-admin-ui/services/session-service';

export default function generateFeatureAbilities(
  context: FeatureAbility,
): void {
  const session = getOwner(context).lookup('service:session') as SessionService;

  if (!session.features?.length) {
    return;
  }

  session.features.forEach((feature) => {
    const abilityName = camelize(`can-access-${feature}`);
    defineProperty(context, abilityName, {
      value: true,
      enumerable: true,
    });
  });
}
