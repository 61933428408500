import { getOwner } from '@ember/application';
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import media from 'ember-responsive/helpers/media';
import Card from 'ember-smily-base/components/card';
import List from 'ember-smily-base/components/list';
import LoadingState from 'ember-smily-base/components/loading-state';
import type { Owner } from 'ember-smily-base/utils/application';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import gt from 'ember-truth-helpers/helpers/gt';
import BookingsBookingItemDesktop from 'smily-admin-ui/components/bookings/booking-item/desktop';
import BookingsBookingItemMobile from 'smily-admin-ui/components/bookings/booking-item/mobile';
import IconsSmily from 'smily-admin-ui/components/icons/smily';
import type BookingsIndexController from 'smily-admin-ui/controllers/bookings';

interface DashboardBookingListSignature {
  Element: HTMLDivElement;
  Args: {
    infiniteQuery: InfiniteQuery<'booking'>;
    title: string;
    showMoreTitle: string;
    showMoreFilter: Record<string, unknown>;
    emptyText: string;
  };
  Blocks: { default: [] };
}

export default class DashboardBookingList extends Component<DashboardBookingListSignature> {
  get showMoreFilter(): Record<string, unknown> {
    const owner = getOwner(this) as Owner;
    const bookingsIndexController = owner.lookup(
      'controller:bookings.index',
    ) as BookingsIndexController;
    const possibleParams: string[] = bookingsIndexController.queryParams;

    return {
      ...Object.fromEntries(possibleParams.map((param) => [param, null])),
      ...this.args.showMoreFilter,
    };
  }

  <template>
    <Card ...attributes>
      <div class='card-header smily-header'>
        <h4>
          {{@title}}

          {{#if @infiniteQuery.isInitialized}}
            <span class='text-tertiary'>
              ({{@infiniteQuery.total}})
            </span>
          {{/if}}
        </h4>
      </div>

      <div class='card-body p-0'>
        <LoadingState
          @model={{@infiniteQuery}}
          @emptyText={{@emptyText}}
          @emptyIconComponent={{IconsSmily}}
          class='h-100 p-3'
        >
          <List @linked={{true}} class='h-100 overflow-y-auto w-100' as |Item|>
            {{#each @infiniteQuery.options as |booking|}}
              <Item>
                <LinkTo
                  @route='bookings.booking'
                  @model={{booking.id}}
                  class='link-unstyled'
                >
                  {{#if (media 'isDesktop')}}
                    <BookingsBookingItemDesktop @booking={{booking}} />
                  {{else}}
                    <BookingsBookingItemMobile @booking={{booking}} />
                  {{/if}}
                </LinkTo>
              </Item>
            {{/each}}

            {{#if (gt @infiniteQuery.total 3)}}
              <Item>
                <LinkTo
                  @route='bookings.index'
                  @query={{this.showMoreFilter}}
                  class='d-flex justify-content-center text-primary'
                >
                  {{@showMoreTitle}}
                </LinkTo>
              </Item>
            {{/if}}
          </List>
        </LoadingState>
      </div>
    </Card>
  </template>
}
