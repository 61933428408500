import { attr, hasMany } from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type ReviewsHostAutomationCommentModel from 'smily-admin-ui/models/reviews-host-automation-comment';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ReviewsHostAutomationModel extends BaseModel {
  @attr privateComment?: string;
  @attr('date', { readOnly: true }) createdAt!: Date;
  @attr('date', { readOnly: true }) updatedAt!: Date;
  @attr('boolean', { readOnly: true }) enabled?: boolean;

  @hasMany('reviews-host-automation-comment', {
    async: false,
    inverse: 'automation',
  })
  comments!: SyncHasMany<ReviewsHostAutomationCommentModel>;

  enable = modelAction<ReviewsHostAutomationModel>('enable', {
    method: 'PATCH',
    pushToStore: true,
  });
  disable = modelAction<ReviewsHostAutomationModel>('disable', {
    method: 'PATCH',
    pushToStore: true,
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reviews-host-automation': ReviewsHostAutomationModel;
  }
}
