import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type MediaService from 'ember-responsive';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { CompoundFilterValue } from 'smily-admin-ui/utils/store';

type RatingQueryMap = {
  [index in RatingType]: CompoundFilterValue | CompoundFilterValue[];
};

export type FilterParams = {
  rating?: RatingType;
  rental?: string;
  source?: string;
};

const RATING_MAP: RatingQueryMap = {
  good: {
    op: 'gteq',
    value: '4',
  },
  average: [
    {
      op: 'gteq',
      value: '2',
    },
    {
      op: 'lt',
      value: '4',
    },
  ],
  bad: {
    op: 'lt',
    value: '2',
  },
};

export const RATING_LABELS = ['good', 'average', 'bad'] as const;
export type RatingType = (typeof RATING_LABELS)[number];

export default abstract class ReviewsBaseIndexRoute extends Route {
  @service coreLinks!: CoreLinksService;
  @service media!: MediaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: StoreService;

  queryParams = {
    tab: { refreshModel: true },
    page: { refreshModel: true },
    rating: { refreshModel: true },
    rental: { refreshModel: true },
    source: { refreshModel: true },
  };

  abstract isHostReview: boolean;

  beforeModel(transition: Transition) {
    return this.store.loadModelsById([
      { modelName: 'rental', ids: transition.to.queryParams.rental },
      { modelName: 'source', ids: transition.to.queryParams.source },
    ]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract model(...args: any[]): any;

  applyFilters(
    originalFilter: Record<string, unknown> = {},
    { rating, rental, source }: FilterParams,
  ): Record<string, unknown> {
    const filter = { ...originalFilter };

    if (rating) {
      const fieldName = this.isHostReview ? 'average_rating' : 'rating';
      filter[fieldName] = RATING_MAP[rating];
    }

    if (rental) {
      filter.rental = rental;
    }

    if (source) {
      filter.source = source;
    }

    return filter;
  }

  releaseNotAccessible() {
    this.coreLinks.redirect('reviews');
  }
}
