import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type RentalsRentalRoute from 'smily-admin-ui/routes/rentals/rental';
import RentalsRentalViewRoute from 'smily-admin-ui/routes/rentals/rental/-view';

export default class RentalsRentalLegalRoute extends RentalsRentalViewRoute {
  @service store!: StoreService;

  async model() {
    const rental = this.modelFor(
      'rentals.rental',
    ) as ModelFor<RentalsRentalRoute>;
    const rentalAgreements = await this.store.query('rental-agreement', {
      filter: {
        rentalAgreementable: `rental:${rental.id}`,
      },
    });

    return { rental, rentalAgreement: rentalAgreements.firstObject! };
  }
}
