import type StoreService from 'ember-smily-base/services/store';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

// TODO replace any type with JSONAPI Response
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function populateRadioOptions(payload: any) {
  const attrs = payload.data.attributes;
  const rentalsEligibility = attrs.allRentals
    ? 'all_rentals'
    : 'specific_rentals';
  const minimumRequirements = attrs.minimumSpend
    ? 'minimum_spend'
    : attrs.minimumNightsBooked
      ? 'minimum_nights_booked'
      : 'none';
  const usageLimit = attrs.numberOfTimes ? 'number_of_times' : 'none';
  const discountValueType = attrs.fixedAmount ? 'fixed_amount' : 'percentage';

  return {
    ...payload,
    data: {
      ...payload.data,
      attributes: {
        ...payload.data.attributes,
        discountValueType,
        rentalsEligibility,
        minimumRequirements,
        usageLimit,
      },
    },
  };
}

export default class DiscountCodeSerializer extends ApplicationSerializer<'discount-code'> {
  normalizeSingleResponse(
    store: StoreService,
    primaryModelClass: object,
    payload: object,
    id: string,
    requestType: string,
  ): object {
    const normalized = super.normalizeSingleResponse(
      store,
      // @ts-expect-error to avoid hassle
      primaryModelClass,
      payload,
      id,
      requestType,
    );

    return populateRadioOptions(normalized);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'discount-code': DiscountCodeSerializer;
  }
}
