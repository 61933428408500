import { service } from '@ember/service';
import { attr, belongsTo } from '@ember-data/model';
import type ApplicationModel from 'smily-admin-ui/models/application';
import type BookingModel from 'smily-admin-ui/models/booking';
import UserModel from 'smily-admin-ui/models/user';
import type SessionService from 'smily-admin-ui/services/session-service';
import { BaseModel } from 'smily-admin-ui/utils/model';

export function isByUser(
  creator: UserModel | ApplicationModel,
): creator is UserModel {
  return creator instanceof UserModel;
}

export default class BookingCommentModel extends BaseModel {
  @service session!: SessionService;

  @attr content?: string;
  @attr('boolean') visibleForCleaners?: boolean;
  @attr('string', { readOnly: true }) creatorName?: string;
  @attr('date', { readOnly: true }) createdAt!: Date;

  @belongsTo('booking', { async: false, inverse: 'comments' })
  booking!: BookingModel;

  @belongsTo('booking-comment-creator', {
    async: false,
    polymorphic: true,
    inverse: null,
    readOnly: true,
  })
  creator!: ApplicationModel | UserModel;

  get isOwned() {
    return isByUser(this.creator) && this.creator === this.session.user;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-comment': BookingCommentModel;
  }
}
