import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class RentalAgreementModel extends BaseModel {
  @attr body?: string;
  @attr('object') bodyTranslations!: Record<string, string | null>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rental-agreement': RentalAgreementModel;
  }
}
