import { attr, belongsTo } from '@ember-data/model';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import type AccountingPaymentModel from 'smily-admin-ui/models/accounting-payment';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class AccountingInvoiceModel extends BaseModel {
  @attr status?: string;
  @attr countryCode?: CountryCode;
  @attr('boolean') euCountry?: boolean;
  @attr issueNumber?: string;
  @attr('date') issuedAt?: Date;
  @attr paymentStatus?: string;
  @attr('number', { readOnly: true }) subtotal?: number;
  @attr('number', { readOnly: true }) taxAmount?: number;
  @attr('number') taxPercentage?: number;
  @attr('number', { readOnly: true }) total?: number;
  @attr currency?: string;
  @attr('object') customer?: {
    fullname?: string;
    address?: string;
    zip?: string;
    email?: string;
    country?: string;
    countryCode?: CountryCode;
  };
  @attr('object') provider?: {
    fullname?: string;
    address?: string;
    zip?: string;
    city?: string;
    country?: string;
    siret?: string;
    ape?: string;
    rcs?: string;
    capital?: string;
    vatNumber?: string;
    duns?: string;
    email?: string;
  };

  @belongsTo('accounting-payment', { async: false, inverse: 'invoice' })
  accountingPayment!: AccountingPaymentModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-invoice': AccountingInvoiceModel;
  }
}
