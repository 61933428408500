import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type MediaService from 'ember-responsive';
import { isMobile } from 'ember-smily-base/utils/application';
import { generateReleaseAbilities } from 'smily-admin-ui/utils/ability';

export default class ReleaseAbility extends Ability<'release'> {
  @service media!: MediaService;

  constructor(owner: object) {
    super(owner);

    generateReleaseAbilities(this);
  }

  declare canAccessBookingsManagement: boolean;

  get canAccessBookingsManagementOnMobile() {
    return this.canAccessBookingsManagement || isMobile(this.media);
  }
}
