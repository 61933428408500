import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type SessionService from 'smily-admin-ui/services/session-service';
import { BaseRoute, disableRouteForMobile } from 'smily-admin-ui/utils/routing';

export default class ProfileRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;
  @service session!: SessionService;

  requiredRouteAbility = 'access profile route';

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (this.session.account?.isSmily) {
      return;
    }

    if (disableRouteForMobile(this, transition)) {
      return;
    }

    this.coreLinks.redirect('profile');
  }
}
