import { BaseRoute as SmilyBaseRoute } from 'ember-smily-base/utils/routing';
import { checkAbilities } from 'smily-admin-ui/utils/routing';

import type { Transition } from 'ember-smily-base/utils/routing';

export default abstract class BaseRoute extends SmilyBaseRoute {
  checkAbilities = false;

  declare requiredRouteAbility?: string;

  beforeModel(transition: Transition): void {
    if (this.checkAbilities || this.requiredRouteAbility) {
      checkAbilities(this, transition);
    }
  }
}
