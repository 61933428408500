import BookingModel from 'smily-admin-ui/models/booking';
import RentalModel from 'smily-admin-ui/models/rental';
import type { EventSource } from 'smily-admin-ui/utils/tasks';

export function isBookingSource(source: EventSource): source is BookingModel {
  return source instanceof BookingModel;
}

export function isRentalSource(source: EventSource): source is RentalModel {
  return source instanceof RentalModel;
}
