import Oauth2 from 'torii/providers/oauth2-bearer';
import { configurable } from 'torii/configuration';
import { run } from '@ember/runloop';
import EmberError from '@ember/error';
import promiseTimeout from 'smily-admin-ui/utils/promise-timeout';
import { close as closeSentry } from 'smily-admin-ui/utils/sentry';
import config from 'smily-admin-ui/config/environment';
import { resolve, reject } from 'rsvp';
import fetch from 'fetch';

class AuthError extends EmberError {
  constructor() {
    super(...arguments);
    this.name = 'AuthError';
  }
}

/**
 * This class implements authentication against BookingSync
 * using the OAuth2 authorization flow in an invisible iframe.
 * @class
 */

export default Oauth2.extend({
  name: 'bookingsync-oauth2',

  responseParams: ['access_token', 'state'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

  baseUrl: configurable('authUrl', () =>
    new URL('oauth/authorize', config.API.HOST || location.href).toString(),
  ),

  tokenValidationUrl: configurable('tokenValidationUrl', () => {
    return new URL(
      'oauth/token/info',
      config.API.HOST || location.href,
    ).toString();
  }),

  redirectUri: configurable('redirectUri', () =>
    new URL('/torii/redirect.html', location.origin).toString(),
  ),

  fetch(data) {
    const expiresInSeconds = data.authorizationToken.info.expires_in_seconds;

    if (expiresInSeconds && expiresInSeconds > 0) {
      return resolve(data);
    }

    return reject();
  },

  open(options) {
    const iframeContainer = document.createElement('div');
    iframeContainer.className = 'torii-iframe-placeholder';
    iframeContainer.style.display = 'none';

    run(() => document.querySelector('body').appendChild(iframeContainer));

    return this.openIframe(options)
      .then((data) => this._retrieveTokenInfo(data))
      .finally(() => run(() => iframeContainer.remove()));
  },

  async openIframe(options) {
    const { name, redirectUri, responseParams } = this;

    responseParams.push('error');

    const url = new URL(this.buildUrl()).toString();

    const iframePromise = this.popup.open(url, responseParams, options);
    const timeoutMeta = {
      name: 'Auth iframe timeout',
      url,
      responseParams,
      options,
    };

    let authData;

    try {
      authData = await promiseTimeout(iframePromise, timeoutMeta);
    } catch (error) {
      window.open(url);
      throw error;
    }

    const missingResponseParams = [];

    if (authData.error) {
      throw new AuthError(authData.error);
    }

    const errorIndex = responseParams.indexOf('error');
    errorIndex && responseParams.splice(errorIndex, 1);

    responseParams.forEach((param) => {
      if (authData[param] === undefined) {
        missingResponseParams.push(param);
      }
    });

    if (missingResponseParams.length) {
      throw new Error(
        `The response from the provider is missing these required response params: ${missingResponseParams.join(
          ', ',
        )}`,
      );
    }

    return {
      authorizationToken: authData,
      provider: name,
      redirectUri,
    };
  },

  async _retrieveTokenInfo(data) {
    const token = data.authorizationToken.access_token;
    const url = new URL(this.tokenValidationUrl);

    url.search = new URLSearchParams({
      access_token: encodeURIComponent(token),
    });

    const response = await fetch(url);

    if (response?.status === 401) {
      const loginUrl = new URL(
        config.API.LOGIN_PAGE_PATH,
        config.API.HOST,
      ).toString();

      closeSentry();
      window.location.replace(loginUrl);

      throw new AuthError(
        'Token response unauthorized. Redirecting to login screen.',
      );
    }

    const info = await response.json();

    if (!info.application?.uid) {
      throw new AuthError(
        'Unexpected response from token validation server. The `application.uid` field may be missing.',
      );
    } else if (info.application.uid !== this.clientId) {
      throw new AuthError(
        "Access token is invalid or has been tempered with. You may be subject to a 'confused deputy' attack.",
      );
    }

    const updatedData = { ...data };

    updatedData.authorizationToken.info = info;

    return data;
  },
});
