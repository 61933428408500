import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StoreService from 'ember-smily-base/services/store';
import { LocalDatetime, parseDateString } from 'ember-smily-base/utils/date';
import type { Transition } from 'ember-smily-base/utils/routing';
import type { QueryParam } from 'smily-admin-ui/controllers/bookings/new';
import type RentalModel from 'smily-admin-ui/models/rental';
import type CacheService from 'smily-admin-ui/services/cache';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type SessionService from 'smily-admin-ui/services/session-service';
import { BaseRoute, getReturnParams } from 'smily-admin-ui/utils/routing';

export default class BookingsNewRoute extends BaseRoute {
  @service abilities!: AbilitiesService;
  @service cache!: CacheService;
  @service coreLinks!: CoreLinksService;
  @service session!: SessionService;
  @service store!: StoreService;

  checkAbilities = true;
  requiredReleaseAbility = 'access bookings management route';

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);

    await this.cache.getPreferencesPayment();
  }

  async model(queryParams: Record<QueryParam, string>) {
    const rentalQuery = {
      ...this.store.generateQuery('rental', 'newBookingRelatedData'),
      page: { number: 1, size: 1 },
    };

    if (queryParams.rental) {
      Object.assign(rentalQuery, {
        filter: {
          id: queryParams.rental,
        },
      });
    }

    const rentals = (await this.store.queryRecords(
      'rental',
      rentalQuery,
    )) as unknown as [RentalModel];

    const startAt = queryParams.start
      ? new LocalDatetime({ date: parseDateString(queryParams.start) })
      : undefined;
    const endAt = queryParams.end
      ? new LocalDatetime({ date: parseDateString(queryParams.end) })
      : undefined;

    return this.store.createRecord('booking', {
      rental: rentals.at(0),
      currency: 'EUR',
      expectedInvoiceIssueDate: new Date(),
      startAt,
      endAt,
      damageDepositCollectionTime:
        this.cache.preferencesPayment.damageDepositCollectionTime,
      damageDepositCollectionMethod:
        this.cache.preferencesPayment.damageDepositCollectionMethod,
    });
  }

  releaseNotAccessible(transition: Transition): void {
    window.history.pushState(window.history.state, document.title);

    this.coreLinks.redirect('bookingsNew', {
      ...transition.to.queryParams,
      ...getReturnParams(transition),
    });
  }
}
