import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class ClientPhoneAbility extends Ability<'client-phone'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'client-phone');
  }

  canEdit!: boolean;

  get canSetAsPrimary(): boolean {
    return !!this.canEdit && !this.model?.primary && !this.model?.isNew;
  }
}
