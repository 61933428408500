import { action } from '@ember/object';
import Component from '@glimmer/component';
import CopyButton from 'ember-cli-clipboard/components/copy-button';
import Icon from 'ember-smily-base/components/icon';
import { notifyCopySuccessful } from 'smily-admin-ui/utils/intl';

interface CopyButtonCompactSignature {
  Element: HTMLButtonElement;
  Args: {
    text: string | undefined;
    container?: string | HTMLElement;
  };
}

export default class CopyButtonCompact extends Component<CopyButtonCompactSignature> {
  @action
  copySuccessful() {
    notifyCopySuccessful(this);
  }

  <template>
    <CopyButton
      @text={{@text}}
      @container={{@container}}
      @onSuccess={{this.copySuccessful}}
      class='btn btn-link'
      ...attributes
    >
      <Icon @icon='copy' @style='far' />
    </CopyButton>
  </template>
}
