import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class BookingAbility extends Ability<'booking'> {
  @service abilities!: AbilitiesService;

  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'booking');
  }

  canRequestPayment() {
    return !!this.model.paymentUrl && this.abilities.can('create payment');
  }

  canViewPayments() {
    return this.model.finalPrice !== undefined;
  }
}
