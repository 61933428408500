import { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type RentalModel from 'smily-admin-ui/models/rental';
import type TaskManagementAttachmentModel from 'smily-admin-ui/models/task-management-attachment';
import type { TaskManagementAttachmentFile } from 'smily-admin-ui/models/task-management-attachment';
import type {
  Category,
  Priority,
} from 'smily-admin-ui/models/task-management-task';
import type UserModel from 'smily-admin-ui/models/user';
import { BaseModel } from 'smily-admin-ui/utils/model';

export const AUTOMATIC_STATUSES = ['active', 'inactive'] as const;
export const STATUSES = [...AUTOMATIC_STATUSES, 'manual'] as const;
export const AUTOMATION_EVENTS = ['check_in', 'check_out'] as const;
export const DEFAULT_TASK_AUTOMATION: TaskDueAtAutomation = {
  delayInMinutes: -60,
  eventSourceType: 'Booking',
  event: 'check_in',
};

export type Status = (typeof STATUSES)[number];
export type AutomationEvent = (typeof AUTOMATION_EVENTS)[number];
export interface TaskDueAtAutomation {
  delayInMinutes: number;
  eventSourceType: 'Booking';
  event: AutomationEvent;
}

export default class TaskManagementTemplateModel extends BaseModel {
  @attr name!: string;
  @attr description?: string;
  @attr({ defaultValue: 'active' }) status!: Status;
  @attr taskName?: string;
  @attr taskDescription?: string;
  @attr taskCategory?: Category;
  @attr taskPriority?: Priority;
  @attr taskExpectedDurationInMinutes?: number;
  @attr('boolean') taskHasIssues?: boolean;
  @attr('boolean') applyToExistingBookings?: boolean;
  @attr('object', {
    defaultValue: () =>
      DEFAULT_TASK_AUTOMATION as unknown as Record<string, unknown>,
  })
  taskDueAtAutomation!: TaskDueAtAutomation;
  @attr('checklist', { defaultValue: () => '' }) checklist?: string;
  @attr('array', { readOnly: true, defaultValue: () => [] })
  newAttachmentFiles!: TaskManagementAttachmentFile[];

  @belongsTo('user', { async: false, inverse: null }) taskAssignee?: UserModel;

  @hasMany('rental', { async: false, inverse: null })
  rentals!: SyncHasMany<RentalModel>;

  @hasMany('task-management-attachment', {
    readOnly: true,
    async: false,
    inverse: null,
  })
  attachments!: SyncHasMany<TaskManagementAttachmentModel>;

  @tracked _isAutomatic?: boolean;

  static compoundFields = ['isActive', 'isAutomatic'];

  /* eslint-disable ember/no-get, ember/classic-decorator-no-classic-methods */
  get isAutomatic() {
    return (
      this.get('_isAutomatic') ??
      AUTOMATIC_STATUSES.includes(
        this.get('status') as (typeof AUTOMATIC_STATUSES)[number],
      )
    );
  }

  set isAutomatic(value: boolean) {
    this._isAutomatic = value;
    this.set('status', value ? 'active' : 'manual');
  }

  get isActive() {
    return this.get('status') === 'active';
  }

  set isActive(value: boolean) {
    if (value) {
      this.set('status', 'active');
    } else {
      this.set('status', 'inactive');
    }
  }
  /* eslint-enable ember/no-get, ember/classic-decorator-no-classic-methods */

  get isManual() {
    return this.status === 'manual';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'task-management-template': TaskManagementTemplateModel;
  }
}
