import { array } from '@ember/helper';
import { service } from '@ember/service';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import type { SelectOption } from 'ember-smily-base/utils/select';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingIdentityDocument from 'smily-admin-ui/components/onboarding/identity-document';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingIdentity extends TemplatesOnboardingTask {
  @service intl!: IntlService;

  actionName = 'completeIdentityDocuments';

  get attributes(): string[] {
    const baseAttrs = [
      'primaryIdentityProof',
      'primaryIdentityProofType',
      'secondaryIdentityProof',
      'secondaryIdentityProofType',
    ];

    if (this.onboardingChangeset.get('primaryIdentityProofType') === 'idCard') {
      baseAttrs.push('primaryIdentityProofBack');
    }

    if (
      this.onboardingChangeset.get('secondaryIdentityProofType') === 'idCard'
    ) {
      baseAttrs.push('secondaryIdentityProofBack');
    }

    return baseAttrs;
  }

  get primaryIdentityProofOptions(): SelectOption<string>[] {
    return ['idCard', 'passport'].map((option) => this.generateOption(option));
  }

  get secondaryIdentityProofOptions(): SelectOption<string>[] {
    return ['idCard', 'passport', 'other'].map((option) =>
      this.generateOption(option),
    );
  }

  generateOption(value: string): SelectOption<string> {
    return {
      label: this.intl.t(`onboarding.identity.identity_proof_options.${value}`),
      value,
    };
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.identity.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @gap={{3}}
            @onSubmit={{this.save}}
            as |F|
          >
            <div class='d-flex flex-column gap-2'>
              <h5>
                {{t 'onboarding.identity.proof_of_identity.title'}}
              </h5>

              <div>
                {{t 'onboarding.identity.proof_of_identity.helper'}}
              </div>

              <div>
                {{t 'onboarding.identity.identity_helper'}}
              </div>
            </div>

            <OnboardingIdentityDocument
              @type='primary'
              @options={{array 'idCard' 'passport'}}
              @onboardingChangeset={{this.onboardingChangeset}}
              @form={{F}}
            />

            <div class='d-flex flex-column gap-2'>
              <h5>
                {{t 'onboarding.identity.additional_proof.title'}}
              </h5>

              <div>
                {{t 'onboarding.identity.additional_proof.helper'}}
              </div>

              <div>
                {{t 'onboarding.identity.identity_helper'}}
              </div>
            </div>

            <OnboardingIdentityDocument
              @type='secondary'
              @options={{array 'idCard' 'passport' 'other'}}
              @onboardingChangeset={{this.onboardingChangeset}}
              @form={{F}}
            />

            <hr class='w-100' />

            <div class='d-flex align-items-center justify-content-between'>
              <OnboardingBackToTaskList />

              <F.Submit
                @label={{t 'common.save'}}
                @disabled={{this.onboardingChangeset.isPristine}}
              />
            </div>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.identity.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.identity.helper.p1'}}
          </div>

          <div>
            {{t 'onboarding.identity.helper.p2'}}
          </div>

          <div>
            {{t 'onboarding.identity.helper.p3'}}
          </div>

          <div>
            {{t 'onboarding.identity.helper.p4'}}
          </div>

          <div class='fw-semibold'>
            {{t 'onboarding.identity.helper.example'}}
          </div>

          <img
            src='/assets/images/id-example.svg'
            alt={{t 'onboarding.identity.example_helper'}}
          />
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingIdentity);
