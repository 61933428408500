import { attr, hasMany } from '@ember-data/model';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type InboxMessageModel from 'smily-admin-ui/models/inbox-message';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class InboxAttachmentModel extends BaseModel {
  @attr declare name?: string;
  @attr('string', { readOnly: true }) declare url?: string;
  @attr declare file?: string;
  @attr('number', { readOnly: true }) declare fileSize?: number;
  @attr('string', { readOnly: true }) declare fileContentType?: string;
  @attr('date', { readOnly: true }) declare createdAt?: Date;
  @attr declare channel?: string;

  @hasMany('inbox-message', {
    async: false,
    inverse: 'attachments',
    readOnly: true,
  })
  messages!: SyncHasMany<InboxMessageModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'inbox-attachment': InboxAttachmentModel;
  }
}
