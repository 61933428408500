import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import UnauthenticatedRoute from 'smily-admin-ui/routes/-unauthenticated';

export default abstract class SignupFreeRoute extends UnauthenticatedRoute {
  @service abilities!: AbilitiesService;
  @service store!: StoreService;

  breadcrumb = null;

  model(
    _params: Record<string, unknown>,
    transition: Transition,
  ): OnboardingModel {
    const { params, queryParams } = transition.to;
    const referralCode = params.type ?? this.extractReferralCode(queryParams);

    return this.store.createRecord('onboarding', {
      phoneCountryCode: '+33',
      privacyPolicyAccepted: true,
      firstName: queryParams.name,
      email: queryParams.email,
      referralCode: referralCode && referralCode.toUpperCase(),
      termsAccepted: this.abilities.cannot('access legal terms modal release'),
      utmVisit: {
        source: queryParams.utm_source,
        medium: queryParams.utm_medium,
        campaign: queryParams.utm_campaign,
        utmId: queryParams.utm_id,
        term: queryParams.utm_term,
        content: queryParams.utm_content,
      },
    });
  }

  extractReferralCode(
    queryParams: Record<string, string | undefined>,
  ): string | undefined {
    return (
      queryParams.referralCode ??
      queryParams.referral_code ??
      queryParams['referral-code']
    );
  }
}
