import { attr, belongsTo, hasMany } from '@ember-data/model';
import { customAction } from 'ember-custom-actions';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type HostModel from 'smily-admin-ui/models/host';
import type InboxMessageModel from 'smily-admin-ui/models/inbox-message';
import type {
  Channel,
  Priority,
  Severity,
} from 'smily-admin-ui/models/inbox-message';
import type InboxParticipantModel from 'smily-admin-ui/models/inbox-participant';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type Status = 'new' | 'sent' | 'seen';
export type Category = (typeof CATEGORIES)[number];

export const CATEGORIES = ['open', 'archived', 'spam'] as const;

export default class InboxConversationModel extends BaseModel {
  @attr('string', { readOnly: true }) lastMessageSummary?: string;
  @attr('string', { defaultValue: 'email' }) defaultChannel!: Channel;
  @attr('string') subject?: string;
  @attr('string', { readOnly: true }) priority?: Priority;
  @attr('string', { readOnly: true }) severity?: Severity;

  @attr('date') closedAt?: Date;
  @attr('date') readAt?: Date;
  @attr('date', { readOnly: true }) lastMessageAt!: Date;
  @attr('date') markedAsSpamAt?: Date;

  @belongsTo('host', { async: false, inverse: null })
  assignee!: HostModel;

  @belongsTo('inbox-participant', {
    async: false,
    inverse: null,
    readOnly: true,
  })
  firstClientParticipant!: InboxParticipantModel;

  @belongsTo('inbox-message', {
    async: false,
    inverse: null,
    readOnly: true,
  })
  lastMessage!: InboxMessageModel;

  @hasMany('booking', { async: false, inverse: null, readOnly: true })
  bookings!: SyncHasMany<BookingModel>;

  @hasMany('inbox-message', { async: false, inverse: 'conversation' })
  messages!: SyncHasMany<InboxMessageModel>;

  @hasMany('inbox-participant', { async: false, inverse: 'conversation' })
  participants!: SyncHasMany<InboxParticipantModel>;

  start = customAction<InboxConversationModel>('start', {
    method: 'POST',
    pushToStore: true,
  });

  declare meta: {
    availableChannels: Channel[];
  };

  get currentBooking() {
    return (
      this.bookings.find(
        (b) =>
          b.isArrivingToday ||
          b.isLeavingToday ||
          b.isAtProperty ||
          b.isFutureBooking,
      ) ?? this.bookings.at(-1)
    );
  }

  get status(): Status {
    if (!this.read) {
      return 'new';
    } else if (this.lastMessage?.isByHost) {
      return 'sent';
    } else {
      return 'seen';
    }
  }

  get category(): Category {
    if (this.spam) {
      return 'spam';
    } else if (this.archived) {
      return 'archived';
    } else {
      return 'open';
    }
  }

  get spam(): boolean {
    return !!this.markedAsSpamAt;
  }

  set spam(value: boolean) {
    this.markedAsSpamAt = value
      ? this.markedAsSpamAt || new Date()
      : (null as unknown as undefined);
  }

  get archived(): boolean {
    return !!this.closedAt;
  }

  set archived(value: boolean) {
    this.closedAt = value
      ? this.closedAt || new Date()
      : (null as unknown as undefined);
  }

  get read(): boolean {
    return !!this.readAt;
  }

  set read(value: boolean) {
    this.readAt = value
      ? this.readAt || new Date()
      : (null as unknown as undefined);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'inbox-conversation': InboxConversationModel;
  }
}
