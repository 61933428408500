import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

export default class RentalsNewRoute extends Route {
  @service coreLinks!: CoreLinksService;

  beforeModel() {
    this.coreLinks.redirect('rentalsNew');
  }
}
