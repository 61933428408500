import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class SettingsCompanyEditRoute extends BaseRoute {
  @service router!: RouterService;

  checkAbilities = true;
  requiredRouteAbility = 'access edit settings route';

  routeNotAccessible(): void {
    this.router.transitionTo('settings.company.index');
  }
}
