import { debug } from '@ember/debug';
import type RouterService from '@ember/routing/router-service';
import { run } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { camelize } from '@ember/string';
import type AbilitiesService from 'ember-can/services/abilities';
import config from 'smily-admin-ui/config/environment';
import type SessionService from 'smily-admin-ui/services/session-service';

type CoreLinkRouteSimple = 'login' | 'logout' | 'confirmation';
export type CoreLinkRouteCallable =
  | 'bookings'
  | 'bookingsNew'
  | 'calendar'
  | 'guests'
  | 'rentals'
  | 'rentalsEdit'
  | 'rentalsNew'
  | 'inquiries'
  | 'reviews'
  | 'apps'
  | 'profile'
  | 'creditCard3DS';
type CoreLinkRoute = CoreLinkRouteSimple | CoreLinkRouteCallable;
type CoreLinkSettingsRoute =
  | 'xSettingsBilling'
  | 'xSettingsInvoices'
  | 'xSettingsGeneral'
  | 'xSettingsUsers'
  | 'xSettingsAccountsUsers'
  | 'xSettingsPayments'
  | 'xSettingsContacts'
  | 'xSettingsFees'
  | 'xSettingsTaxes'
  | 'xSettingsRentalLinks'
  | 'xSettingsRentalsTags'
  | 'xSettingsBookingsTags'
  | 'xSettingsSources'
  | 'xSettingsBookings'
  | 'xSettingsNotifications'
  | 'xSettingsPublicCalendars'
  | 'xSettingsInquiries'
  | 'xSettingsExports'
  | 'xSettingsSplitPayments';
type ID = string | number;

const {
  API: { HOST, LOGIN_PAGE_PATH, LOGOUT_PAGE_PATH, CONFIRMATION_PATH },
} = config;

function buildUrl(path: string, queryParams: Record<string, string> = {}) {
  const url = new URL(path, HOST);

  Object.entries(queryParams).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });

  return url.toString();
}

function isNotSettingsRoute(route: string): route is CoreLinkRoute {
  return !route.startsWith('x-settings.');
}

export default class CoreLinksService extends Service {
  @service abilities!: AbilitiesService;
  @service router!: RouterService;
  @service session!: SessionService;

  redirect(route: string, ...args: unknown[]): void {
    if (!route || config.environment === 'test') {
      return;
    }

    const url = isNotSettingsRoute(route)
      ? this.generateUrl(route, ...args)
      : this.generateSettingsUrl(route);

    if (!url) {
      return;
    }

    this.redirectToUrl(url);
  }

  redirectToUrl(url: string) {
    run(() => {
      debug(`The App called external redirection to: ${url}`);
      window.location.replace(url);
    });
  }

  generateUrl(route: CoreLinkRoute, ...args: unknown[]): string {
    if (this.isCallableRoute(route)) {
      // @ts-expect-error to avoid hassle
      return this[route](...args);
    }

    return this[route];
  }

  isCallableRoute(route: CoreLinkRoute): route is CoreLinkRouteCallable {
    return typeof this[route] === 'function';
  }

  generateSettingsUrl(route: string): string {
    const settingsProp = camelize(route) as CoreLinkSettingsRoute;
    const urlOrMethod = this[settingsProp];

    if (typeof urlOrMethod === 'string') {
      return buildUrl(urlOrMethod);
    } else if (typeof urlOrMethod === 'function') {
      return urlOrMethod.call(this);
    } else {
      return this.xSettingsBilling;
    }
  }

  get accountId(): string | undefined {
    return this.session.account?.id;
  }

  get rentalApiVersion() {
    return this.abilities.can('access rentals feature') ? 'v3' : 'v2';
  }

  get login(): string {
    return buildUrl(LOGIN_PAGE_PATH);
  }

  get logout(): string {
    return buildUrl(LOGOUT_PAGE_PATH);
  }

  get confirmation(): string {
    return buildUrl(CONFIRMATION_PATH);
  }

  bookings(id?: ID, queryParams?: Record<string, string>): string {
    return buildUrl(this._buildModelUrl('bookings', id, null), queryParams);
  }

  bookingsEdit(id?: ID, queryParams?: Record<string, string>): string {
    return buildUrl(
      `${this._buildModelUrl('bookings', id, null)}/edit`,
      queryParams,
    );
  }

  bookingsNew(queryParams: Record<string, string>): string {
    return buildUrl('en/bookings/new', queryParams);
  }

  calendar(): string {
    return this.bookings();
  }

  guests(id?: ID): string {
    return this._buildModelUrl('clients', id, null);
  }

  rentals(id?: ID): string {
    return this._buildModelUrl('rentals', id, this.rentalApiVersion);
  }

  rentalsEdit(id?: ID): string {
    return `${this.rentals(id)}/edit`;
  }

  rentalsNew(): string {
    return this._buildModelUrl('rentals/new');
  }

  rentalPhotos(id: ID): string {
    return `${this.rentals(id)}/photos`;
  }

  rentalDescription(id: ID): string {
    return `${this.rentals(id)}/edit?edit=name_and_descriptions`;
  }

  rentalRooms(id: ID): string {
    return `${this.rentals(id)}/edit?edit=rooms_and_guests`;
  }

  rentalAmenities(id: ID): string {
    return `${this.rentals(id)}/edit?edit=amenities`;
  }

  rentalLocation(id: ID): string {
    return `${this.rentals(id)}/edit?edit=location`;
  }

  rentalCheckinCheckout(id: ID): string {
    return `${this.rentals(id)}/edit?edit=checkin_checkout`;
  }

  rentalPreferences(id: ID): string {
    return `${this.rentals(id)}/edit?edit=preferences`;
  }

  rentalRates(id: ID): string {
    return `${this.rentals(id)}?section=rates`;
  }

  rentalLegal(id: ID): string {
    return `${this.rentals(id)}/edit?edit=legal`;
  }

  rentalTaxes(id: ID): string {
    return `${this.rentals(id)}/rentals_taxes`;
  }

  rentalRentalAgreement(id: ID): string {
    return `${this._buildPublicUrl('rentals', id)}/rental_agreement.pdf`;
  }

  inquiries(id?: ID): string {
    return this._buildModelUrl('inquiries', id, null);
  }

  reviews(): string {
    return this._buildModelUrl('reviews', undefined, 'v2');
  }

  apps(id?: ID): string {
    return this._buildModelUrl('apps', id, 'v2');
  }

  profile(): string {
    return this._buildModelUrl('profile', undefined, null);
  }

  creditCard3DS(creditCardId: string) {
    return new URL(
      `authentications/subscriptions/${creditCardId}/versioning`,
      config.APP.THREEDS_URL,
    ).toString();
  }

  xSettingsBilling = 'account';
  xSettingsInvoices = 'en/account/accounting_invoices';
  xSettingsGeneral = 'en/preferences/general_setting';
  xSettingsUsers = 'en/preferences/users';
  xSettingsAccountsUsers = 'en/preferences/accounts_users';

  xSettingsPayments(): string {
    return this._buildXSettingsUrl('payment');
  }

  xSettingsContacts(): string {
    return this._buildXSettingsUrl('contacts');
  }

  xSettingsFees(): string {
    return this._buildXSettingsUrl('fees');
  }

  xSettingsTaxes(): string {
    return this._buildXSettingsUrl('taxes');
  }

  xSettingsRentalLinks(): string {
    return this._buildModelUrl(
      'preferences/rental_link_groups',
      undefined,
      'v2',
    );
  }

  xSettingsRentalsTags(): string {
    return this._buildXSettingsUrl('rentals_tags');
  }

  xSettingsBookingsTags(): string {
    return this._buildXSettingsUrl('bookings_tags');
  }

  xSettingsSources(): string {
    return this._buildXSettingsUrl('sources');
  }

  xSettingsBookings = 'en/preferences/bookings';
  xSettingsNotifications = 'en/preferences/notifications';
  xSettingsPublicCalendars = 'en/preferences/public_calendars';

  xSettingsInquiries(): string {
    return this._buildXSettingsUrl('inquiries');
  }

  xSettingsExports = 'en/preferences/exports';

  private xSettingsSplitPayments() {
    return this._buildXSettingsUrl('split_payments');
  }

  private _buildModelUrl(
    type: string,
    id?: ID,
    apiVersion: string | null = 'v3',
  ): string {
    let url = 'en/';

    if (apiVersion) {
      url += `admin/${apiVersion}/${this.accountId}/`;
    }

    url += type;

    if (id) {
      url += `/${id}`;
    }

    return buildUrl(url);
  }

  private _buildXSettingsUrl(path: string): string {
    return this._buildModelUrl(`preferences/${path}`, undefined, 'v2');
  }

  private _buildPublicUrl(type: string, id?: ID): string {
    let url = 'en/public/';

    url += type;

    if (id) {
      url += `/${id}`;
    }

    return buildUrl(url);
  }
}
