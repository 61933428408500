import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMonths,
  differenceInWeeks,
} from 'date-fns';
import type IntlService from 'ember-intl/services/intl';
import type BookingModel from 'smily-admin-ui/models/booking';

type Unit = 'hour' | 'day' | 'week' | 'month';

function getDifference(booking: BookingModel): { diff: number; unit: Unit } {
  const now = new Date();
  const expiresAt = booking.tentativeExpiresAt as Date;
  const diffInMonths = differenceInMonths(expiresAt, now);

  if (diffInMonths > 0) {
    return { diff: diffInMonths, unit: 'month' };
  }

  const diffInWeeks = differenceInWeeks(expiresAt, now);

  if (diffInWeeks > 0) {
    return { diff: diffInWeeks, unit: 'week' };
  }

  const diffInHours = differenceInHours(expiresAt, now);

  if (diffInHours < 72) {
    return { diff: diffInHours, unit: 'hour' };
  }

  return {
    diff: differenceInCalendarDays(expiresAt, now),
    unit: 'day',
  };
}

interface BookingStatusSignature {
  Args: {
    Positional: [BookingModel];
    Named: {
      verbose?: boolean;
    };
  };
  Return: string;
}

export default class BookingStatus extends Helper<BookingStatusSignature> {
  @service intl!: IntlService;

  compute(
    [booking]: BookingStatusSignature['Args']['Positional'],
    { verbose }: BookingStatusSignature['Args']['Named'],
  ): string {
    const statusShort = this.intl.t(
      `bookings.status_options.${booking.status.toLowerCase()}`,
    );

    if (!verbose || !booking.isTentative) {
      return statusShort;
    }

    const { diff, unit } = getDifference(booking);
    const length = this.intl.formatRelative(diff, { unit });
    const expiresLabel = this.intl.t('bookings.expires', { length });

    return `${statusShort} ${expiresLabel}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'booking-status': typeof BookingStatus;
  }
}
