import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class SystemBillingDetailAbility extends Ability<'system-billing-detail'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'system-billing-detail');
  }
}
