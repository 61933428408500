import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import media from 'ember-responsive/helpers/media';
import Card from 'ember-smily-base/components/card';
import Form from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import TopbarActions from 'ember-smily-base/components/topbar/actions';
import { createChangeset } from 'ember-smily-base/utils/model';
import type { SelectOption } from 'ember-smily-base/utils/select';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
import TasksDeleteTemplate from 'smily-admin-ui/components/tasks/delete-template';
import TasksTemplateFormTaskSections from 'smily-admin-ui/components/tasks/template/form-task-sections';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';
import { AUTOMATION_EVENTS } from 'smily-admin-ui/models/task-management-template';
import {
  AutomationChangeset,
  getTimeUnitOptions,
} from 'smily-admin-ui/utils/tasks';

const FormSubmitButton: typeof LoadingButton<true> = LoadingButton;

interface TasksTemplateFormSignature {
  Args: {
    template: TaskManagementTemplateModel;
    isNew?: boolean;
  };
}

export default class TasksTemplateForm extends Component<TasksTemplateFormSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;

  changeset = createChangeset(this.args.template);
  automationChangeset = new AutomationChangeset(
    this.changeset,
    'taskDueAtAutomation',
  );

  get disableSubmit() {
    return this.args.isNew ? false : this.changeset.isPristine;
  }

  get automationOptions() {
    return [true, false].map(
      (value) =>
        ({
          value,
          label: this.intl.t(`tasks.template_automation_options.${value}`),
        }) as SelectOption<boolean>,
    );
  }

  get timeUnitOptions() {
    return getTimeUnitOptions(this.intl, this.automationChangeset.value);
  }

  get automationBeforeOrAfterOptions() {
    return ['before', 'after'].map((value) => ({
      value,
      label: this.intl.t(`tasks.before_or_after_options.${value}`),
    }));
  }

  get automationEventOptions() {
    return AUTOMATION_EVENTS.map((value) => ({
      value,
      label: this.intl.t(`tasks.automation_event_options.${value}`),
    }));
  }

  save = task({ drop: true }, async () => {
    await this.changeset.save();

    this.router.transitionTo('tasks.templates.index');
    this.args.template.unloadRecord();
    const notificationKey = this.args.isNew
      ? 'tasks.template_form.template_created'
      : 'tasks.template_form.template_updated';
    this.notify.success(this.intl.t(notificationKey));
  });

  <template>
    <TopbarActions>
      <FormSubmitButton
        @label={{t 'common.save'}}
        @isFormSubmit={{true}}
        @isSubmittingForm={{this.save.isRunning}}
        @disabled={{this.disableSubmit}}
        form='template-form'
      />
    </TopbarActions>

    <Form
      @model={{this.changeset}}
      @onSubmit={{perform this.save}}
      id='template-form'
      class='p-3'
      as |F|
    >
      <div class='gap-3 d-flex flex-column flex-lg-row'>
        <div class='flex-8 d-flex flex-column gap-3'>
          <Card>
            <div class='card-body'>
              <List @large={{true}} @sticky={{true}} @style='light' as |Item|>
                <Item>
                  <F.Section
                    @icon='sticky-note'
                    @title={{t 'tasks.template_form.template'}}
                    @subtitle={{t 'tasks.template_form.template_subtitle'}}
                  >
                    <F.Input
                      @property='name'
                      @placeholder={{t 'tasks.task_form.name_placeholder'}}
                      @required={{true}}
                    />

                    <F.Textarea
                      @property='description'
                      @placeholder={{t
                        'tasks.template_form.description_placeholder'
                      }}
                      @required={{true}}
                    />
                  </F.Section>
                </Item>

                <TasksTemplateFormTaskSections
                  @form={{F}}
                  @item={{Item}}
                  @changeset={{this.changeset}}
                  @isTemplate={{true}}
                />
              </List>
            </div>
          </Card>

          {{#if (and (not @isNew) (media 'isSmallScreen'))}}
            <TasksDeleteTemplate @template={{@template}} />
          {{/if}}
        </div>

        <div class='flex-5 d-flex flex-column gap-3'>
          <Card>
            <div class='card-body pb-0'>
              <F.Section
                @icon='cog'
                @title={{t 'tasks.template_form.automation_title'}}
                @subtitle={{t 'tasks.template_form.automation_subtitle'}}
                @vertical={{true}}
              >
                <F.Radio
                  @property='isAutomatic'
                  @label={{t 'tasks.template_form.automation_title'}}
                  @options={{this.automationOptions}}
                  as |Radio|
                >
                  <List
                    @large={{true}}
                    @sticky={{true}}
                    @style='light'
                    as |Item|
                  >
                    <Item>
                      <Radio @index={{0}} />
                    </Item>

                    {{#if (eq this.changeset.isAutomatic true)}}
                      <Item>
                        <div class='ps-3'>
                          <List
                            @large={{true}}
                            @sticky={{true}}
                            @style='light'
                            as |SectionItem|
                          >
                            <SectionItem>
                              <F.SelectInfiniteMultiple
                                @property='rentals'
                                @modelName='rental'
                                @searchField='name'
                                @loose={{true}}
                                @placeholder={{t
                                  'tasks.template_form.rentals_placeholder'
                                }}
                                @helperText={{t
                                  'tasks.template_form.rentals_helper_text'
                                }}
                              />
                            </SectionItem>

                            <SectionItem>
                              <F.Section
                                @icon='clock'
                                @title={{t
                                  'tasks.template_form.due_date_title'
                                }}
                                @subtitle={{t
                                  'tasks.template_form.due_date_subtitle'
                                }}
                                @vertical={{true}}
                              >
                                <div
                                  {{didUpdate
                                    this.automationChangeset.changeAutomation
                                    this.automationChangeset.value
                                    this.automationChangeset.unit
                                    this.automationChangeset.beforeOrAfter
                                    this.automationChangeset.event
                                  }}
                                  class='d-flex flex-column gap-1'
                                >
                                  <div
                                    class='d-flex flex-column flex-lg-row align-items-lg-end gap-1 gap-lg-2'
                                  >
                                    <F.Input
                                      @model={{this.automationChangeset}}
                                      @property='value'
                                      @label={{t
                                        'tasks.template_form.due_date_trigger_label_1'
                                      }}
                                      @placeholder={{t
                                        'tasks.task_form.time_value_placeholder'
                                      }}
                                      @required={{true}}
                                      class='flex-1'
                                    />

                                    <F.Select
                                      @model={{this.automationChangeset}}
                                      @property='unit'
                                      @options={{this.timeUnitOptions}}
                                      @renderLabel={{false}}
                                      @required={{true}}
                                      class='flex-1'
                                    />
                                  </div>

                                  <div
                                    class='d-flex flex-column flex-lg-row align-items-lg-end gap-2'
                                  >
                                    <F.Select
                                      @model={{this.automationChangeset}}
                                      @property='beforeOrAfter'
                                      @label={{t
                                        'tasks.template_form.due_date_trigger_label_2'
                                      }}
                                      @options={{this.automationBeforeOrAfterOptions}}
                                      @required={{true}}
                                      class='flex-1'
                                    />

                                    <F.Select
                                      @model={{this.automationChangeset}}
                                      @property='event'
                                      @options={{this.automationEventOptions}}
                                      @renderLabel={{false}}
                                      @required={{true}}
                                      class='flex-1'
                                    />
                                  </div>
                                </div>
                              </F.Section>
                            </SectionItem>

                            <SectionItem>
                              <F.Toggle
                                @property='isActive'
                                @label={{t
                                  'tasks.template_form.automation_is_live_label'
                                }}
                              />

                              <div class='fst-italic'>
                                {{t
                                  'tasks.template_form.automation_is_live_description'
                                }}
                              </div>
                            </SectionItem>

                            {{#if @template.isNew}}
                              <SectionItem>
                                <F.Toggle @property='applyToExistingBookings' />

                                <div class='fst-italic'>
                                  {{t
                                    'tasks.template_form.apply_to_existing_bookings_description'
                                  }}
                                </div>
                              </SectionItem>
                            {{/if}}
                          </List>
                        </div>
                      </Item>
                    {{/if}}

                    <Item>
                      <Radio @index={{1}} />
                    </Item>

                    <Item>
                      {{t 'tasks.template_form.automation_description'}}
                    </Item>
                  </List>
                </F.Radio>
              </F.Section>
            </div>
          </Card>

          {{#if (and (not @isNew) (media 'isDesktop'))}}
            <TasksDeleteTemplate @template={{@template}} />
          {{/if}}
        </div>
      </div>
    </Form>
  </template>
}
