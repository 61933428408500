import type RouteInfo from '@ember/routing/-private/route-info';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

export default class RentalsRentalEditRoute extends Route {
  @service coreLinks!: CoreLinksService;

  beforeModel(transition: Transition) {
    const { rental_id: id } = (transition.to.parent as RouteInfo).params;
    this.coreLinks.redirect('rentalsEdit', id);
  }
}
