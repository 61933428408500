import type { TOC } from '@ember/component/template-only';
import formatNumber from 'ember-intl/helpers/format-number';
import Badge from 'ember-smily-base/components/badge';
import type TaskManagementTaskModel from 'smily-admin-ui/models/task-management-task';
import { serializeMinutesToUnitData } from 'smily-admin-ui/utils/intl';

const TasksExpectedDurationBadge: TOC<{
  Args: {
    task: TaskManagementTaskModel;
  };
}> = <template>
  {{#let
    (serializeMinutesToUnitData @task.expectedDurationInMinutes)
    as |duration|
  }}
    <Badge
      @label={{formatNumber
        duration.value
        style='unit'
        unit=duration.unit
        unitDisplay='narrow'
      }}
      @color='body'
    />
  {{/let}}
</template>;

export default TasksExpectedDurationBadge;
