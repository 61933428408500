import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Calendly from 'ember-smily-base/components/calendly';
import Icon from 'ember-smily-base/components/icon';
import List from 'ember-smily-base/components/list';
import type { AvailableLocaleLong } from 'ember-smily-base/utils/intl/dictionary';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import config from 'smily-admin-ui/config/environment';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type SessionService from 'smily-admin-ui/services/session-service';

const {
  calendly: { bookACallUrl },
} = config;

interface CalendlyPrefillOptions {
  name: string;
  email?: string;
  customAnswers?: {
    a1: string;
  };
}

class TemplatesOnboardingBookACall extends Component {
  @service intl!: IntlService;
  @service session!: SessionService;

  get calendlyUrl(): string {
    return bookACallUrl[this.intl.primaryLocale as AvailableLocaleLong];
  }

  get prefill(): CalendlyPrefillOptions {
    const { firstName, lastName, email, phone, phoneCountryCode } = this.session
      .onboarding as OnboardingModel;

    // firstName and lastName separately don't seem to work despite the docs stating so
    const name = `${firstName} ${lastName}`.trim();
    const a1 = `${phoneCountryCode}${phone}`;

    return { name, email, customAnswers: { a1 } };
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3 h-100'>
          <h2>
            {{t 'onboarding.book_a_call.title'}}
          </h2>

          <List @large={{true}} @sticky={{true}} class='flex-1' as |Item|>
            <Item class='flex-1'>
              <Calendly
                @url={{this.calendlyUrl}}
                @prefill={{this.prefill}}
                class='h-100'
              />
            </Item>

            <Item class='d-flex align-items-center justify-content-between'>
              <LinkTo @route='onboarding.services' class='btn-link'>
                <Icon @icon='arrow-left' />

                {{t 'common.back'}}
              </LinkTo>
            </Item>
          </List>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.book_a_call.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.book_a_call.helper.p1'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingBookACall);
