import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type MediaService from 'ember-responsive';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import { isMobile } from 'ember-smily-base/utils/application';
import AccountSwitcher from 'smily-admin-ui/components/account-switcher';
import SidebarMobileMenuSwitchAccountTrigger from 'smily-admin-ui/components/sidebar/mobile-menu/switch-account-trigger';
import UnauthenticatedScreen from 'smily-admin-ui/components/unauthenticated-screen';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';

class TemplatesAccountSuspended extends Component {
  @service cache!: CacheService;
  @service media!: MediaService;
  @service session!: SessionService;

  <template>
    <div class='vh-100 d-flex flex-column'>
      <UnauthenticatedScreen>
        <div class='h-0 flex-1 p-3'>
          <Card class='h-100'>
            <div
              class='card-body d-flex align-items-center justify-content-center'
            >
              <div
                class='unauthenticated-section-container d-flex flex-column gap-3'
              >
                <h2 class='text-lg-28'>
                  {{t 'settings.billing.account_suspended_title'}}
                </h2>

                <div class='d-flex flex-column gap-3 text-lg-18'>
                  <div>
                    {{t 'settings.billing.account_suspended_description'}}
                  </div>

                  {{#if (isMobile this.media)}}
                    <div>
                      {{t 'settings.billing.account_suspended_helper_mobile'}}
                    </div>
                  {{else}}
                    <div>
                      {{t
                        'settings.billing.account_suspended_helper_desktop.text'
                      }}

                      <LinkTo @route='x-settings.billing' class='fw-semibold'>
                        {{t
                          'settings.billing.account_suspended_helper_desktop.link'
                        }}
                      </LinkTo>
                    </div>
                  {{/if}}
                </div>

                <hr class='w-100' />

                <div class='d-flex gap-3'>
                  {{#if this.cache.hasMultipleAccounts}}
                    <div>
                      <AccountSwitcher
                        @triggerClass='p-0 border-0 bg-white'
                        @triggerComponent={{component
                          SidebarMobileMenuSwitchAccountTrigger
                          linkClass='text-lg-18 fw-semibold'
                        }}
                        as |account|
                      >
                        <div class='ps-2 text-body text-truncate'>
                          {{account.businessName}}
                        </div>
                      </AccountSwitcher>
                    </div>
                  {{/if}}

                  <LinkTo @route='auth.logout' class='text-lg-18 fw-semibold'>
                    {{t 'breadcrumbs.auth.logout'}}
                  </LinkTo>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </UnauthenticatedScreen>
    </div>
  </template>
}

export default RouteTemplate(TemplatesAccountSuspended);
