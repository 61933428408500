import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { ModelKeys } from 'ember-smily-base/utils/model';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class TasksTemplatesDuplicateRoute extends BaseRoute {
  @service store!: StoreService;

  async model({
    task_management_template_id: id,
  }: {
    task_management_template_id: string;
  }) {
    const source = await this.store.findRecord(
      'task-management-template',
      id,
      this.store.generateQuery('task-management-template'),
    );

    const attributes = {} as Record<
      ModelKeys<TaskManagementTemplateModel>,
      unknown
    >;

    // @ts-expect-error wrong model typing
    source.eachAttribute((attr: ModelKeys<TaskManagementTemplateModel>) => {
      if (attr === 'name') {
        attributes.name = this.intl.t('tasks.template_form.duplicated_name', {
          name: source.name,
        });
      } else {
        attributes[attr] = source[attr];
      }
    });

    return this.store.createRecord('task-management-template', {
      ...attributes,
      newAttachmentFiles: source.attachments.map(({ file }) => file),
      newAttachments: source.attachments.map(
        ({ file: { original } }) => original,
      ),
      taskAssignee: source.taskAssignee,
      rentals: source.rentals,
    });
  }
}
