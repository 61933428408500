import Controller from '@ember/controller';
import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import type InboxController from 'smily-admin-ui/controllers/inbox';
import type { QueryParam } from 'smily-admin-ui/controllers/inbox';

export default abstract class InboxChildController extends Controller {
  @controller('inbox') inboxController!: InboxController;

  get page() {
    return this.inboxController.page;
  }

  get status() {
    return this.inboxController.status;
  }

  get read() {
    return this.inboxController.read;
  }

  get assignee() {
    return this.inboxController.assignee;
  }

  get client() {
    return this.inboxController.client;
  }

  get stage() {
    return this.inboxController.stage;
  }

  get source() {
    return this.inboxController.source;
  }

  get bookingStatus() {
    return this.inboxController.bookingStatus;
  }

  get rental() {
    return this.inboxController.rental;
  }

  get bookingTag() {
    return this.inboxController.bookingTag;
  }

  get rentalTag() {
    return this.inboxController.rentalTag;
  }

  get priority() {
    return this.inboxController.priority;
  }

  get severity() {
    return this.inboxController.severity;
  }

  get sort() {
    return this.inboxController.sort;
  }

  @action
  changeQueryParam(key: QueryParam, value: string | undefined): void {
    this.inboxController.changeQueryParam(key, value);
  }

  @action
  changePage(page: number) {
    this.inboxController.changePage(page);
  }
}
