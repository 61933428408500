import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import can from 'ember-can/helpers/can';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import RouteTemplate from 'ember-route-template';
import AnnotatedCard from 'ember-smily-base/components/annotated-card';
import Form from 'ember-smily-base/components/form';
import TopbarAbove from 'ember-smily-base/components/topbar/above';
import type StoreService from 'ember-smily-base/services/store';
import type { LanguageCode } from 'ember-smily-base/utils/intl';
import { getLanguages, getLongLocale } from 'ember-smily-base/utils/intl';
import type { Error } from 'ember-smily-base/utils/json-api';
import {
  clearAllFeedback,
  createChangeset,
  handleErrors,
} from 'ember-smily-base/utils/model';
import type { SelectOption } from 'ember-smily-base/utils/select';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';
import OnboardingCurrencyBanner from 'smily-admin-ui/components/onboarding/currency-banner';
import type UserModel from 'smily-admin-ui/models/user';
import type SessionService from 'smily-admin-ui/services/session-service';

const ALLOWED_LOCALES = ['EN', 'FR'];

class TemplatesProfile extends Component {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service session!: SessionService;
  @service store!: StoreService;

  @tracked changePasswordChangeset = createChangeset(this.user);

  personalInformationChangeset = createChangeset(
    this.session.onboarding || this.user,
  );
  preferredLanguageChangeset = createChangeset(this.user);

  get user(): UserModel {
    return this.session.user as UserModel;
  }

  get localeOptions(): SelectOption<LanguageCode>[] {
    const allLocales = getLanguages(this.intl.primaryLocale);

    return allLocales.filter(({ value }) => ALLOWED_LOCALES.includes(value));
  }

  @action
  async savePersonalInformationChangeset(): Promise<void> {
    await this.personalInformationChangeset.save();

    this.notify.success(
      this.intl.t('user.notifications.personal_info_updated'),
    );
  }

  @action
  async changePassword(): Promise<void> {
    const payload = {
      data: {
        id: this.user.id,
        type: 'users',
        attributes: {
          'old-password': this.changePasswordChangeset.get('oldPassword'),
          'new-password': this.changePasswordChangeset.get('newPassword'),
          'new-password-confirmation': this.changePasswordChangeset.get(
            'newPasswordConfirmation',
          ),
        },
      },
    };

    clearAllFeedback(guidFor(this.user));

    try {
      await this.user.changePassword(payload);

      this.changePasswordChangeset = createChangeset(this.user);
      this.notify.success(this.intl.t('user.notifications.password_updated'));
    } catch (error) {
      // const serializer = this.store.serializerFor('user' as never);
      const { intl, notify } = this;
      const typedError = error as {
        errors: Error[];
      };

      handleErrors(this.user, typedError, {
        intl,
        notify,
        // serializer,
      });

      const isNotValidationError = typedError.errors.find(
        ({ status }) => Number(status) !== 422,
      );

      if (isNotValidationError) {
        this.notify.error(this.intl.t('internals.errors.default'));
        throw error;
      }
    }
  }

  @action
  async savePreferredLanguageChangeset(): Promise<void> {
    await this.preferredLanguageChangeset.save();

    this.intl.setLocale(
      getLongLocale(this.preferredLanguageChangeset.get('preferredLocale')),
    );
    this.notify.success(
      this.intl.t('user.notifications.preferred_language_updated'),
    );
  }

  <template>
    <TopbarAbove>
      {{#if (can 'display currency banner in onboarding')}}
        <OnboardingCurrencyBanner />
      {{/if}}
    </TopbarAbove>

    <div class='p-3 d-flex flex-column gap-3'>
      <AnnotatedCard @title={{t 'user.personal_information'}}>
        <Form
          @model={{this.personalInformationChangeset}}
          @onSubmit={{this.savePersonalInformationChangeset}}
          as |F|
        >
          <div class='d-flex flex-column flex-lg-row gap-3'>
            <F.Input
              @property='firstName'
              @autocomplete='given-name'
              class='flex-1'
            />

            <F.Input
              @property='lastName'
              @autocomplete='family-name'
              class='flex-1'
            />
          </div>

          <F.Input @property='email' @autocomplete='email' />

          <F.File @property='profilePictureFile' />

          <F.Submit
            @label={{t 'common.save_changes'}}
            @disabled={{not (or this.personalInformationChangeset.isDirty)}}
            class='align-self-end w-100 w-lg-auto'
          />
        </Form>
      </AnnotatedCard>

      <AnnotatedCard @title={{t 'user.change_password'}}>
        <Form
          @model={{this.changePasswordChangeset}}
          @onSubmit={{this.changePassword}}
          @disablePrompt={{true}}
          as |F|
        >
          <F.Input
            @type='password'
            @property='oldPassword'
            @required={{true}}
            @autocomplete='current-password'
          />

          <F.Input
            @type='password'
            @property='newPassword'
            @required={{true}}
            @autocomplete='new-password'
          />

          <F.Input
            @type='password'
            @property='newPasswordConfirmation'
            @required={{true}}
            @autocomplete='new-password'
          />

          <F.Submit
            disabled={{or
              (not this.changePasswordChangeset.oldPassword)
              (not this.changePasswordChangeset.newPassword)
              (not this.changePasswordChangeset.newPasswordConfirmation)
            }}
            class='align-self-end w-100 w-lg-auto'
          >
            {{t 'common.save_changes'}}
          </F.Submit>
        </Form>
      </AnnotatedCard>

      <AnnotatedCard @title={{t 'user.preferred_language'}}>
        <Form
          @model={{this.preferredLanguageChangeset}}
          @onSubmit={{this.savePreferredLanguageChangeset}}
          as |F|
        >
          <F.Select
            @property='preferredLocale'
            @options={{this.localeOptions}}
            data-test-preferred-locale
          />

          <F.Submit
            disabled={{not this.preferredLanguageChangeset.isDirty}}
            class='align-self-end w-100 w-lg-auto'
            data-test-save-preferred-language
          >
            {{t 'common.save_changes'}}
          </F.Submit>
        </Form>
      </AnnotatedCard>
    </div>
  </template>
}

export default RouteTemplate(TemplatesProfile);
