import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';

const SidebarHeaderAccountSwitcherTrigger: TOC<{
  Element: HTMLDivElement;
  Args: {
    businessName: string;
    singleAccount?: boolean;
  };
}> = <template>
  <div class='account-switcher-icon'>
    <Icon @icon='smily' @style='fak' class='text-24' />
  </div>

  <div class='d-flex flex-column flex-1 align-items-start'>
    <div>
      {{@businessName}}
    </div>

    {{#unless @singleAccount}}
      <div
        class='position-relative smily-custom-trigger smily-select-like text-10 pe-4'
      >
        {{t 'common.switch_account'}}

        <div class='smily-select-icon'>
          <Icon @icon='chevron-down' />
        </div>
      </div>
    {{/unless}}
  </div>
</template>;

export default SidebarHeaderAccountSwitcherTrigger;
