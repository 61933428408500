import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import SignupLayout from 'smily-admin-ui/components/signup/layout';
import type SignupManagerController from 'smily-admin-ui/controllers/signup/manager';
import type SignupManagerRoute from 'smily-admin-ui/routes/signup/manager';

const TemplatesSignupManager: TOC<{
  Args: {
    model: ModelFor<SignupManagerRoute>;
    controller: SignupManagerController;
  };
}> = <template>
  <SignupLayout
    @onboarding={{@model}}
    @airbnbError={{@controller.airbnbButtonError}}
    @isManager={{true}}
  />
</template>;

export default RouteTemplate(TemplatesSignupManager);
