import { attr } from '@ember-data/model';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import { BaseModel, generateFullAddress } from 'smily-admin-ui/utils/model';

export default class SystemBillingDetailModel extends BaseModel {
  @attr fullName?: string;
  @attr city?: string;
  @attr zip?: string;
  @attr countryCode?: CountryCode;
  @attr state?: string;
  @attr address1?: string;
  @attr address2?: string;
  @attr vatNumber?: string;
  @attr email?: string;

  get fullAddress(): string {
    return generateFullAddress(
      this.address1,
      this.address2,
      this.city,
      this.zip,
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'system-billing-detail': SystemBillingDetailModel;
  }
}
