import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

type Role = 'property_owner' | 'property_manager';

export default class PaymentGatewayModel extends BaseModel {
  @attr name!: string;
  @attr role?: Role;

  get nameWithRole() {
    if (!this.role) {
      return this.name;
    }

    const roleLabel = this.intl.t(
      `finance.payment_gateway_role_options.${this.role}`,
    );

    return `${this.name} (${roleLabel})`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-gateway': PaymentGatewayModel;
  }
}
