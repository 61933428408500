import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class AccountAbility extends Ability<'account'> {
  @service abilities!: AbilitiesService;
  @service session!: SessionService;

  get account() {
    return this.session.account!;
  }

  get canAccessApp() {
    return this.account.status !== 'suspended';
  }

  get canAccessChannelImport() {
    return this.session.channelImportInProgress;
  }

  get canAccessCompany() {
    return !!this.account.accountCompany;
  }

  get canAccessUpgradeToSmily() {
    return this.account.isFree && !this.account.onboardingInProgress;
  }

  get canSeeSuspensionInfo() {
    return (
      this.session.role !== 'cleaner' &&
      this.abilities.can('access outstanding payments banner release')
    );
  }
}
