import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ensure from 'ember-smily-base/helpers/ensure';
import type StoreService from 'ember-smily-base/services/store';
import type { CalculatePosition } from 'ember-smily-base/utils/dropdown';
import { positionSelectContent } from 'ember-smily-base/utils/dropdown';
import AccountSwitcher from 'smily-admin-ui/components/account-switcher';
import SidebarHeaderAccountSwitcherTrigger from 'smily-admin-ui/components/sidebar/header/account-switcher-trigger';
import type AccountModel from 'smily-admin-ui/models/account';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';

function positionAccountSwitcher(...args: Parameters<CalculatePosition>) {
  const result = positionSelectContent(...args);

  result.style.top = result.style.top! - 5;

  return result;
}

interface SidebarHeaderSignature {
  Element: HTMLElement;
  Args: {
    // not used, but we need it for correct typing
    appName: string;
  };
}

export default class SidebarHeader extends Component<SidebarHeaderSignature> {
  @service cache!: CacheService;
  @service session!: SessionService;
  @service store!: StoreService;

  @tracked showSwitcher = false;

  get accountSwitcherQuery() {
    return {
      ...this.store.generateQuery('account', 'accountSwitcher'),
      filter: {
        id: {
          op: 'not_eq',
          value: this.session.account!.id,
        },
      },
    };
  }

  @action
  switchAccount(account: AccountModel | undefined) {
    this.session.switchAccount.perform(account!.id);
  }

  <template>
    <div class='account-switcher'>
      {{#if this.cache.hasMultipleAccounts}}
        <AccountSwitcher
          @triggerClass='account-switcher-toggle smily-custom-trigger'
          @positionAccountSwitcher={{positionAccountSwitcher}}
          @triggerComponent={{component
            SidebarHeaderAccountSwitcherTrigger
            businessName=this.session.account.businessName
          }}
          as |account|
        >
          <div class='account-switcher-item'>
            <div class='mw-100 text-truncate'>
              {{account.businessName}}
            </div>
          </div>
        </AccountSwitcher>
      {{else}}
        <div class='account-switcher-toggle' data-test-single-account>
          <SidebarHeaderAccountSwitcherTrigger
            @businessName={{ensure this.session.account.businessName}}
            @singleAccount={{true}}
          />
        </div>
      {{/if}}
    </div>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sidebar::Header': typeof SidebarHeader;
  }
}
