import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import SignupLayout from 'smily-admin-ui/components/signup/layout';
import type SignupCobrandedSignupController from 'smily-admin-ui/controllers/signup/cobranded-signup';
import type SignupCobrandedSignupRoute from 'smily-admin-ui/routes/signup/cobranded-signup';

const TemplatesSignupCobrandedSignup: TOC<{
  Args: {
    model: ModelFor<SignupCobrandedSignupRoute>;
    controller: SignupCobrandedSignupController;
  };
}> = <template>
  <SignupLayout
    @onboarding={{@model}}
    @airbnbError={{@controller.airbnbButtonError}}
  />
</template>;

export default RouteTemplate(TemplatesSignupCobrandedSignup);
