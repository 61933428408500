import Transform from '@ember-data/serializer/transform';
import type { LanguageCode } from 'ember-smily-base/utils/intl';

export default class LanguageTransform extends Transform {
  deserialize(serialized?: string): LanguageCode | undefined {
    if (!serialized) {
      return undefined;
    }

    return serialized.toUpperCase() as LanguageCode;
  }

  serialize(deserialized?: LanguageCode): string | undefined {
    return deserialized ? deserialized.toLowerCase() : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    language: LanguageCode;
  }
}
