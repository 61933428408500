import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { checkAbilities } from 'smily-admin-ui/utils/routing';

import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type RouterService from '@ember/routing/router-service';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type { CoreLinkRouteCallable } from 'smily-admin-ui/services/core-links';

export default abstract class FeatureIndexRoute extends Route {
  @service coreLinks!: CoreLinksService;
  @service router!: RouterService;
  @service session!: SessionService;

  abstract feature: string;
  coreLink?: CoreLinkRouteCallable;
  hasRailsCounterpart = true;

  get requiredFeatureAbility(): string {
    return `access ${this.feature} feature`;
  }

  get requiredRouteAbility(): string {
    return `access ${this.feature} route`;
  }

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'auth.login');
    checkAbilities(this, transition);
  }

  featureNotAccessible() {
    if (!this.hasRailsCounterpart) {
      this.router.transitionTo('index');
      return;
    }

    this.coreLinks.redirect(this.coreLink ?? this.feature);
  }
}
