import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { get } from 'ember-smily-base/utils/promise';
import type { ModelFor, Transition } from 'ember-smily-base/utils/routing';
import config from 'smily-admin-ui/config/environment';
import type { QueryParam } from 'smily-admin-ui/controllers/performance';
import type PerformanceBaseController from 'smily-admin-ui/controllers/performance/-base';
import type SessionService from 'smily-admin-ui/services/session-service';
import type {
  ScoreboardDataResponse,
  Subtab,
  Tab,
} from 'smily-admin-ui/utils/performance';
import { buildQuery, tabConfig } from 'smily-admin-ui/utils/performance';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

const {
  API: { HOST, NAMESPACE },
} = config;

export default abstract class PerformanceBaseRoute extends BaseRoute {
  @service session!: SessionService;
  @service store!: StoreService;

  abstract type: Tab;

  get tabs() {
    return tabConfig.filter((tab) => tab.category === this.type);
  }

  model() {
    const params = this.paramsFor('performance') as Record<
      QueryParam,
      string | undefined
    >;

    return Object.fromEntries(
      this.tabs.map(({ name }) => [
        name,
        {
          base: this.getChartData(name, params),
          compare: params.compare
            ? this.getChartData(name, params, true)
            : undefined,
        },
      ]),
    ) as Record<
      Subtab,
      {
        base: Promise<ScoreboardDataResponse>;
        compare?: Promise<ScoreboardDataResponse>;
      }
    >;
  }

  setupController(
    controller: PerformanceBaseController,
    model: ModelFor<this>,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);
    controller.setupDefaultTab();
  }

  getChartData(
    tab: Subtab,
    performanceParams: Record<QueryParam, string | undefined>,
    isCompare?: boolean,
  ) {
    const { apiType } = tabConfig.find((t) => t.name === tab)!;
    const params = buildQuery(performanceParams, isCompare);

    return get<ScoreboardDataResponse>(
      this,
      `${HOST}/${NAMESPACE}/${apiType}?${params}`,
      undefined,
      { headers: this.session.authorizationHeader },
    );
  }
}
