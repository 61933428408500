import { service } from '@ember/service';
import { subDays } from 'date-fns';
import type StoreService from 'ember-smily-base/services/store';
import { dateString } from 'ember-smily-base/utils/date';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';
import type { CompoundFilterValue } from 'smily-admin-ui/utils/store';

function buildQuery(store: StoreService, filter: Record<string, unknown>) {
  return new InfiniteQuery(store, 'booking', {
    ...store.generateQuery('booking', 'listDefault'),
    filter,
    page: { number: 1, size: 3 },
  });
}

function buildRange(onlyToday = true) {
  const date = dateString();
  const fromToday: CompoundFilterValue = {
    op: 'gteq',
    value: `${date} 00:00:00`,
  };
  const toToday: CompoundFilterValue = {
    op: 'lteq',
    value: `${date} 23:59:59`,
  };
  return onlyToday ? [fromToday, toToday] : [fromToday];
}

export default class DashboardRoute extends BaseRoute {
  @service store!: StoreService;

  checkAbilities = true;
  requiredRouteAbility = 'access dashboard route';

  model(): {
    tentatives: InfiniteQuery<'booking'>;
    paymentDue: InfiniteQuery<'booking'>;
    arrivingToday: InfiniteQuery<'booking'>;
    leavingToday: InfiniteQuery<'booking'>;
  } {
    const tentatives = buildQuery(this.store, {
      status: 'tentative',
      startAt: buildRange(false),
    });
    const paymentDue = buildQuery(this.store, {
      paymentDue: true,
      endAt: {
        op: 'gteq',
        value: dateString(subDays(new Date(), 7)),
      },
    });
    const arrivingToday = buildQuery(this.store, {
      status: 'booked',
      startAt: buildRange(),
    });
    const leavingToday = buildQuery(this.store, {
      status: 'booked',
      endAt: buildRange(),
    });

    return { tentatives, paymentDue, arrivingToday, leavingToday };
  }
}
