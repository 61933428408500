import type { TOC } from '@ember/component/template-only';
import Icon from 'ember-smily-base/components/icon';

const IconsHandHoldingEur: TOC<{
  Element: HTMLSpanElement;
}> = <template>
  <span class='nav-icon fa-layers' ...attributes>
    <Icon @icon='hand-holding' @style='far' @flip='horizontal' />

    <Icon
      @icon='euro-sign'
      @mask='circle'
      @transform='shrink-6'
      class='icon-euro-circle-in-hand'
    />
  </span>
</template>;

export default IconsHandHoldingEur;
