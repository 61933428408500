import 'ember-smily-base/transforms';
// TODO remove Intl polyfills at some point (drops Safari 13.1 support)
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en'; // Add English data
import '@formatjs/intl-relativetimeformat/locale-data/fr'; // Add French data

import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import config from 'smily-admin-ui/config/environment';
import { initialize as initSentry } from 'smily-admin-ui/utils/sentry';

interface EngineConfig {
  [index: string]: {
    dependencies: {
      [index: string]: string[];
    };
  };
}

const ENGINE_NAMES = [
  'bsa-invoices-ui-engine',
  // 'bsa-website-ui-engine',
];

initSentry(config.sentry);

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  declare engines: EngineConfig;

  constructor(properties?: Record<string, unknown>) {
    super(properties);

    this.engines = Object.fromEntries(
      ENGINE_NAMES.map((engineName) => [
        engineName,
        {
          dependencies: {
            services: ['engine-api', 'intl', 'media', 'notify', 'teleport'],
          },
        },
      ]),
    );
  }
}

loadInitializers(App, config.modulePrefix);
