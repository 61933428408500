import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { Changeset } from 'ember-smily-base/utils/model';
import type {
  AutomationEvent,
  TaskDueAtAutomation,
} from 'smily-admin-ui/models/task-management-template';
import type { BeforeOrAfter, TimeUnit } from 'smily-admin-ui/utils/tasks';
import { getAutomationFields } from 'smily-admin-ui/utils/tasks';

export default class AutomationChangeset {
  changeset: Changeset;
  automationPropName;

  constructor(changeset: Changeset, automationPropName: string) {
    this.changeset = changeset;
    this.automationPropName = automationPropName;

    this.setupAutomation();
  }

  @tracked value!: string;
  @tracked unit!: TimeUnit;
  @tracked beforeOrAfter!: BeforeOrAfter;
  @tracked event!: AutomationEvent;

  get automation() {
    return this.changeset.get(this.automationPropName) as TaskDueAtAutomation;
  }

  setupAutomation() {
    const { value, unit, beforeOrAfter, event } = getAutomationFields(
      this.automation,
    );

    this.value = value;
    this.unit = unit;
    this.beforeOrAfter = beforeOrAfter;
    this.event = event;
  }

  @action
  changeAutomation() {
    if (!this.value) {
      return;
    }

    const value = Number(this.value);
    const minutes =
      this.unit === 'day'
        ? value * 1440
        : this.unit === 'hour'
          ? value * 60
          : value;
    const { eventSourceType } = this.automation;

    if (this.beforeOrAfter === 'before') {
      this.changeset.set(this.automationPropName, {
        delayInMinutes: -minutes,
        eventSourceType,
        event: this.event,
      });
    } else {
      this.changeset.set(this.automationPropName, {
        delayInMinutes: minutes,
        eventSourceType,
        event: this.event,
      });
    }
  }
}
