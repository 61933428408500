import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'tab' | 'rating' | 'rental' | 'source';

export default class ReviewsHostIndexController extends Controller {
  queryParams = ['tab', 'page', 'rating', 'rental', 'source'];

  @tracked tab = 'all';
  @tracked page = 1;
  @tracked rating = '';
  @tracked rental = '';
  @tracked source = '';

  @action
  changeQueryParam(key: QueryParam, value: string): void {
    this.page = 1;
    this[key] = value;
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'reviews.host.index': ReviewsHostIndexController;
  }
}
