import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'status' | 'rental';

export default class DiscountsIndexController extends Controller {
  queryParams = ['page', 'status', 'rental'];

  @tracked page = 1;
  @tracked status?: string;
  @tracked rental?: string;

  @action
  changeQueryParam(key: QueryParam, value: string): void {
    this[key] = value;
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'discounts.index': DiscountsIndexController;
  }
}
