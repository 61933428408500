import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isBefore } from 'date-fns';
import type StoreService from 'ember-smily-base/services/store';
import { isMobileApp } from 'ember-smily-base/utils/application';
import { dateString, parseDateString } from 'ember-smily-base/utils/date';
import type { ModelFor, Transition } from 'ember-smily-base/utils/routing';
import type { Query } from 'ember-smily-base/utils/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { QueryParam as CalendarQueryParam } from 'smily-admin-ui/controllers/calendar';
import type CalendarBaseController from 'smily-admin-ui/controllers/calendar/-base';
import type CalendarRoute from 'smily-admin-ui/routes/calendar';
import type { SetupController } from 'smily-admin-ui/utils/routing';
import { BaseRoute } from 'smily-admin-ui/utils/routing';
import type { CompoundFilterValue } from 'smily-admin-ui/utils/store';

type CalendarFilterParam = Exclude<CalendarQueryParam, 'rental'>;

const FILTERS: CalendarFilterParam[] = [
  'destination',
  'availableOn',
  'sleeps',
  'bedrooms',
  'rentalTag',
  'rentalType',
  'amenities',
];

export default class CalendarBaseRoute extends BaseRoute {
  @service router!: RouterService;
  @service store!: StoreService;

  calendarType!: 'full' | 'planning';

  get title() {
    return this.isMobile ? undefined : super.title;
  }

  async model(_params: Record<string, unknown>, transition: Transition) {
    const filterIds = transition.to.queryParams.rental;
    const rentalQuery: Query = {
      ...this.store.generateQuery('calendar'),
      filter: this.buildFilterQuery(),
    };

    if (filterIds) {
      rentalQuery.filter!.id = filterIds;
    }

    const rentals = new InfiniteQuery(this.store, 'calendar', rentalQuery);

    await rentals.promise;

    if (
      this.calendarType === 'planning' &&
      rentals.pages > 1 &&
      rentals.lastLoadedPage === 1
    ) {
      await rentals.loadNextPage.perform();
    }

    return {
      ...(this.modelFor('calendar') as ModelFor<CalendarRoute>),
      rentals,
      query: rentalQuery,
    };
  }

  setupController(
    controller: SetupController<CalendarBaseController>,
    model: ModelFor<CalendarBaseRoute>,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    if (!controller.date) {
      controller.date = dateString();
    } else if (isBefore(parseDateString(controller.date), model.minDate)) {
      controller.date = dateString(model.minDate);
    }
  }

  get isMobile() {
    return isMobileApp || this.media.isSmallScreen;
  }

  buildFilterQuery() {
    const { queryParams } = this.router.currentRoute;
    const existingKeys = FILTERS.filter((key) => queryParams[key]);

    if (!existingKeys.length) {
      return {};
    }

    return Object.fromEntries(
      existingKeys.map((key) => {
        const value = queryParams[key];

        switch (key) {
          case 'destination':
            return ['destinations', value];
          case 'sleeps':
            return ['sleeps', value];
          case 'bedrooms':
            return ['bedroomsCount', value];
          case 'availableOn':
            return [
              key,
              {
                op: 'between',
                value,
              },
            ];
          case 'rentalTag':
            return ['rentalsTags', value];
          case 'rentalType':
            return ['rentalTypes', value];
          default:
            return [key, value];
        }
      }),
    ) as Record<string, CompoundFilterValue | string>;
  }
}
