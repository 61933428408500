import { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'smily-admin-ui/models/account';
import type AccountsUserModel from 'smily-admin-ui/models/accounts-user';
import type UserModel from 'smily-admin-ui/models/user';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class InvitationModel extends BaseModel {
  @attr fullName?: string;
  @attr email?: string;
  @attr role?: string;

  @belongsTo('account', { async: false, inverse: null })
  account!: AccountModel;

  @belongsTo('accounts-user', { async: false, inverse: null })
  accountsUser!: AccountsUserModel;

  @belongsTo('user', { async: false, inverse: null })
  user!: UserModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    invitation: InvitationModel;
  }
}
