import type StoreService from 'ember-smily-base/services/store';
import { getSrc } from 'ember-smily-base/utils/file';
import type { Changeset, SyncHasMany } from 'ember-smily-base/utils/model';
import { all } from 'rsvp';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type { Channel } from 'smily-admin-ui/models/inbox-message';

export default async function addAttachment(
  messageChangeset: Changeset,
  store: StoreService,
  event: Event,
) {
  const input = event.target as HTMLInputElement;
  const files = input.files;

  if (!files?.length) {
    return;
  }

  const srcs = await all(Array.from(files).map((file) => getSrc(file)));
  const models = Array.from(files).map((file, index) =>
    store.createRecord('inbox-attachment', {
      name: file.name,
      file: srcs.at(index)!,
      channel: messageChangeset.channel as Channel,
    }),
  );

  await all(models.map((model) => model.save()));

  messageChangeset.set('attachments', [
    ...(messageChangeset.attachments as SyncHasMany<InboxAttachmentModel>),
    ...models,
  ]);

  input.value = '';
}
