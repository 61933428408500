import { getOwner } from '@ember/application';
import { getService } from 'ember-smily-base/utils/application';

import type Route from '@ember/routing/route';

export default function refreshRoute(obj: object, routeName?: string) {
  const owner = getOwner(obj);

  if (routeName) {
    return owner.lookup<Route>(`route:${routeName}`)!.refresh();
  }

  const { currentRouteName } = getService(obj, 'router');

  return owner.lookup<Route>(`route:${currentRouteName}`)!.refresh();
}
