import { service } from '@ember/service';
import { addMonths, startOfMonth, subMonths } from 'date-fns';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CacheService from 'smily-admin-ui/services/cache';
import { FeatureIndexRoute } from 'smily-admin-ui/utils/routing';

const ALLOWED_NUMBER_OF_PAST_MONTHS = 12;
const ALLOWED_NUMBER_OF_FUTURE_MONTHS = 36;

export default class CalendarRoute extends FeatureIndexRoute {
  @service cache!: CacheService;
  @service store!: StoreService;

  feature = 'calendars';
  coreLink = 'calendar' as const;
  breadcrumbLinkable = false;

  get requiredRouteAbility(): string {
    return 'access calendar route';
  }

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    return this.store.loadModelsById([
      { modelName: 'calendar', ids: transition.to.queryParams.rental },
      { modelName: 'rentals-tag', ids: transition.to.queryParams.rentalTag },
      {
        modelName: 'amenity',
        ids: transition.to.queryParams.amenities,
        primaryKey: 'key',
      },
    ]);
  }

  async model(): Promise<{ minDate: Date; maxDate: Date }> {
    const minDate = subMonths(
      startOfMonth(new Date()),
      ALLOWED_NUMBER_OF_PAST_MONTHS,
    );
    const maxDate = addMonths(
      startOfMonth(new Date()),
      ALLOWED_NUMBER_OF_FUTURE_MONTHS - 1,
    );

    this.cache.loadFirstBooking();
    this.cache.updateSleepsBedroomsCount();
    this.cache.loadDestinations();

    return { minDate, maxDate };
  }
}
