import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import List from 'ember-smily-base/components/list';
import LoadingState from 'ember-smily-base/components/loading-state';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import MultilineText from 'ember-smily-base/components/multiline-text';
import time from 'ember-smily-base/helpers/time';
import { asString } from 'ember-smily-base/utils/template';
import or from 'ember-truth-helpers/helpers/or';
import BookingsBookingStatus from 'smily-admin-ui/components/bookings/booking-status';
import BookingsPaymentBarDamageDeposit from 'smily-admin-ui/components/bookings/payment-bar/damage-deposit';
import BookingsPaymentBarDetailed from 'smily-admin-ui/components/bookings/payment-bar/detailed';
import CalendarsDamageDepositBadge from 'smily-admin-ui/components/calendars/damage-deposit-badge';
import CalendarsStatusCircle from 'smily-admin-ui/components/calendars/status-circle';
import SourceText from 'smily-admin-ui/components/source-text';
import BookingModel from 'smily-admin-ui/models/booking';
import type RentalModel from 'smily-admin-ui/models/rental';
import { getPhotoTypeByWidth } from 'smily-admin-ui/utils/rentals';

export function setupBackgroundImage(
  model: BookingModel | RentalModel,
  element: HTMLElement,
) {
  const rental = model instanceof BookingModel ? model.rental : model;
  const photoType = getPhotoTypeByWidth(element.offsetWidth);
  const imageUrl = rental.primaryPhoto?.[photoType];

  element.style.backgroundImage = `url(${imageUrl})`;
}

function showSource(booking: BookingModel) {
  return !!booking.sourceName && !booking.isUnavailable;
}

const CalendarsBookingDialog: TOC<{
  Args: {
    booking: BookingModel;
    isOpen: boolean;
    isLoading: boolean;
    decoratePaymentBars?: boolean;
    closeModal: () => void;
    transitionToBooking: (id: string) => void;
  };
}> = <template>
  <ModalDialog @type='bottom' @separated={{true}} as |dialog|>
    <dialog.modal
      @isOpen={{@isOpen}}
      @closeModal={{@closeModal}}
      class='booking-card-modern'
      as |modal|
    >
      {{#if @isLoading}}
        <modal.body>
          <LoadingState @isLoading={{@isLoading}} />
        </modal.body>
      {{else}}
        <modal.header
          {{didInsert (fn setupBackgroundImage @booking)}}
          class='booking-card-header background-cover'
        >
          <div class='text-white z-index-1'>
            <div class='fw-semibold'>
              {{@booking.rental.name}}
            </div>

            <div class='d-flex gap-2'>
              <IconText
                @icon='user-friends'
                @iconStyle='far'
                @text={{asString
                  (or @booking.rental.sleepsMax @booking.rental.sleeps)
                }}
                @gap={{1}}
              />

              <IconText
                @icon='bath'
                @iconStyle='far'
                @text={{asString @booking.rental.bathroomsCount}}
                @gap={{1}}
              />

              <IconText
                @icon='ruler-combined'
                @iconStyle='far'
                @text={{@booking.rental.area}}
                @gap={{1}}
              />

              <IconText
                @icon='bed-alt'
                @iconStyle='far'
                @text={{asString @booking.rental.bedroomsCount}}
                @gap={{1}}
              />
            </div>
          </div>
        </modal.header>

        <modal.body class='p-0'>
          <List @style='light' as |Item|>
            {{#unless @booking.isUnavailable}}
              <Item class='d-flex gap-2'>
                <div>
                  <Icon
                    @icon='hand-holding-usd'
                    @style='far'
                    @fixedWidth={{true}}
                  />
                </div>

                <div class='flex-1 d-flex flex-column gap-3'>
                  <BookingsPaymentBarDetailed
                    @booking={{@booking}}
                    class='flex-1'
                  >
                    {{#if @decoratePaymentBars}}
                      <CalendarsStatusCircle
                        @booking={{@booking}}
                        @hideSource={{true}}
                      />
                    {{/if}}
                  </BookingsPaymentBarDetailed>

                  {{#if @booking.damageDeposit}}
                    <BookingsPaymentBarDamageDeposit
                      @booking={{@booking}}
                      class='flex-1'
                    >
                      {{#if @decoratePaymentBars}}
                        <CalendarsDamageDepositBadge @booking={{@booking}} />
                      {{/if}}
                    </BookingsPaymentBarDamageDeposit>
                  {{/if}}
                </div>
              </Item>

              {{#if @booking.notes}}
                <Item class='d-flex gap-2'>
                  <div>
                    <Icon @icon='pencil' @style='far' @fixedWidth={{true}} />
                  </div>

                  <MultilineText @text={{@booking.notes}} class='flex-1' />
                </Item>
              {{/if}}
            {{/unless}}

            <Item class='d-flex gap-2'>
              <div>
                <Icon @icon='calendar' @style='far' @fixedWidth={{true}} />
              </div>

              <div class='flex-1 booking-card-checkin-checkout'>
                <div>
                  <div>
                    {{#if @booking.isUnavailable}}
                      {{t 'internals.fields.startDate'}}
                    {{else}}
                      {{t 'bookings.check_in'}}
                    {{/if}}
                  </div>

                  <div class='text-14 fw-semibold'>
                    {{formatDate @booking.startAt.date format='longWithWeek'}}
                  </div>

                  {{#unless @booking.isUnavailable}}
                    <div>
                      {{t 'bookings.after_time' time=(time @booking.startAt)}}
                    </div>
                  {{/unless}}
                </div>

                <div>
                  <div class='text-end'>
                    {{#if @booking.isUnavailable}}
                      {{t 'internals.fields.endDate'}}
                    {{else}}
                      {{t 'bookings.check_out'}}
                    {{/if}}
                  </div>

                  <div class='text-14 fw-semibold text-end'>
                    {{formatDate @booking.endAt.date format='longWithWeek'}}
                  </div>

                  {{#unless @booking.isUnavailable}}
                    <div class='text-end'>
                      {{t 'bookings.before_time' time=(time @booking.endAt)}}
                    </div>
                  {{/unless}}
                </div>
              </div>
            </Item>

            <Item class='d-flex gap-2'>
              <div>
                <Icon @icon='book-open' @style='far' @fixedWidth={{true}} />
              </div>

              <div class='flex-1 d-flex flex-column gap-2'>
                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'common.nights'}}
                  </div>

                  <div>
                    {{@booking.nights}}
                  </div>
                </div>

                {{#unless @booking.isUnavailable}}
                  <div class='d-flex justify-content-between gap-3'>
                    <div>
                      {{t 'common.people'}}
                    </div>

                    <div>
                      {{@booking.guestsCount}}
                    </div>
                  </div>

                  <div class='d-flex justify-content-between gap-3'>
                    <div>
                      {{t 'internals.fields.name'}}
                    </div>

                    <div>
                      {{@booking.clientFullname}}
                    </div>
                  </div>
                {{/unless}}

                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'internals.fields.status'}}
                  </div>

                  <div>
                    <BookingsBookingStatus @booking={{@booking}} />
                  </div>
                </div>

                {{#if (showSource @booking)}}
                  <div class='d-flex justify-content-between gap-3'>
                    <div>
                      {{t 'internals.models.source'}}
                    </div>

                    <SourceText @source={{@booking.sourceName}} />
                  </div>
                {{/if}}
              </div>
            </Item>
          </List>
        </modal.body>

        <modal.footer>
          <Link @action={{@closeModal}} class='btn btn-secondary'>
            {{t 'common.close'}}
          </Link>

          <Link
            @action={{fn @transitionToBooking @booking.id}}
            class='btn btn-primary'
          >
            {{t 'bookings.edit_booking'}}
          </Link>
        </modal.footer>
      {{/if}}
    </dialog.modal>
  </ModalDialog>
</template>;

export default CalendarsBookingDialog;
