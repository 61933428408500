import { getOwner } from '@ember/application';
import { addDays } from 'date-fns';
import { getService } from 'ember-smily-base/utils/application';
import { dateString } from 'ember-smily-base/utils/date';
import type { Query } from 'ember-smily-base/utils/store';
import type { QueryParam } from 'smily-admin-ui/controllers/inbox';
import type InboxRoute from 'smily-admin-ui/routes/inbox';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { CompoundFilterValue } from 'smily-admin-ui/utils/store';

type CompoundFilter = Record<
  string,
  CompoundFilterValue | CompoundFilterValue[]
>;

export default function buildQuery(context: object) {
  const route = getOwner(context).lookup('route:inbox') as InboxRoute;
  const store = getService(context, 'store');
  const session = getOwner(context).lookup('service:session') as SessionService;
  const params = route.paramsFor('inbox') as Record<QueryParam, string>;

  const {
    status,
    read,
    assignee,
    client,
    stage,
    source,
    bookingStatus,
    bookingLabel,
    rental,
    bookingTag,
    rentalTag,
    priority,
    severity,
    sort,
  } = params;

  const query = store.generateQuery(
    'inbox-conversation',
    'listDefault',
    { spam: status === 'spam' },
    sort,
  ) as Query & Required<Pick<Query, 'filter'>>;

  if (status !== 'spam') {
    query.filter.closed = status === 'archived';
  }

  if (read) {
    query.filter.read = read;
  }

  if (assignee) {
    query.filter.assignee = assignee;
  }

  if (client) {
    query.filter.participatingClients = client;
  } else {
    query.filter.lastMessageAt = {
      op: 'not_eq',
      value: 'null',
    };
  }

  if (stage) {
    query.filter = {
      ...query.filter,
      ...getBookingStageValue(stage),
    };
  }

  if (source) {
    query.filter.bookingSources = source;
  }

  if (bookingStatus) {
    query.filter.bookingStatus = bookingStatus;
  }

  if (bookingLabel) {
    query.filter.bookingLabel = bookingLabel;
  }

  if (rental) {
    query.filter.bookingRentals = rental;
  }

  if (bookingTag) {
    query.filter.bookingTags = bookingTag;
  }

  if (priority) {
    query.filter.priority = priority;
  }

  if (severity) {
    query.filter.severity = severity;
  }

  if (!session.account?.isSmily && rentalTag) {
    query.filter.bookingRentalTags = rentalTag;
  }

  return query;
}

function getBookingStageValue(stage: string): CompoundFilter {
  const now = new Date();
  const startOfDay = `${dateString(now)} 00:00:00`;
  const startOfTomorrow = `${dateString(addDays(now, 1))} 00:00:00`;

  switch (stage) {
    case 'past':
      return {
        bookingEndAt: [
          {
            value: startOfDay,
            op: 'lt',
          },
        ],
      };
    case 'checkin':
      return {
        bookingStartAt: [
          {
            value: startOfDay,
            op: 'gteq',
          },
          {
            value: startOfTomorrow,
            op: 'lt',
          },
        ],
      };
    case 'ongoing':
      return {
        bookingStartAt: [
          {
            value: startOfDay,
            op: 'lt',
          },
        ],
        bookingEndAt: [
          {
            value: startOfTomorrow,
            op: 'gteq',
          },
        ],
      };
    case 'checkout':
      return {
        bookingEndAt: [
          {
            value: startOfDay,
            op: 'gteq',
          },
          {
            value: startOfTomorrow,
            op: 'lt',
          },
        ],
      };
    default:
      return {
        bookingStartAt: [
          {
            value: startOfTomorrow,
            op: 'gteq',
          },
        ],
      };
  }
}
