import type RouteInfo from '@ember/routing/-private/route-info';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class RentalsRentalIndexRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;
  @service router!: RouterService;

  checkAbilities = true;
  requiredFeatureAbility = 'access rentals feature';

  redirect() {
    this.router.transitionTo('rentals.rental.details');
  }

  featureNotAccessible(transition: Transition) {
    const { rental_id: id } = (transition.to.parent as RouteInfo).params;
    this.coreLinks.redirect('rentals', id);
  }
}
