import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class FinanceIndexRoute extends Route {
  @service private session!: SessionService;
  @service private router!: RouterService;

  redirect(): void {
    if (this.session.sessionInfo?.financeDataAvailable) {
      this.router.transitionTo('finance.completed-payouts');
    } else {
      this.router.transitionTo('finance.discover');
    }
  }
}
