import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type MediaService from 'ember-responsive';
import { isMobileApp } from 'ember-smily-base/utils/application';

export default class CalendarIndexRoute extends Route {
  @service router!: RouterService;
  @service media!: MediaService;

  redirect(): void {
    if (this.media.isSmallScreen || isMobileApp) {
      this.router.transitionTo('calendar.month');
    } else {
      this.router.transitionTo('calendar.planning');
    }
  }
}
