import { service } from '@ember/service';
import RouteTemplate from 'ember-route-template';
import type StoreService from 'ember-smily-base/services/store';
import LegalIndividualRouteLayout from 'smily-admin-ui/components/legal/individual-route-layout';
import UnauthenticatedScreen from 'smily-admin-ui/components/unauthenticated-screen';
import type PrivacyPolicyController from 'smily-admin-ui/controllers/privacy-policy';
import { createCustomActionProxy } from 'smily-admin-ui/utils/model';

interface ExtraArgs {
  controller: PrivacyPolicyController;
}

class TemplatesPrivacyPolicy extends UnauthenticatedScreen<ExtraArgs> {
  @service store!: StoreService;

  get privacyPolicy() {
    this.intl.primaryLocale;

    const actionName =
      this.args.controller.for === 'software'
        ? 'publicSoftwarePrivacyPolicy'
        : 'publicPrivacyPolicy';

    return createCustomActionProxy('legal-term', actionName, this.store, {
      custom: true,
    });
  }

  <template>
    <LegalIndividualRouteLayout @legalTerm={{this.privacyPolicy}} />
  </template>
}

export default RouteTemplate(TemplatesPrivacyPolicy);
