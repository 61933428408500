import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import ExternalLink from 'ember-smily-base/components/external-link';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import CopyUrl from 'smily-admin-ui/components/copy-url';

const CalendarsIcalExport: TOC<{
  Args: {
    url: string;
  };
}> = <template>
  <ModalDialog as |dialog|>
    <dialog.toggle class='btn btn-sm btn-link p-0'>
      {{t 'rentals.export_ical.title'}}
    </dialog.toggle>

    <dialog.modal as |modal|>
      <modal.header>
        <h4>
          {{t 'rentals.export_ical.title'}}
        </h4>
      </modal.header>

      <modal.body class='d-flex flex-column gap-3'>
        <div>
          {{t 'rentals.export_ical.description'}}
        </div>

        <CopyUrl
          @url={{@url}}
          @inputLabel={{t 'rentals.export_ical.input_label'}}
          @buttonLabel={{t 'rentals.export_ical.copy_link'}}
        />

        <div>
          {{t 'rentals.export_ical.manual_link.1'}}

          <ExternalLink
            @label={{t 'rentals.export_ical.manual_link.link.label'}}
            @url={{t 'rentals.export_ical.manual_link.link.url'}}
          />

          {{t 'rentals.export_ical.manual_link.2'}}
        </div>
      </modal.body>
    </dialog.modal>
  </ModalDialog>
</template>;

export default CalendarsIcalExport;
