import { attr, belongsTo } from '@ember-data/model';
import type ClientModel from 'smily-admin-ui/models/client';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ClientEmailModel extends BaseModel {
  @attr('string', { defaultValue: '' }) label?: string;
  @attr('string', { defaultValue: '' }) email?: string;
  @attr('boolean') primary?: boolean;
  @attr('object', { readOnly: true }) lock?: Record<string, unknown>;

  @belongsTo('client', { async: false, inverse: 'emails' })
  client!: ClientModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'client-email': ClientEmailModel;
  }
}
