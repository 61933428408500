import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import SelectButton from 'ember-smily-base/components/select-button';
import type { Changeset } from 'ember-smily-base/utils/model';
import type { SelectOption } from 'ember-smily-base/utils/select';
import not from 'ember-truth-helpers/helpers/not';
import InboxChannelIcon from 'smily-admin-ui/components/inbox/channel-icon';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';
import type { Channel } from 'smily-admin-ui/models/inbox-message';

interface InboxSendButtonSignature {
  Element: HTMLDivElement;
  Args: {
    changeset: Changeset;
    small?: boolean;
    send: () => void;
  };
}

export default class InboxSendButton extends Component<InboxSendButtonSignature> {
  @service intl!: IntlService;

  get channel() {
    return this.channels.find(
      ({ value }) => value === this.args.changeset.channel,
    )!;
  }

  get channels() {
    return (
      this.args.changeset.conversation as InboxConversationModel
    ).meta.availableChannels.map(
      (value) =>
        ({
          label: this.intl.t(`inbox.channel_options.${value}`),
          value,
        }) as SelectOption<Channel>,
    );
  }

  @action
  changeChannel(option: SelectOption<Channel>) {
    this.args.changeset.set('channel', option.value);
  }

  <template>
    <SelectButton
      @value={{this.channel}}
      @options={{this.channels}}
      @disabled={{not @changeset.content}}
      @buttonClass={{if @small 'btn btn-primary btn-sm'}}
      @action={{@send}}
      @onChange={{this.changeChannel}}
      ...attributes
    >
      <:button>
        <div class='d-flex align-items-center gap-2'>
          <InboxChannelIcon
            @channel={{getChannel @changeset}}
            @wrapped={{true}}
          />

          <div>
            {{t 'common.send'}}
          </div>
        </div>
      </:button>

      <:selectItem as |item|>
        <div class='d-flex align-items-center gap-2'>
          <InboxChannelIcon @channel={{item.value}} @wrapped={{true}} />

          <div>
            {{optionLabel item.value 'inbox.channel_options'}}
          </div>
        </div>
      </:selectItem>
    </SelectButton>
  </template>
}

function getChannel(changeset: Changeset) {
  return changeset.channel as Channel;
}
