import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

const ENGINIZED_APPS = ['invoices'];
const ENGINE_PATH_RE = new RegExp(`apps.(${ENGINIZED_APPS.join('|')}).*`);

export default class AppsRoute extends BaseRoute {
  @service router!: RouterService;

  checkAbilities = true;
  requiredRouteAbility = 'access apps route';

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (!transition.to.name.match(ENGINE_PATH_RE)) {
      this.router.transitionTo('apps.index');
    }
  }
}
