import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class InboxConversationAbility extends Ability<'inbox-conversation'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'inbox-conversation');
  }

  canRemove = false;
}
