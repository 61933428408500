import { array, concat, fn } from '@ember/helper';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import { callAction } from 'ember-smily-base/utils/model';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import type { EnrollmentType } from 'smily-admin-ui/models/onboarding';
import type OnboardingCompanyRoute from 'smily-admin-ui/routes/onboarding/company';
import type CacheService from 'smily-admin-ui/services/cache';

interface TemplatesOnboardingCompanyTypeSignature {
  model: ModelFor<OnboardingCompanyRoute>;
}

class TemplatesOnboardingCompanyType extends Component<TemplatesOnboardingCompanyTypeSignature> {
  @service cache!: CacheService;
  @service router!: RouterService;

  @action
  async proceed(enrollmentType: string): Promise<void> {
    const changeset = this.args.model;
    const typedEnrollmentType = enrollmentType as EnrollmentType;

    changeset.set('enrollmentType', enrollmentType);

    if (typedEnrollmentType !== 'individual') {
      const nextRoute =
        typedEnrollmentType === 'selfEmployed'
          ? 'onboarding.company.legal'
          : 'onboarding.company.details';
      this.router.transitionTo(nextRoute);

      return;
    }

    await callAction(changeset, 'completeCompanyDetails', ['enrollmentType']);

    this.cache.updateOnboardingCompletionPercentage();
    this.router.transitionTo('onboarding.index');
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.company.type.title'}}
          </h2>

          <List @large={{true}} @sticky={{true}} as |Item|>
            <Item class='d-flex flex-column align-items-center gap-3'>
              {{#each
                (array 'individual' 'selfEmployed' 'company')
                as |enrollmentType|
              }}
                <LoadingButton
                  @action={{fn this.proceed enrollmentType}}
                  class='btn btn-secondary w-100 d-flex'
                >
                  {{t
                    (concat
                      'onboarding.company.type.enrollment_type_options.'
                      enrollmentType
                    )
                  }}
                </LoadingButton>
              {{/each}}
            </Item>

            <Item>
              <OnboardingBackToTaskList />
            </Item>
          </List>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.company.type.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.company.type.helper.p1'}}
          </div>

          <div>
            {{t 'onboarding.company.type.helper.p2'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingCompanyType);
