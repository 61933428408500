import { attr, belongsTo } from '@ember-data/model';
import { join } from 'ember-smily-base/utils/string';
import type UserModel from 'smily-admin-ui/models/user';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class HostModel extends BaseModel {
  @attr declare email?: string;
  @attr declare firstName?: string;
  @attr declare lastName?: string;

  // unless `withUsers` filter is passed to host query, this can be null
  @belongsTo('user', { async: false, inverse: null })
  user?: UserModel;

  get fullName(): string {
    return join([this.firstName, this.lastName], ' ');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    host: HostModel;
  }
}
