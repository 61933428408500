import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import type PhotoModel from 'smily-admin-ui/models/photo';
import type RentalModel from 'smily-admin-ui/models/rental';

interface RentalsPhotosSignature {
  Element: HTMLDivElement;
  Args: {
    rental: RentalModel;
  };
}

export default class RentalsPhotos extends Component<RentalsPhotosSignature> {
  get firstTwoPhotos(): PhotoModel[] {
    return this.args.rental.photos.slice(0, 2);
  }

  get secondTwoPhotos(): PhotoModel[] {
    return this.args.rental.photos.slice(2, 4);
  }

  get numberOfRemainingPhotos() {
    return Math.max((this.args.rental.photos.length as number) - 4, 0);
  }

  <template>
    <div class='d-flex flex-wrap gap-2 gap-lg-3 overflow-hidden'>
      <div class='flex-1 d-flex gap-2 gap-lg-3'>
        {{#each this.firstTwoPhotos as |photo|}}
          <img
            src={{photo.mediumUrl}}
            class='rental-details-photo'
            alt={{@rental.name}}
          />
        {{/each}}
      </div>

      <div class='flex-1 d-flex gap-2 gap-lg-3'>
        {{#each this.secondTwoPhotos as |photo index|}}
          {{#if (and this.numberOfRemainingPhotos (eq index 1))}}
            <div class='rental-details-photo-more'>
              <img
                src={{photo.mediumUrl}}
                class='rental-details-photo'
                alt={{@rental.name}}
              />

              <div class='px-2 py-1 rounded'>
                {{t 'rentals.more_photos' count=this.numberOfRemainingPhotos}}
              </div>
            </div>
          {{else}}
            <img
              src={{photo.mediumUrl}}
              class='rental-details-photo'
              alt={{@rental.name}}
            />
          {{/if}}
        {{/each}}
      </div>
    </div>
  </template>
}
