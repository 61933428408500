import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class SignupIndexController extends Controller {
  @tracked airbnbButtonError: string | undefined;
}

declare module '@ember/controller' {
  interface Registry {
    'signup/index': SignupIndexController;
  }
}
