import { getOwner } from '@ember/application';
import type Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type { FormYield } from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import type ListItemComponent from 'ember-smily-base/components/list/item';
import type StoreService from 'ember-smily-base/services/store';
import { getSrc } from 'ember-smily-base/utils/file';
import type { Changeset, SyncHasMany } from 'ember-smily-base/utils/model';
import { all } from 'rsvp';
import TasksFiles from 'smily-admin-ui/components/tasks/files';
import TasksTemplateAddFiles from 'smily-admin-ui/components/tasks/template/add-files';
import TasksTemplateFormTaskSection from 'smily-admin-ui/components/tasks/template/form-task-section';
import type TaskManagementAttachmentModel from 'smily-admin-ui/models/task-management-attachment';
import type {
  AttachmentTarget,
  TaskManagementAttachmentFile,
} from 'smily-admin-ui/models/task-management-attachment';
import { DIRECT_UPLOADS_URL } from 'smily-admin-ui/models/task-management-attachment';
import type ActiveStorageService from 'smily-admin-ui/services/active-storage-service';

interface TasksTemplateFormTaskSectionsSignature {
  Element: HTMLDivElement;
  Args: {
    form: FormYield;
    item: WithBoundArgs<typeof ListItemComponent, 'linked' | 'fixed'>;
    changeset: Changeset;
    isTemplate?: boolean;
  };
}

export default class TasksTemplateFormTaskSections extends Component<TasksTemplateFormTaskSectionsSignature> {
  @service activeStorage!: ActiveStorageService;
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service store!: StoreService;
  @service router!: RouterService;

  @tracked newAttachments: string[] =
    (this.args.changeset.get('newAttachments') as string[] | undefined) ?? [];

  get attachments() {
    const attachments = (
      this.args.changeset.get(
        'attachments',
      ) as SyncHasMany<TaskManagementAttachmentModel>
    ).map(({ file }) => file.original);

    return [...attachments, ...this.newAttachments];
  }

  get firstThreeAttachments() {
    return this.attachments.slice(0, 3);
  }

  get extraFiles() {
    return this.attachments.length > 3 ? this.attachments.length - 3 : 0;
  }

  uploadFiles = task({ drop: true }, async (event: Event) => {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    try {
      const srcs = await all(
        Array.from(input.files).map((file) => getSrc(file)),
      );
      const results = await all(
        Array.from(input.files).map((file) =>
          this.activeStorage.upload(file, DIRECT_UPLOADS_URL),
        ),
      );
      const attachments = results.map(
        ({ signedId }) => ({ signedId }) as TaskManagementAttachmentFile,
      );

      if (this.args.changeset.isNew) {
        this.newAttachments = [...this.newAttachments, ...srcs];
        this.args.changeset.set('newAttachmentFiles', [
          ...(this.args.changeset.get(
            'newAttachmentFiles',
          ) as TaskManagementAttachmentFile[]),
          ...attachments,
        ]);
      } else {
        const createdAttachments = await all(
          attachments.map((attachment) =>
            this.store
              .createRecord('task-management-attachment', {
                file: attachment,
                target: this.args.changeset._content as AttachmentTarget,
              })
              .save(),
          ),
        );

        this.args.changeset.set('attachments', [
          ...this.args.changeset.get('attachments'),
          ...createdAttachments,
        ]);

        const { currentRouteName } = this.router;

        await (
          getOwner(this).lookup(`route:${currentRouteName}`) as Route
        ).refresh();

        this.notify.success(this.intl.t('tasks.template_form.files_uploaded'));
      }
    } finally {
      input.value = '';
    }
  });

  deleteFile = task({ drop: true }, async (fileName: string) => {
    const file = (
      this.args.changeset.get(
        'attachments',
      ) as SyncHasMany<TaskManagementAttachmentModel>
    ).find(({ file }) => file.original === fileName)!;

    await file.destroyRecord();

    this.args.changeset.set(
      'attachments',
      (
        this.args.changeset.get(
          'attachments',
        ) as SyncHasMany<TaskManagementAttachmentModel>
      ).filter(({ file }) => file.original !== fileName),
    );
  });

  <template>
    <@item>
      <TasksTemplateFormTaskSection
        @form={{@form}}
        @changeset={{@changeset}}
        @isTemplate={{@isTemplate}}
      />
    </@item>

    <@item>
      <@form.Section
        @icon='image'
        @title={{t 'tasks.task_form.files_and_media_title'}}
      >
        <List @large={{true}} @sticky={{true}} @style='light' as |Item|>
          {{#if this.attachments.length}}
            <Item>
              <TasksFiles
                @files={{this.attachments}}
                @deleteFile={{this.deleteFile}}
              />
            </Item>
          {{/if}}

          <Item class='d-flex flex-column gap-2'>
            <TasksTemplateAddFiles
              @disabled={{this.uploadFiles.isRunning}}
              @onChange={{perform this.uploadFiles}}
            />

            <div>
              {{t 'tasks.task_form.file_requirements.p1'}}

              <ul>
                <li>{{t 'tasks.task_form.file_requirements.p2'}}</li>
                <li>{{t 'tasks.task_form.file_requirements.p3'}}</li>
                <li>{{t 'tasks.task_form.file_requirements.p4'}}</li>
                <li>{{t 'tasks.task_form.file_requirements.p5'}}</li>
                <li>{{t 'tasks.task_form.file_requirements.p6'}}</li>
              </ul>
            </div>
          </Item>
        </List>
      </@form.Section>
    </@item>

    {{#if @isTemplate}}
      <@item>
        <@form.Section
          @icon='pennant'
          @title={{t 'tasks.task_form.report_issue_title'}}
          @subtitle={{t 'tasks.task_form.report_issue_subtitle'}}
        >
          <List @large={{true}} @sticky={{true}} @style='light' as |Item|>
            <Item class='pb-0'>
              <@form.Toggle
                @label={{t 'tasks.task_form.report_issue_label'}}
                @property={{if @isTemplate 'taskHasIssues' 'hasIssues'}}
              />
            </Item>

            <Item class='d-flex flex-column gap-3'>
              <div>
                {{t 'tasks.task_form.report_issue_description'}}
              </div>

              <img
                src='/assets/images/task-template-has-issues.png'
                class='border rounded-3'
                alt={{t 'tasks.task_form.report_issue_label'}}
              />
            </Item>
          </List>
        </@form.Section>
      </@item>
    {{/if}}
  </template>
}
