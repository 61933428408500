import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import t from 'ember-intl/helpers/t';
import IconText from 'ember-smily-base/components/icon-text';
import LoadingButton from 'ember-smily-base/components/loading-button';
import type { Changeset } from 'ember-smily-base/utils/model';
import sortableGroup from 'ember-sortable/modifiers/sortable-group';
import style from 'ember-style-modifier/modifiers/style';
import TasksChecklistItem from 'smily-admin-ui/components/tasks/checklist-item';
import type { TaskItem } from 'smily-admin-ui/serializers/task-management-task';
import { CHECKLIST_SEPARATOR } from 'smily-admin-ui/transforms/checklist';

interface TasksChecklistSignature {
  Args: {
    changeset: Changeset;
    isTemplate?: boolean;
  };
}

export default class TasksChecklist extends Component<TasksChecklistSignature> {
  @tracked checklist!: TaskItem[];

  constructor(owner: unknown, args: TasksChecklistSignature['Args']) {
    super(owner, args);

    this.updateChecklist();
  }

  @action
  changeItem(item: TaskItem, value: string) {
    item.name = value;

    this.changeChecklist(this.checklist);
  }

  @action
  addItem() {
    this.updateChecklist();
    this.changeChecklist([
      ...this.checklist,
      { name: '', position: this.checklist.length + 1 },
    ]);
    this.updateChecklist();
  }

  @action
  deleteItem(item: TaskItem) {
    this.changeChecklist(
      this.checklist
        .filter((i) => i !== item)
        .map((item, index) => ({ ...item, position: index + 1 })),
    );
    this.updateChecklist();
  }

  @action
  reorderChecklist(positions: number[]) {
    this.updateChecklist();
    this.changeChecklist(
      positions.map((p, index) => {
        return {
          ...this.checklist.find((item) => item.position === p)!,
          position: index + 1,
        };
      }),
    );
    this.updateChecklist();
  }

  changeChecklist(items: TaskItem[]) {
    if (this.args.isTemplate) {
      this.args.changeset.set(
        'checklist',
        items.length
          ? items.map((item) => item.name).join(CHECKLIST_SEPARATOR)
          : undefined,
      );
    } else {
      this.args.changeset.set('taskItems', JSON.stringify(items));
    }
  }

  updateChecklist() {
    if (this.args.isTemplate) {
      const checklist = this.args.changeset.get('checklist') as
        | string
        | undefined;

      if (checklist === undefined) {
        this.checklist = [];
      } else {
        this.checklist = checklist
          .split(CHECKLIST_SEPARATOR)
          .map((name, index) => ({ name, position: index + 1 }));
      }
    } else {
      this.checklist = JSON.parse(
        this.args.changeset.get('taskItems') as string,
      ) as TaskItem[];
    }
  }

  <template>
    <div>
      {{#let (guidFor (hash)) as |id|}}
        <label for={{id}} class='form-label mb-2'>
          {{t 'tasks.template_form.checklist'}}
        </label>

        <div {{sortableGroup onChange=this.reorderChecklist}} id={{id}}>
          {{#each this.checklist as |item|}}
            <TasksChecklistItem
              @item={{item}}
              @changeItem={{this.changeItem}}
              @deleteItem={{this.deleteItem}}
            />
          {{/each}}

          <LoadingButton
            {{style marginLeft=(if this.checklist.length '53px' '0')}}
            @action={{this.addItem}}
            class='align-self-start btn btn-secondary btn-sm mt-3'
          >
            <IconText
              @icon='plus'
              @text={{t 'tasks.template_form.add_checklist_item'}}
            />
          </LoadingButton>
        </div>
      {{/let}}
    </div>
  </template>
}
