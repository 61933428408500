import { getOwner } from '@ember/application';
import { array, concat } from '@ember/helper';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import can from 'ember-can/helpers/can';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';
import LoadingButton from 'ember-smily-base/components/loading-button';
import and from 'ember-truth-helpers/helpers/and';
import not from 'ember-truth-helpers/helpers/not';
import SignupHelper from 'smily-admin-ui/components/signup/helper';
import type AccountModel from 'smily-admin-ui/models/account';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type ApplicationRoute from 'smily-admin-ui/routes/application';
import type SessionService from 'smily-admin-ui/services/session-service';

class TemplatesUpgradeToSmily extends Component {
  @service intl!: IntlService;
  @service router!: RouterService;
  @service session!: SessionService;

  get onboarding(): OnboardingModel {
    return this.session.onboarding as OnboardingModel;
  }

  get learnMoreHref(): string {
    return new URL(
      this.intl.primaryLocale === 'fr-fr' ? 'fr' : '',
      'https://www.smily.com',
    ).toString();
  }

  @action
  async upgrade(): Promise<void> {
    const account = this.session.account as AccountModel;

    await account.startLightOnboarding({
      data: {
        type: 'accounts',
        id: account.id,
        attributes: {},
      },
    });

    await (
      getOwner(this).lookup('route:application') as ApplicationRoute
    ).fetchSession();

    this.router.transitionTo('onboarding');
  }

  <template>
    <div class='p-3'>
      <Card>
        <div
          class='card-body d-flex flex-column-reverse flex-lg-row align-items-lg-center gap-3'
        >
          <div class='flex-1 p-lg-5 d-flex flex-column align-items-start gap-3'>
            <h2 class='text-lg-28'>
              <b>{{t 'onboarding.upgrade_to_smily.title'}}</b>
            </h2>

            <div class='text-lg-16'>
              <b>{{t 'onboarding.upgrade_to_smily.subtitle.1'}}</b>

              {{t 'onboarding.upgrade_to_smily.subtitle.2'}}
            </div>

            <div class='upgrade-to-smily-features align-items-center gap-2'>
              {{#each (array 1 2 3 4) as |index|}}
                <Icon @icon='check' />

                <div class='text-lg-16'>
                  {{t (concat 'onboarding.upgrade_to_smily.features.' index)}}
                </div>
              {{/each}}

              <div>
                {{! first grid column placeholder }}
              </div>

              <a
                href={{this.learnMoreHref}}
                target='_blank'
                rel='noopener noreferrer'
                class='text-lg-16'
              >
                {{t 'common.learn_more'}}
              </a>
            </div>

            <div class='d-flex flex-column gap-2'>
              <div>
                {{t 'onboarding.upgrade_to_smily.helper'}}
              </div>

              {{#let
                (and
                  (not this.session.termsAndConditionsAccepted)
                  (not (can 'access legal terms modal release'))
                )
                as |needsAcceptance|
              }}
                {{#if needsAcceptance}}
                  <SignupHelper
                    @label={{t 'onboarding.upgrade_to_smily.ok'}}
                    @excludePrivacyPolicy={{true}}
                  />
                {{/if}}

                <LoadingButton
                  @action={{this.upgrade}}
                  @label={{if
                    needsAcceptance
                    (t 'onboarding.upgrade_to_smily.ok_toc')
                    (t 'onboarding.upgrade_to_smily.ok')
                  }}
                  class='btn btn-primary align-self-start'
                />
              {{/let}}
            </div>

            <LinkTo @route='index' class='btn btn-link btn-sm p-0'>
              {{t 'onboarding.upgrade_to_smily.cancel'}}
            </LinkTo>
          </div>

          <div class='flex-1'>
            <img
              src='/assets/images/upgrade-to-smily.png'
              class='w-100'
              alt=''
            />
          </div>
        </div>
      </Card>
    </div>
  </template>
}

export default RouteTemplate(TemplatesUpgradeToSmily);
