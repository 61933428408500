import type { ModelName } from 'ember-smily-base/utils/store';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

interface OnboardingAdapterOptions {
  isFree?: boolean;
  isBusiness?: boolean;
  isManager?: boolean;
}

export default class OnboardingAdapter extends ApplicationAdapter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlForCreateRecord<K extends ModelName>(modelName: K, snapshot: any): string {
    const url = super.urlForCreateRecord(modelName, snapshot);
    const options = snapshot.adapterOptions as OnboardingAdapterOptions;
    const path = options.isFree
      ? 'free'
      : options.isBusiness
        ? 'business'
        : options.isManager
          ? 'manager'
          : 'owner';

    return `${url}/${path}`;
  }
}
