import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import TasksTemplateStatus from 'smily-admin-ui/components/tasks/template/status';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';

const TasksTemplateCard: TOC<{
  Element: HTMLDivElement;
  Args: {
    model: TaskManagementTemplateModel;
  };
}> = <template>
  <div class='py-3 d-flex justify-content-between gap-3' ...attributes>
    <div class='d-flex flex-column'>
      <div class='fw-semibold'>
        {{@model.name}}
      </div>

      <div>
        {{t 'internals.model_counters.rental' counter=@model.rentals.length}}
      </div>
    </div>

    <TasksTemplateStatus @status={{@model.status}} />
  </div>
</template>;

export default TasksTemplateCard;
