import { Ability } from 'ember-can';
import { generateFeatureAbilities } from 'smily-admin-ui/utils/ability';

export default class FeatureAbility extends Ability<'feature'> {
  constructor(owner: object) {
    super(owner);

    generateFeatureAbilities(this);
  }
}
