import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import type { WithBoundArgs } from '@glint/template';
import t from 'ember-intl/helpers/t';
import LoadingButton from 'ember-smily-base/components/loading-button';
import BookingsPaymentDialog from 'smily-admin-ui/components/bookings/payment-dialog';
import type BookingModel from 'smily-admin-ui/models/booking';
import type PaymentModel from 'smily-admin-ui/models/payment';

const Button: typeof LoadingButton<false> = LoadingButton;

const BookingsPaymentActions: TOC<{
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
    payment: PaymentModel;
    popoverId?: string;
  };
  Blocks: {
    default: [
      {
        Cancel: WithBoundArgs<
          typeof Button,
          'label' | 'action' | 'needsConfirmation'
        >;
        Edit: WithBoundArgs<
          typeof BookingsPaymentDialog,
          'booking' | 'payment' | 'popoverId'
        >;
        Restore: WithBoundArgs<
          typeof Button,
          'label' | 'action' | 'needsConfirmation'
        >;
      },
    ];
  };
}> = <template>
  {{#let
    (component
      Button
      label=(t 'common.cancel')
      action=@payment.deleteForBooking
      needsConfirmation=true
    )
    (component
      BookingsPaymentDialog
      booking=@booking
      payment=@payment
      popoverId=@popoverId
    )
    (component
      Button
      label=(t 'finance.payment_bar.restore')
      action=@payment.restoreForBooking
      needsConfirmation=true
    )
    as |Cancel Edit Restore|
  }}
    {{yield (hash Cancel=Cancel Edit=Edit Restore=Restore)}}
  {{/let}}
</template>;

export default BookingsPaymentActions;
