import { fn } from '@ember/helper';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import Form from 'ember-smily-base/components/form';
import Icon from 'ember-smily-base/components/icon';
import LoadingButton from 'ember-smily-base/components/loading-button';
import type { Changeset } from 'ember-smily-base/utils/model';
import { asString } from 'ember-smily-base/utils/template';
import type BookingFeeModel from 'smily-admin-ui/models/booking-fee';

interface BookingFeeSignature {
  Element: HTMLFormElement;
  Args: {
    fee: Changeset;
    currency: string;
    deleteFee: (fee: Changeset) => void;
    recalculate: () => void;
  };
}

export default class BookingFee extends Component<BookingFeeSignature> {
  get rentalFee() {
    return this.args.fee.get('rentalFee') as BookingFeeModel['rentalFee'];
  }

  get isCountable() {
    const count = this.rentalFee?.maximumBookable;

    return !count || count > 1;
  }

  <template>
    <Form
      @model={{@fee}}
      class='w-100 flex-lg-row align-items-start align-items-lg-center gap-3'
      ...attributes
      as |F|
    >
      <label class='flex-1'>
        {{asString @fee.name}}
      </label>

      <div class='flex-1 d-flex gap-3'>
        <div class='flex-4 d-flex gap-3'>
          {{#if this.isCountable}}
            <div class='flex-1 position-relative'>
              <F.Input @property='timesBooked' @onBlur={{@recalculate}} />

              <div class='input-multiplier-sign'>
                x
              </div>
            </div>
          {{/if}}

          <F.Input
            @property='price'
            @unit={{@currency}}
            @onBlur={{@recalculate}}
            class='flex-1'
          />
        </div>

        <LoadingButton
          @action={{fn @deleteFee @fee}}
          @label={{t 'bookings.fees.delete_fee'}}
          @iconOnly={{true}}
          @needsConfirmation={{true}}
          class='btn btn-secondary btn-input horizontal-input-alignment'
        >
          <Icon @icon='trash-alt' @style='far' />
        </LoadingButton>
      </div>
    </Form>
  </template>
}
