import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import BookingsBookingIndexRoute from 'smily-admin-ui/routes/bookings/booking/index';
import type CacheService from 'smily-admin-ui/services/cache';

export default class BookingsBookingEditRoute extends BookingsBookingIndexRoute {
  @service cache!: CacheService;

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);

    await this.cache.getPreferencesPayment();
  }
}
