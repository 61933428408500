import type Store from '@ember-data/store';
import type { Snapshot } from '@ember-data/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import type { AdapterOptions } from 'ember-smily-base/utils/serializer';
import { isNew } from 'ember-smily-base/utils/serializer';
import type { Model, ModelName } from 'ember-smily-base/utils/store';
import type { TaskManagementAttachmentFile } from 'smily-admin-ui/models/task-management-attachment';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';
import { trim } from 'smily-admin-ui/utils/serializer';

export interface TaskItem {
  id?: string;
  name: string;
  position: number;
}

export default class TaskManagementTaskSerializer extends ApplicationSerializer<'task-management-task'> {
  normalizeSingleResponse(
    store: Store,
    primaryModelClass: Model<ModelName>,
    payload: ResponseWithData<ResourceObject>,
    id: string | number,
    requestType: string,
  ) {
    const taskItems = (payload.included || []).filter((item) =>
      item.type.startsWith('task-management-task-item'),
    );

    payload.data.attributes!['task-items'] = taskItems
      .map(({ id, attributes }) => {
        const { name, position } = attributes!;
        return { id, name, position };
      })
      .sort((pv, nx) => {
        return (pv.position as number) - (nx.position as number);
      });

    payload.data.attributes!['checked-task-item-ids'] = taskItems
      .filter((item) => {
        return item.attributes!['checked-at'];
      })
      .map((item) => {
        return item.id;
      })
      .sort((pv, nx) => {
        return pv.localeCompare(nx);
      });

    return super.normalizeSingleResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    );
  }

  serialize(snapshot: Snapshot, options?: AdapterOptions) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;

    if (isNew(snapshot, options)) {
      json.data.attributes!['attachments'] = (
        snapshot.attr('newAttachmentFiles') as TaskManagementAttachmentFile[]
      ).map(({ signedId }) => ({ file: signedId }));

      delete json.data.attributes!['task-items'];
      delete json.data.attributes!['checked-task-item-ids'];
    } else {
      throw new Error(
        'Tasks update actions (manage and handle) must build their own payloads, serializer is expected to be called only for new records.',
      );
    }

    trim(snapshot, json, { relationships: true });

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'task-management-task': TaskManagementTaskSerializer;
  }
}
