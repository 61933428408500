import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import TemplatesPerformanceBase from 'smily-admin-ui/templates/performance/-base';

class TemplatesPerformanceFinancialMetrics extends TemplatesPerformanceBase {
  @service intl!: IntlService;

  tab = 'financialMetrics' as const;

  get description() {
    return this.intl.t('performance.financial_metrics.description');
  }
}

export default RouteTemplate(TemplatesPerformanceFinancialMetrics);
