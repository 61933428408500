import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base//utils/routing';
import type StoreService from 'ember-smily-base/services/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import { all } from 'rsvp';
import type { QueryParam } from 'smily-admin-ui/controllers/tasks/index';
import { FILTER_PARAMS } from 'smily-admin-ui/controllers/tasks/index';
import type CacheService from 'smily-admin-ui/services/cache';
import { BaseRoute } from 'smily-admin-ui/utils/routing';
import { buildDueAtFilter } from 'smily-admin-ui/utils/tasks/due-at-filter';

type RouteParams = Record<QueryParam, string | undefined> & {
  page: number;
  sort: string;
};

function buildFilters(params: RouteParams) {
  const filter: Record<string, unknown> = {};

  FILTER_PARAMS.forEach((key) => {
    const value = params[key];

    if (!value) {
      return;
    } else if (key === 'name') {
      filter[key] = { op: 'matches', value };
    } else if (key === 'dueAt') {
      filter[key] = buildDueAtFilter(value);
    } else {
      filter[key] = value;
    }
  });

  return filter;
}

export default class TasksIndexRoute extends BaseRoute {
  @service cache!: CacheService;
  @service store!: StoreService;

  queryParams = {
    ...Object.fromEntries(
      [...FILTER_PARAMS, 'page', 'sort'].map((key) => [
        key,
        { refreshModel: true },
      ]),
    ),
  };

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    const params = transition.to.queryParams as Partial<RouteParams>;

    const filtersPromise = this.store.loadModelsById([
      {
        modelName: 'rental',
        ids: params.rental,
      },
      {
        modelName: 'user',
        ids: params.assignee,
      },
      {
        modelName: 'task-management-template',
        ids: params.template,
      },
    ]);

    if (this.cache.taskCount !== undefined) {
      return filtersPromise;
    }

    return all([filtersPromise, this.cache.updateTasksCount()]);
  }

  model(params: RouteParams) {
    const { page } = params;

    const query = this.store.generateQuery(
      'task-management-task',
      'listDefault',
      buildFilters(params),
      params.sort,
    );

    return new InfiniteQuery(this.store, 'task-management-task', query, {
      page,
    });
  }
}
