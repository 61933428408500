import type { Snapshot } from '@ember-data/store';
import type StoreService from 'ember-smily-base/services/store';
import type {
  ResourceObject,
  Response,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import { isNew } from 'ember-smily-base/utils/serializer';
import type { Model } from 'ember-smily-base/utils/store';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';
import { trim } from 'smily-admin-ui/utils/serializer';

export interface BookingFeePayload {
  rentalFeeId?: string;
  amount: number;
  quantity: number;
  id?: string;
}

function addBookingFeesToJson(
  snapshot: Snapshot,
  json: ResponseWithData<ResourceObject>,
) {
  const bookingFees = snapshot.hasMany('bookingFees');

  if (!bookingFees) {
    return json;
  }

  return {
    ...json,
    data: {
      ...json.data,
      attributes: {
        ...json.data.attributes,
        bookingFees: bookingFees.map(serializeBookingFee),
      },
    },
  };
}

function serializeBookingFee(fee: Snapshot) {
  const payload: Record<string, unknown> = {
    price: fee.attr('price'),
    timesBooked: fee.attr('timesBooked'),
    rentalFeeId: fee.belongsTo('rentalFee')?.id,
  };

  if (fee.id && !fee.id.includes('-')) {
    payload.id = fee.id;
  }

  return payload;
}

export default class BookingSerializer extends ApplicationSerializer<'booking'> {
  serialize(snapshot: Snapshot, options?: {}) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;

    trim(snapshot, json, { undefined: false });

    if (isNew(snapshot, options)) {
      // strip nullish values from new bookings
      json.data.attributes = Object.fromEntries(
        Object.entries(json.data.attributes!).filter(
          ([_, value]) => value !== null,
        ),
      );
    }

    return addBookingFeesToJson(snapshot, json);
  }

  normalizeSingleResponse(
    store: StoreService,
    primaryModelClass: Model,
    payload: object,
    id: string | number,
    requestType: string,
  ) {
    const json = super.normalizeSingleResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    ) as Response<ResourceObject>;

    const attributes = json.data?.attributes;

    if (!attributes) {
      return json;
    }

    if (attributes.status === 'Booked') {
      attributes.booked = true;
    } else if (attributes.status === 'Unavailable') {
      attributes.unavailable = true;
    }

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    booking: BookingSerializer;
  }
}
