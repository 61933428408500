import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import RouteTemplate from 'ember-route-template';
import AnnotatedCard from 'ember-smily-base/components/annotated-card';
import Form from 'ember-smily-base/components/form';
import InputsRadioGroup from 'ember-smily-base/components/inputs/radio-group';
import List from 'ember-smily-base/components/list';
import { createChangeset } from 'ember-smily-base/utils/model';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { SelectOption } from 'ember-smily-base/utils/select';
import not from 'ember-truth-helpers/helpers/not';
import type SettingsRoute from 'smily-admin-ui/routes/settings';

interface TemplatesSettingsCompanyLegalSignature {
  Args: {
    model: ModelFor<SettingsRoute>;
  };
}

class TemplatesSettingsCompanyLegal extends Component<TemplatesSettingsCompanyLegalSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;

  @tracked declare _isVatRegistered: boolean;

  companyChangeset = createChangeset(this.args.model.accountCompany);

  declare cachedVatNumber: string;

  get yesNoOptions(): SelectOption<boolean>[] {
    this.intl.primaryLocale;

    return [true, false].map((value) => ({
      label: this.intl.t(`common.yes_no_options.${value}`),
      value,
    }));
  }

  get isVatRegistered(): boolean {
    return this._isVatRegistered ?? !!this.args.model.accountCompany.vatNumber;
  }

  @action
  changeVatRegistered(value: boolean): void {
    this._isVatRegistered = value;

    if (value && this.cachedVatNumber) {
      this.companyChangeset.set('vatNumber', this.cachedVatNumber);
    }

    if (!value) {
      this.cachedVatNumber = this.companyChangeset.get('vatNumber');
      this.companyChangeset.set('vatNumber', null);
    }
  }

  save = task({ drop: true }, async () => {
    await this.companyChangeset.save();
    await this.router.transitionTo('settings.billing.index');

    this.notify.success(
      this.intl.t('settings.company.company_details_updated'),
    );
  });

  <template>
    <div class='p-3 d-flex flex-column gap-3'>
      <AnnotatedCard @title={{t 'settings.company.legal_information'}}>
        <Form
          @model={{this.companyChangeset}}
          @onSubmit={{perform this.save}}
          as |F|
        >
          <List @sticky={{true}} as |Item|>
            <Item class='d-flex flex-column gap-2'>
              <div>
                {{t 'settings.company.registered_as_professional'}}
              </div>

              <F.Radio
                @property='registeredProfessional'
                @options={{this.yesNoOptions}}
                class='d-flex gap-2'
              />

              <BsCollapse
                @collapsed={{not this.companyChangeset.registeredProfessional}}
              >
                <F.File @property='incorporationCertificateFile' />
              </BsCollapse>
            </Item>

            <Item class='d-flex flex-column gap-2'>
              <div>
                {{t 'settings.company.vat_registered'}}
              </div>

              <InputsRadioGroup
                @value={{this.isVatRegistered}}
                @options={{this.yesNoOptions}}
                @onChange={{this.changeVatRegistered}}
                class='d-flex gap-2'
              />

              <BsCollapse @collapsed={{not this.isVatRegistered}}>
                <F.Input @property='vatNumber' />
              </BsCollapse>
            </Item>

            <Item class='d-flex flex-column gap-2'>
              <div>
                {{t 'settings.company.declare_revenue'}}
              </div>

              <F.Radio
                @property='declaresRevenue'
                @options={{this.yesNoOptions}}
                class='d-flex gap-2'
              />
            </Item>
          </List>

          <F.Submit
            class='align-self-lg-end'
            disabled={{this.companyChangeset.isPristine}}
          >
            {{t 'common.save_changes'}}
          </F.Submit>
        </Form>
      </AnnotatedCard>
    </div>
  </template>
}

export default RouteTemplate(TemplatesSettingsCompanyLegal);
