import type StoreService from 'ember-smily-base/services/store';
import type { LocalDatetime } from 'ember-smily-base/utils/date';
import { dateString } from 'ember-smily-base/utils/date';
import type {
  NewResourceObject,
  Request,
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import { callAction } from 'ember-smily-base/utils/model';
import type { Changeset } from 'ember-smily-base/utils/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type RentalModel from 'smily-admin-ui/models/rental';

interface BookingFeePayload {
  rentalFeeId?: string;
  amount: number;
  quantity: number;
  id?: string;
}

let quoteId = 1;

export default async function getQuote(
  store: StoreService,
  changeset: Changeset,
) {
  const result = (await callAction(
    store.createRecord('reservations-quote'),
    'calculate',
    getQuotePayload(changeset) as unknown as Record<string, unknown>,
  )) as ResponseWithData<ResourceObject>;
  const id = quoteId++;

  result.data.type = 'reservations-quote-result';
  result.data.id = id.toString();

  result.included?.forEach((data) => {
    if (data.type !== 'booking-fees' || data.id.includes('-')) {
      return;
    }

    store.peekRecord('booking-fee', data.id)?.unloadRecord();
    store.pushPayload('booking-fee', { data });
  });

  store.pushPayload('reservation-quote-result', result);

  return store.peekRecord('reservations-quote-result', id)!;
}

function getQuotePayload(changeset: Changeset) {
  const payload = {
    data: {
      type: 'reservations-quotes',
      attributes: {
        startDate: dateString((changeset.startAt as LocalDatetime).date),
        endDate: dateString((changeset.endAt as LocalDatetime).date),
        expectedInvoiceIssueDate: dateString(
          (changeset.expectedInvoiceIssueDate as Date | undefined) ??
            new Date(),
        ),
        adults: changeset.adults,
        bookingFees: getBookingFeesPayload(changeset),
      },
      relationships: {
        rental: {
          data: {
            type: 'rentals',
            id: (changeset.rental as RentalModel).id,
          },
        },
      },
    },
  } as Request<NewResourceObject>;

  [
    'children',
    'currency',
    'initialPrice',
    'discount',
    'reconciliation',
  ].forEach((key) => {
    if (changeset.get(key)) {
      payload.data.attributes![key] = changeset.get(key);
    }
  });

  if (changeset.get('id')) {
    payload.data.relationships!.booking = {
      data: {
        type: 'bookings',
        id: changeset.get('id'),
      },
    };
  }

  return payload;
}

function getBookingFeesPayload(changeset: Changeset) {
  return (changeset.bookingFees as BookingModel['bookingFees'])
    .filter((fee) => fee.timesBooked)
    .map((fee) => {
      const payload: BookingFeePayload = {
        amount: fee.price,
        quantity: fee.timesBooked,
      };

      if (fee.id && !fee.id.includes('-')) {
        payload.id = fee.id;
      }

      if (fee.rentalFee?.id) {
        payload.rentalFeeId = fee.rentalFee?.id;
      }

      return payload;
    });
}
