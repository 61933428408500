import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import LoadingState from 'ember-smily-base/components/loading-state';

interface TasksTaskAddAttachmentSignature {
  Element: HTMLDivElement;
  Args: {
    isAddingAttachment: boolean;
    onChange: (event: Event) => Promise<void>;
  };
}

export default class TasksTaskAddAttachment extends Component<TasksTaskAddAttachmentSignature> {
  get id() {
    return guidFor(this);
  }

  @action
  clickInput() {
    document.getElementById(this.id)!.click();
  }

  <template>
    <div class='file-input' ...attributes>
      {{! template-lint-disable require-input-label }}
      <input
        {{on 'change' @onChange}}
        type='file'
        id={{this.id}}
        accept='image/*,video/*,.pdf'
        aria-label={{t 'tasks.task_form.add_files_and_media'}}
        tabindex='-1'
      />
      {{! template-lint-enable require-input-label }}

      <Link
        @action={{this.clickInput}}
        class='btn btn-secondary file-input-mask interactive-icon btn-sm'
        disabled={{@isAddingAttachment}}
        aria-label={{t 'tasks.task_form.add_attachment'}}
      >
        {{#if @isAddingAttachment}}
          <LoadingState @isLoading={{true}} class='small' />
        {{else}}
          <Icon @icon='paperclip' @style='far' />
        {{/if}}
      </Link>
    </div>
  </template>
}
