import { attr } from '@ember-data/model';
import { resourceAction } from 'ember-custom-actions';
import { BaseModel } from 'smily-admin-ui/utils/model';

export enum TranscriptRole {
  User = 'user',
  Assistant = 'assistant',
}

export interface SmilyCopilotMessagePayload {
  data: {
    attributes: {
      message: string;
      'conversation-id'?: string;
    };
  };
}

export interface Message {
  role: TranscriptRole;
  content: string;
}

export default class SmilyCopilotConversationModel extends BaseModel {
  @attr('array') transcript!: Message[];

  createMessage = resourceAction<SmilyCopilotConversationModel, false>(
    'create-message',
    {
      method: 'POST',
      pushToStore: false,
    },
  );
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'smily-copilot-conversation': SmilyCopilotConversationModel;
  }
}
