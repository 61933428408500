import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type { FormYield } from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import type { Changeset } from 'ember-smily-base/utils/model';
import { asBoolean } from 'ember-smily-base/utils/template';
import or from 'ember-truth-helpers/helpers/or';
import TasksChecklist from 'smily-admin-ui/components/tasks/checklist';
import {
  CATEGORIES,
  PRIORITIES,
} from 'smily-admin-ui/models/task-management-task';
import { allowNumbersOnly } from 'smily-admin-ui/utils/dom';
import {
  DurationChangeset,
  getTimeUnitOptions,
} from 'smily-admin-ui/utils/tasks';

interface TasksTemplateFormTaskSectionSignature {
  Element: HTMLDivElement;
  Args: {
    form: FormYield;
    changeset: Changeset;
    isTemplate?: boolean;
  };
}

export default class TasksTemplateFormTaskSection extends Component<TasksTemplateFormTaskSectionSignature> {
  @service intl!: IntlService;

  durationChangeset = new DurationChangeset(
    this.args.changeset,
    this.args.isTemplate
      ? 'taskExpectedDurationInMinutes'
      : 'expectedDurationInMinutes',
  );

  get timeUnitOptions() {
    return getTimeUnitOptions(this.intl, this.durationChangeset.value);
  }

  get categoryOptions() {
    return CATEGORIES.map((value) => ({
      value,
      label: this.intl.t(`tasks.category_options.${value}`),
    }));
  }

  get priorityOptions() {
    return PRIORITIES.map((value) => ({
      value,
      label: this.intl.t(`tasks.priority_options.${value}`),
    }));
  }

  <template>
    <@form.Section
      @icon='check-square'
      @title={{t 'tasks.task_form.task_content'}}
      @subtitle={{t 'tasks.task_form.task_content_subtitle'}}
    >
      <List @large={{true}} @sticky={{true}} @style='light' as |SectionItem|>
        <SectionItem>
          <@form.Input
            @property={{if @isTemplate 'taskName' 'name'}}
            @placeholder={{t 'tasks.task_form.name_placeholder'}}
            @required={{true}}
          />

          <@form.Textarea
            @property={{if @isTemplate 'taskDescription' 'description'}}
            @placeholder={{t 'tasks.task_form.description_placeholder'}}
            @required={{true}}
          />
        </SectionItem>

        <SectionItem>
          <TasksChecklist
            @changeset={{@changeset}}
            @isTemplate={{or (asBoolean @changeset.isNew) @isTemplate}}
          />
        </SectionItem>

        <SectionItem>
          <div
            {{didUpdate
              this.durationChangeset.changeDuration
              this.durationChangeset.value
              this.durationChangeset.unit
            }}
            class='d-flex flex-column flex-lg-row align-items-lg-end gap-2'
          >
            <@form.Input
              @model={{this.durationChangeset}}
              @property='value'
              @type='tel'
              @label={{t 'tasks.task_form.expected_duration'}}
              @placeholder={{t 'tasks.task_form.time_value_placeholder'}}
              @required={{true}}
              @onInput={{allowNumbersOnly}}
              class='flex-1'
              data-smily-form-field='taskExpectedDurationInMinutes'
            />

            <@form.Select
              @model={{this.durationChangeset}}
              @property='unit'
              @options={{this.timeUnitOptions}}
              @renderLabel={{false}}
              @required={{true}}
              class='flex-1'
            />
          </div>

          <@form.Select
            @property={{if @isTemplate 'taskCategory' 'category'}}
            @placeholder={{t 'tasks.task_form.category_placeholder'}}
            @options={{this.categoryOptions}}
            @required={{true}}
          />

          <@form.Select
            @property={{if @isTemplate 'taskPriority' 'priority'}}
            @placeholder={{t 'tasks.task_form.priority_placeholder'}}
            @options={{this.priorityOptions}}
            @required={{true}}
          />

          <@form.SelectInfinite
            @property={{if @isTemplate 'taskAssignee' 'assignee'}}
            @label={{t 'tasks.task_form.assignee'}}
            @modelName='user'
            @query={{generateQuery 'user' 'nameOnly'}}
            @displayField='fullName'
            @searchField='fullname'
            @loose={{true}}
            @allowClear={{true}}
            @placeholder={{t 'tasks.task_form.assignee_placeholder'}}
            @helperText={{t 'tasks.task_form.assignee_helper_text'}}
          />
        </SectionItem>
      </List>
    </@form.Section>
  </template>
}
