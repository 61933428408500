import type { TOC } from '@ember/component/template-only';
import LoadingButton from 'ember-smily-base/components/loading-button';

const CreditCardFormSubmit: TOC<{
  Element: HTMLElement;
  Args: {
    save: () => Promise<void>;
  };
  Blocks: { default: [] };
}> = <template>
  <LoadingButton
    @action={{@save}}
    class='btn btn-primary align-self-lg-end'
    ...attributes
  >
    {{yield}}
  </LoadingButton>
</template>;

export default CreditCardFormSubmit;
