import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import can from 'ember-can/helpers/can';
import call from 'ember-composable-helpers/helpers/call';
import and from 'ember-truth-helpers/helpers/and';
import not from 'ember-truth-helpers/helpers/not';
import CalendarsDamageDepositBadge from 'smily-admin-ui/components/calendars/damage-deposit-badge';
import CalendarsStatusCircle from 'smily-admin-ui/components/calendars/status-circle';
import type BookingModel from 'smily-admin-ui/models/booking';

function showDamageDepositBadge(booking: BookingModel) {
  return !booking.isUnavailable && !!booking.damageDeposit;
}

const CalendarsPlanningBookingPillContent: TOC<{
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}> = <template>
  {{#if
    (and
      (can 'view payments in booking' @booking)
      (call (fn showDamageDepositBadge @booking))
    )
  }}
    <CalendarsDamageDepositBadge @booking={{@booking}} class='pill-corner' />
  {{/if}}

  <div
    class='planning-calendar-booking-pill-inner px-2 d-flex align-items-center gap-1 text-truncate'
    ...attributes
  >
    {{#if
      (and
        (can 'view payments in booking' @booking) (not @booking.isUnavailable)
      )
    }}
      <CalendarsStatusCircle @booking={{@booking}} />
    {{/if}}

    <div class='d-flex gap-1'>
      {{@booking.clientFullname}}

      {{#if @booking.adults}}
        <div>•</div>

        <div>
          {{@booking.adults}}

          {{#if @booking.children}}
            +
            {{@booking.children}}
          {{/if}}
        </div>
      {{/if}}

      {{#if @booking.notes}}
        <div>•</div>

        <div>{{@booking.notes}}</div>
      {{/if}}
    </div>
  </div>
</template>;

export default CalendarsPlanningBookingPillContent;
