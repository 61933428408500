import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';

const ErrorScreen = <template>
  <div
    class='w-100 h-100 p-3 d-flex flex-column align-items-center justify-content-center gap-3'
  >
    <Icon @icon='exclamation-triangle' class='mb-4 text-75 text-lg-100' />

    <h3 class='text-lg-28'>
      {{t 'user.404.title'}}
    </h3>

    <div class='text-lg-18 text-center'>
      {{t 'user.404.subtitle'}}
    </div>

    <LinkTo @route='index' class='btn btn-primary'>
      {{t 'user.404.button'}}
    </LinkTo>
  </div>
</template>;

export default ErrorScreen;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ErrorScreen: typeof ErrorScreen;
  }
}
