import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class InboxAttachmentAbility extends Ability<'inbox-attachment'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'inbox-attachment');
  }
}
