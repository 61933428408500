import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import BsTooltip from 'ember-bootstrap/components/bs-tooltip';
import findBy from 'ember-composable-helpers/helpers/find-by';
import t from 'ember-intl/helpers/t';
import type NotifyService from 'ember-notify/services/notify';
import RouteTemplate from 'ember-route-template';
import Breadcrumbs from 'ember-smily-base/components/breadcrumbs';
import Form from 'ember-smily-base/components/form';
import Notifications from 'ember-smily-base/components/notifications';
import Select from 'ember-smily-base/components/select/simple';
import SmilyLayoutRouteIcon from 'ember-smily-base/components/smily-layout/route-icon';
import type StoreService from 'ember-smily-base/services/store';
import type { Error } from 'ember-smily-base/utils/json-api';
import { handleErrors } from 'ember-smily-base/utils/model';
import UnauthenticatedScreen from 'smily-admin-ui/components/unauthenticated-screen';
import type CompleteChannelSignupController from 'smily-admin-ui/controllers/complete-channel-signup';
import { createCustomActionProxy } from 'smily-admin-ui/utils/model';

interface Args {
  controller: CompleteChannelSignupController;
}

class TemplatesCompleteChannelSignup extends UnauthenticatedScreen<Args> {
  @service notify!: NotifyService;
  @service store!: StoreService;

  data: Record<string, unknown> = {};

  accountChannelSignup = this.store.createRecord('account-channel-signup');

  @action
  async setPassword(): Promise<void> {
    await createCustomActionProxy(
      this.accountChannelSignup,
      'completeChannelSignup',
      this.store,
      {
        payload: {
          data: {
            type: 'users',
            attributes: {
              token: this.args.controller.token,
              password: this.data.password,
              'accept-privacy-policy': true,
            },
          },
        },
        custom: true,
      },
    );
    const helperModel = this.store.createRecord('account-channel-signup');
    const payload = {
      data: {
        type: 'users',
        attributes: {
          token: this.args.controller.token,
          password: this.data.password,
          'accept-privacy-policy': true,
        },
      },
    };

    try {
      await helperModel.completeChannelSignup(payload);

      helperModel.destroyRecord();
      window.location.replace(window.location.origin);
    } catch (error) {
      handleErrors(helperModel, error as { errors: Error[] }, {
        notify: this.notify,
        intl: this.intl,
        // serializer: this.store.serializerFor('account-channel-signup' as never),
      });
    }
  }

  <template>
    <div class='h-100 d-flex flex-column'>
      <div class='smily-topbar'>
        <div class='container-xl d-flex justify-content-between'>
          <div class='d-flex align-items-center'>
            <div class='smily-topbar-icon'>
              <SmilyLayoutRouteIcon />
            </div>

            <Breadcrumbs class='flex-1' />
          </div>

          {{#unless @hideLocaleSelect}}
            <div class='d-flex align-items-center'>
              <Select
                @value={{findBy 'value' this.intl.primaryLocale this.languages}}
                @options={{this.languages}}
                @triggerClass='btn btn-link link-unstyled btn-sm with-smily-select-icon'
                @onChange={{this.changeLocale}}
                as |item|
              >
                <div class='d-flex align-items-center gap-1'>
                  <div class='smily-flag {{item.flag}}'></div>

                  <div>{{item.label}}</div>
                </div>
              </Select>
            </div>
          {{/unless}}
        </div>
      </div>

      <div class='bg-white flex-1 overflow-auto'>
        <div class='min-h-100 d-flex align-items-center justify-content-center'>
          <div class='signup-container p-3 p-lg-5'>
            <div class='d-flex flex-column gap-3'>
              <h2>
                {{t 'onboarding.complete_channel_signup.title'}}
              </h2>

              <div>
                {{t 'onboarding.complete_channel_signup.subtitle'}}
              </div>

              <Form @model={{this.data}} @onSubmit={{this.setPassword}} as |F|>
                <div>
                  <BsTooltip>
                    {{t 'onboarding.signup.password_requirements'}}
                  </BsTooltip>

                  <F.Input
                    @property='password'
                    @label={{t
                      'onboarding.complete_channel_signup.password_placeholder'
                    }}
                    @type='password'
                    @autocomplete='current-password'
                    @required={{true}}
                    data-smily-form-object={{guidFor this.accountChannelSignup}}
                  />
                </div>

                <F.Submit @label={{t 'onboarding.signup.get_started'}} />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Notifications />
  </template>
}

export default RouteTemplate(TemplatesCompleteChannelSignup);
