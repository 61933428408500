export interface TaskConfigEntry {
  route: string;
  condition: string;
  locked?: string;
  hidden?: string;
}

interface StepConfigEntry {
  2: TaskConfigEntry[];
  3: TaskConfigEntry[];
  4: TaskConfigEntry[];
}

const stepConfig: StepConfigEntry = {
  // rental task completion does not affect the UI in any way
  2: [{ route: 'rental', condition: 'nonExistent' }],
  3: [
    {
      route: 'contact',
      condition: 'complete_contact_details',
      locked: 'hasFirstThreeTasksCompleted',
    },
    {
      route: 'company',
      condition: 'complete_company_details',
      locked: 'hasFirstThreeTasksCompleted',
    },
    {
      route: 'bank',
      condition: 'complete_bank_details',
      locked: 'hasFirstThreeTasksCompleted',
    },
    {
      route: 'services',
      condition: 'book_a_call',
      locked: 'hasFirstFourTasksCompleted',
    },
    {
      route: 'phone',
      condition: 'verify_otp',
      locked: 'isPhoneTaskLocked',
    },
  ],
  4: [
    {
      route: 'billing',
      condition: 'authenticate_credit_card,complete_credit_card_details',
    },
    {
      route: 'company-documents',
      condition: 'complete_company_documents',
      hidden: 'isIndividual',
    },
    {
      route: 'identity',
      condition: 'complete_identity_documents',
    },
    {
      route: 'tax-information',
      condition: 'complete_taxpayer_information',
      hidden: 'isNotIndividual',
    },
    { route: 'picture', condition: 'complete_profile_picture' },
  ],
};

export default stepConfig;
