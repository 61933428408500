import Route from '@ember/routing/route';
import { service } from '@ember/service';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import type StoreService from 'ember-smily-base/services/store';
import type CreditCardModel from 'smily-admin-ui/models/credit-card';

export default class CreditCardAuthRoute extends Route {
  @service store!: StoreService;

  model({
    credit_card_id,
  }: {
    credit_card_id: string;
  }): DS.PromiseObject<CreditCardModel> {
    return this.store.findRecord('credit-card', credit_card_id);
  }
}
