import { service } from '@ember/service';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import type AbilitiesService from 'ember-can/services/abilities';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import HostModel from 'smily-admin-ui/models/host';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';
import type InboxParticipantModel from 'smily-admin-ui/models/inbox-participant';
import type SessionService from 'smily-admin-ui/services/session-service';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type Visibility = 'internal' | 'external';
export type Priority = (typeof PRIORITIES)[number];
export type Severity = (typeof SEVERITIES)[number];
export type Origin =
  | 'airbnb_message'
  | 'booking-com'
  | 'email'
  | 'inquiry'
  | 'bookingsync_inbox'
  | 'automated_message';
export type Channel = (typeof CHANNELS)[number];

export const PRIORITIES = ['low', 'medium', 'high', 'urgent'] as const;
export const SEVERITIES = [
  'informational',
  'minor',
  'major',
  'critical',
] as const;
export const CHANNELS = [
  'airbnb',
  'booking-com',
  'email',
  'expedia',
  'homeaway',
  'vrbo',
] as const;

export default class InboxMessageModel extends BaseModel {
  @service abilities!: AbilitiesService;
  @service session!: SessionService;

  @attr('date', { readOnly: true }) sentAt!: Date;
  @attr('string', { defaultValue: 'external' }) visibility!: Visibility;
  @attr('string', { defaultValue: 'bookingsync_inbox' }) origin!: Origin;
  @attr('string', { defaultValue: '' }) content?: string;
  @attr('string') channel?: Channel;
  @attr('string', { readOnly: true }) priority?: Priority;
  @attr('string', { readOnly: true }) severity?: Severity;

  @belongsTo('inbox-participant', { async: false, inverse: null })
  sender?: InboxParticipantModel;

  @belongsTo('inbox-conversation', { async: false, inverse: 'messages' })
  conversation!: InboxConversationModel;

  @hasMany('inbox-attachment', { async: false, inverse: 'messages' })
  attachments!: SyncHasMany<InboxAttachmentModel>;

  static compoundFields = ['isNote'];

  /* eslint-disable ember/no-get, ember/classic-decorator-no-classic-methods */
  get isNote() {
    return this.get('visibility') === 'internal';
  }
  /* eslint-enable ember/no-get, ember/classic-decorator-no-classic-methods */

  get isByHost() {
    return this.sender?.member instanceof HostModel;
  }

  get senderProfilePicture() {
    const member = this.sender?.member;

    if (!(member instanceof HostModel)) {
      return undefined;
    }

    const canViewHostUser = this.abilities.can('view user in host', member);

    return canViewHostUser ? member.user?.profilePicture : undefined;
  }
}

export function asAttachments(attacments: unknown) {
  return attacments as SyncHasMany<InboxAttachmentModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'inbox-message': InboxMessageModel;
  }
}
