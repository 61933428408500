import * as Sentry from '@sentry/ember';

export default function setContext(
  name: string,
  context: {
    [key: string]: unknown;
  } | null,
): void {
  Sentry.setContext(name, context);
}
