import Transform from '@ember-data/serializer/transform';
import config from 'smily-admin-ui/config/environment';
import { isRelativeUrl } from 'smily-admin-ui/utils/request';

export default class ImageTransform extends Transform {
  deserialize(serialized?: { url: string } | null) {
    const url = serialized?.url;

    if (!url || !isRelativeUrl(url)) {
      return url;
    }

    return `${config.API.HOST}${url}`;
  }

  serialize(deserialized?: string | null) {
    return deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    image: string;
  }
}
