import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type HostReviewModel from 'smily-admin-ui/models/host-review';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class ReviewsHostReviewRoute extends BaseRoute {
  @service store!: StoreService;

  async model({
    host_review_id: id,
  }: {
    host_review_id: string;
  }): Promise<HostReviewModel> {
    const reviews = await this.store.queryRecords(
      'host-review',
      this.store.generateQuery('host-review', 'default', { id }),
    );

    return reviews.firstObject as HostReviewModel;
  }

  get title() {
    return this.intl.t('reviews.host_review_breadcrumb', {
      name: this.currentModel?.booking.client.fullName,
    });
  }
}
