import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import SignupLayout from 'smily-admin-ui/components/signup/layout';
import type SignupFreeRoute from 'smily-admin-ui/routes/signup/free';

const TemplatesSignupFree: TOC<{
  Args: {
    model: ModelFor<SignupFreeRoute>;
  };
}> = <template>
  <SignupLayout @onboarding={{@model}} @isFree={{true}} />
</template>;

export default RouteTemplate(TemplatesSignupFree);
