import { attr, belongsTo } from '@ember-data/model';
import type { Day } from 'date-fns/types';
import { customAction, modelAction } from 'ember-custom-actions';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import type { FileMeta } from 'ember-smily-base/utils/model';
import { fileMeta } from 'ember-smily-base/utils/model';
import { join } from 'ember-smily-base/utils/string';
import type AccountModel from 'smily-admin-ui/models/account';
import type AccountsUserModel from 'smily-admin-ui/models/accounts-user';
import type ApplicationMetadatumModel from 'smily-admin-ui/models/application-metadatum';
import { BaseModel, generateFullAddress } from 'smily-admin-ui/utils/model';

export default class UserModel extends BaseModel {
  @attr firstName?: string;
  @attr lastName?: string;
  @attr email?: string;
  @attr('image') profilePicture?: string;
  @attr('language') preferredLocale?: string;
  @attr('number') preferredWeekOffset?: Day;
  @attr preferredBookingsView?: string;
  @attr('boolean', { readOnly: true }) superuser?: boolean;
  @attr('boolean', { readOnly: true }) hasSignedIn?: boolean;
  @attr('boolean', { readOnly: true }) confirmed?: boolean;

  @attr city?: string;
  @attr zip?: string;
  @attr countryCode?: CountryCode;
  @attr address1?: string;
  @attr address2?: string;
  @attr phone?: string;
  @attr gender?: string;

  @attr password?: string;
  @attr('string', { readOnly: true }) oldPassword?: string;
  @attr('string', { readOnly: true }) newPassword?: string;
  @attr('string', { readOnly: true }) newPasswordConfirmation?: string;

  @belongsTo('application-metadatum', { async: false, inverse: 'user' })
  currentApplicationMetadata?: ApplicationMetadatumModel;
  @belongsTo('account', { async: false, inverse: null, readOnly: true })
  currentAccount!: AccountModel;
  @belongsTo('accounts-user', { async: false, inverse: 'user', readOnly: true })
  accountsUser!: AccountsUserModel;

  @fileMeta('profilePicture') profilePictureFile!: FileMeta;

  get fullName(): string {
    return join([this.firstName, this.lastName], ' ');
  }

  get fullAddress(): string {
    return generateFullAddress(
      this.address1,
      this.address2,
      this.city,
      this.zip,
    );
  }

  updateOwnerContact = modelAction<UserModel>('update-owner-contact', {
    method: 'PATCH',
    pushToStore: true,
  });

  changePassword = modelAction<UserModel>('change-password', {
    method: 'PATCH',
    pushToStore: true,
  });

  completeChannelSignup = customAction<UserModel>('completeChannelSignup', {
    method: 'POST',
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
