import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { Line, ScaleLinear, ScaleTime, Selection } from 'd3';
import {
  axisBottom,
  axisLeft,
  curveCatmullRom,
  easeLinear,
  line,
  max,
  min,
  scaleLinear,
  scaleTime,
  select,
} from 'd3';
import { isSameDay } from 'date-fns';
import type IntlService from 'ember-intl/services/intl';
import { parseDateString } from 'ember-smily-base/utils/date';
import { formatValueWithUnitType } from 'smily-admin-ui/helpers/format-value-with-unit-type';
import type {
  ChartDataPoint,
  DataPoint,
  TabHelper,
} from 'smily-admin-ui/utils/performance';
import {
  createTooltip,
  moveTooltip,
  showTooltip,
} from 'smily-admin-ui/utils/performance';

interface PerformanceChartSignature {
  Args: {
    tab: TabHelper;
  };
}

export default class PerformanceChart extends Component<PerformanceChartSignature> {
  @service intl!: IntlService;

  get title() {
    return this.intl.t(`performance.tabs.${this.args.tab.name}.title`);
  }

  get baseYear() {
    return parseDateString(
      this.args.tab.baseDataPoints.at(-1)!.date,
    ).getFullYear();
  }

  get compareYear() {
    if (!this.args.tab.compareDataPoints) {
      return undefined;
    }

    return parseDateString(
      this.args.tab.compareDataPoints.at(-1)!.date,
    ).getFullYear();
  }

  @action
  draw(element: HTMLDivElement) {
    const { baseDataPoints, compareDataPoints, xUnit, yUnit } = this.args.tab;

    // Declare the dataset.
    const baseData = this._buildData(baseDataPoints);
    const compareData = compareDataPoints
      ? this._buildData(compareDataPoints, baseDataPoints)
      : undefined;
    const allPoints = [...(compareData ?? []), ...baseData];
    const isPercentage = yUnit === 'percentage';

    // Declare the y-axis domain.
    const dataMin = min(allPoints, (d) => d.y)!;
    const dataMax = max(allPoints, (d) => d.y)!;
    const dataMargin = (dataMax - dataMin) * 0.1;
    const minY = isPercentage ? 0 : Math.max(0, dataMin - dataMargin);
    const maxY = isPercentage ? 100 : dataMax + dataMargin;
    const highestValueWithUnit = formatValueWithUnitType(
      this.intl,
      Math.round(maxY),
      yUnit,
      true,
    );

    // Declare the chart dimensions and margins.
    const width = element.clientWidth;
    const height = element.clientHeight;
    const marginTop = 20;
    const marginRight = 0;
    const marginBottom = 20;
    const marginLeft = 20 + highestValueWithUnit.length * 4;
    const paddingLeft = 30;
    const paddingRight = 30;

    // Declare the x (horizontal position) scale.
    const x = scaleTime()
      .domain([
        min(allPoints, (d) => d.compareX ?? d.x) ?? new Date(),
        max(allPoints, (d) => d.compareX ?? d.x) ?? new Date(),
      ])
      .range([marginLeft + paddingLeft, width - marginRight - paddingRight]);

    // Declare the y (vertical position) scale.
    const y = scaleLinear([minY, maxY], [height - marginBottom, marginTop]);

    // Declare the line generator.
    const lineGenerator = line<ChartDataPoint>()
      .x((d) => x(d.x))
      .y((d) => y(d.y))
      .curve(curveCatmullRom);

    // Create the SVG container.
    const svg = select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

    // Add the x-axis.
    const tickValues = baseData.map((d) => d.x);
    const isDaily = xUnit === 'daily';
    const xFormat = isDaily ? 'longWithWeek' : 'monthYear';
    const tickCount = Math.min(isDaily ? 7 : 12, tickValues.length);
    svg
      .append('g')
      .attr('transform', `translate(0,${height - marginBottom})`)
      .call(
        axisBottom(x)
          .ticks(tickCount)
          .tickFormat((domainValue) => {
            if (domainValue instanceof Date) {
              return this.intl.formatDate(domainValue, { format: xFormat });
            }
            return '';
          }),
      );

    // Add the y-axis, remove the domain line and add grid lines.
    svg
      .append('g')
      .attr('transform', `translate(${marginLeft},0)`)
      .call(
        axisLeft(y)
          .ticks(height / 40)
          .tickFormat((d) =>
            formatValueWithUnitType(this.intl, d as number, yUnit, true),
          ),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', width - marginLeft - marginRight)
          .attr('stroke-opacity', 0.1),
      );

    // Draw the lines.
    if (compareData) {
      this.drawLine(compareData, true, svg, lineGenerator);
    }

    this.drawLine(baseData, false, svg, lineGenerator);

    // Draw the dots.
    this.drawDots(allPoints, svg, x, y);

    // Add the tooltip.
    this.drawTooltip(svg, baseData, compareData);
  }

  private _buildData(dataset: DataPoint[], compareBase?: DataPoint[]) {
    return dataset.map((point, index) => {
      const x = parseDateString(point.date);
      const y = point.value;

      if (compareBase) {
        return {
          x,
          y,
          compareX: parseDateString(compareBase[index]!.date),
        } as ChartDataPoint;
      } else {
        return { x, y } as ChartDataPoint;
      }
    });
  }

  drawLine(
    data: ChartDataPoint[],
    isCompare: boolean,
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    lineGenerator: Line<ChartDataPoint>,
  ) {
    const adjustedData = data.map(({ x, y, compareX }) => ({
      x: compareX ? compareX : x,
      y,
    }));

    svg
      .append('path')
      .attr(
        'class',
        isCompare
          ? 'performance-legend-compare-color'
          : 'performance-legend-base-color',
      )
      .attr('fill', 'none')
      .attr('stroke-width', 1.5)
      .datum(adjustedData)
      .attr('d', lineGenerator(adjustedData))
      .style('filter', 'drop-shadow(0px 0px 30px var(--bs-primary))')
      .style('stroke-dasharray', function () {
        const totalLength = this.getTotalLength();
        return totalLength + ' ' + totalLength;
      })
      .style('stroke-dashoffset', function () {
        return this.getTotalLength();
      })
      .transition()
      .duration(500)
      .ease(easeLinear)
      .style('stroke-dashoffset', 0);
  }

  drawDots(
    data: ChartDataPoint[],
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
  ) {
    svg
      .selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', (d) => {
        const colorClass = d.compareX
          ? 'performance-legend-compare-color'
          : 'performance-legend-base-color';
        return `dot ${colorClass}`;
      })
      .attr('cx', (d) => x(d.compareX ?? d.x))
      .attr('cy', (d) => y(d.y))
      .attr('r', 0)
      .transition()
      .duration(500)
      .ease(easeLinear)
      .attr('r', 5);
  }

  drawTooltip(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    baseDataPoints: ChartDataPoint[],
    compareDataPoints?: ChartDataPoint[],
  ) {
    const intl = this.intl;
    const dateFormat =
      this.args.tab.xUnit === 'daily' ? 'longWithWeek' : 'monthYear';
    const valueType = this.args.tab.yUnit;

    // Create the tooltip
    createTooltip(!!this.args.tab.compareDataPoints);

    // Add mouse events to the dots
    svg
      .selectAll('circle')
      .on('mouseover', function (event, data) {
        const d = data as ChartDataPoint;
        const baseData = baseDataPoints.find(({ x }) =>
          isSameDay(x, d.compareX ?? d.x),
        )!;
        const compareData = compareDataPoints?.find(({ compareX }) =>
          isSameDay(compareX!, d.compareX ?? d.x),
        );
        showTooltip(intl, dateFormat, valueType, baseData, compareData);
      })
      .on('mousemove', function (event) {
        moveTooltip(event);
      })
      .on('mouseout', function () {
        // On mouseout, hide the tooltip
        document.getElementById('performance-tooltip')!.style.visibility =
          'hidden';
      });
  }

  @action
  removeTooltip() {
    document.querySelector('.performance-tooltip')?.remove();
  }

  <template>
    <div class='h-100 d-flex flex-column gap-3'>
      <h5 class='fw-bold'>{{this.title}}</h5>

      <div
        {{didInsert this.draw}}
        {{willDestroy this.removeTooltip}}
        class='h-0 flex-1'
      >
      </div>

      <div class='align-self-end d-flex gap-3'>
        {{#if @tab.compareDataPoints}}
          <div class='d-flex align-items-center gap-1'>
            <div
              class='performance-chart-legend performance-legend-compare-color'
            >
            </div>

            <div>
              {{this.compareYear}}
            </div>
          </div>
        {{/if}}

        <div class='d-flex align-items-center gap-1'>
          <div class='performance-chart-legend performance-legend-base-color'>
          </div>

          <div>
            {{this.baseYear}}
          </div>
        </div>
      </div>
    </div>
  </template>
}
