import Transform from '@ember-data/serializer/transform';

export const CHECKLIST_SEPARATOR = '^°';

export default class ChecklistTransform extends Transform {
  deserialize(serialized: string[] | undefined) {
    return serialized ? serialized.join(CHECKLIST_SEPARATOR) : undefined;
  }

  serialize(deserialized: string | undefined) {
    return deserialized ? deserialized.split(CHECKLIST_SEPARATOR) : [];
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    checklist: string;
  }
}
