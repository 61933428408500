import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export const FILTER_PARAMS = [
  'taskCategory',
  'rental',
  'taskPriority',
  'status',
  'taskAssignee',
  'name',
] as const;
export type QueryParam = (typeof FILTER_PARAMS)[number];

export default class TasksTemplatesIndexController extends Controller {
  queryParams = [...FILTER_PARAMS, 'page'];

  @tracked page = 1;
  @tracked taskCategory?: string;
  @tracked rental?: string;
  @tracked taskPriority?: string;
  @tracked status?: string;
  @tracked taskAssignee?: string;
  @tracked name?: string;

  @action
  changeQueryParam(key: QueryParam, value: string): void {
    this.page = 1;
    this[key] = value;
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'tasks.templates.index': TasksTemplatesIndexController;
  }
}
