import type { TOC } from '@ember/component/template-only';
import SmilyLayoutSidebarSubmenuHeader from 'ember-smily-base/components/smily-layout/sidebar/submenu/header';
import eq from 'ember-truth-helpers/helpers/eq';
import InboxSidebarSubmenuItem from 'smily-admin-ui/components/inbox/sidebar-submenu-item';
import {
  BASE_CATEGORIES,
  STATUS_CATEGORIES,
} from 'smily-admin-ui/routes/inbox';

const SidebarSubmenusInbox: TOC<{
  Args: {
    isTemporarySubmenu?: boolean;
  };
}> = <template>
  <SmilyLayoutSidebarSubmenuHeader
    @route='inbox'
    @isTemporarySubmenu={{@isTemporarySubmenu}}
  />

  <ul class='flex-1 h-0 nav d-flex flex-column flex-nowrap overflow-y-auto'>
    {{#each BASE_CATEGORIES as |category|}}
      <InboxSidebarSubmenuItem @category={{category}} />
    {{/each}}

    {{#each STATUS_CATEGORIES as |category index|}}
      <InboxSidebarSubmenuItem
        @category={{category}}
        class='{{if (eq index 0) "border-top"}}'
      />
    {{/each}}
  </ul>
</template>;

export default SidebarSubmenusInbox;
