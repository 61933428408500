import type { TOC } from '@ember/component/template-only';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import formatMoney from 'ember-smily-base/helpers/format-money';
import type ServiceFeePaymentInvoiceModel from 'smily-admin-ui/models/service-fee-payment-invoice';

const SettingsInvoiceListItem: TOC<{
  Element: HTMLAnchorElement;
  Args: {
    invoice: ServiceFeePaymentInvoiceModel;
  };
}> = <template>
  <div>
    {{formatMoney @invoice.amount @invoice.currency}}
    •
    {{formatDate @invoice.invoicedAt format='short'}}
  </div>

  <div>
    <a
      href={{@invoice.url}}
      class='btn btn-link p-0'
      target='_blank'
      rel='noopener noreferrer'
    >
      {{t 'settings.billing.download_invoice'}}
    </a>
  </div>
</template>;

export default SettingsInvoiceListItem;
