import type { TabConfig } from './types';

const tabConfig: TabConfig = [
  {
    name: 'numberOfBookings',
    apiType: 'scoreboard-number-of-bookings',
    type: 'count',
    category: 'calendarOccupancy',
    order: 1,
  },
  {
    name: 'occupancyRate',
    apiType: 'scoreboard-occupancy-rates',
    type: 'percentage',
    category: 'calendarOccupancy',
    order: 2,
  },
  {
    name: 'averageBookingWindow',
    apiType: 'scoreboard-average-booking-windows',
    type: 'day',
    category: 'calendarOccupancy',
    order: 3,
  },
  {
    name: 'averageLengthOfStay',
    apiType: 'scoreboard-average-length-of-stay',
    type: 'day',
    category: 'calendarOccupancy',
    order: 4,
  },
  {
    name: 'grossBookingValue',
    apiType: 'scoreboard-gross-booking-values',
    type: 'currency',
    category: 'financialMetrics',
    order: 1,
  },
  {
    name: 'averageDailyRate',
    apiType: 'scoreboard-average-daily-rates',
    type: 'currency',
    category: 'financialMetrics',
    order: 2,
  },
  {
    name: 'averageRevenuePerStay',
    apiType: 'scoreboard-average-revenue-per-stay',
    type: 'currency',
    category: 'financialMetrics',
    order: 3,
  },
  {
    name: 'revenuePerAvailableNight',
    apiType: 'scoreboard-revenue-per-available-nights',
    type: 'currency',
    category: 'financialMetrics',
    order: 4,
  },
  {
    name: 'numberOfReviews',
    apiType: 'scoreboard-number-of-reviews',
    type: 'count',
    category: 'qualityMetrics',
    order: 1,
  },
  {
    name: 'averageReviewScore',
    apiType: 'scoreboard-average-review-scores',
    type: 'count',
    category: 'qualityMetrics',
    order: 2,
  },
  {
    name: 'fiveStarRatings',
    apiType: 'scoreboard-five-star-review-rates',
    type: 'percentage',
    category: 'qualityMetrics',
    order: 3,
  },
  {
    name: 'guestReviews',
    apiType: 'scoreboard-review-rates',
    type: 'percentage',
    category: 'qualityMetrics',
    order: 4,
  },
  {
    name: 'responsePercentage',
    apiType: 'scoreboard-review-response-rates',
    type: 'percentage',
    category: 'qualityMetrics',
    order: 5,
  },
  {
    name: 'hostReviewPercentage',
    apiType: 'scoreboard-host-review-rates',
    type: 'percentage',
    category: 'qualityMetrics',
    order: 6,
  },
];

export default tabConfig;
