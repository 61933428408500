import PerformanceBaseController from 'smily-admin-ui/controllers/performance/-base';

export default class PerformanceFinancialMetricsController extends PerformanceBaseController {
  type = 'financialMetrics' as const;
}

declare module '@ember/controller' {
  interface Registry {
    'financial-metrics': PerformanceFinancialMetricsController;
  }
}
