import { fn } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import Form from 'ember-smily-base/components/form';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import currencySign from 'ember-smily-base/helpers/currency-sign';
import type StoreService from 'ember-smily-base/services/store';
import { createChangeset } from 'ember-smily-base/utils/model';
import type { SelectOption } from 'ember-smily-base/utils/select';
import type BookingModel from 'smily-admin-ui/models/booking';
import type PaymentModel from 'smily-admin-ui/models/payment';
import { EDITABLE_PAYMENT_KINDS } from 'smily-admin-ui/models/payment';
import { refreshRoute } from 'smily-admin-ui/utils/routing';

interface BookingsPaymentDialogSignature {
  Element: HTMLElement;
  Args: {
    booking: BookingModel;
    payment?: PaymentModel;
    popoverId?: string;
    isNew?: boolean;
    amount?: number;
  };
  Blocks: {
    default: [];
  };
}

export default class BookingsPaymentDialog extends Component<BookingsPaymentDialogSignature> {
  @service intl!: IntlService;
  @service store!: StoreService;

  @tracked paymentChangeset = this.args.isNew
    ? this._createNewPayment()
    : createChangeset(this.args.payment!);

  get paymentKindOptions() {
    return EDITABLE_PAYMENT_KINDS.map(
      (kind) =>
        ({
          value: kind,
          label: this.intl.t(`finance.payment_kind_options.${kind}`),
        }) as SelectOption<string>,
    );
  }

  get currency() {
    return (
      (this.paymentChangeset.currency as string | undefined) ??
      this.args.booking!.currency
    );
  }

  @action
  async savePayment(toggleModal: () => void) {
    if (this.args.isNew) {
      (this.paymentChangeset._content as PaymentModel).set(
        'booking',
        this.args.booking,
      );
    }

    await this.paymentChangeset.save();
    await refreshRoute(this, 'bookings.booking');

    toggleModal();

    if (this.args.isNew) {
      this.paymentChangeset = this._createNewPayment();
    }
  }

  private _createNewPayment() {
    return createChangeset(
      this.store.createRecord('payment', {
        paidAt: new Date(),
        amount: this.args.amount,
        currency: this.args.booking!.currency,
      }),
    );
  }

  <template>
    <ModalDialog @type='side' as |dialog|>
      <dialog.toggle ...attributes>
        {{#if (has-block)}}
          {{yield}}
        {{else}}
          {{t 'common.edit'}}
        {{/if}}
      </dialog.toggle>

      <dialog.modal
        class='payment-dialog'
        data-popover-dialog={{@popoverId}}
        as |modal|
      >
        <modal.header>
          <h4>
            {{#if @isNew}}
              {{t 'finance.payment_bar.add_payment'}}
            {{else}}
              {{t 'finance.payment_bar.edit_payment'}}
            {{/if}}
          </h4>
        </modal.header>

        <modal.body>
          <Form
            @model={{this.paymentChangeset}}
            @onSubmit={{fn this.savePayment dialog.toggleModal}}
            as |F|
          >
            <F.Date @property='paidAt' @required={{true}} />

            <F.Input
              @property='amount'
              @type='tel'
              @required={{true}}
              @unit={{currencySign this.currency}}
            />

            <F.Select
              @property='kind'
              @options={{this.paymentKindOptions}}
              @required={{true}}
            />

            <F.Textarea @property='notes' />

            <F.Submit @label={{t 'common.save'}} class='align-self-end' />
          </Form>
        </modal.body>
      </dialog.modal>
    </ModalDialog>
  </template>
}
