import type { TOC } from '@ember/component/template-only';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import formatMoney from 'ember-smily-base/helpers/format-money';
import type UpcomingPayoutModel from 'smily-admin-ui/models/upcoming-payout';

const FinanceUpcomingPayoutsItem: TOC<{
  Element: HTMLUListElement;
  Args: {
    model: UpcomingPayoutModel;
  };
}> = <template>
  <div class='d-flex justify-content-between gap-3'>
    <div class='d-flex flex-column'>
      <div class='fw-semibold'>
        {{@model.paymentGateway.nameWithRole}}
      </div>

      <div class='fw-semibold'>
        {{t
          'internals.model_counters.booking'
          counter=@model.bookingPayouts.length
        }}
      </div>

      <div class='text-16'>
        {{formatDate @model.estimatedPayoutDate format='short'}}
      </div>
    </div>

    <div class='d-flex align-items-center fw-semibold'>
      {{formatMoney @model.amount @model.currency}}
    </div>
  </div>
</template>;

export default FinanceUpcomingPayoutsItem;
