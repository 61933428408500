import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'search';

export default class ClientsIndexController extends Controller {
  queryParams = ['page', 'search'];

  @tracked page = 1;
  @tracked search?: string;

  @action
  changeQueryParam(key: QueryParam, value: string): void {
    this.page = 1;
    this[key] = value;
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'clients.index': ClientsIndexController;
  }
}
