import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import type { AnyFn } from 'ember/-private/type-utils';
import queue from 'ember-composable-helpers/helpers/queue';
import t from 'ember-intl/helpers/t';
import type { SelectAPI } from 'ember-smily-base/utils/select';
import type InboxReplyTemplateModel from 'smily-admin-ui/models/inbox-reply-template';

const InboxSavedRepliesAdd: TOC<{
  Element: HTMLDivElement;
  Args: {
    select: SelectAPI<InboxReplyTemplateModel>;
    editReply: AnyFn;
    handleSaveKeyDown: (
      select: SelectAPI<InboxReplyTemplateModel>,
      event: Event,
    ) => void;
  };
}> = <template>
  <div class='d-flex justify-content-center p-2 border-top' ...attributes>
    <a
      {{on 'keydown' (fn @handleSaveKeyDown @select)}}
      {{on 'click' (queue @select.actions.close @editReply)}}
      class='link-unstyled'
      href='#'
      data-save-reply
    >
      {{t 'inbox.save_this_reply'}}
    </a>
  </div>
</template>;

export default InboxSavedRepliesAdd;
