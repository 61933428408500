import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class TaxTemplateModel extends BaseModel {
  @attr('date') startDate!: Date;
  @attr('date') endDate?: Date;
  @attr kind!: string;
  @attr('number') percentage!: number;
  @attr notes?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tax-template': TaxTemplateModel;
  }
}
