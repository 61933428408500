import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import CreditCardAuth from 'smily-admin-ui/components/credit-card-auth';
import type CreditCardAuthRoute from 'smily-admin-ui/routes/credit-card-auth';

const TemplatesCreditCardAuthPending: TOC<{
  Args: {
    model: ModelFor<CreditCardAuthRoute>;
  };
}> = <template>
  <CreditCardAuth @creditCard={{@model}} @isPending={{true}} />
</template>;

export default RouteTemplate(TemplatesCreditCardAuthPending);
