import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import t from 'ember-intl/helpers/t';
import media from 'ember-responsive/helpers/media';

const OnboardingStepTitle: TOC<{
  Element: HTMLDivElement;
  Args: {
    step: number;
  };
}> = <template>
  <div class='d-flex flex-column gap-1' ...attributes>
    {{#if (media 'isSmallScreen')}}
      <h4>
        {{t 'onboarding.step'}}
        {{@step}}
      </h4>

      <h4>
        {{t (concat 'onboarding.steps.' @step '.title')}}
      </h4>
    {{else}}
      <h4>
        {{t 'onboarding.step'}}
        {{@step}}
        -
        {{t (concat 'onboarding.steps.' @step '.title')}}
      </h4>

      <div>
        {{t (concat 'onboarding.steps.' @step '.subtitle')}}
      </div>
    {{/if}}
  </div>
</template>;

export default OnboardingStepTitle;
