import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class RentalsTagModel extends BaseModel {
  @attr name?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rentals-tag': RentalsTagModel;
  }
}
