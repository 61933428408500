import { attr, belongsTo } from '@ember-data/model';
import { differenceInDays, isBefore } from 'date-fns';
import { modelAction } from 'ember-custom-actions';
import type BookingModel from 'smily-admin-ui/models/booking';
import type ReviewReplyModel from 'smily-admin-ui/models/review-reply';
import type SourceModel from 'smily-admin-ui/models/source';
import type { ReviewCriteria } from 'smily-admin-ui/utils/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class HostReviewModel extends BaseModel {
  @attr comment?: string;
  @attr privateComment?: string;
  @attr('number') averageRating?: number;
  @attr('date') createdAt?: Date;
  @attr('date') submittedAt!: Date;
  @attr('date') expiresAt!: Date;
  @attr('date') dismissedAt?: Date;
  @attr('boolean') shareable?: boolean;
  @attr('boolean', { allowNull: true }) isGuestRecommended?: boolean;
  @attr('criteria') criteria!: ReviewCriteria;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @belongsTo('review-reply', { async: false, inverse: 'hostReview' })
  reviewReply!: ReviewReplyModel;

  @belongsTo('source', { async: false, inverse: null })
  source!: SourceModel;

  get rating(): number | undefined {
    return this.averageRating;
  }

  get daysLeft(): number {
    return differenceInDays(this.expiresAt, new Date());
  }

  get isExpired(): boolean {
    return !this.expiresAt ? false : isBefore(this.expiresAt, new Date());
  }

  get isDismissed(): boolean {
    return !!this.dismissedAt;
  }

  get isAlmostExpired(): boolean {
    return this.daysLeft <= 3;
  }

  canBeRepliedTo = false;

  submit = modelAction<HostReviewModel>('submit', {
    method: 'PATCH',
    pushToStore: true,
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'host-review': HostReviewModel;
  }
}
