import type { Snapshot } from '@ember-data/store';
import type { ModelName } from 'ember-smily-base/utils/store';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

export default class LegalTermAdapter extends ApplicationAdapter {
  urlForCustomAction<MN extends ModelName>(
    modelName: MN,
    id: string | null,
    snapshot: Snapshot<MN>,
    actionId: string,
    queryParams?: object,
  ): string {
    const url = super.urlForCustomAction(
      modelName,
      id,
      snapshot,
      actionId,
      queryParams,
    );
    const parts = url.split('legal-terms') as [string, string];

    return `${parts[0]}public/legal-terms${parts[1]}`;
  }

  methodForCustomAction(): string {
    return 'GET';
  }
}
