import PerformanceBaseController from 'smily-admin-ui/controllers/performance/-base';

export default class PerformanceQualityMetricsController extends PerformanceBaseController {
  type = 'qualityMetrics' as const;
}

declare module '@ember/controller' {
  interface Registry {
    'quality-metrics': PerformanceQualityMetricsController;
  }
}
