import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'for';

export default class PrivacyPolicyController extends Controller {
  queryParams = ['for'];

  @tracked for = 'service';
}
