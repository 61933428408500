import { array } from '@ember/helper';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import FilterBar from 'ember-smily-base/components/filter-bar';
import FiltersDateRange from 'ember-smily-base/components/filters/date-range';
import Footer from 'ember-smily-base/components/footer';
import ListingScreen from 'ember-smily-base/components/listing-screen';
import formatMoney from 'ember-smily-base/helpers/format-money';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import gt from 'ember-truth-helpers/helpers/gt';
import FinanceGrossEarningBookingItemDesktop from 'smily-admin-ui/components/finance/gross-earning-booking-item/desktop';
import FinanceGrossEarningBookingItemMobile from 'smily-admin-ui/components/finance/gross-earning-booking-item/mobile';
import FinanceListingScreenTabs from 'smily-admin-ui/components/finance/listing-screen-tabs';
import type FinanceGrossEarningsController from 'smily-admin-ui/controllers/finance/gross-earnings';
import type FinanceGrossEarningsRoute from 'smily-admin-ui/routes/finance/gross-earnings';

interface TemplatesFinanceGrossEarningsSignature {
  Args: {
    model: ModelFor<FinanceGrossEarningsRoute>;
    controller: FinanceGrossEarningsController;
  };
}

class TemplatesFinanceGrossEarnings extends Component<TemplatesFinanceGrossEarningsSignature> {
  get dates(): string | undefined {
    return this.args.controller.from
      ? `${this.args.controller.from},${this.args.controller.to}`
      : undefined;
  }

  <template>
    <ListingScreen
      @model={{@model.bookings}}
      @page={{@controller.page}}
      @mobileComponent={{FinanceGrossEarningBookingItemMobile}}
      @desktopComponent={{FinanceGrossEarningBookingItemDesktop}}
      @changePage={{@controller.changePage}}
    >
      <:tabs>
        <FinanceListingScreenTabs />
      </:tabs>

      <:filters>
        <FilterBar @filters={{array this.dates}} @model={{@model.bookings}}>
          <FiltersDateRange
            @label={{t 'common.dates'}}
            @value={{this.dates}}
            @onChange={{@controller.changeDates}}
          />
        </FilterBar>
      </:filters>
    </ListingScreen>

    <Footer>
      <div class='main-footer d-flex justify-content-between gap-3'>
        <h4>
          {{t 'common.total'}}
        </h4>

        <div class='fw-semibold'>
          {{#each @controller.grossEarnings as |grossEarning index|}}
            {{#if (gt index 0)}}
              +
            {{/if}}

            {{formatMoney grossEarning.grossAmount grossEarning.currency}}
          {{else}}
            -
          {{/each}}
        </div>
      </div>
    </Footer>
  </template>
}

export default RouteTemplate(TemplatesFinanceGrossEarnings);
