import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import BsTooltip from 'ember-bootstrap/components/bs-tooltip';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import eq from 'ember-truth-helpers/helpers/eq';
import type { Task } from 'smily-admin-ui/utils/onboarding';

const OnboardingTask: TOC<{
  Args: {
    task: Task;
  };
}> = <template>
  <LinkTo
    @route={{concat 'onboarding.' @task.route}}
    @disabled={{@task.isLocked}}
    class='link-unstyled px-0'
  >
    <div
      class='d-flex align-items-center gap-2
        {{if @task.isComplete "text-tertiary"}}'
    >
      {{#if @task.isComplete}}
        <Icon @icon='check-circle' class='text-18 text-complete' />
      {{else}}
        <Icon @icon='circle' @style='far' class='text-18 text-icon' />
      {{/if}}

      {{t (concat 'onboarding.tasks.' @task.route)}}
    </div>

    {{#if @task.isLocked}}
      <div>
        <Icon @icon='lock' class='text-20 text-icon' />

        {{#if (eq @task.route 'phone')}}
          <BsTooltip @placement='left'>
            {{t 'onboarding.phone_task_locked_helper.1'}}

            <br />

            {{t 'onboarding.phone_task_locked_helper.2'}}
          </BsTooltip>
        {{/if}}
      </div>
    {{else}}
      <div class='d-flex align-items-center gap-1 fw-semibold text-primary'>
        <div>
          {{#if @task.isComplete}}
            {{t 'common.edit'}}
          {{else}}
            {{t 'onboarding.complete'}}
          {{/if}}
        </div>

        <Icon @icon='arrow-right' class='text-primary' />
      </div>
    {{/if}}
  </LinkTo>
</template>;

export default OnboardingTask;
