import { concat, fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import pick from 'ember-composable-helpers/helpers/pick';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import IconText from 'ember-smily-base/components/icon-text';
import InputsRadioGroup from 'ember-smily-base/components/inputs/radio-group';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import Textarea from 'ember-smily-base/components/textarea';
import type StoreService from 'ember-smily-base/services/store';
import type { Error } from 'ember-smily-base/utils/json-api';
import { handleErrors } from 'ember-smily-base/utils/model';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { SelectOption } from 'ember-smily-base/utils/select';
import BookingsCard from 'smily-admin-ui/components/bookings/card';
import ReviewsHostReviewCriterion from 'smily-admin-ui/components/reviews/host-review-criterion';
import ReviewsShow from 'smily-admin-ui/components/reviews/show';
import type ReviewsHostReviewRoute from 'smily-admin-ui/routes/reviews/host/review';
import { HOST_REVIEW_CRITERIA } from 'smily-admin-ui/utils/model/review-criteria';

interface TemplatesReviewsHostReviewSignature {
  Args: {
    model: ModelFor<ReviewsHostReviewRoute>;
  };
}

class TemplatesReviewsHostReview extends Component<TemplatesReviewsHostReviewSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;
  @service store!: StoreService;

  @tracked hasComment = false;

  get criterionNames(): typeof HOST_REVIEW_CRITERIA {
    return HOST_REVIEW_CRITERIA;
  }

  get isCriteriaFilled(): boolean {
    return !HOST_REVIEW_CRITERIA.filter(
      (criterionName) => !this.args.model.criteria[criterionName].rating,
    ).length;
  }

  get isSaveDisabled(): boolean {
    const { isGuestRecommended, comment } = this.args.model;
    return (
      !comment || isGuestRecommended === undefined || !this.isCriteriaFilled
    );
  }

  get yesNoOptions(): SelectOption<boolean>[] {
    return [true, false].map((value) => ({
      label: this.intl.t(`common.yes_no_options.${value}`),
      value,
    }));
  }

  save = task({ drop: true }, async () => {
    const { model } = this.args;
    const {
      comment,
      'private-comment': privateComment,
      'is-guest-recommended': isGuestRecommended,
      criteria,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = (model.serialize() as any).data.attributes;
    const { intl, notify } = this;
    // const serializer = this.store.serializerFor('host-review' as never);

    try {
      await model.submit({
        data: {
          type: 'host-reviews',
          id: model.id,
          attributes: {
            comment,
            'private-comment': privateComment,
            'is-guest-recommended': isGuestRecommended,
            criteria,
          },
        },
      });
    } catch (error) {
      handleErrors(model, error as { errors: Error[] }, {
        intl,
        notify,
        // serializer,
      });
      throw error;
    }

    this.router.transitionTo('reviews.host');
  });

  @action
  toggleComment(): void {
    this.hasComment = !this.hasComment;

    if (!this.hasComment) {
      this.args.model.privateComment = undefined;
    }
  }

  <template>
    <div class='h-100 p-3'>
      <div class='d-flex flex-column flex-lg-row gap-3'>
        <Card class='flex-1'>
          <div
            class='card-header smily-header {{if @model.isDismissed "bg-body"}}'
          >
            <h4>{{t 'internals.models.review'}}</h4>

            {{#if @model.isDismissed}}
              <IconText
                @icon='exclamation-circle'
                @iconStyle='fal'
                @text={{t 'reviews.retracted'}}
                @gap={{1}}
              />
            {{/if}}
          </div>

          {{#if @model.submittedAt}}
            <ReviewsShow @review={{@model}} @isHostReview={{true}} />
          {{else}}
            <div class='card-body px-0 py-2'>
              <List @padded={{true}} @justified={{true}} as |Item|>
                {{#each this.criterionNames as |criterionName|}}
                  {{#let (get @model.criteria criterionName) as |criterion|}}
                    <Item>
                      <ReviewsHostReviewCriterion
                        @label={{t (concat 'reviews.criteria.' criterionName)}}
                        @criterion={{criterion}}
                        class='w-100'
                      />
                    </Item>
                  {{/let}}
                {{/each}}

                <Item>
                  <div class='fw-semibold'>
                    {{t 'reviews.is_guest_recommended'}}
                  </div>

                  <InputsRadioGroup
                    @value={{@model.isGuestRecommended}}
                    @options={{this.yesNoOptions}}
                    @onChange={{fn (mut @model.isGuestRecommended)}}
                    class='d-flex gap-3'
                  />
                </Item>

                <Item class='flex-column align-items-start gap-3'>
                  <Textarea
                    {{on
                      'input'
                      (pick 'target.value' (fn (mut @model.comment)))
                    }}
                    value={{@model.comment}}
                    placeholder={{t 'reviews.write_a_public_review'}}
                    class='form-control w-100'
                    aria-label={{t 'reviews.write_a_public_review'}}
                  />

                  <button
                    {{on 'click' this.toggleComment}}
                    type='button'
                    class='btn btn-link p-0'
                  >
                    {{t
                      (concat
                        'reviews.private_comment_button.'
                        (if this.hasComment 'remove' 'add')
                      )
                    }}
                  </button>

                  {{#if this.hasComment}}
                    <Textarea
                      {{on
                        'input'
                        (pick 'target.value' (fn (mut @model.privateComment)))
                      }}
                      value={{@model.privateComment}}
                      placeholder={{t 'reviews.write_a_public_review'}}
                      class='form-control w-100'
                      aria-label={{t 'reviews.write_a_public_review'}}
                    />
                  {{/if}}
                </Item>

                <Item class='justify-content-end'>
                  <LoadingButton
                    @action={{perform this.save}}
                    class='btn btn-primary'
                    disabled={{this.isSaveDisabled}}
                  >
                    {{t 'reviews.post_review'}}
                  </LoadingButton>
                </Item>
              </List>
            </div>
          {{/if}}
        </Card>

        <div class='review-side-column d-flex flex-column gap-3'>
          <Card>
            <div class='card-header smily-header'>
              <h4>{{t 'bookings.booking_details'}}</h4>
            </div>

            <div class='card-body py-2'>
              <LinkTo
                @route='bookings.booking'
                @model={{@model.booking.id}}
                class='link-unstyled'
              >
                <BookingsCard @booking={{@model.booking}} />
              </LinkTo>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </template>
}

export default RouteTemplate(TemplatesReviewsHostReview);
