import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import pick from 'ember-composable-helpers/helpers/pick';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import LoadingButton from 'ember-smily-base/components/loading-button';
import Textarea from 'ember-smily-base/components/textarea';
import or from 'ember-truth-helpers/helpers/or';

const MobileMessageComposer: TOC<{
  Element: HTMLDivElement;
  Args: {
    value: string | undefined;
    label: string;
    placeholder?: string;
    characterLimit?: number;
    onChange: (value: string) => void;
    send: () => void | Promise<void>;
  };
  Blocks: {
    default: [];
  };
}> = <template>
  <div class='mb-3 d-flex align-items-end gap-2' ...attributes>
    {{yield}}

    <div class='flex-1'>
      <Textarea
        {{on 'input' (pick 'target.value' @onChange)}}
        @characterLimit={{@characterLimit}}
        @height='1rem'
        value={{@value}}
        placeholder={{or @placeholder @label}}
        class='form-control'
        aria-label={{@label}}
      />
    </div>

    <div>
      <LoadingButton
        @label={{t 'common.send'}}
        @iconOnly={{true}}
        @action={{@send}}
        class='btn btn-primary px-2'
      >
        <Icon @icon='paper-plane' class='text-22' />
      </LoadingButton>
    </div>
  </div>
</template>;

export default MobileMessageComposer;
