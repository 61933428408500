import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class AccountCompanySerializer extends ApplicationSerializer<'account-company'> {
  attrs = {
    registeredProfessional:
      'registered-as-professional-at-trade-commercial-register',
    declaresRevenue: 'declares-revenue-as-professional-for-direct-tax-purposes',
  };

  formAttrs = {
    incorporationCertificate: 'incorporationCertificateFile',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'account-company': AccountCompanySerializer;
  }
}
