import type { TOC } from '@ember/component/template-only';
import formatNumber from 'ember-intl/helpers/format-number';
import t from 'ember-intl/helpers/t';
import TasksTemplateStatus from 'smily-admin-ui/components/tasks/template/status';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type TasksTemplateModel from 'smily-admin-ui/models/task-management-template';
import { getAutomationFields } from 'smily-admin-ui/utils/tasks';

const TasksTemplateListItem: TOC<{
  Element: HTMLDivElement;
  Args: {
    model: TasksTemplateModel;
  };
}> = <template>
  <td>
    {{@model.name}}
  </td>

  <td>
    {{#if @model.isManual}}
      {{optionLabel @model.status 'tasks.template_status_options'}}
    {{else}}
      {{t 'tasks.template_list.automatic'}}

      <TasksTemplateStatus @status={{@model.status}} class='flex-1' />
    {{/if}}
  </td>

  <td>
    {{t 'internals.model_counters.rental' counter=@model.rentals.length}}
  </td>

  <td>
    {{#unless @model.isManual}}
      {{#let (getAutomationFields @model.taskDueAtAutomation) as |fields|}}
        {{formatNumber
          fields.value
          style='unit'
          unit=fields.unit
          unitDisplay='long'
        }}

        {{optionLabel fields.beforeOrAfter 'tasks.before_or_after_options'}}

        {{optionLabel fields.event 'tasks.automation_event_options'}}
      {{/let}}
    {{/unless}}
  </td>
</template>;

export default TasksTemplateListItem;
