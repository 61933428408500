import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { LinkTo } from '@ember/routing';
import media from 'ember-responsive/helpers/media';
import List from 'ember-smily-base/components/list';
import LoadingState from 'ember-smily-base/components/loading-state';
import Pagination from 'ember-smily-base/components/pagination';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import ReviewsItem from 'smily-admin-ui/components/reviews/item';

function setup(loadReviews: (page?: number) => void, element: HTMLDivElement) {
  element.remove();
  loadReviews();
}

const ClientsReviewsTab: TOC<{
  Element: HTMLUListElement;
  Args: {
    reviews: InfiniteQuery<'review'>;
    reviewsPage: number;
    loadReviews: (page?: number) => void;
  };
}> = <template>
  <div {{didInsert (fn setup @loadReviews)}}>
  </div>

  <LoadingState @model={{@reviews}} class='h-100 py-3'>
    <List @linked={{true}} class='h-100 overflow-y-auto' as |Item|>
      {{#each @reviews.options as |review|}}
        <Item>
          <LinkTo
            @route='reviews.guest.review'
            @model={{review.id}}
            class='p-0 link-unstyled'
          >
            <ReviewsItem
              @review={{review}}
              @smallScreen={{media 'isSmallScreen'}}
              @compact={{true}}
            />
          </LinkTo>
        </Item>
      {{/each}}
    </List>

    <Pagination
      @page={{@reviewsPage}}
      @total={{@reviews.pages}}
      @onChange={{@loadReviews}}
      class='p-3 border-top'
    />
  </LoadingState>
</template>;

export default ClientsReviewsTab;
