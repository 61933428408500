import type { TOC } from '@ember/component/template-only';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import t from 'ember-intl/helpers/t';
import type DiscountCodeModel from 'smily-admin-ui/models/discount-code';

const DiscountsUsageCount: TOC<{
  Element: HTMLDivElement;
  Args: {
    discountCode: DiscountCodeModel;
  };
}> = <template>
  <div ...attributes>
    {{#if @discountCode.numberOfTimes}}
      {{@discountCode.usageCount}}/{{@discountCode.numberOfTimes}}
      {{lowercase (t 'internals.models.bookings')}}
    {{else}}
      {{t 'internals.model_counters.booking' counter=@discountCode.usageCount}}
    {{/if}}
  </div>
</template>;

export default DiscountsUsageCount;
