import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import formatNumber from 'ember-intl/helpers/format-number';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import List from 'ember-smily-base/components/list';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import RentalsLayout from 'smily-admin-ui/components/rentals/layout';
import availableFieldLanguages from 'smily-admin-ui/helpers/available-field-languages';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type RentalsRentalRoute from 'smily-admin-ui/routes/rentals/rental';
import { getLocalizedHourLabel } from 'smily-admin-ui/utils/date';

interface TemplatesRentalsRentalBookingSettingsSignature {
  Args: {
    model: ModelFor<RentalsRentalRoute>;
  };
}

class TemplatesRentalsRentalBookingSettings extends Component<TemplatesRentalsRentalBookingSettingsSignature> {
  @service intl!: IntlService;

  get checkinTimeLabel() {
    return getLocalizedHourLabel(
      this.args.model.checkinTime,
      this.intl.primaryLocale,
    );
  }

  get checkoutTimeLabel() {
    return getLocalizedHourLabel(
      this.args.model.checkoutTime,
      this.intl.primaryLocale,
    );
  }

  <template>
    <RentalsLayout @rental={{@model}}>
      <List @large={{true}} @sticky={{true}} class='p-3' as |Item|>
        <Item class='pb-5'>
          <div class='d-flex flex-column gap-3'>
            <div class='d-flex align-items-center justify-content-between'>
              <h3>
                {{t
                  'breadcrumbs.rentals.rental.booking-settings.checkin-checkout'
                }}
              </h3>

              <LinkTo
                @route='rentals.rental.booking-settings.checkin-checkout'
                class='btn btn-secondary btn-sm'
              >
                {{t 'common.edit'}}
              </LinkTo>
            </div>

            <List @sticky={{true}} @justified={{true}} as |SectionItem|>
              <SectionItem>
                <div>
                  {{t 'rentals.checkin_type'}}
                </div>

                <div>
                  {{optionLabel
                    @model.checkinType
                    'rentals.checkin_type_options'
                  }}
                </div>
              </SectionItem>

              <SectionItem>
                <div>
                  {{t 'rentals.checkin_time'}}
                </div>

                <div>
                  {{this.checkinTimeLabel}}
                </div>
              </SectionItem>

              <SectionItem>
                <div>
                  {{t 'rentals.checkin_instructions'}}
                </div>

                <div>
                  {{availableFieldLanguages @model.checkinDetailsTranslations}}
                </div>
              </SectionItem>

              <SectionItem>
                <div>
                  {{t 'rentals.checkout_time'}}
                </div>

                <div>
                  {{this.checkoutTimeLabel}}
                </div>
              </SectionItem>

              <SectionItem>
                <div>
                  {{t 'rentals.checkout_instructions'}}
                </div>

                <div>
                  {{availableFieldLanguages @model.checkoutDetailsTranslations}}
                </div>
              </SectionItem>
            </List>
          </div>
        </Item>

        <Item class='pt-5'>
          <div class='d-flex flex-column gap-3'>
            <div class='d-flex align-items-center justify-content-between'>
              <h3>
                {{t 'breadcrumbs.rentals.rental.booking-settings.preferences'}}
              </h3>

              <LinkTo
                @route='rentals.rental.booking-settings.preferences'
                class='btn btn-secondary btn-sm'
              >
                {{t 'common.edit'}}
              </LinkTo>
            </div>

            <List @sticky={{true}} @justified={{true}} as |SectionItem|>
              <SectionItem>
                <div>
                  {{t 'rentals.cancellation_policy'}}
                </div>

                <div>
                  {{optionLabel
                    @model.cancelationPolicyPreset
                    'rentals.cancellation_policy_options'
                  }}
                </div>
              </SectionItem>

              {{!-- <SectionItem>
                <div>
                  {{t "rentals.preparation_time"}}
                </div>

                <div>
                  1 night before and after each reservation
                </div>
              </SectionItem> --}}

              <SectionItem>
                <div>
                  {{t 'rentals.advance_notice'}}
                </div>

                <div>
                  {{#if @model.advanceNotice}}
                    {{formatNumber
                      @model.advanceNotice
                      style='unit'
                      unit='day'
                      unitDisplay='long'
                    }}
                  {{else}}
                    –
                  {{/if}}
                </div>
              </SectionItem>
            </List>
          </div>
        </Item>
      </List>
    </RentalsLayout>
  </template>
}

export default RouteTemplate(TemplatesRentalsRentalBookingSettings);
