import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import List from 'ember-smily-base/components/list';
import formatMoney from 'ember-smily-base/helpers/format-money';
import gt from 'ember-truth-helpers/helpers/gt';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type BookingModel from 'smily-admin-ui/models/booking';

const BookingsPriceTab: TOC<{
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}> = <template>
  <div class='d-flex flex-column gap-5' ...attributes>
    <div class='d-flex flex-column gap-3'>
      <h4>
        {{t 'finance.price_builder.price'}}
      </h4>

      <List @sticky={{true}} @justified={{true}} as |Item|>
        <Item>
          <div class='fw-semibold'>
            {{t 'finance.price_builder.rent'}}
          </div>

          <div>
            {{formatMoney @booking.initialPrice @booking.currency}}
          </div>
        </Item>

        {{#if @booking.discount}}
          <Item>
            <div class='fw-semibold'>
              {{t 'finance.price_builder.discount'}}
            </div>

            <div>
              {{formatMoney @booking.discount @booking.currency}}
            </div>
          </Item>
        {{/if}}
      </List>
    </div>

    {{#if @booking.bookingFees.length}}
      <div class='d-flex flex-column gap-3'>
        <h4>
          {{t 'finance.price_builder.fees_and_services'}}
        </h4>

        <List @sticky={{true}} @justified={{true}} as |Item|>
          {{#each @booking.bookingFees as |fee|}}
            <Item>
              <div class='fw-semibold'>
                {{fee.name}}
              </div>

              <div>
                {{#if (gt fee.timesBooked 1)}}
                  {{fee.timesBooked}}

                  x
                {{/if}}

                {{formatMoney fee.price @booking.currency}}
              </div>
            </Item>
          {{/each}}
        </List>
      </div>
    {{/if}}

    {{#if @booking.bookingTaxes.length}}
      <div class='d-flex flex-column gap-3'>
        <h4>
          {{t 'finance.price_builder.taxes'}}
        </h4>

        <List @sticky={{true}} @justified={{true}} as |Item|>
          {{#each @booking.bookingTaxes as |tax|}}
            <Item>
              <div class='fw-semibold'>
                {{tax.name}}
              </div>

              <div>
                {{formatMoney tax.amount @booking.currency}}
              </div>
            </Item>
          {{/each}}
        </List>
      </div>
    {{/if}}

    {{#if @booking.reconciliation}}
      <div class='d-flex justify-content-between gap-3'>
        <div>
          {{t 'finance.price_builder.reconciliation'}}
        </div>

        <div>
          {{formatMoney @booking.reconciliation @booking.currency}}
        </div>
      </div>
    {{/if}}

    <div class='w-100 d-flex flex-column gap-3'>
      <div class='d-flex align-items-center justify-content-between gap-3'>
        <h3>
          {{t 'finance.price_builder.total_price'}}
        </h3>

        <h3>
          {{formatMoney @booking.finalPrice @booking.currency}}
        </h3>
      </div>

      {{#if @booking.downpayment}}
        <List
          @padded={{true}}
          @justified={{true}}
          class='ms-3 pe-0 mb-1 border-start'
          as |Item|
        >
          <Item>
            <div class='fw-semibold'>
              {{t 'internals.fields.downpayment'}}
            </div>

            <div>
              {{formatMoney @booking.downpayment @booking.currency}}
            </div>
          </Item>
        </List>
      {{/if}}
    </div>

    <div class='d-flex flex-column gap-3'>
      <h4>
        {{t 'finance.price_builder.damage_deposit'}}
      </h4>

      <List @sticky={{true}} @justified={{true}} as |Item|>
        <Item>
          <div class='fw-semibold'>
            {{t 'finance.price_builder.damage_deposit'}}
          </div>

          <div>
            {{formatMoney @booking.damageDeposit @booking.currency}}
          </div>
        </Item>

        <Item>
          <div class='fw-semibold'>
            {{t 'finance.price_builder.charge_damage_deposit'}}
          </div>

          <div>
            {{optionLabel
              @booking.damageDepositCollectionTime
              'finance.damage_deposit_collection_time_options'
            }}
          </div>
        </Item>
      </List>
    </div>
  </div>
</template>;

export default BookingsPriceTab;
