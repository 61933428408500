import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default abstract class RentalsRentalRailsEditRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;

  abstract coreLink: string;

  beforeModel(transition: Transition) {
    const rentalId = transition.to.parent!.parent!.params.rental_id;
    this.coreLinks.redirect(this.coreLink, rentalId);
  }
}
