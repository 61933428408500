import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import config from 'smily-admin-ui/config/environment';
import type { Error as JSONAPIError } from 'ember-smily-base/utils/json-api';
import type { Request } from 'ember-smily-base/utils/json-api';
import { getService } from 'ember-smily-base/utils/application';
import { getOwner } from '@ember/application';
import type SessionService from 'smily-admin-ui/services/session-service';

const {
  API: { HOST, NAMESPACE },
} = config;
const TRANSLATED_ERROR_CODES = [
  'open_ai_rate_limit_exceeded_per_resource',
  'open_ai_interpretation_error',
];

export default async function queryAI<
  Ret extends {} = ResponseWithData<ResourceObject>,
>(context: object, url: string, payload: Request) {
  const session = getOwner(context).lookup('service:session') as SessionService;
  const fullUrl = `${HOST}/${NAMESPACE}/${url}`;
  const fetchResponse = await fetch(fullUrl, {
    headers: {
      ...session.authorizationHeader,
      'Content-Type': 'application/vnd.api+json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!fetchResponse.ok && fetchResponse.status !== 422) {
    showError(context);
    throw new Error(`An error occurred while trying to POST ${fullUrl}`);
  }

  const jsonResponse = await fetchResponse.json();

  if (fetchResponse.status === 422) {
    const errorKey = (jsonResponse as { errors: JSONAPIError[] }).errors.at(0)
      ?.code;
    const consistentErrorKey =
      errorKey && TRANSLATED_ERROR_CODES.includes(errorKey)
        ? errorKey
        : 'open_ai_default_error';
    showError(context, consistentErrorKey);

    return new AIError();
  } else {
    return jsonResponse as Ret;
  }
}

function showError(context: object, errorKey?: string) {
  const intl = getService(context, 'intl');
  const notify = getService(context, 'notify');

  notify.error(intl.t(`internals.errors.${errorKey ?? 'default'}`));
}

class AIError {
  isError = true;
}

export function isError<T>(value: T | AIError): value is AIError {
  return value instanceof AIError;
}
