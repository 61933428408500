import LoadingState from 'ember-smily-base/components/loading-state';

const LoadingScreen = <template>
  <div class='flex-1 d-flex align-items-center justify-content-center'>
    <LoadingState @isLoading={{true}} />
  </div>
</template>;

export default LoadingScreen;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LoadingScreen: typeof LoadingScreen;
  }
}
