import t from 'ember-intl/helpers/t';

const FeatureDiscoveryLastBanner = <template>
  <div class='feature-discovery-banner'>
    <img
      src='/assets/images/feature-discovery-whats-new.jpg'
      alt={{t 'user.feature_discovery.intro_banner_alt'}}
    />
  </div>
</template>;

export default FeatureDiscoveryLastBanner;
