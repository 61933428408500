import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';

const TemplatesChannelImportComplete: TOC<{
  Element: SVGElement;
}> = <template>
  <div class='h-100 p-3'>
    <Card class='min-h-100'>
      <div
        class='card-body p-3 p-lg-5 d-flex align-items-center justify-content-center'
      >
        <div class='position-absolute align-self-start w-100 px-3 px-lg-5'>
          <img src='/assets/images/success-pattern.svg' alt='' class='w-100' />
        </div>

        <div class='d-flex flex-column align-items-center gap-3'>
          <Icon
            @icon='check'
            @style='fal'
            @mask='circle'
            @transform='shrink-8'
            class='mb-4 text-primary text-75 text-lg-100'
          />

          <h3 class='text-lg-28'>
            {{t 'onboarding.channel_import_complete.title'}}
          </h3>

          <div class='text-lg-18 text-center'>
            {{t 'onboarding.channel_import_complete.subtitle'}}
          </div>

          <LinkTo @route='index' class='btn btn-primary'>
            {{t 'onboarding.channel_import_complete.button'}}
          </LinkTo>
        </div>
      </div>
    </Card>
  </div>
</template>;

export default RouteTemplate(TemplatesChannelImportComplete);
