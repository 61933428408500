import type { TOC } from '@ember/component/template-only';
import media from 'ember-responsive/helpers/media';
import Icon from 'ember-smily-base/components/icon';

const OnboardingStepIcon: TOC<{
  Args: {
    isCompleted: boolean;
  };
}> = <template>
  {{#if @isCompleted}}
    <Icon
      @icon='check'
      @mask='circle'
      @transform='shrink-8'
      class='text-complete'
    />
  {{else}}
    {{#if (media 'isDesktop')}}
      <Icon @icon='chevron-right' @mask='circle' @transform='shrink-8' />
    {{else}}
      <Icon @icon='chevron-down' @mask='circle' @transform='shrink-8' />
    {{/if}}
  {{/if}}
</template>;

export default OnboardingStepIcon;
