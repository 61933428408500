import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class TasksTemplatesTemplateRoute extends BaseRoute {
  @service store!: StoreService;

  model({
    task_management_template_id: id,
  }: {
    task_management_template_id: string;
  }) {
    return this.store.findRecord(
      'task-management-template',
      id,
      this.store.generateQuery('task-management-template'),
    );
  }
}
