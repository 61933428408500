import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type TeleportService from 'ember-smily-base/services/teleport';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class AuthLogoutRoute extends Route {
  @service coreLinks!: CoreLinksService;
  @service session!: SessionService;
  @service teleport!: TeleportService;

  async beforeModel() {
    this.teleport.disable();

    await this.session.invalidate();

    this.coreLinks.redirect('logout');
  }
}
