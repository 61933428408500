import { hash } from '@ember/helper';
import Component from '@glimmer/component';
import BsPopover from 'ember-bootstrap/components/bs-popover';
import can from 'ember-can/helpers/can';
import { focusTrap } from 'ember-focus-trap';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import Badge from 'ember-smily-base/components/badge';
import formatMoney from 'ember-smily-base/helpers/format-money';
import BookingsPaymentDialog from 'smily-admin-ui/components/bookings/payment-dialog';
import type BookingModel from 'smily-admin-ui/models/booking';

interface BookingsPaymentBarDetailedPaymentUnpaidDotSignature {
  Args: {
    booking: BookingModel;
    remaining: number;
  };
}

export default class BookingsPaymentBarDetailedPaymentUnpaidDot extends Component<BookingsPaymentBarDetailedPaymentUnpaidDotSignature> {
  noop() {}

  <template>
    <a
      href='#'
      class='payment-progress-milestone expected-milestone'
      tabindex='0'
    >
      <BsPopover @placement='top' class='booking-payment-popover' as |popover|>
        <div
          {{focusTrap
            focusTrapOptions=(hash
              onDeactivate=popover.close clickOutsideDeactivates=true
            )
          }}
          class='d-flex flex-column gap-2'
        >
          <div class='d-flex flex-column'>
            <div class='d-flex align-items-end justify-content-between gap-3'>
              <div class='h4'>
                {{formatMoney @remaining @booking.currency}}
              </div>

              <Badge
                @label={{t 'finance.payment_status_options.not_paid'}}
                @color='secondary'
                @skeleton={{true}}
              />
            </div>

            {{#if @booking.tentativeExpiresAt}}
              <div>
                {{formatDate @booking.tentativeExpiresAt format='short'}}
              </div>
            {{/if}}
          </div>

          <div class='d-flex flex-column'>
            <div class='fw-semibold text-11'>
              {{t 'finance.payment_bar.expected_payment'}}
            </div>

            <div>
              ?
            </div>
          </div>

          {{#if (can 'edit payments')}}
            <BookingsPaymentDialog
              @booking={{@booking}}
              @isNew={{true}}
              @amount={{@remaining}}
              class='btn btn-primary btn-sm'
            >
              {{t 'finance.payment_bar.mark_as_paid'}}
            </BookingsPaymentDialog>
          {{/if}}
        </div>
      </BsPopover>
    </a>
  </template>
}
