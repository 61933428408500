import RouteTemplate from 'ember-route-template';
import LoadingState from 'ember-smily-base/components/loading-state';

export default RouteTemplate(<template>
  <div
    class='min-vw-100 min-vh-100 d-flex align-items-center justify-content-center'
  >
    <LoadingState />
  </div>
</template>);
