import type { CoreLinkRouteCallable } from 'smily-admin-ui/services/core-links';
import { FeatureIndexRoute } from 'smily-admin-ui/utils/routing';

export default class ReviewsRoute extends FeatureIndexRoute {
  breadcrumb = null;
  feature = 'centralized reviews';
  coreLink: CoreLinkRouteCallable = 'reviews';

  get requiredRouteAbility(): string {
    return 'access reviews route';
  }
}
