import { service } from '@ember/service';
import { startOfMonth, subDays } from 'date-fns';
import type StoreService from 'ember-smily-base/services/store';
import { dateString } from 'ember-smily-base/utils/date';
import type { ModelFor, Transition } from 'ember-smily-base/utils/routing';
import type { Query } from 'ember-smily-base/utils/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type FinanceGrossEarningsController from 'smily-admin-ui/controllers/finance/gross-earnings';
import type { QueryParam } from 'smily-admin-ui/controllers/finance/gross-earnings';
import type { Range } from 'smily-admin-ui/routes/finance/-payouts';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

type Controller = Parameters<BaseRoute['setupController']>[0];

export default class FinanceGrossEarningsRoute extends BaseRoute {
  @service store!: StoreService;

  queryParams = {
    page: { refreshModel: true },
    from: { refreshModel: true },
    to: { refreshModel: true },
  };

  checkAbilities = true;
  requiredRouteAbility = 'access finance gross earnings route';

  model(params: Record<QueryParam, string>) {
    const { from, to } = params;
    const page = params.page as unknown as number;
    const bookingQuery = this.store.generateQuery('booking', 'grossEarning');
    const grossEarningQuery: Query = {};
    const relevantRange: Range = { from, to };

    this.setDefaultParams(relevantRange);

    if (relevantRange.from) {
      const filter = {
        finalizedAt: [
          {
            op: 'gteq',
            value: `${relevantRange.from} 00:00:00`,
          },
          {
            op: 'lteq',
            value: `${relevantRange.to} 23:59:59`,
          },
        ],
      };

      bookingQuery.filter = {
        ...bookingQuery.filter,
        ...filter,
      };
      grossEarningQuery.filter = filter;
    }

    const bookings = new InfiniteQuery(this.store, 'booking', bookingQuery, {
      page,
    });
    const grossEarnings = this.store.queryRecords(
      'booking-gross-earning',
      grossEarningQuery,
    );

    return {
      grossEarnings,
      bookings,
    };
  }

  setupController(
    controller: Controller & FinanceGrossEarningsController,
    model: ModelFor<this>,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);
    this.setDefaultParams(controller);
    controller.loadGrossEarnings(model.grossEarnings);
  }

  setDefaultParams(object: Range) {
    if (object.from !== undefined) {
      return;
    }

    const now = new Date();

    object.from = dateString(startOfMonth(now));
    object.to = dateString(subDays(now, 1));
  }
}
