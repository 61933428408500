import Transform from '@ember-data/serializer/transform';

export default class JsonStringTransform extends Transform {
  deserialize(serialized: object[] | undefined) {
    return serialized ? JSON.stringify(serialized) : undefined;
  }

  serialize(deserialized: string | undefined) {
    return deserialized ? JSON.parse(deserialized) : undefined;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'json-string': string;
  }
}
