import Component from '@glimmer/component';
import BsProgress from 'ember-bootstrap/components/bs-progress';
import type BookingModel from 'smily-admin-ui/models/booking';

interface BookingsPaymentBarCompactSignature {
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}

export default class BookingsPaymentBarCompact extends Component<BookingsPaymentBarCompactSignature> {
  get authorizedAmount() {
    return this.args.booking.authorizedAmount || 0;
  }

  get paidAmount() {
    return this.args.booking.paidAmount || 0;
  }

  get totalAmount() {
    return this.args.booking.totalAmount;
  }

  get finalPrice() {
    return this.args.booking.finalPrice || 0;
  }

  get damageDeposit() {
    return this.args.booking.damageDeposit || 0;
  }

  get totalToPay() {
    return this.args.booking.totalToPay || 0;
  }

  get isFirstPartPaid() {
    return this.totalAmount >= this.finalPrice;
  }

  get isPaid() {
    return this.totalAmount >= this.totalToPay;
  }

  /*
   * Calculate yellow, green and grey parts of the bar with a marker between
   * booking price and damage deposit.
   */

  get firstAuthorizedPill() {
    return Math.min(this.finalPrice, this.authorizedAmount);
  }

  get firstPaidPill() {
    return Math.min(
      this.finalPrice - this.firstAuthorizedPill,
      this.paidAmount,
    );
  }

  get fillerPill() {
    return Math.max(
      0,
      this.finalPrice - this.firstAuthorizedPill - this.firstPaidPill,
    );
  }

  get markerWidth() {
    return this.totalToPay / 100;
  }

  get secondAuthorizedPill() {
    return this.args.booking.damageDepositAuthorized || 0;
  }

  get secondPaidPill() {
    return this.args.booking.damageDepositPaid || 0;
  }

  <template>
    <BsProgress class='smily-progress' ...attributes as |pg|>
      {{#if this.firstAuthorizedPill}}
        <pg.bar
          @value={{this.firstAuthorizedPill}}
          @maxValue={{@booking.totalToPay}}
          class='bg-warning'
        />
      {{/if}}

      {{#if this.firstPaidPill}}
        <pg.bar
          @value={{this.firstPaidPill}}
          @maxValue={{@booking.totalToPay}}
          class={{if this.isFirstPartPaid 'bg-success'}}
        />
      {{/if}}

      {{#if @booking.damageDeposit}}
        {{#if this.fillerPill}}
          <pg.bar
            @value={{this.fillerPill}}
            @maxValue={{@booking.totalToPay}}
            class='bg-transparent'
          />
        {{/if}}

        {{! template-lint-disable no-inline-styles }}
        <pg.bar
          @value={{this.markerWidth}}
          @maxValue={{@booking.totalToPay}}
          class='bg-black'
          style='max-width: 2px;'
        />
        {{! template-lint-enable no-inline-styles }}

        {{#if this.secondAuthorizedPill}}
          <pg.bar
            @value={{this.secondAuthorizedPill}}
            @maxValue={{@booking.totalToPay}}
            class='bg-warning'
          />
        {{/if}}

        {{#if this.secondPaidPill}}
          <pg.bar
            @value={{this.secondPaidPill}}
            @maxValue={{@booking.totalToPay}}
            class={{if this.isPaid 'bg-success'}}
          />
        {{/if}}
      {{/if}}
    </BsProgress>
  </template>
}
