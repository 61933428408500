import { attr, belongsTo } from '@ember-data/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type RentalFeeModel from 'smily-admin-ui/models/rental-fee';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class BookingFeeModel extends BaseModel {
  @attr name!: string;
  @attr('number') price!: number;
  @attr('number') timesBooked!: number;
  @attr('date', { readOnly: true }) createdAt!: Date;
  @attr('date', { readOnly: true }) updatedAt!: Date;

  @belongsTo('booking', { async: false, inverse: 'bookingFees' })
  booking!: BookingModel;
  @belongsTo('rental-fee', { async: false, inverse: null })
  rentalFee?: RentalFeeModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-fee': BookingFeeModel;
  }
}
