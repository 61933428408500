import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type StoreService from 'ember-smily-base/services/store';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { Category } from 'smily-admin-ui/models/inbox-conversation';
import type InboxConversationRoute from 'smily-admin-ui/routes/inbox/conversation';
import type CacheService from 'smily-admin-ui/services/cache';
import { buildQuery } from 'smily-admin-ui/utils/inbox';

export const FILTER_PARAMS: QueryParam[] = [
  'read',
  'assignee',
  'client',
  'stage',
  'source',
  'bookingStatus',
  'rental',
  'bookingTag',
  'rentalTag',
  'priority',
  'severity',
];

export type QueryParam =
  | 'status'
  | 'read'
  | 'assignee'
  | 'client'
  | 'stage'
  | 'source'
  | 'bookingStatus'
  | 'bookingLabel'
  | 'rental'
  | 'bookingTag'
  | 'rentalTag'
  | 'priority'
  | 'severity'
  | 'sort';

export default class InboxController extends Controller {
  @service cache!: CacheService;
  @service router!: RouterService;
  @service store!: StoreService;

  queryParams = [
    'page',
    'status',
    'read',
    'assignee',
    'client',
    'stage',
    'source',
    'bookingStatus',
    'bookingLabel',
    'rental',
    'bookingTag',
    'rentalTag',
    'priority',
    'severity',
    'sort',
  ];

  @tracked page = 1;
  @tracked status? = 'open';
  @tracked read?: string;
  @tracked assignee?: string;
  @tracked client?: string;
  @tracked stage?: string;
  @tracked source?: string;
  @tracked bookingStatus?: string;
  @tracked bookingLabel?: string;
  @tracked rental?: string;
  @tracked bookingTag?: string;
  @tracked rentalTag?: string;
  @tracked priority?: string;
  @tracked severity?: string;
  @tracked sort? = '-lastMessageAt';

  @action
  changeQueryParam(key: QueryParam, value: string | undefined): void {
    this[key] = value;
  }

  @action
  changePage(page: number) {
    this.page = page;
  }

  handleInboxAction(key: QueryParam, value?: string) {
    this._refreshRouteOnAction(key, value);
    this._updateCounter(key, value);
  }

  private async _refreshRouteOnAction(key: QueryParam, value?: string) {
    const isRelevantStatus = isStatus(key, value) && this.status !== value;

    if (!isRelevantStatus && !this[key]) {
      return;
    }

    const route = getOwner(this).lookup(
      'route:inbox.conversation',
    ) as InboxConversationRoute;
    const model = route.currentModel;
    const nextConversation = this._getNextConversation(model);
    const query = buildQuery(this);

    query.filter = {
      ...query.filter,
      id: model!.conversation.id,
    };

    const isStillInCurrentScope = await this.store.queryRecords(
      'inbox-conversation',
      query,
    );

    if (isStillInCurrentScope.length) {
      return;
    } else if (nextConversation) {
      this.router.transitionTo('inbox.conversation', nextConversation.id);
    } else {
      this.router.transitionTo('inbox.index');
    }
  }

  private _updateCounter(key: string, value?: string) {
    if (!isStatus(key, value)) {
      return;
    }

    if (value === 'spam') {
      this.cache.inboxSpamCounter = this.cache.inboxSpamCounter! + 1;
    } else if (value === 'archived') {
      this.cache.inboxArchivedCounter = this.cache.inboxArchivedCounter! + 1;
    }
  }

  private _getNextConversation(
    model: ModelFor<InboxConversationRoute> | undefined,
  ) {
    const conversations = model?.conversations;
    const conversation = model?.conversation;
    const index = conversation && conversations?.options.indexOf(conversation);

    return conversations?.options.at((index ?? 0) + 1);
  }
}

function isStatus(
  key: string,
  _value: string | undefined,
): _value is Category | undefined {
  return key === 'status';
}

declare module '@ember/controller' {
  interface Registry {
    inbox: InboxController;
  }
}
