import type { Snapshot } from '@ember-data/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class ReservationsQuoteSerializer extends ApplicationSerializer<'reservations-quote'> {
  serialize(snapshot: Snapshot, options?: {}) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;

    // strip nullish values from reservations-quote payload
    json.data.attributes = Object.fromEntries(
      Object.entries(json.data.attributes!).filter(
        ([_, value]) => value !== null,
      ),
    );

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'reservations-quote': ReservationsQuoteSerializer;
  }
}
