import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class CreditCardModel extends BaseModel {
  @attr declare firstName: string;
  @attr declare lastName: string;
  @attr declare number: string;
  @attr declare expirationDate: string;
  @attr status!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'credit-card': CreditCardModel;
  }
}
