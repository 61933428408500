import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { FilterParams } from 'smily-admin-ui/routes/reviews/-base-index';
import ReviewsBaseIndexRoute from 'smily-admin-ui/routes/reviews/-base-index';

export default class ReviewsGuestIndexRoute extends ReviewsBaseIndexRoute {
  isHostReview = false;

  model(params: FilterParams & { tab: string; page: number }) {
    const { tab, page } = params;
    const query = {
      ...this.store.generateQuery(
        'review',
        tab === 'all' ? 'listDefault' : 'actionable',
      ),
    };

    query.filter = this.applyFilters(query.filter, params);

    return new InfiniteQuery(this.store, 'review', query, { page });
  }
}
