import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import ExternalLink from 'ember-smily-base/components/external-link';
import Icon from 'ember-smily-base/components/icon';

const TemplatesOnboardingPaymentAccountVerification = <template>
  <div class='h-100 p-3'>
    <Card class='min-h-100'>
      <div
        class='card-body p-3 p-lg-5 d-flex align-items-center justify-content-center'
      >
        <div class='position-absolute align-self-start w-100 px-3 px-lg-5'>
          <img src='/assets/images/success-pattern.svg' alt='' class='w-100' />
        </div>

        <div class='d-flex flex-column align-items-center gap-3'>
          <div
            class='d-flex align-items-center justify-content-center mb-4 text-75 text-lg-100'
          >
            <Icon @icon='circle' class='text-primary position-absolute' />

            <Icon
              @icon='spinner-third'
              @style='fal'
              @spin={{true}}
              @transform='shrink-8'
              class='text-white'
            />
          </div>

          <h3 class='text-lg-28'>
            {{t 'onboarding.payment_account_verification.title'}}
          </h3>

          <div class='text-lg-18 text-center'>
            {{t 'onboarding.payment_account_verification.p1' htmlSafe=true}}
          </div>

          <div class='text-lg-18 text-center'>
            {{t 'onboarding.payment_account_verification.p2' htmlSafe=true}}

            <ExternalLink
              @label={{t 'onboarding.payment_account_verification.manual_link'}}
              @url={{t 'onboarding.payment_account_verification.manual_link'}}
            />
          </div>

          <div class='text-lg-18 text-center'>
            {{t 'onboarding.payment_account_verification.p3' htmlSafe=true}}
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>;

export default RouteTemplate(TemplatesOnboardingPaymentAccountVerification);
