import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'to';

export default class InboxNewController extends Controller {
  queryParams: QueryParam[] = ['to'];

  @tracked to?: string;
}

declare module '@ember/controller' {
  interface Registry {
    'inbox.new': InboxNewController;
  }
}
