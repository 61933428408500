import type { QueryResult } from 'ember-smily-base/utils/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';

export default class ConversationInfiniteQuery extends InfiniteQuery<'inbox-conversation'> {
  pageInfo: Map<InboxConversationModel, number> = new Map();

  async queryHandler(promise: QueryResult<InboxConversationModel>) {
    const results = await promise;
    const page = results.meta.pagination.current_page;

    results.forEach((result) => {
      this.pageInfo.set(result, page);
    });

    return results;
  }

  getPageByModel(model: InboxConversationModel) {
    return this.pageInfo.get(model);
  }
}
