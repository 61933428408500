import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class PerformanceIndexRoute extends BaseRoute {
  @service router!: RouterService;

  redirect() {
    this.router.transitionTo('performance.calendar-occupancy');
  }
}
