import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class ReviewsHostAutomationCommentRoute extends BaseRoute {
  @service store!: StoreService;

  model({
    reviews_host_automation_comment_id,
  }: {
    reviews_host_automation_comment_id: string;
  }) {
    // For some reason `findRecord` runs each time the route is entered, so we
    // try to peek the record first.
    return (
      this.store.peekRecord(
        'reviews-host-automation-comment',
        reviews_host_automation_comment_id,
      ) ??
      this.store.findRecord(
        'reviews-host-automation-comment',
        reviews_host_automation_comment_id,
      )
    );
  }
}
