import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import CounterText from 'smily-admin-ui/components/counter-text';
import type BookingModel from 'smily-admin-ui/models/booking';

const BookingsClientCounter: TOC<{
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}> = <template>
  <div class='d-flex gap-1' ...attributes>
    <CounterText
      @counter={{@booking.adults}}
      @text={{t 'bookings.adults_counter' counter=@booking.adults}}
    />

    <div>•</div>

    <CounterText
      @counter={{@booking.children}}
      @text={{t 'bookings.children_counter' counter=@booking.children}}
    />
  </div>
</template>;

export default BookingsClientCounter;
