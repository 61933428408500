import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { LinkTo } from '@ember/routing';
import List from 'ember-smily-base/components/list';
import LoadingState from 'ember-smily-base/components/loading-state';
import Pagination from 'ember-smily-base/components/pagination';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import InboxConversationListItemCompact from 'smily-admin-ui/components/inbox/conversation/list-item-compact';

function setup(loadReviews: (page?: number) => void, element: HTMLDivElement) {
  element.remove();
  loadReviews();
}

const ClientsConversationsTab: TOC<{
  Element: HTMLUListElement;
  Args: {
    conversations: InfiniteQuery<'inbox-conversation'>;
    conversationsPage: number;
    loadConversations: (page?: number) => void;
  };
}> = <template>
  <div {{didInsert (fn setup @loadConversations)}}>
  </div>

  <LoadingState @model={{@conversations}} class='h-100 py-3'>
    <List @padded={{true}} @linked={{true}} class='text-nowrap p-0' as |Item|>
      {{#each @conversations.options as |conversation|}}
        <Item class='p-0'>
          <LinkTo
            @route='inbox.conversation'
            @model={{conversation.id}}
            class='p-0 link-unstyled'
          >
            <InboxConversationListItemCompact @conversation={{conversation}} />
          </LinkTo>
        </Item>
      {{/each}}

      <Pagination
        @page={{@conversationsPage}}
        @total={{@conversations.pages}}
        @onChange={{@loadConversations}}
        class='p-3 border-top'
      />
    </List>
  </LoadingState>
</template>;

export default ClientsConversationsTab;
