import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type QueryParam =
  | 'status'
  | 'source'
  | 'rental'
  | 'client'
  | 'bookingsTag'
  | 'startAt'
  | 'endAt'
  | 'atProperty'
  | 'payments'
  | 'discountCode'
  | 'reference';

export default class BookingsIndexController extends Controller {
  queryParams = [
    'page',
    'status',
    'source',
    'rental',
    'client',
    'bookingsTag',
    'startAt',
    'endAt',
    'atProperty',
    'payments',
    'discountCode',
    'reference',
  ];

  @tracked page = 1;
  @tracked status?: string;
  @tracked rental?: string;
  @tracked startAt?: string;
  @tracked endAt?: string;
  @tracked atProperty?: string;
  @tracked source?: string;
  @tracked client?: string;
  @tracked bookingsTag?: string;
  @tracked payments?: string;
  @tracked discountCode?: string;
  @tracked reference?: string;

  @action
  changeQueryParam(key: QueryParam, value: string): void {
    this.page = 1;
    this[key] = value;
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'bookings.index': BookingsIndexController;
  }
}
