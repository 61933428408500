import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class InvitationAbility extends Ability<'invitation'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'invitation');
  }
}
