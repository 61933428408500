import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { CountryCode, LanguageCode } from 'ember-smily-base/utils/intl';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import { join } from 'ember-smily-base/utils/string';
import type BookingModel from 'smily-admin-ui/models/booking';
import type ClientAddressModel from 'smily-admin-ui/models/client-address';
import type ClientEmailModel from 'smily-admin-ui/models/client-email';
import type ClientPhoneModel from 'smily-admin-ui/models/client-phone';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ClientModel extends BaseModel {
  @attr('string') firstName?: string;
  @attr('string') lastName?: string;

  @attr('date') birthday?: Date;

  @attr('string') notes?: string;
  @attr('string') company?: string;
  @attr('string') language?: LanguageCode;
  @attr('string') vatNumber?: string;
  @attr('string') passportNumber?: string;
  @attr('string') passportCountry?: CountryCode;

  @attr('number', { readOnly: true }) reviewsRate?: number;
  @attr('number', { readOnly: true }) reviewsCount?: number;
  @attr('number', { readOnly: true }) bookingsCount?: number;
  @attr('number', { readOnly: true }) confirmedBookingsCount?: number;
  @attr('number', { readOnly: true }) upcomingBookingsCount?: number;

  @belongsTo('client-email', { async: false, inverse: null, readOnly: true })
  primaryEmail?: ClientEmailModel;

  @belongsTo('client-phone', { async: false, inverse: null, readOnly: true })
  primaryPhone?: ClientPhoneModel;

  @belongsTo('client-address', { async: false, inverse: null, readOnly: true })
  primaryAddress?: ClientAddressModel;

  @hasMany('client-email', { async: false, inverse: 'client' })
  emails!: SyncHasMany<ClientEmailModel>;

  @hasMany('client-phone', { async: false, inverse: 'client' })
  phones!: SyncHasMany<ClientPhoneModel>;

  @hasMany('client-address', { async: false, inverse: 'client' })
  addresses!: SyncHasMany<ClientAddressModel>;

  @hasMany('booking', { async: false, inverse: 'client' })
  bookings!: SyncHasMany<BookingModel>;

  get fullName(): string {
    return join([this.firstName, this.lastName], ' ');
  }

  get nameAndEmail() {
    const email = this.primaryEmail?.email;

    if (!email) {
      return this.fullName;
    }

    return `${this.fullName} (${email})`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    client: ClientModel;
  }
}
