import type { TOC } from '@ember/component/template-only';
import { isSameDay, subDays } from 'date-fns';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import InfiniteList from 'ember-smily-base/components/infinite-list';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import InboxMessage from 'smily-admin-ui/components/inbox/message';
import type InboxMessageModel from 'smily-admin-ui/models/inbox-message';

const InboxMessages: TOC<{
  Element: HTMLDivElement;
  Args: {
    messages: InfiniteQuery<'inbox-message'>;
  };
}> = <template>
  <div
    class='flex-1 h-0 d-flex flex-column
      {{if
        @messages.isInitialized
        "justify-content-end"
        "justify-content-center"
      }}'
    ...attributes
  >
    <InfiniteList
      @infiniteQuery={{@messages}}
      @reverse={{true}}
      @yieldItem={{false}}
      class='messenger h-100 overflow-auto p-3'
      as |message index messages|
    >
      {{#if (needsDaySplit message index messages)}}
        <div class='d-flex align-items-center gap-2'>
          <hr class='flex-1' />

          <div class='px-2 py-1 border rounded-3'>
            {{#if (isToday message.sentAt)}}
              {{t 'common.today'}}
            {{else if (isYesterday message.sentAt)}}
              {{t 'common.yesterday'}}
            {{else}}
              {{formatDate message.sentAt format='long'}}
            {{/if}}
          </div>

          <hr class='flex-1' />
        </div>
      {{/if}}

      <InboxMessage @message={{message}} />
    </InfiniteList>
  </div>
</template>;

function needsDaySplit(
  message: InboxMessageModel,
  index: number,
  messages: InboxMessageModel[],
) {
  return (
    index === 0 || !isSameDay(message.sentAt, messages.at(index - 1)!.sentAt)
  );
}

function isToday(date: Date) {
  return isSameDay(date, new Date());
}

function isYesterday(date: Date) {
  return isSameDay(date, subDays(new Date(), 1));
}

export default InboxMessages;
