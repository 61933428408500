import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class ClientEmailAbility extends Ability<'client-email'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'client-email');
  }

  canEdit!: boolean;

  get canSetAsPrimary(): boolean {
    if (!this.canEdit) {
      return false;
    }

    return !this.model?.primary && !this.model?.isNew;
  }
}
