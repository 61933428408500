import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import List from 'ember-smily-base/components/list';
import countryName from 'ember-smily-base/helpers/country-name';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import RentalsAmenities from 'smily-admin-ui/components/rentals/amenities';
import RentalsLayout from 'smily-admin-ui/components/rentals/layout';
import RentalsPhotos from 'smily-admin-ui/components/rentals/photos';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type RentalsRentalRoute from 'smily-admin-ui/routes/rentals/rental';

const TemplatesRentalsRentalDetails: TOC<{
  Args: {
    model: ModelFor<RentalsRentalRoute>;
  };
}> = <template>
  <RentalsLayout @rental={{@model}}>
    <List @large={{true}} @sticky={{true}} class='p-3' as |Item|>
      <Item class='pb-5'>
        <div class='d-flex flex-column gap-3'>
          <div class='d-flex align-items-center justify-content-between'>
            <h3>
              {{t 'internals.models.photos'}}
            </h3>

            <LinkTo
              @route='rentals.rental.details.photos'
              class='btn btn-secondary btn-sm'
            >
              {{t 'common.edit'}}
            </LinkTo>
          </div>

          <RentalsPhotos @rental={{@model}} />
        </div>
      </Item>

      <Item class='py-5'>
        <div class='d-flex flex-column gap-3'>
          <div class='d-flex align-items-center justify-content-between'>
            <h3>
              {{t 'rentals.title_and_description'}}
            </h3>

            <LinkTo
              @route='rentals.rental.details.description'
              class='btn btn-secondary btn-sm'
            >
              {{t 'common.edit'}}
            </LinkTo>
          </div>

          <div class='d-flex flex-column gap-2'>
            <div>
              {{@model.headline}}
            </div>

            <div>
              {{@model.summary}}
            </div>
          </div>
        </div>
      </Item>

      <Item class='py-5'>
        <div class='d-flex flex-column gap-3'>
          <div class='d-flex align-items-center justify-content-between'>
            <h3>
              {{t 'rentals.rooms_and_guests'}}
            </h3>

            <LinkTo
              @route='rentals.rental.details.rooms'
              class='btn btn-secondary btn-sm'
            >
              {{t 'common.edit'}}
            </LinkTo>
          </div>

          <div class='d-flex flex-column gap-2'>
            <div class='d-flex gap-2'>
              <div class='flex-1'>
                {{t 'rentals.rental_type'}}:
                {{optionLabel @model.rentalType 'rentals.rental_type_options'}}
              </div>

              <div class='flex-1'>
                {{t 'rentals.living_rooms'}}:
                {{@model.livingRoomsCount}}
              </div>
            </div>

            <div class='d-flex gap-2'>
              <div class='flex-1'>
                {{t 'rentals.accommodates'}}:
                {{@model.sleeps}}
              </div>

              <div class='flex-1'>
                {{t 'rentals.area'}}:
                {{@model.area}}
              </div>
            </div>

            <div class='d-flex gap-2'>
              <div class='flex-1'>
                {{t 'rentals.bedrooms'}}:
                {{@model.bedroomsCount}}
              </div>

              <div class='flex-1'>
                {{t 'rentals.stories'}}:
                {{@model.storiesCount}}
              </div>
            </div>

            <div class='d-flex gap-2'>
              <div class='flex-1'>
                {{t 'rentals.bathrooms'}}:
                {{@model.bathroomsCount}}
              </div>

              <div class='flex-1'>
                {{t 'rentals.floor_number'}}:
                {{@model.floor}}
              </div>
            </div>
          </div>
        </div>
      </Item>

      <Item class='py-5'>
        <div class='d-flex flex-column gap-3'>
          <div class='d-flex align-items-center justify-content-between'>
            <h3>
              {{t 'internals.models.amenities'}}
            </h3>

            <LinkTo
              @route='rentals.rental.details.amenities'
              class='btn btn-secondary btn-sm'
            >
              {{t 'common.edit'}}
            </LinkTo>
          </div>

          <RentalsAmenities @rental={{@model}} />
        </div>
      </Item>

      <Item class='pt-5'>
        <div class='d-flex flex-column gap-3'>
          <div class='d-flex align-items-center justify-content-between'>
            <h3>
              {{t 'rentals.location'}}
            </h3>

            <LinkTo
              @route='rentals.rental.details.location'
              class='btn btn-secondary btn-sm'
            >
              {{t 'common.edit'}}
            </LinkTo>
          </div>

          <div class='d-flex justify-content-between'>
            <div>
              {{t 'common.address'}}
            </div>

            <div>
              {{@model.city}},
              {{countryName @model.countryCode}}
            </div>
          </div>

          {{#if @model.mapUrl}}
            <iframe
              src={{@model.mapUrl}}
              title={{t 'rentals.map_alt'}}
              width='100%'
              height='450'
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
              class='border-0'
            >
            </iframe>
          {{/if}}
        </div>
      </Item>

      {{!-- <Item class="pt-5 pb-0">
        <div class="d-flex flex-column gap-3">
          <div class="d-flex align-items-center justify-content-between">
            <h3>
              Languages
            </h3>

            <LinkTo
              @route="rentals.rental.details"
              class="btn btn-secondary btn-sm"
            >
              {{t "common.edit"}}
            </LinkTo>
          </div>

          <div>
            {{this.rentalLanguages}}
          </div>
        </div>
      </Item> --}}
    </List>
  </RentalsLayout>
</template>;

export default RouteTemplate(TemplatesRentalsRentalDetails);
