import UpstreamActiveStorageService from '@algonauti/ember-active-storage/services/active-storage';
import { service } from '@ember/service';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class ActiveStorageService extends UpstreamActiveStorageService {
  @service session!: SessionService;

  get headers() {
    return this.session.authorizationHeader;
  }
}
