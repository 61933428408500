import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type BookingsBookingRoute from 'smily-admin-ui/routes/bookings/booking';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

export default class BookingsBookingContractRoute extends Route {
  @service coreLinks!: CoreLinksService;

  beforeModel() {
    const booking = this.modelFor(
      'bookings.booking',
    ) as ModelFor<BookingsBookingRoute>;

    this.coreLinks.redirectToUrl(booking.contractUrl!);
  }
}
