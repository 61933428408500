import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type { Transition } from 'ember-smily-base/utils/routing';
import type SignupController from 'smily-admin-ui/controllers/signup';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import SignupFreeRoute from 'smily-admin-ui/routes/signup/free';
import type { SetupController } from 'smily-admin-ui/utils/routing';

const AIRBNB_ERRORS = [
  'contact_support',
  'already_signed_up',
  'airbnb_already_connected',
];
const COBRAND_CODES = ['cdiscount', 'skiset', 'maeva'];

export default class SignupIndexRoute extends SignupFreeRoute {
  @service intl!: IntlService;
  @service notify!: NotifyService;

  beforeModel(transition: Transition): void {
    super.beforeModel(transition);
    this.maybeRedirect(transition);
  }

  setupController(
    controller: SetupController<SignupController>,
    model: OnboardingModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    this.handleAirbnbErrors(controller, transition);
  }

  maybeRedirect(transition: Transition): void {
    const { name, queryParams } = transition.to;

    if (name === 'signup.cobranded-signup') {
      return;
    }

    const referralCode = this.extractReferralCode(queryParams);
    const needsRedirect =
      referralCode && COBRAND_CODES.includes(referralCode.toLowerCase());

    if (needsRedirect) {
      this.router.transitionTo(`signup.cobranded-signup`, referralCode, {
        queryParams: {
          ...queryParams,
          referralCode: undefined,
          referral_code: undefined,
          'referral-code': undefined,
        },
      });
    }
  }

  handleAirbnbErrors(
    controller: SignupController,
    transition: Transition,
  ): void {
    const error = transition.to.queryParams.airbnbError;

    if (!error) {
      return;
    }

    if (AIRBNB_ERRORS.includes(error)) {
      controller.airbnbButtonError = error;
    } else {
      this.notify.error(this.intl.t('internals.errors.airbnb.default'));
    }
  }
}
