import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { addMonths, isAfter, isBefore, startOfMonth } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'ember-smily-base/services/store';
import { dateString, parseDateString } from 'ember-smily-base/utils/date';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { SelectOption } from 'ember-smily-base/utils/select';
import type { Query } from 'ember-smily-base/utils/store';
import type CalendarBaseController from 'smily-admin-ui/controllers/calendar/-base';
import type BookingModel from 'smily-admin-ui/models/booking';
import type CalendarBaseRoute from 'smily-admin-ui/routes/calendar/-base';
import type CacheService from 'smily-admin-ui/services/cache';
import { getBookingPillClass } from 'smily-admin-ui/utils/calendar';
import { refreshRoute } from 'smily-admin-ui/utils/routing';

type CalendarApi = {
  scrollToDate: (date: Date) => Promise<void>;
} & (
  | {
      reload: (callback: () => void) => void;
      loadBookings: (rentalIds: string[], start: Date) => void;
    }
  // eslint-disable-next-line @typescript-eslint/ban-types
  | {}
);

function isDateParam(param: string, _value: unknown): _value is Date {
  return param === 'date';
}

interface TemplatesCalendarBaseSignature<
  Route extends CalendarBaseRoute,
  Controller extends CalendarBaseController,
> {
  Element: HTMLDivElement;
  Args: {
    model: ModelFor<Route>;
    controller: Controller;
  };
}

export default abstract class TemplatesCalendarBase<
  Route extends CalendarBaseRoute,
  Controller extends CalendarBaseController,
> extends Component<TemplatesCalendarBaseSignature<Route, Controller>> {
  @service abilities!: AbilitiesService;
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service store!: StoreService;

  calendarAPI!: CalendarApi;
  getBookingPillClass = getBookingPillClass;

  @action
  setupAPI(api: CalendarApi) {
    this.calendarAPI = api;
  }

  get date() {
    return parseDateString(this.args.controller.date);
  }

  get minDate() {
    return this.cache.firstBookingDate &&
      isBefore(this.cache.firstBookingDate, this.args.model.minDate)
      ? startOfMonth(this.cache.firstBookingDate)
      : this.args.model.minDate;
  }

  get monthDates() {
    const dates: Date[] = [];

    let monthShifter = this.minDate;

    while (!isAfter(monthShifter, this.args.model.maxDate)) {
      dates.push(monthShifter);
      monthShifter = addMonths(monthShifter, 1);
    }

    return dates;
  }

  get months() {
    this.intl.primaryLocale;

    return this.monthDates.map<SelectOption<string>>((date) => ({
      value: dateString(date),
      label: this.intl.formatDate(date, { month: 'long', year: 'numeric' }),
    }));
  }

  @tracked isModalOpen = false;
  @tracked booking!: BookingModel;

  showBookingDetails = task({ restartable: true }, async (id: string) => {
    const preloadedBooking = this.store.peekRecord('booking', id);

    if (
      preloadedBooking?.ability &&
      !this.abilities.can('view payments in booking', preloadedBooking)
    ) {
      this.router.transitionTo('bookings.booking', id);
      return;
    }

    this.isModalOpen = true;

    const booking = await this.store.findRecord(
      'booking',
      id,
      this.store.generateQuery('booking', 'calendarDetails'),
    );

    this.booking = booking;
  });

  get allBookings(): BookingModel[] {
    return this.store
      .peekAll('booking')
      .filter((booking) => booking.status !== 'Canceled');
  }

  abstract get bookingQuery(): Query;

  @action
  findBooking(rental: string, start: string, end: string, page: number) {
    const query = { ...this.bookingQuery, page: { size: 160, number: page } };

    query.filter = {
      ...(query.filter || {}),
      covering: `${start},${end}`,
      rental,
    };

    return this.store.queryRecords('booking', query);
  }

  @action
  transitionToBooking(id: string) {
    this.router.transitionTo('bookings.booking', id);
  }

  @action
  transitionToNewBooking(rentalId: string, start: string, end: string) {
    const queryParams = this.abilities.can('access bookings management route')
      ? {
          rental: rentalId,
          start,
          end,
        }
      : {
          rental_id: rentalId,
          start_at: start,
          end_at: end,
        };

    this.router.transitionTo('bookings.new', {
      queryParams,
    });
  }

  @action
  refreshRoute() {
    refreshRoute(this);
  }

  @action
  updateCalendarState(hash: Record<string, unknown>) {
    Object.entries(hash).forEach(([param, value]) => {
      const transformedValue = isDateParam(param, value)
        ? dateString(value)
        : (value as string);
      this.args.controller.changeQueryParam(param, transformedValue);
    });
  }

  @action
  scrollToDate(
    calendarScrollToDate: (date: Date) => Promise<void>,
    _event?: Event,
    date = dateString(startOfMonth(new Date())),
  ) {
    this.args.controller.changeQueryParam('date', date);
    return calendarScrollToDate(parseDateString(date));
  }
}
