import { camelize, underscore } from '@ember/string';
import Transform from '@ember-data/serializer/transform';

export default class PathTransform extends Transform {
  deserialize(serialized?: string | null): string | undefined | null {
    return serialized ? underscore(serialized).replace('_', '.') : serialized;
  }

  serialize(deserialized?: string | null): string | undefined | null {
    return deserialized ? underscore(camelize(deserialized)) : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    path: string;
  }
}
