import { attr, belongsTo } from '@ember-data/model';
import type UserModel from 'smily-admin-ui/models/user';
import type { FeatureName } from 'smily-admin-ui/utils/config';
import { BaseModel } from 'smily-admin-ui/utils/model';

export interface ApplicationMetadataPayload {
  feature_discovery?: {
    [featureName in FeatureName]?: {
      sessions: number;
    };
  };
}

export default class ApplicationMetadatumModel extends BaseModel {
  // payload is underscored by the API, no transformation on the client side
  @attr payload!: ApplicationMetadataPayload;

  @belongsTo('user', { async: false, inverse: 'currentApplicationMetadata' })
  user!: UserModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'application-metadatum': ApplicationMetadatumModel;
  }
}
