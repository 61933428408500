import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { createChangeset } from 'ember-smily-base/utils/model';
import type { Transition } from 'ember-smily-base/utils/routing';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OnboardingCompanyRoute extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  breadcrumb = null;

  beforeModel(transition: Transition): void {
    if (transition.to.name !== 'onboarding.company.type') {
      this.router.transitionTo('onboarding.company.type');
    }
  }

  model() {
    return createChangeset(this.session.onboarding!);
  }
}
