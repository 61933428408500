import type { ModelFor } from 'ember-smily-base/utils/routing';
import { hash } from 'rsvp';
import type CurrencyModel from 'smily-admin-ui/models/currency';
import type DiscountCodeModel from 'smily-admin-ui/models/discount-code';
import DiscountsCurrencyRoute from 'smily-admin-ui/routes/discounts/-currency-route';

export default class DiscountsDiscountRoute extends DiscountsCurrencyRoute {
  async model({ discount_code_id: id }: { discount_code_id: string }): Promise<{
    currencies: CurrencyModel[];
    discountCode: DiscountCodeModel;
  }> {
    const currencies = await this.loadCurrencies();
    const discountCode = this.store.findRecord('discount-code', id, {
      include: 'rentals',
    });

    return hash({ currencies, discountCode });
  }

  afterModel(model: ModelFor<this>): void {
    model.discountCode.selectedRentals = model.discountCode.rentals.slice();
  }
}
