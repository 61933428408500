import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import toggleAction from 'ember-composable-helpers/helpers/toggle-action';
import t from 'ember-intl/helpers/t';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import not from 'ember-truth-helpers/helpers/not';
import type AmenityModel from 'smily-admin-ui/models/amenity';
import type RentalModel from 'smily-admin-ui/models/rental';

interface RentalsAmenitiesSignature {
  Element: HTMLDivElement;
  Args: {
    rental: RentalModel;
  };
}

function groupByTwo(amenities: AmenityModel[]) {
  return amenities.reduce(
    (pairs, amenity, i) => {
      if (i % 2 === 0) {
        pairs.push([amenity]);
      } else {
        pairs.at(-1)!.push(amenity);
      }
      return pairs;
    },
    [] as [AmenityModel, AmenityModel?][],
  );
}

export default class RentalsAmenities extends Component<RentalsAmenitiesSignature> {
  @tracked showMoreAmenities = false;

  get firstSixAmenities() {
    return groupByTwo(this.args.rental.amenities.slice(0, 6));
  }

  get remainingAmenities() {
    return groupByTwo(this.args.rental.amenities.slice(6));
  }

  <template>
    <div class='d-flex flex-column gap-2'>
      {{#each this.firstSixAmenities as |amenityPair|}}
        <div class='d-flex gap-2'>
          {{#each amenityPair as |amenity|}}
            <IconText @icon='check' @text={{amenity.title}} class='flex-1' />
          {{/each}}
        </div>
      {{/each}}

      {{#if this.remainingAmenities.length}}
        <BsCollapse @collapsed={{not this.showMoreAmenities}}>
          <div class='d-flex flex-column gap-2'>
            {{#each this.remainingAmenities as |amenityPair|}}
              <div class='d-flex gap-2'>
                {{#each amenityPair as |amenity|}}
                  <IconText
                    @icon='check'
                    @text={{amenity.title}}
                    class='flex-1'
                  />
                {{/each}}
              </div>
            {{/each}}
          </div>
        </BsCollapse>

        <Link
          @action={{toggleAction 'showMoreAmenities' this}}
          class='text-primary'
        >
          <IconText
            @icon={{if this.showMoreAmenities 'minus' 'plus'}}
            @text={{if
              this.showMoreAmenities
              (t 'common.show_less')
              (t 'common.show_more')
            }}
          />
        </Link>
      {{/if}}
    </div>
  </template>
}
