import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'page' | 'from' | 'to';

export default abstract class FinancePayoutsController extends Controller {
  queryParams = ['page', 'from', 'to'];

  @tracked page = 1;
  @tracked from?: string;
  @tracked to?: string;

  @action
  changeDates(dateStr: string): void {
    this.page = 1;

    if (dateStr) {
      const [from, to] = dateStr.split(',');

      this.from = from;
      this.to = to;
    } else {
      this.from = '';
      this.to = '';
    }
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}
