import { fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import pick from 'ember-composable-helpers/helpers/pick';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import RouteTemplate from 'ember-route-template';
import AnnotatedCard from 'ember-smily-base/components/annotated-card';
import ExternalLink from 'ember-smily-base/components/external-link';
import LoadingButton from 'ember-smily-base/components/loading-button';
import Textarea from 'ember-smily-base/components/textarea';
import type { LanguageCode } from 'ember-smily-base/utils/intl';
import { getLanguages, getShortLocale } from 'ember-smily-base/utils/intl';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { SelectOption } from 'ember-smily-base/utils/select';
import eq from 'ember-truth-helpers/helpers/eq';
import type SettingsRoute from 'smily-admin-ui/routes/settings';

const MANUAL_HOST = 'https://manual.smily.com';
const MANUAL_PATH = {
  EN: 'privacy-policy-template',
  FR: 'modele-de-politique-de-confidentialite',
};

interface TemplatesSettingsPrivacyEditSignature {
  Args: {
    model: ModelFor<SettingsRoute>;
  };
}

class TemplatesSettingsPrivacyEdit extends Component<TemplatesSettingsPrivacyEditSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;

  @tracked activeTab = this.privacyPolicyLanguages[0]!.value;

  get privacyPolicyLanguages() {
    const languages = getLanguages(this.intl.primaryLocale);
    const selectedLocales = this.args.model.preferencesGeneralSetting
      .selectedLocales as LanguageCode[];

    return selectedLocales.map<SelectOption<LanguageCode>>((code) => ({
      value: code,
      label: languages.find(({ value }) => value.toLowerCase() === code)!.label,
    }));
  }

  get manualUrl(): string {
    const locale = getShortLocale(this.intl.primaryLocale);

    return new URL(
      `${locale.toLowerCase()}/${MANUAL_PATH[locale]}`,
      MANUAL_HOST,
    ).toString();
  }

  @action
  async save(): Promise<void> {
    await this.args.model.preferencesGeneralSetting.save();
    await this.router.transitionTo('settings.privacy.index');

    this.notify.success(this.intl.t('settings.privacy.policy_updated'));
  }

  <template>
    <div class='p-3 d-flex flex-column gap-3'>
      <AnnotatedCard
        @title={{t 'settings.privacy.title'}}
        @subtitle={{t 'settings.privacy.subtitle'}}
      >
        <div class='d-flex flex-column gap-3'>
          <ul class='nav nav-underline' role='tablist'>
            {{#each this.privacyPolicyLanguages as |language index|}}
              <li class='nav-item {{if (eq index 0) "ps-0"}}'>
                <a
                  {{on 'click' (fn (mut this.activeTab) language.value)}}
                  href='#'
                  class='nav-link
                    {{if (eq this.activeTab language.value) "active"}}'
                >
                  {{language.label}}
                </a>
              </li>
            {{/each}}
          </ul>

          <div class='d-flex flex-column gap-3'>
            {{#let
              (get
                @model.preferencesGeneralSetting.privacyPolicyTranslations
                this.activeTab
              )
              as |privacyPolicy|
            }}
              <Textarea
                {{on 'input' (pick 'target.value' (fn (mut privacyPolicy)))}}
                value={{privacyPolicy}}
                placeholder={{t 'settings.privacy.title'}}
                class='form-control w-100'
                aria-label={{t 'settings.privacy.title'}}
              />
            {{/let}}
          </div>

          <div class='d-flex justify-content-between'>
            <ExternalLink
              @label={{t 'settings.privacy.use_predefined_template'}}
              @url={{this.manualUrl}}
            />

            <LoadingButton
              @label={{t 'common.save_changes'}}
              @action={{this.save}}
              class='btn btn-primary btn-sm'
            />
          </div>
        </div>
      </AnnotatedCard>
    </div>
  </template>
}

export default RouteTemplate(TemplatesSettingsPrivacyEdit);
