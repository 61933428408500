import Component from '@glimmer/component';
import type { TaskForAsyncTaskFunction } from 'ember-concurrency';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import MediaGallery from 'ember-smily-base/components/media-gallery';
import MediaPlaceholder from 'ember-smily-base/components/media-placeholder';
import { isPDF, isVideo } from 'ember-smily-base/utils/file';
import or from 'ember-truth-helpers/helpers/or';

interface TasksFilesSignature {
  Element: HTMLDivElement;
  Args: {
    files: string[];
    deleteFile?:
      | undefined
      | TaskForAsyncTaskFunction<
          {
            drop: boolean;
          },
          (fileName: string) => Promise<void>
        >;
  };
}

export default class TasksFiles extends Component<TasksFilesSignature> {
  get files() {
    // In development mode, the files array may contain empty values
    return this.args.files.filter((el) => !!el);
  }

  get firstThreeFiles() {
    return this.files.slice(0, 3);
  }

  get extraFiles() {
    return this.files.length > 3 ? this.files.length - 3 : 0;
  }

  <template>
    <div class='d-flex' ...attributes>
      <div class='d-flex gap-1'>
        {{#each this.firstThreeFiles as |attachment|}}
          <MediaGallery
            @files={{this.files}}
            @deleteFile={{@deleteFile}}
            as |Trigger|
          >
            <Trigger
              class='flex-1 btn btn-link d-flex align-items-center justify-content-end gap-2 pe-0'
            >
              {{#if (or (isPDF attachment) (isVideo attachment))}}
                <div class='media-thumbnail on-gray'>
                  <MediaPlaceholder @file={{attachment}} />
                </div>
              {{else}}
                <img
                  src={{attachment}}
                  class='media-thumbnail'
                  alt={{attachment}}
                />
              {{/if}}
            </Trigger>
          </MediaGallery>
        {{/each}}
      </div>

      {{#if this.extraFiles}}
        <MediaGallery @files={{this.files}} as |Trigger|>
          <Trigger
            class='flex-1 btn btn-link d-flex align-items-center justify-content-end gap-2 pe-0'
          >
            <div>
              {{t 'tasks.task_form.extra_files' count=this.extraFiles}}
            </div>

            <Icon @icon='chevron-right' @style='far' />
          </Trigger>
        </MediaGallery>
      {{/if}}
    </div>
  </template>
}
