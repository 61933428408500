import type { AnyComponent } from 'ember-smily-base/utils/glimmer';
import SidebarMobileSubmenusCalendar from 'smily-admin-ui/components/sidebar/mobile-submenus/calendar';
import SidebarMobileSubmenusInbox from 'smily-admin-ui/components/sidebar/mobile-submenus/inbox';
import SidebarMobileSubmenusReviews from 'smily-admin-ui/components/sidebar/mobile-submenus/reviews';
import SidebarSubmenusCalendar from 'smily-admin-ui/components/sidebar/submenus/calendar';
import SidebarSubmenusInbox from 'smily-admin-ui/components/sidebar/submenus/inbox';
import SidebarSubmenusReviews from 'smily-admin-ui/components/sidebar/submenus/reviews';

type SubmenuFields =
  | {
      submenu: AnyComponent;
      noRedirect?: boolean;
    }
  | {
      submenu?: never;
      noRedirect?: never;
    };

export type MenuConfigSourceEntry = {
  route: string;
  order?: number;
  mobileSubmenu?: AnyComponent;
} & SubmenuFields;

const menuConfig: MenuConfigSourceEntry[] = [
  { route: 'onboarding' },
  { route: 'dashboard' },
  {
    route: 'inbox',
    order: 2,
    submenu: SidebarSubmenusInbox,
    mobileSubmenu: SidebarMobileSubmenusInbox,
  },
  {
    route: 'tasks',
    order: 1,
  },
  {
    route: 'calendar',
    order: 3,
    submenu: SidebarSubmenusCalendar,
    mobileSubmenu: SidebarMobileSubmenusCalendar,
  },
  { route: 'old-bookings' },
  { route: 'old-bookings-with-calendar-name' },
  { route: 'bookings' },
  { route: 'clients' },
  { route: 'rentals' },
  { route: 'discounts' },
  { route: 'inquiries' },
  {
    route: 'reviews',
    order: 4,
    submenu: SidebarSubmenusReviews,
    mobileSubmenu: SidebarMobileSubmenusReviews,
  },
  { route: 'performance' },
  { route: 'finance' },
  { route: 'apps' },
];

const topLevelRoutes = [
  'tasks.index',
  'inbox.index',
  'calendar.month',
  'reviews.guest.index',
  'reviews.host.index',
];

export { topLevelRoutes };
export default menuConfig;
