import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingServiceItem from 'smily-admin-ui/components/onboarding/service-item';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

export type Channel = 'airbnb' | 'homeaway' | 'bookingCom';

const SERVICES_ATTRS: [Channel, string[]][] = [
  ['airbnb', ['airbnbLogin', 'airbnbPassword']],
  ['homeaway', ['homeawayLogin', 'homeawayPassword']],
  ['bookingCom', ['bookingComLogin', 'bookingComPassword']],
];

class TemplatesOnboardingServices extends TemplatesOnboardingTask {
  actionName = 'configureChannels';
  nextRoute = 'onboarding.book-a-call';

  get attributes(): string[] {
    let filteredAttrs: string[] = [];

    SERVICES_ATTRS.forEach(([service, attrs]) => {
      if (this[`${service}Checked`]) {
        filteredAttrs = [...filteredAttrs, ...attrs];
      }
    });

    return filteredAttrs;
  }

  @tracked airbnbChecked = !!this.session.onboarding!.airbnbLogin;
  @tracked homeawayChecked = !!this.session.onboarding!.homeawayLogin;
  @tracked bookingComChecked = !!this.session.onboarding!.bookingComLogin;

  services: Channel[] = ['airbnb', 'homeaway', 'bookingCom'];

  @action
  toggle(service: Channel, value: boolean): void {
    this[`${service}Checked`] = value;

    if (!value) {
      this.onboardingChangeset.set(`${service}Login`, null);
      this.onboardingChangeset.set(`${service}Password`, null);
    }
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3 h-100'>
          <h2>
            {{t 'onboarding.services.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @onSubmit={{this.save}}
            @disablePrompt={{true}}
            class='h-100 overflow-auto'
            as |F|
          >
            <List @large={{true}} @sticky={{true}} as |Item|>
              <Item class='d-flex flex-column gap-3'>
                <OnboardingServiceItem
                  @service='airbnb'
                  @isChecked={{this.airbnbChecked}}
                  @form={{F}}
                  @toggle={{this.toggle}}
                />
              </Item>

              <Item class='d-flex flex-column gap-3'>
                <OnboardingServiceItem
                  @service='homeaway'
                  @isChecked={{this.homeawayChecked}}
                  @form={{F}}
                  @toggle={{this.toggle}}
                />
              </Item>

              <Item class='d-flex flex-column gap-3'>
                <OnboardingServiceItem
                  @service='bookingCom'
                  @isChecked={{this.bookingComChecked}}
                  @form={{F}}
                  @toggle={{this.toggle}}
                />
              </Item>

              <Item class='d-flex align-items-center justify-content-between'>
                <OnboardingBackToTaskList />

                <F.Submit @label={{t 'common.next'}} />
              </Item>
            </List>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.services.helper.1.title'}}
          </div>

          <div>
            {{t 'onboarding.services.helper.1.p1'}}
          </div>

          <div class='fw-semibold'>
            {{t 'onboarding.services.helper.2.title'}}
          </div>

          <div>
            {{t 'onboarding.services.helper.2.p1'}}
          </div>

          <div>
            {{t 'onboarding.services.helper.2.p2'}}
          </div>

          <div>
            {{t 'onboarding.services.helper.2.p3'}}
          </div>

          <div>
            {{t 'onboarding.services.helper.2.p4'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingServices);
