import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class AmenityModel extends BaseModel {
  @attr key!: string;
  @attr title!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    amenity: AmenityModel;
  }
}
