import { getLanguages } from 'ember-smily-base/utils/intl';

import type IntlService from 'ember-intl/services/intl';

export default function getLanguagesLabelOfTranslatedField(
  intl: IntlService,
  field: Record<string, string | null>,
) {
  const existingLanguages = Object.entries(field)
    .filter(([_language, value]) => Boolean(value))
    .map(([language]) => language);
  const languagesLabel = existingLanguages
    .slice(0, 2)
    .map(
      (language) =>
        getLanguages(intl.primaryLocale).find(
          ({ value }) => value === language.toUpperCase(),
        )!.label,
    )
    .join(', ');
  const capitalizedLanguagesLabel =
    languagesLabel.charAt(0).toUpperCase() + languagesLabel.slice(1);

  if (existingLanguages.length <= 2) {
    return capitalizedLanguagesLabel;
  }

  return intl.t('common.language_options', {
    languages: capitalizedLanguagesLabel,
    count: existingLanguages.length - 2,
  });
}
