import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type SettingsRoute from 'smily-admin-ui/routes/settings';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class SettingsBillingCreditCardRoute extends BaseRoute {
  @service router!: RouterService;

  checkAbilities = true;
  requiredRouteAbility = 'access edit settings route';

  model(): ModelFor<SettingsRoute> {
    return this.modelFor('settings') as ModelFor<SettingsRoute>;
  }

  routeNotAccessible(): void {
    this.router.transitionTo('settings.billing.index');
  }
}
