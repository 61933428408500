import { service } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import IconText from 'ember-smily-base/components/icon-text';
import type { IconName, IconPrefix } from 'ember-smily-base/utils/icons';
import type SourceModel from 'smily-admin-ui/models/source';

const SOURCES = [
  'tripadvisor',
  'airbnb',
  'booking-com',
  'homeaway',
  'expedia',
  'monsejour',
];

const CUSTOM_ICONS = [
  'tripadvisor',
  'booking-com',
  'homeaway',
  'expedia',
  'monsejour',
];

interface SourceTextSignature {
  Element: HTMLDivElement;
  Args: {
    source?: SourceModel | string;
    iconClass?: string;
    hideText?: boolean;
    directIfEmpty?: boolean;
    gap?: number;
  };
}

export default class SourceText extends Component<SourceTextSignature> {
  @service intl!: IntlService;

  get sourceName(): string | undefined {
    const source = this.args.source;

    if (!source || typeof source === 'string') {
      return source;
    }

    return source?.name;
  }

  get text(): string | undefined {
    if (this.args.directIfEmpty && !this.sourceName) {
      return this.intl.t('common.direct');
    }

    return this.sourceName;
  }

  get icon(): IconName | undefined {
    if (!this.sourceName) {
      return undefined;
    }

    return dasherize(this.sourceName.replace('.', '-')) as IconName;
  }

  get iconStyle(): IconPrefix | 'fak' | undefined {
    if (!this.icon) {
      return undefined;
    }

    return CUSTOM_ICONS.includes(this.icon) ? 'fak' : 'fab';
  }

  get hideIcon(): boolean {
    return !this.icon || !SOURCES.includes(this.icon);
  }

  <template>
    <IconText
      @text={{this.text}}
      @icon={{this.icon}}
      @iconStyle={{this.iconStyle}}
      @iconClass={{@iconClass}}
      @hideIcon={{this.hideIcon}}
      @hideText={{@hideText}}
      @gap={{@gap}}
      ...attributes
    />
  </template>
}
