import Controller, { inject as controller } from '@ember/controller';
import type ApplicationController from 'smily-admin-ui/controllers/application';

export default abstract class CreditCardAuthBaseController extends Controller {
  @controller('application')
  applicationController!: ApplicationController;

  get returnPath() {
    return this.applicationController.returnPath;
  }
}
