import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface OptionLabelSignature {
  Args: {
    Positional: [string | undefined, string];
  };
  Return: string;
}

/**
 * Provided an option value and a dictionary scope, returns the translation
 * or an en dash if the value is undefined.
 *
 * ```handlebars
 * {{optionLabel this.rentalCheckinType "rentals.checkin_type_options"}}
 * ```
 */
export default class OptionLabelHelper extends Helper<OptionLabelSignature> {
  @service intl!: IntlService;

  compute([value, scope]: OptionLabelSignature['Args']['Positional']) {
    return value ? this.intl.t(`${scope}.${value}`) : '–';
  }
}
