import { array, fn, hash } from '@ember/helper';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Badge from 'ember-smily-base/components/badge';
import FiltersDestinations from 'ember-smily-base/components/filters/destinations';
import FiltersSelect from 'ember-smily-base/components/filters/select';
import FiltersSelectInfiniteMultiple from 'ember-smily-base/components/filters/select-infinite-multiple';
import HorizontallyScrollable from 'ember-smily-base/components/horizontally-scrollable';
import IconText from 'ember-smily-base/components/icon-text';
import NavTabs from 'ember-smily-base/components/nav-tabs';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import { asString } from 'ember-smily-base/utils/template';
import type PerformanceController from 'smily-admin-ui/controllers/performance';
import type PerformanceRoute from 'smily-admin-ui/routes/performance';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';

const PERIOD_OPTIONS = [
  'next_year',
  'year_from_today',
  'next_6m',
  'next_3m',
  'next_30d',
  'this_month',
  'next_14d',
  'next_7d',
  'this_week',
  'today',
  'yesterday',
  'last_7d',
  'last_14d',
  'last_30d',
  'last_3m',
  'last_6m',
  'year_to_today',
  'last_year',
];

const COMPARE_OPTIONS = ['prev_year'];

interface TemplatesPerformanceSignature {
  Args: {
    model: ModelFor<PerformanceRoute>;
    controller: PerformanceController;
  };
}

class TemplatesPerformance extends Component<TemplatesPerformanceSignature> {
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service session!: SessionService;

  get periodOptions() {
    return PERIOD_OPTIONS.map((value) => ({
      value,
      label: this.intl.t(`performance.period_options.${value}`),
    }));
  }

  get compareOptions() {
    return COMPARE_OPTIONS.map((value) => ({
      value,
      label: this.intl.t(`performance.compare_options.${value}`),
    }));
  }

  get filled() {
    return this.args.controller.queryParams.filter(
      (el) => !!this.args.controller[el],
    ).length;
  }

  <template>
    <div class='h-100 d-flex flex-column'>
      <div class='h-0 flex-1 p-3 px-0 pt-1'>
        <div class='h-100 d-flex flex-column overflow-hidden'>
          <div class='filter-bar'>
            <div class='px-0 pt-1 border-bottom'>
              <HorizontallyScrollable
                @containerClass='d-flex gap-3 overflow-y-hidden px-0 pt-2 pb-3'
              >
                <div class='d-flex align-items-center gap-1'>
                  <IconText
                    @icon='filter'
                    @iconStyle='far'
                    @text={{t 'smily_base.filters'}}
                    @gap={{1}}
                  />

                  {{#if this.filled}}
                    <Badge
                      @label={{asString this.filled}}
                      @color='primary'
                      class='text-white'
                    />
                  {{/if}}
                </div>

                <div class='d-flex gap-2'>
                  <FiltersSelect
                    @label={{t 'performance.period'}}
                    @value={{@controller.period}}
                    @options={{this.periodOptions}}
                    @omitAll={{true}}
                    @onChange={{fn @controller.changeQueryParam 'period'}}
                  />

                  <FiltersSelect
                    @label={{t 'performance.compare_to'}}
                    @value={{@controller.compare}}
                    @options={{this.compareOptions}}
                    @allLabel={{t 'performance.do_not_compare'}}
                    @onChange={{fn @controller.changeQueryParam 'compare'}}
                  />

                  <FiltersSelectInfiniteMultiple
                    @label={{t 'internals.models.source'}}
                    @value={{@controller.source}}
                    @modelName='source'
                    @query={{generateQuery 'source' 'filter'}}
                    @onChange={{fn @controller.changeQueryParam 'source'}}
                  />

                  <FiltersSelectInfiniteMultiple
                    @label={{t 'internals.models.rentals-tag'}}
                    @value={{@controller.rentalsTags}}
                    @modelName='rentals-tag'
                    @query={{generateQuery 'rentals-tag' 'filter'}}
                    @searchField='name'
                    @onChange={{fn @controller.changeQueryParam 'rentalsTags'}}
                  />

                  <FiltersSelectInfiniteMultiple
                    @label={{t 'internals.models.rental'}}
                    @value={{@controller.rental}}
                    @modelName='rental'
                    @query={{generateQuery 'rental' 'filter'}}
                    @onChange={{fn @controller.changeQueryParam 'rental'}}
                  />

                  {{#unless this.session.account.isSmily}}
                    <FiltersDestinations
                      @label={{t 'rentals.location'}}
                      @value={{@controller.destinations}}
                      @destinations={{this.cache.destinations}}
                      @onChange={{fn
                        @controller.changeQueryParam
                        'destinations'
                      }}
                    />
                  {{/unless}}
                </div>
              </HorizontallyScrollable>
            </div>
          </div>

          <div class='h-0 flex-1 d-flex flex-column'>
            <NavTabs
              @config={{array
                (hash
                  route='performance.calendar-occupancy'
                  title=(t 'performance.calendar_occupancy.title')
                  icon='calendar'
                  iconStyle='far'
                )
                (hash
                  route='performance.financial-metrics'
                  title=(t 'performance.financial_metrics.title')
                  icon='coins'
                  iconStyle='far'
                )
                (hash
                  route='performance.quality-metrics'
                  title=(t 'performance.quality_metrics.title')
                  icon='star'
                  iconStyle='far'
                )
              }}
              class='flex-shrink-0 border-bottom my-2'
            />

            <div class='h-0 flex-1 overflow-hidden'>
              {{outlet}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
}

export default RouteTemplate(TemplatesPerformance);
