export default {
  time: {
    hhmm: {
      hour: 'numeric',
      minute: 'numeric',
    },
    hhmmss: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
  },

  date: {
    hhmm: {
      hour: 'numeric',
      minute: 'numeric',
    },
    hhmmss: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    longWithWeek: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    long: {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    dayMonthNumeric: {
      day: 'numeric',
      month: 'numeric',
    },
    monthYear: {
      month: 'long',
      year: 'numeric',
    },
    full: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },

    timestamp: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
    timestampSameYear: {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
    },
    timestampDateOnly: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    timestampSameYearDateOnly: {
      day: 'numeric',
      month: 'short',
    },
  },

  number: {
    compact: { notation: 'compact' },
    EUR: {
      style: 'currency',
      currency: 'EUR',
    },
    USD: {
      style: 'currency',
      currency: 'USD',
    },
  },
};
