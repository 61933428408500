import { concat } from '@ember/helper';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import Badge from 'ember-smily-base/components/badge';
import type BookingModel from 'smily-admin-ui/models/booking';

export interface Args {
  booking: BookingModel;
}

interface BookingsClientStatusSignature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class BookingsClientStatus extends Component<BookingsClientStatusSignature> {
  get color() {
    if (this.args.booking.isArrivingToday) {
      return 'success';
    } else if (this.args.booking.isLeavingToday) {
      return 'body-text';
    } else {
      return 'body';
    }
  }

  <template>
    {{#if @booking.guestStatus}}
      <Badge
        @color={{this.color}}
        @label={{t
          (concat 'bookings.guest_status_types.' @booking.guestStatus)
        }}
        ...attributes
      />
    {{/if}}
  </template>
}
