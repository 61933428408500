import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { AnyFn } from 'ember/-private/type-utils';
import optional from 'ember-composable-helpers/helpers/optional';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import Form from 'ember-smily-base/components/form';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import LoadingButton from 'ember-smily-base/components/loading-button';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import SelectInfinite from 'ember-smily-base/components/select/infinite';
import type StoreService from 'ember-smily-base/services/store';
import type { Changeset } from 'ember-smily-base/utils/model';
import { createChangeset } from 'ember-smily-base/utils/model';
import type { SelectAPI } from 'ember-smily-base/utils/select';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import InboxSavedRepliesAdd from 'smily-admin-ui/components/inbox/saved-replies/add';
import InboxSavedRepliesDesktopPlaceholder from 'smily-admin-ui/components/inbox/saved-replies/desktop-placeholder';
import InboxSavedRepliesMobileTrigger from 'smily-admin-ui/components/inbox/saved-replies/mobile-trigger';
import type InboxReplyTemplateModel from 'smily-admin-ui/models/inbox-reply-template';

const Empty: typeof Component = Component;
const SelectReplyTemplate: typeof SelectInfinite<'inbox-reply-template'> =
  SelectInfinite;

function asKeyboardEvent(event: Event) {
  return event as KeyboardEvent;
}

interface InboxSavedRepliesSignature {
  Element: HTMLDivElement;
  Args: {
    changeset: Changeset;
    compact?: boolean;
  };
}

export default class InboxSavedReplies extends Component<InboxSavedRepliesSignature> {
  @service store!: StoreService;

  @tracked declare savedReply: Changeset;
  replyToDelete?: InboxReplyTemplateModel;

  @action
  insertSavedReply(
    toggleEditModal: AnyFn,
    toggleDeleteModal: AnyFn,
    savedReply: InboxReplyTemplateModel | undefined,
    _select: SelectAPI<InboxReplyTemplateModel>,
    event?: Event,
  ): void {
    const savedReplyModel = savedReply as InboxReplyTemplateModel;

    if ((event?.target as HTMLElement).closest('a')) {
      // clicked reply action, other handler handles it
      this.handleActionClick(
        savedReplyModel,
        event!,
        toggleEditModal,
        toggleDeleteModal,
      );
      return;
    }

    this.args.changeset.set(
      'content',
      `${this.args.changeset.content}${savedReplyModel.content}`,
    );
  }

  @action
  editReply(
    savedReply: InboxReplyTemplateModel | null,
    toggleModal?: AnyFn,
  ): void {
    const model =
      savedReply ||
      this.store.createRecord('inbox-reply-template', {
        content: this.args.changeset.content,
      });
    this.savedReply = createChangeset(model);
    toggleModal?.();
  }

  @action
  cancelReplySave(toggleModal: AnyFn): void {
    const savedReply = this.savedReply;
    const savedReplyModel = savedReply._content as InboxReplyTemplateModel;

    savedReply.rollback();

    if (savedReplyModel.isNew as unknown as boolean) {
      savedReplyModel.destroyRecord();
    }

    toggleModal();
  }

  saveReply = task({ drop: true }, async (toggleModal: AnyFn) => {
    await this.savedReply.save();

    toggleModal();
  });

  deleteReply = task({ drop: true }, async (toggleModal: AnyFn) => {
    await (this.replyToDelete as InboxReplyTemplateModel).destroyRecord();

    this.replyToDelete = undefined;

    toggleModal();
  });

  @action
  async focusSaveButtonIfNoOptions(
    select: SelectAPI<InboxReplyTemplateModel>,
    _: Event,
    infiniteQuery: InfiniteQuery<'inbox-reply-template'>,
  ): Promise<void> {
    await infiniteQuery.promise;

    if (!infiniteQuery.total) {
      this.focusSaveButton(select);
    }
  }

  @action
  handleSaveKeyDown(
    select: SelectAPI<InboxReplyTemplateModel>,
    event: Event,
  ): void {
    if (['Tab', 'Escape'].includes(asKeyboardEvent(event).key)) {
      select.actions.close();
    } else if (['ArrowUp', 'ArrowDown'].includes(asKeyboardEvent(event).key)) {
      (
        document.querySelector(
          `[data-ebd-id="${select.uniqueId}-trigger"]`,
        ) as HTMLElement
      ).focus();
    }
  }

  @action
  handleActionKeyDown(
    savedReply: InboxReplyTemplateModel,
    toggleEditModal: AnyFn,
    toggleDeleteModal: AnyFn,
    select: SelectAPI<InboxReplyTemplateModel>,
    event: Event,
  ): boolean | void {
    if (asKeyboardEvent(event).key === 'Escape') {
      select.actions.close();
      return;
    }

    return this.handleKeyDown(
      select,
      event,
      savedReply,
      toggleEditModal,
      toggleDeleteModal,
    );
  }

  @action
  handleSelectKeyDown(
    select: SelectAPI<InboxReplyTemplateModel>,
    event: Event,
  ): boolean | void {
    return this.handleKeyDown(select, event, undefined);
  }

  @action
  handleKeyDown(
    select: SelectAPI<InboxReplyTemplateModel>,
    event: Event,
    savedReply: InboxReplyTemplateModel | undefined,
    toggleEditModal?: AnyFn,
    toggleDeleteModal?: AnyFn,
  ): boolean | void {
    const isRight = asKeyboardEvent(event).key === 'ArrowRight';
    const isLeft = asKeyboardEvent(event).key === 'ArrowLeft';
    const isUp = asKeyboardEvent(event).key === 'ArrowUp';
    const isDown = asKeyboardEvent(event).key === 'ArrowDown';

    if (
      (isUp && select.results.indexOf(select.highlighted) === 0) ||
      (isDown &&
        select.results.indexOf(select.highlighted) === select.resultsCount - 1)
    ) {
      this.focusSaveButton(select);
      return false;
    } else if (asKeyboardEvent(event).key === 'Enter' && savedReply) {
      this.handleActionClick(
        savedReply,
        event,
        toggleEditModal,
        toggleDeleteModal,
        select,
      );
      return false;
    } else if (isLeft || isRight) {
      this.navigateActions(select.uniqueId, event, isRight);
      return false;
    }

    return;
  }

  async handleActionClick(
    savedReply: InboxReplyTemplateModel,
    event: Event,
    toggleEditModal?: AnyFn,
    toggleDeleteModal?: AnyFn,
    select?: SelectAPI<InboxReplyTemplateModel>,
  ): Promise<void> {
    const button = (event.target as HTMLElement).closest('a');

    if (!button) {
      return;
    }

    if ('editReply' in button.dataset) {
      this.editReply(savedReply, toggleEditModal);
    } else if ('removeReply' in button.dataset) {
      this.replyToDelete = savedReply;

      select?.actions?.close?.();
      toggleDeleteModal?.();
    }
  }

  navigateActions(id: string, event: Event, isRight: boolean): void {
    const highlighted = (
      document.getElementById(`ember-power-select-options-${id}`) as HTMLElement
    ).querySelector('[aria-current="true"]') as HTMLElement;
    const trigger = document.querySelector(
      `[data-ebd-id="${id}-trigger"]`,
    ) as HTMLElement;
    const focusableElements = [
      trigger,
      ...(Array.from(
        highlighted.querySelectorAll('.saved-reply-actions a'),
      ) as HTMLElement[]),
    ];
    const focused =
      focusableElements.find((element) => event.target === element) ||
      focusableElements[0]!;
    const index = focusableElements.indexOf(focused);
    const newIndex = (index + 3 + (isRight ? 1 : -1)) % 3;

    focusableElements[newIndex]!.focus();
  }

  focusSaveButton(select: SelectAPI<InboxReplyTemplateModel>) {
    return (
      document.getElementById(
        `ember-basic-dropdown-content-${select.uniqueId}`,
      ) as HTMLElement
    )
      .querySelector<HTMLButtonElement>('[data-save-reply]')
      ?.focus();
  }

  <template>
    <div>
      <ModalDialog as |deleteDialog|>
        <ModalDialog as |editDialog|>
          <SelectReplyTemplate
            @modelName='inbox-reply-template'
            @verticalPosition='above'
            @searchEnabled={{false}}
            @searchField='name'
            @triggerClass='btn btn-secondary {{if
              @compact
              "px-0 border-0 text-20 lh-1"
              "btn-sm with-smily-select-icon"
            }}'
            @dropdownClass='saved-replies-dropdown'
            @triggerComponent={{if @compact InboxSavedRepliesMobileTrigger}}
            @placeholderComponent={{if
              @compact
              undefined
              InboxSavedRepliesDesktopPlaceholder
            }}
            @searchMessageComponent={{Empty}}
            @afterOptionsComponent={{component
              InboxSavedRepliesAdd
              editReply=(fn this.editReply null editDialog.toggleModal)
              handleSaveKeyDown=this.handleSaveKeyDown
            }}
            @onOpen={{this.focusSaveButtonIfNoOptions}}
            @onChange={{fn
              this.insertSavedReply
              editDialog.toggleModal
              deleteDialog.toggleModal
            }}
            @onKeydown={{this.handleSelectKeyDown}}
            as |savedReply select|
          >
            <div class='d-flex justify-content-between gap-2'>
              <div class='text-truncate'>
                {{savedReply.name}}
              </div>

              <div class='saved-reply-actions d-flex gap-1'>
                <Link
                  {{on
                    'keydown'
                    (fn
                      this.handleActionKeyDown
                      savedReply
                      editDialog.toggleModal
                      (optional undefined)
                      select
                    )
                  }}
                  class='btn h-100 d-flex align-items-center p-0'
                  aria-label={{t 'inbox.edit_saved_reply'}}
                  data-edit-reply
                >
                  <Icon @icon='pencil-alt' class='interactive-icon' />
                </Link>

                <Link
                  {{on
                    'keydown'
                    (fn
                      this.handleActionKeyDown
                      savedReply
                      (optional undefined)
                      deleteDialog.toggleModal
                      select
                    )
                  }}
                  class='btn h-100 d-flex align-items-center p-0'
                  aria-label={{t 'inbox.delete_saved_reply'}}
                  data-remove-reply
                >
                  <Icon
                    @icon='trash-alt'
                    @style='far'
                    class='interactive-icon'
                  />
                </Link>
              </div>
            </div>
          </SelectReplyTemplate>

          <editDialog.modal as |modal|>
            <modal.header>
              <h4>
                {{#if this.savedReply.isNew}}
                  {{t 'inbox.new_saved_reply'}}
                {{else}}
                  {{t 'inbox.edit_saved_reply'}}
                {{/if}}
              </h4>
            </modal.header>

            <modal.body>
              <Form @model={{this.savedReply}} autocomplete='off' as |F|>
                <F.Input @property='name' data-test-reply-title />

                <F.Textarea @property='content' data-test-reply-content />
              </Form>
            </modal.body>

            <modal.footer>
              <button
                {{on 'click' (fn this.cancelReplySave editDialog.toggleModal)}}
                type='button'
                class='btn btn-secondary'
                data-test-cancel
              >
                {{t 'common.cancel'}}
              </button>

              <LoadingButton
                @action={{perform this.saveReply editDialog.toggleModal}}
                class='btn btn-primary'
                data-test-save-reply
              >
                {{t 'inbox.save_reply'}}
              </LoadingButton>
            </modal.footer>
          </editDialog.modal>
        </ModalDialog>

        <deleteDialog.modal as |modal|>
          <modal.header>
            <h4>
              {{t 'inbox.delete_reply_dialog_title'}}
            </h4>
          </modal.header>

          <modal.footer>
            <button
              {{on 'click' deleteDialog.toggleModal}}
              type='button'
              class='btn btn-secondary'
              data-test-delete-cancel
            >
              {{t 'common.cancel'}}
            </button>

            <LoadingButton
              @action={{perform this.deleteReply deleteDialog.toggleModal}}
              class='btn btn-danger'
              data-test-delete-ok
            >
              {{t 'common.ok'}}
            </LoadingButton>
          </modal.footer>
        </deleteDialog.modal>
      </ModalDialog>
    </div>
  </template>
}
