import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { QueryParam } from 'smily-admin-ui/controllers/clients';
import type { CoreLinkRouteCallable } from 'smily-admin-ui/services/core-links';
import { FeatureIndexRoute } from 'smily-admin-ui/utils/routing';

export default class ClientsIndexRoute extends FeatureIndexRoute {
  @service store!: StoreService;

  feature = 'guests';
  coreLink: CoreLinkRouteCallable = 'guests';

  queryParams = {
    page: { refreshModel: true },
    search: { refreshModel: true },
  };

  get requiredRouteAbility() {
    return 'access clients route';
  }

  model(
    params: Record<QueryParam, string> & { page: number },
  ): InfiniteQuery<'client'> {
    const { search, page } = params;

    return new InfiniteQuery(
      this.store,
      'client',
      this.store.generateQuery('client', 'listDefault', { search }),
      { page },
    );
  }
}
