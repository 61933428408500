import type { TOC } from '@ember/component/template-only';
import RouteTask from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import TasksTaskNewForm from 'smily-admin-ui/components/tasks/task/new-form';
import type TasksNewFromTemplateRoute from 'smily-admin-ui/routes/tasks/new-from-template';

const TasksNewFromTemplate: TOC<{
  Args: {
    model: ModelFor<TasksNewFromTemplateRoute>;
  };
}> = <template><TasksTaskNewForm @task={{@model}} /></template>;

export default RouteTask(TasksNewFromTemplate);
