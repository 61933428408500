import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import Component from '@glimmer/component';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import Badge from 'ember-smily-base/components/badge';
import Link from 'ember-smily-base/components/link';
import formatMoney from 'ember-smily-base/helpers/format-money';
import not from 'ember-truth-helpers/helpers/not';
import BookingsPaymentActions from 'smily-admin-ui/components/bookings/payment-actions';
import PopoverFocused from 'smily-admin-ui/components/popover-focused';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type BookingModel from 'smily-admin-ui/models/booking';
import type PaymentModel from 'smily-admin-ui/models/payment';

interface BookingsPaymentBarDetailedPaymentDotSignature {
  Args: {
    booking: BookingModel;
    payment: PaymentModel;

    /**
     * Renders a different popup with a gray dot for the end of the unpaid
     * payment bar.
     */
    isNotPaid?: boolean;
  };
}

export default class BookingsPaymentBarDetailedPaymentDot extends Component<BookingsPaymentBarDetailedPaymentDotSignature> {
  @action
  reposition(element: HTMLAnchorElement) {
    const allChildren = Array.from(
      (element.parentElement as HTMLDivElement).children,
    );

    let left = 0;

    for (let i = 0, x = allChildren.length; i < x; i++) {
      const child = allChildren[i] as HTMLDivElement | HTMLAnchorElement;

      if (child.className.includes('progress-bar')) {
        left += Number(child.style.width.slice(0, -1));
      }

      if (child === element) {
        break;
      }
    }

    if (left < 100) {
      element.style.left = `${left}%`;
    } else {
      element.style.right = '-1px';
      element.style.transform = 'translate(0, -50%)';
    }
  }

  <template>
    <Link
      {{didInsert this.reposition}}
      {{didUpdate this.reposition @payment.amount}}
      class='payment-progress-milestone
        {{if @payment.isAuthorized "bg-warning"}}'
      as |id|
    >
      <PopoverFocused
        @id={{id}}
        @disableFocus={{not @payment.isEditable}}
        class='booking-payment-popover'
      >
        <div class='d-flex flex-column'>
          <div class='d-flex align-items-end justify-content-between gap-3'>
            <div class='h4'>
              {{formatMoney @payment.amount @payment.currency}}
            </div>

            <Badge
              @label={{t 'finance.payment_status_options.paid'}}
              @color='success'
              @skeleton={{true}}
            />
          </div>

          <div>
            {{#if @payment.paidAt}}
              {{formatDate @payment.paidAt format='short'}}
            {{else if @payment.authorizedAt}}
              {{t
                'finance.payment_bar.authorized_at'
                date=(formatDate @payment.authorizedAt format='short')
              }}
            {{/if}}
            •
            {{optionLabel @payment.kind 'finance.payment_kind_options'}}
          </div>
        </div>

        {{#if @payment.notes}}
          <div class='d-flex flex-column'>
            <div class='fw-semibold text-11'>
              {{t 'internals.fields.notes'}}
            </div>

            <div>
              {{@payment.notes}}
            </div>
          </div>
        {{/if}}

        {{#if @payment.isEditable}}
          <div class='d-flex gap-2'>
            <BookingsPaymentActions
              @booking={{@booking}}
              @payment={{@payment}}
              @popoverId={{id}}
              as |Actions|
            >
              <Actions.Cancel class='flex-1 btn btn-danger btn-sm' />

              <Actions.Edit class='flex-1 btn btn-secondary btn-sm' />
            </BookingsPaymentActions>
          </div>
        {{/if}}
      </PopoverFocused>
    </Link>
  </template>
}
