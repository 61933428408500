import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import Checkbox from 'ember-smily-base/components/checkbox';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import LoadingButton from 'ember-smily-base/components/loading-button';
import sortableHandle from 'ember-sortable/modifiers/sortable-handle';
import sortableItem from 'ember-sortable/modifiers/sortable-item';
import type { TaskItem } from 'smily-admin-ui/serializers/task-management-task';

interface TasksTemplateChecklistItemSignature {
  Element: HTMLDivElement;
  Args: {
    item: TaskItem;
    changeItem: (item: TaskItem, value: string) => void;
    deleteItem: (item: TaskItem) => void;
  };
}

export default class TasksTemplateChecklistItem extends Component<TasksTemplateChecklistItemSignature> {
  @action
  setValue(event: Event) {
    const target = event.target as HTMLInputElement;
    this.args.changeItem(this.args.item, target.value);
  }

  <template>
    <div
      {{sortableItem model=@item.position}}
      class='smily-form-field d-flex align-items-center gap-2 my-2'
      ...attributes
    >
      <Link {{sortableHandle}} class='interactive-icon'>
        <Icon @icon='grip-vertical' />
      </Link>

      <Checkbox
        @value={{false}}
        @disabled={{true}}
        @label=''
        @renderLabel={{false}}
        role='presentation'
      />

      <div class='flex-1 d-flex align-items-center gap-2'>
        <div class='flex-1 form-control-container'>
          <input
            {{on 'input' this.setValue}}
            required
            value={{@item.name}}
            placeholder={{t 'tasks.template_form.checklist_item_placeholder'}}
            class='flex-1 form-control form-control-sm'
            aria-label={{t 'tasks.template_form.checklist_item'}}
          />
        </div>

        <LoadingButton
          @action={{fn @deleteItem @item}}
          @label={{t 'tasks.template_form.delete_checklist_item'}}
          @iconOnly={{true}}
          class='btn btn-secondary btn-sm'
        >
          <Icon @icon='trash-alt' @style='far' />
        </LoadingButton>
      </div>
    </div>
  </template>
}
