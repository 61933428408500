import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';

const OnboardingBackToTaskList = <template>
  <LinkTo @route='onboarding.index' class='btn-link'>
    <Icon @icon='arrow-left' />

    {{t 'onboarding.back_to_task_list'}}
  </LinkTo>
</template>;

export default OnboardingBackToTaskList;
