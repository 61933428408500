import { array, fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BasicDropdown from 'ember-basic-dropdown/components/basic-dropdown';
import calculatePosition from 'ember-basic-dropdown/utils/calculate-position';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import focusTrap from 'ember-focus-trap/modifiers/focus-trap';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import FilterBar from 'ember-smily-base/components/filter-bar';
import FiltersSelect from 'ember-smily-base/components/filters/select';
import FiltersSelectInfiniteMultiple from 'ember-smily-base/components/filters/select-infinite-multiple';
import FiltersSelectMultiple from 'ember-smily-base/components/filters/select-multiple';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import LoadingState from 'ember-smily-base/components/loading-state';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import type { SelectOption } from 'ember-smily-base/utils/select';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import not from 'ember-truth-helpers/helpers/not';
import CopyButtonCompact from 'smily-admin-ui/components/copy-button-compact';
import type InboxChildController from 'smily-admin-ui/controllers/inbox/-child';
import type { Stage, Status } from 'smily-admin-ui/models/booking';
import { STAGES, STATUSES } from 'smily-admin-ui/models/booking';
import type { Priority, Severity } from 'smily-admin-ui/models/inbox-message';
import { PRIORITIES, SEVERITIES } from 'smily-admin-ui/models/inbox-message';
import type SessionService from 'smily-admin-ui/services/session-service';
import { isError, queryAI } from 'smily-admin-ui/utils/promise';

type CalculatePositionParams = Parameters<typeof calculatePosition>;

interface FilterResponseAttributes {
  read: boolean;
  assignee: number[];
  client: number[];
  stage: Stage;
  source: number[];
  booking_status: Status[];
  rental: number[];
  booking_tag: number[];
  rental_tag: number[];
  priority: Priority[];
  severity: Severity[];
}

interface InboxConversationFiltersSignature {
  Element: HTMLDivElement;
  Args: {
    model: InfiniteQuery<'inbox-conversation'>;
    controller: InboxChildController;
    isTableFilters?: boolean;
  };
}

export default class InboxConversationFilters extends Component<InboxConversationFiltersSignature> {
  @service intl!: IntlService;
  @service session!: SessionService;

  get readOptions(): SelectOption<string>[] {
    return [
      {
        label: this.intl.t('inbox.read_options.unread'),
        value: 'false',
      },
      {
        label: this.intl.t('inbox.read_options.read'),
        value: 'true',
      },
    ];
  }

  get stageOptions(): SelectOption<string>[] {
    return STAGES.map((value) => ({
      label: this.intl.t(`bookings.stage_options.${value}`),
      value,
    }));
  }

  get statusOptions(): SelectOption<string>[] {
    return STATUSES.map((value) => ({
      label: this.intl.t(`bookings.status_options.${value.toLowerCase()}`),
      value: value.toLowerCase(),
    }));
  }

  get priorityOptions(): SelectOption<string>[] {
    return PRIORITIES.map((value) => ({
      label: this.intl.t(`inbox.message_priority_options.${value}`),
      value,
    }));
  }
  get severityOptions(): SelectOption<string>[] {
    return SEVERITIES.map((value) => ({
      label: this.intl.t(`inbox.message_severity_options.${value}`),
      value,
    }));
  }

  positionAiHelperTooltip(...args: CalculatePositionParams) {
    return calculatePosition(
      document.querySelector('[data-ai-search-input]')!,
      ...(args.slice(1) as [
        CalculatePositionParams[1],
        CalculatePositionParams[2],
        CalculatePositionParams[3],
      ]),
    );
  }

  handleAiSearchInput = task({ drop: true }, async (event: KeyboardEvent) => {
    const text = (event.target as HTMLInputElement).value.trim();

    if (event.key !== 'Enter' || !text) {
      return;
    }

    const payload = {
      data: {
        attributes: { text },
        type: 'inbox-text-search-to-filters',
      },
    };
    const response = await queryAI(
      this,
      'inbox-text-search-to-filters',
      payload,
    );

    if (isError(response)) {
      return;
    }

    this.overrideFilters(
      response.data.attributes!.filters as FilterResponseAttributes,
    );
  });

  overrideFilters(filters: FilterResponseAttributes) {
    const { changeQueryParam } = this.args.controller;

    changeQueryParam(
      'read',
      filters.read === undefined ? undefined : String(filters.read),
    );
    changeQueryParam('assignee', mapNumberArrayFilter(filters.assignee));
    changeQueryParam('client', mapNumberArrayFilter(filters.client));
    changeQueryParam('stage', filters.stage);
    changeQueryParam('source', mapNumberArrayFilter(filters.source));
    changeQueryParam(
      'bookingStatus',
      mapStringArrayFilter(filters.booking_status),
    );
    changeQueryParam('rental', mapNumberArrayFilter(filters.rental));
    changeQueryParam('bookingTag', mapNumberArrayFilter(filters.booking_tag));
    changeQueryParam('rentalTag', mapNumberArrayFilter(filters.rental_tag));
    changeQueryParam('priority', mapStringArrayFilter(filters.priority));
    changeQueryParam('severity', mapStringArrayFilter(filters.severity));
  }

  <template>
    <FilterBar
      @filters={{array
        @controller.stage
        @controller.source
        @controller.rental
        @controller.priority
        @controller.severity
        @controller.assignee
        @controller.read
        @controller.client
        @controller.bookingTag
        @controller.rentalTag
      }}
      @model={{@model}}
      @compact={{not @isTableFilters}}
      @isTableFilters={{@isTableFilters}}
      ...attributes
    >
      <div>
        <BasicDropdown
          @calculatePosition={{this.positionAiHelperTooltip}}
          as |dd|
        >
          <dd.Trigger
            class='alert alert-info smily-alert d-flex align-items-center gap-1'
          >
            <Icon @icon='info-circle' />

            <div class='alert-link'>
              {{t 'inbox.how_to_use_ai.trigger'}}
            </div>
          </dd.Trigger>

          <dd.Content
            {{focusTrap
              focusTrapOptions=(hash
                clickOutsideDeactivates=true onDeactivate=dd.actions.close
              )
            }}
            class='smily-tooltip'
          >
            <h3>
              {{t 'inbox.how_to_use_ai.title'}}
            </h3>

            {{t 'inbox.how_to_use_ai.description'}}

            <hr />

            <div class='d-flex align-items-start justify-content-between gap-3'>
              <div>
                {{t
                  'inbox.how_to_use_ai.example'
                  exampleText=(t 'inbox.how_to_use_ai.example_text')
                }}
              </div>

              <CopyButtonCompact
                @text={{t 'inbox.how_to_use_ai.example_text'}}
                @container='.smily-tooltip'
                class='p-0'
              />
            </div>

            <Link
              @action={{dd.actions.close}}
              class='btn btn-link text-start ps-0'
            >
              {{t 'common.close'}}
            </Link>
          </dd.Content>
        </BasicDropdown>
      </div>

      <div class='smily-form-field position-relative united'>
        <input
          {{on 'keydown' (perform this.handleAiSearchInput)}}
          class='form-control filter-search-input filter-trigger pre-iconed
            {{unless @isTableFilters "w-100"}}
            '
          placeholder={{t 'inbox.ask_ai_to_filter'}}
          disabled={{this.handleAiSearchInput.isRunning}}
          enterkeyhint='search'
          aria-label={{t 'inbox.ask_ai_to_filter'}}
          data-ai-search-input
        />

        {{#if this.handleAiSearchInput.isRunning}}
          <LoadingState @isLoading={{true}} class='pre-icon small' />
        {{else}}
          <Icon @icon='search' @style='far' class='pre-icon' />
        {{/if}}

        <div class='unit'>
          <Icon @icon='sparkles' class='text-purple' />
        </div>
      </div>

      <FiltersSelect
        @label={{t 'inbox.filters.booking_stage'}}
        @value={{@controller.stage}}
        @displayField='label'
        @options={{this.stageOptions}}
        @onChange={{fn @controller.changeQueryParam 'stage'}}
      />

      <FiltersSelectInfiniteMultiple
        @label={{t 'inbox.filters.booking_source'}}
        @value={{@controller.source}}
        @modelName='source'
        @query={{generateQuery 'source' 'filter'}}
        @onChange={{fn @controller.changeQueryParam 'source'}}
        data-test-filter-source
      />

      <FiltersSelectInfiniteMultiple
        @label={{t 'internals.models.rental'}}
        @value={{@controller.rental}}
        @modelName='rental'
        @query={{generateQuery 'rental' 'filter'}}
        @onChange={{fn @controller.changeQueryParam 'rental'}}
      />

      <FiltersSelectMultiple
        @label={{t 'internals.fields.taskPriority'}}
        @value={{@controller.priority}}
        @displayField='label'
        @options={{this.priorityOptions}}
        @onChange={{fn @controller.changeQueryParam 'priority'}}
      />

      <FiltersSelectMultiple
        @label={{t 'internals.fields.severity'}}
        @value={{@controller.severity}}
        @displayField='label'
        @options={{this.severityOptions}}
        @onChange={{fn @controller.changeQueryParam 'severity'}}
      />

      <FiltersSelectInfiniteMultiple
        @label={{t 'common.assignee'}}
        @value={{@controller.assignee}}
        @modelName='host'
        @query={{generateQuery 'host' 'inboxAssignee'}}
        @displayField='fullName'
        @onChange={{fn @controller.changeQueryParam 'assignee'}}
      />

      <FiltersSelect
        @label={{t 'inbox.filters.read_status'}}
        @value={{@controller.read}}
        @options={{this.readOptions}}
        @onChange={{fn @controller.changeQueryParam 'read'}}
      />

      <FiltersSelectInfiniteMultiple
        @label={{t 'internals.models.client'}}
        @value={{@controller.client}}
        @modelName='client'
        @query={{generateQuery 'client' 'nameAndEmail'}}
        @displayField='nameAndEmail'
        @searchField='search'
        @loose={{false}}
        @onChange={{fn @controller.changeQueryParam 'client'}}
        data-test-filter-client
      />

      <FiltersSelectInfiniteMultiple
        @label={{t 'inbox.filters.booking_tag'}}
        @value={{@controller.bookingTag}}
        @modelName='bookings-tag'
        @query={{generateQuery 'bookings-tag' 'filter'}}
        @displayField='mappedName'
        @searchField='name'
        @onChange={{fn @controller.changeQueryParam 'bookingTag'}}
      />

      {{#unless this.session.account.isSmily}}
        <FiltersSelectInfiniteMultiple
          @label={{t 'internals.models.rentals-tag'}}
          @value={{@controller.rentalTag}}
          @modelName='rentals-tag'
          @onChange={{fn @controller.changeQueryParam 'rentalTag'}}
        />
      {{/unless}}
    </FilterBar>
  </template>
}

function mapNumberArrayFilter(filter: number[] | undefined) {
  return filter?.length ? filter.join(',') : undefined;
}

function mapStringArrayFilter(filter: string[] | undefined) {
  if (!filter?.length) {
    return;
  }

  return filter.map((value) => value.toLowerCase()).join(',');
}
