import { tracked } from '@glimmer/tracking';
import { camelize, dasherize } from '@ember/string';
import ReviewCriterion from './review-criterion';

import type { ReviewCriterionSerialized } from './review-criterion';

export const HOST_REVIEW_CRITERIA = [
  'cleanliness',
  'communication',
  'respectHouseRules',
] as const;
export type HostReviewCriterionName = (typeof HOST_REVIEW_CRITERIA)[number];

export const REVIEW_CRITERIA = [
  'cleanliness',
  'checkin',
  'communication',
  'location',
  'facilities',
  'comfort',
  'staff',
  'valueForMoney',
  'accuracy',
  'propertyCondition',
  'ecoFriendliness',
] as const;
export type ClientReviewCriterionName = (typeof REVIEW_CRITERIA)[number];

export type CriterionName = HostReviewCriterionName | ClientReviewCriterionName;
export type CriterionEntry = {
  [key in CriterionName]?: ReviewCriterion;
};

export const CRITERIA: CriterionName[] = [
  ...REVIEW_CRITERIA,
  ...HOST_REVIEW_CRITERIA.filter(
    (criterion) =>
      !REVIEW_CRITERIA.includes(criterion as ClientReviewCriterionName),
  ),
];

export default class ReviewCriteria {
  @tracked cleanliness = new ReviewCriterion();
  @tracked checkin = new ReviewCriterion();
  @tracked communication = new ReviewCriterion();
  @tracked location = new ReviewCriterion();
  @tracked comfort = new ReviewCriterion();
  @tracked facilities = new ReviewCriterion();
  @tracked staff = new ReviewCriterion();
  @tracked valueForMoney = new ReviewCriterion();
  @tracked accuracy = new ReviewCriterion();
  @tracked respectHouseRules = new ReviewCriterion();
  @tracked propertyCondition = new ReviewCriterion();
  @tracked ecoFriendliness = new ReviewCriterion();

  constructor(hash?: CriterionEntry) {
    if (hash) {
      this.populateCriteria(hash);
    }
  }

  populateCriteria(hash: CriterionEntry): void {
    Object.entries(hash).forEach(([criterionName, data]) => {
      if (data) {
        this[camelize(criterionName) as CriterionName] = new ReviewCriterion(
          data.rating,
          data.comment,
        );
      }
    });
  }

  serialize(): Record<string, ReviewCriterionSerialized> {
    const serialized: Record<string, ReviewCriterionSerialized> = {};

    CRITERIA.forEach((key) => {
      const criterion = this[key];
      if (criterion.hasRating) {
        serialized[dasherize(key)] = criterion.serialize();
      }
    });

    return serialized;
  }
}
