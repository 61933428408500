import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type DamageDepositCollectionTime = 'on_arrival' | 'before_arrival';
export type DamageDepositCollectionMethod =
  | 'with_smily'
  | 'external'
  | 'smily_damage_deposit_with_swikly';

export default class PreferencesPaymentModel extends BaseModel {
  @attr('string') damageDepositCollectionMethod!: string;
  @attr('string') damageDepositCollectionTime!: string;
  @attr('array') damageDepositCollectionMethodValues!: string[];
  @attr('array') damageDepositCollectionTimeValues!: string[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'preferences-payment': PreferencesPaymentModel;
  }
}
