import ObjectProxy from '@ember/object/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { getOwner } from '@ember/application';
import { handleErrors } from 'ember-smily-base/utils/model';

import type { PromiseObject } from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type StoreService from 'ember-smily-base/services/store';
import type { Model, ModelName } from 'ember-smily-base/utils/store';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

interface CustomActionProxyOptions {
  custom?: boolean;
  payload?: object;
}

interface ResourceObject {
  data: { id: string | number };
}

interface AnyAction<MN extends ModelName, M extends Model<MN> = Model<MN>> {
  (payload?: object): Promise<ResourceObject | undefined> | Promise<M>;
}

function isCustom(
  _payload?: object,
  options?: CustomActionProxyOptions,
): _payload is ResourceObject | undefined {
  return !!options?.custom;
}

export default function createCustomActionProxy<
  MN extends ModelName,
  M extends Model<MN> = Model<MN>,
>(
  modelNameOrModel: MN | M,
  actionName: keyof M,
  store: StoreService,
  options?: CustomActionProxyOptions,
): PromiseObject<M> {
  const needsTempModel = typeof modelNameOrModel === 'string';
  const tempModel = needsTempModel
    ? store.createRecord(modelNameOrModel)
    : modelNameOrModel;
  const promise = ((tempModel as M)[actionName] as unknown as AnyAction<MN>)(
    options?.payload,
  )
    .then((payload) => {
      if (!payload) {
        return undefined;
      } else if (!isCustom(payload, options)) {
        return payload;
      }

      store.pushPayload(payload);

      const model = needsTempModel
        ? store.peekRecord(modelNameOrModel, payload.data.id)!
        : modelNameOrModel;

      return model;
    })
    .catch((e) => {
      const owner = getOwner(store);
      const intl = owner.lookup('service:intl') as IntlService;
      const notify = owner.lookup('service:notify') as NotifyService;
      // const modelName = needsTempModel
      //   ? modelNameOrModel
      //   : getModelName(modelNameOrModel);
      // const serializer = store.serializerFor(modelName as never);

      handleErrors(tempModel, e, { intl, notify });

      throw e;
    });

  if (needsTempModel) {
    tempModel.unloadRecord();
  }

  // @ts-expect-error to avoid hassle
  return ObjectPromiseProxy.create({ promise });
}
