import type { TOC } from '@ember/component/template-only';
import Icon from 'ember-smily-base/components/icon';
import UserAvatar from 'ember-smily-base/components/user-avatar';
import type HostModel from 'smily-admin-ui/models/host';
import type { HostOption } from 'smily-admin-ui/utils/inbox';

function isCustom(option: HostOption) {
  return (option as { custom: boolean }).custom;
}

function getHostImage(option: HostOption) {
  return (option as HostModel).user!.profilePicture;
}

const InboxAssigneeOption: TOC<{
  Args: {
    option: HostOption;
  };
}> = <template>
  <div class='inbox-assignee-option d-flex align-items-center gap-1'>
    <div class='d-flex align-items-center justify-content-center'>
      {{#if (isCustom @option)}}
        <Icon @icon='user' />
      {{else}}
        <UserAvatar
          @name={{@option.fullName}}
          @image={{getHostImage @option}}
          @small={{true}}
        />
      {{/if}}
    </div>

    {{@option.fullName}}
  </div>
</template>;

export default InboxAssigneeOption;
