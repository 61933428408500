import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

const ATTRIBUTE_MAP: Record<string, string> = {
  taskName: 'name',
  taskDescription: 'description',
  checklist: 'checklist',
  taskCategory: 'category',
  taskPriority: 'priority',
  taskExpectedDurationInMinutes: 'expectedDurationInMinutes',
  taskHasIssues: 'hasIssues',
};

export default class TasksTemplatesNewFromTemplateRoute extends BaseRoute {
  @service store!: StoreService;

  async model({
    task_management_template_id: id,
  }: {
    task_management_template_id: string;
  }) {
    const template = await this.store.findRecord(
      'task-management-template',
      id,
      this.store.generateQuery('task-management-template'),
    );

    const attributes = {} as Record<string, unknown>;

    // @ts-expect-error wrong model typing
    template.eachAttribute((attr: string) => {
      const targetAttr = ATTRIBUTE_MAP[attr] as string;

      if (targetAttr) {
        attributes[targetAttr] = (
          template as unknown as Record<string, unknown>
        )[attr];
      }
    });

    return this.store.createRecord('task-management-task', {
      ...attributes,
      newAttachmentFiles: template.attachments.map(({ file }) => file),
      newAttachments: template.attachments.map(
        ({ file: { original } }) => original,
      ),
      assignee: template.taskAssignee,
    });
  }
}
