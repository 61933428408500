import { tracked } from '@glimmer/tracking';
import BaseController from 'smily-admin-ui/controllers/calendar/-base';

export default class CalendarMonthController extends BaseController {
  queryParams = ['date', 'activeRental'];

  @tracked isLoadingRentals = false;
  @tracked activeRental!: string;
}

declare module '@ember/controller' {
  interface Registry {
    'calendar.month': CalendarMonthController;
  }
}
