import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class PreferencesGeneralSettingAbility extends Ability<'preferences-general-setting'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'preferences-general-setting');
  }
}
