import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import t from 'ember-intl/helpers/t';
import type { FeatureDiscoveryConfigEntry } from 'smily-admin-ui/utils/config';

const FeatureDiscoveryFirstContent: TOC<{
  Args: {
    features: FeatureDiscoveryConfigEntry[];
  };
}> = <template>
  <div>
    {{t 'user.feature_discovery.intro_description'}}
  </div>

  <ul>
    {{#each @features as |feature|}}
      <li>
        {{t (concat 'user.feature_discovery.features.' feature.name '.name')}}
      </li>
    {{/each}}
  </ul>
</template>;

export default FeatureDiscoveryFirstContent;
