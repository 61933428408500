import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import TasksTemplateForm from 'smily-admin-ui/components/tasks/template/form';
import type TasksTemplatesTemplateRoute from 'smily-admin-ui/routes/tasks/templates/template';

const TemplatesTasksTemplatesTemplate: TOC<{
  Args: {
    model: ModelFor<TasksTemplatesTemplateRoute>;
  };
}> = <template><TasksTemplateForm @template={{@model}} /></template>;

export default RouteTemplate(TemplatesTasksTemplatesTemplate);
