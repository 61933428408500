import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import type RentalModel from 'smily-admin-ui/models/rental';

const RentalsCompletionLabel: TOC<{
  Element: HTMLDivElement;
  Args: {
    rental: RentalModel;
  };
}> = <template>
  <div ...attributes>
    {{#if @rental.isListed}}
      {{t 'rentals.completion_label.completed'}}
    {{else}}
      {{t 'rentals.completion_label.in_progress'}}
    {{/if}}

    {{@rental.completionPercentage}}%
  </div>
</template>;

export default RentalsCompletionLabel;
