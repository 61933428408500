import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type ClientModel from 'smily-admin-ui/models/client';

export default class ClientsNewRoute extends Route {
  @service store!: StoreService;

  model(): ClientModel {
    return this.store.createRecord('client');
  }
}
