import { attr, belongsTo } from '@ember-data/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type ReviewReplyModel from 'smily-admin-ui/models/review-reply';
import type SourceModel from 'smily-admin-ui/models/source';
import type { ReviewCriteria } from 'smily-admin-ui/utils/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ReviewModel extends BaseModel {
  @attr title?: string;
  @attr comment?: string;
  @attr('number') rating?: number;
  @attr reviewerName?: string;
  @attr('date') createdAt?: Date;
  @attr('date') submittedAt!: Date;
  @attr('date') dismissedAt?: Date;
  @attr('boolean') shareable?: boolean;
  @attr privateComment?: string;
  @attr('criteria') criteria?: ReviewCriteria;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @belongsTo('review-reply', { async: false, inverse: 'review' })
  reviewReply!: ReviewReplyModel;

  @belongsTo('source', { async: false, inverse: null })
  source!: SourceModel;

  get isDismissed(): boolean {
    return !!this.dismissedAt;
  }

  get canBeRepliedTo(): boolean {
    return !this.reviewReply;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    review: ReviewModel;
  }
}
