import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import BaseAuthenticator from 'ember-simple-auth/authenticators/torii';
import type { SessionAuthenticationMeta } from 'ember-simple-auth/services/session';

export default class ToriiAuthenticator extends BaseAuthenticator {
  // must inject the torii service as per
  // http://ember-simple-auth.com/api/classes/ToriiAuthenticator.html
  @service torii: unknown;

  async authenticate(...args: unknown[]): Promise<SessionAuthenticationMeta> {
    const data = await super.authenticate(...args);
    return this._scheduleRefreshToken(data);
  }

  async restore(data: Record<string, unknown>) {
    const restored = await super.restore(data);
    return this._scheduleRefreshToken(restored);
  }

  refreshToken = task(
    { restartable: true },
    async (expiresInSeconds: number, resourceOwnerId: number) => {
      await timeout(expiresInSeconds * 1000);

      this.authenticate('bookingsync-oauth2', { resourceOwnerId });
    },
  );

  private _scheduleRefreshToken(data: SessionAuthenticationMeta) {
    const {
      expires_in_seconds: expiresInSeconds,
      resource_owner_id: resourceOwnerId,
    } = data.authorizationToken.info;

    this.refreshToken.perform(expiresInSeconds, resourceOwnerId);

    // TODO check if this is needed
    // Triggerring the sessionDataUpdated event will refresh the cookie with the new session data
    // this.trigger('sessionDataUpdated', data);

    return data;
  }
}
