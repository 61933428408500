import type { TOC } from '@ember/component/template-only';
import Badge from 'ember-smily-base/components/badge';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type { Priority } from 'smily-admin-ui/models/task-management-task';

const TasksPriorityBadge: TOC<{
  Args: {
    priority: Priority;
  };
}> = <template>
  <Badge
    @label={{optionLabel @priority 'tasks.priority_options'}}
    @skeleton={{true}}
    class='task-priority-{{@priority}}'
  />
</template>;

export default TasksPriorityBadge;
