import type BookingModel from 'smily-admin-ui/models/booking';
import type { BookingModel as CalendarBookingModel } from 'ember-planning/utils/types';

// TODO check if booking parameter can indeed be missing
export function getBookingPillClass(
  booking?: BookingModel | CalendarBookingModel,
): string {
  if (!(booking as BookingModel)?.status) {
    return '';
  }

  return (booking as BookingModel).status.toLowerCase();
}
