import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class InvitationSerializer extends ApplicationSerializer<'invitation'> {
  attrs = {
    fullName: 'fullname',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    invitation: InvitationSerializer;
  }
}
