import { array } from '@ember/helper';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import BsAlert from 'ember-bootstrap/components/bs-alert';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import FilterBar from 'ember-smily-base/components/filter-bar';
import FiltersDateRange from 'ember-smily-base/components/filters/date-range';
import Link from 'ember-smily-base/components/link';
import List from 'ember-smily-base/components/list';
import ListingScreen from 'ember-smily-base/components/listing-screen';
import TopbarActions from 'ember-smily-base/components/topbar/actions';
import formatMoney from 'ember-smily-base/helpers/format-money';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
import FinanceBookingPayoutItem from 'smily-admin-ui/components/finance/booking-payout-item';
import FinanceCompletedPayoutsItem from 'smily-admin-ui/components/finance/completed-payouts-item';
import FinanceListingScreenTabs from 'smily-admin-ui/components/finance/listing-screen-tabs';
import config from 'smily-admin-ui/config/environment';
import type FinanceCompletedPayoutsController from 'smily-admin-ui/controllers/finance/completed-payouts';
import type FinanceCompletedPayoutsRoute from 'smily-admin-ui/routes/finance/completed-payouts';
import TemplatesFinancePayoutsComponent from 'smily-admin-ui/templates/finance/-payouts';

const {
  API: { HOST, NAMESPACE },
} = config;

class TemplatesFinanceCompletedPayouts extends TemplatesFinancePayoutsComponent<
  'completed-payout',
  FinanceCompletedPayoutsController,
  FinanceCompletedPayoutsRoute
> {
  get hasMultipleGateways() {
    return this.args.model.payouts.options.some(
      (payout) => payout.sharedWallet,
    );
  }

  get exportUrl() {
    const url = new URL(`${NAMESPACE}/completed-payouts/export`, HOST);

    if (this.dates) {
      const [from, to] = this.dates.split(',');
      url.searchParams.set('filter[scheduled-at][][op]', 'gteq');
      url.searchParams.set('filter[scheduled-at][][value]', `${from} 00:00:00`);
      url.searchParams.append('filter[scheduled-at][][op]', 'lteq');
      url.searchParams.append(
        'filter[scheduled-at][][value]',
        `${to} 23:59:59`,
      );
    }

    return url.toString();
  }

  @action
  async export() {
    const response = await fetch(this.exportUrl, {
      method: 'GET',
      headers: new Headers({
        ...this.session.authorizationHeader,
        'Content-Type': 'application/json',
      }),
    });
    const blob = await response.blob();

    const blobUrl = URL.createObjectURL(blob);
    const tempLink = document.createElement('a');

    tempLink.href = blobUrl;
    tempLink.setAttribute('download', 'payout-export.csv');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(blobUrl);
  }

  <template>
    <TopbarActions>
      <Link @action={{this.export}} class='btn btn-primary'>
        {{t 'common.export'}}
      </Link>
    </TopbarActions>

    <ListingScreen
      @model={{if
        this.needsOnboardingCompletion
        this.emptyOptions
        @model.payouts
      }}
      @mobileComponent={{FinanceCompletedPayoutsItem}}
      @page={{@controller.page}}
      @changePage={{@controller.changePage}}
      @onModalOpen={{this.setModalModel}}
    >
      <:tabs>
        <FinanceListingScreenTabs />
      </:tabs>

      <:filters>
        <FilterBar @filters={{array this.dates}} @model={{@model.payouts}}>
          <FiltersDateRange
            @label={{t 'common.dates'}}
            @value={{this.dates}}
            @onChange={{@controller.changeDates}}
          />
        </FilterBar>
      </:filters>

      <:information>
        {{#if this.hasMultipleGateways}}
          <BsAlert @type='warning' @dismissible={{false}} class='mb-0'>
            {{t 'finance.warning_multiple_gateways'}}
          </BsAlert>
        {{else if this.needsOnboardingCompletion}}
          <BsAlert @type='warning' @dismissible={{false}} class='mb-0'>
            {{#if (eq this.session.onboarding.currentStep 4)}}
              {{t 'finance.warning_complete_onboarding_step_4'}}
            {{else}}
              {{t 'finance.warning_complete_onboarding'}}
            {{/if}}
          </BsAlert>
        {{else if (not this.session.sessionInfo.financeDataAvailable)}}
          <BsAlert @type='warning' @dismissible={{false}} class='mb-0'>
            {{t 'finance.activate_smilypay'}}
          </BsAlert>
        {{/if}}
      </:information>

      <:modal as |modal|>
        {{#if this.selectedPayout}}
          <modal.header>
            <div class='d-flex-flex-column'>
              <div>
                {{this.selectedPayout.paymentGateway.nameWithRole}}
              </div>

              <div>
                {{formatDate this.selectedPayout.scheduledAt format='short'}}
                •
                {{t
                  'internals.model_counters.booking'
                  counter=this.selectedPayout.bookingPayouts.length
                }}
              </div>

              <div class='fw-semibold'>
                {{formatMoney
                  this.selectedPayout.amount
                  this.selectedPayout.currency
                }}
              </div>
            </div>
          </modal.header>

          <modal.body class='h-0 d-flex flex-column p-0'>
            <div class='h-0 flex-1 overflow-auto'>
              <List @linked={{true}} as |Item|>
                {{#each this.selectedPayout.bookingPayouts as |bookingPayout|}}
                  <Item>
                    <LinkTo
                      @route='bookings.booking'
                      @model={{bookingPayout.booking.id}}
                      class='link-unstyled p-3'
                    >
                      <FinanceBookingPayoutItem
                        @bookingPayout={{bookingPayout}}
                      />
                    </LinkTo>
                  </Item>
                {{/each}}
              </List>
            </div>

            <div class='d-flex p-3 border-top'>
              <div class='flex-1 d-flex flex-column'>
                <div class='fw-semibold'>
                  {{t 'finance.estimated_arrival'}}
                </div>

                <div>
                  {{formatDate
                    this.selectedPayout.expectedArrivalDate
                    format='short'
                  }}
                </div>
              </div>
            </div>
          </modal.body>
        {{/if}}
      </:modal>
    </ListingScreen>
  </template>
}

export default RouteTemplate(TemplatesFinanceCompletedPayouts);
