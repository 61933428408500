// TODO check if can be removed

import type StoreService from 'ember-smily-base/services/store';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type { Channel } from 'smily-admin-ui/models/inbox-message';

interface Context {
  store: StoreService;
}

function createAttachment(
  this: Context,
  file: string,
  name: string,
  fileSize: number,
  channel: string,
): {
  attachment: InboxAttachmentModel;
  promise: Promise<InboxAttachmentModel>;
} {
  const attachment = this.store.createRecord('inbox-attachment', {
    file,
    name,
    fileSize,
    channel,
  });

  const promise = attachment.save();

  return { attachment, promise };
}

const SUPPORTED_FILE_TYPES = {
  email: 'application/pdf, image/jpeg, image/png',
  airbnb: 'image/jpeg, image/png',
  'booking-com': 'image/jpeg, image/png',
};

const CHANNELS_SUPPORTING_ATTACHMENTS: Channel[] = ['email', 'airbnb'];

export {
  CHANNELS_SUPPORTING_ATTACHMENTS,
  createAttachment,
  SUPPORTED_FILE_TYPES,
};
