import { getOwner } from '@ember/application';
import { defineProperty } from '@ember/object';
import { camelize } from '@ember/string';
import { pluralize } from 'ember-inflector';
import { getService } from 'ember-smily-base/utils/application';

import type { Ability } from 'ember-can';
import type { ModelName } from 'ember-smily-base/utils/store';
import type AbilityModel from 'smily-admin-ui/models/ability';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { AbilityRecord, Action } from 'smily-admin-ui/utils/ability';

/**
  Generates the following abilities:
  For models:
  can create [model]
  can view [model]
  can edit [model]
  can delete [model]

  For attributes:
  can view [attribute] in [model]
  can edit [attribute] in [model]

  For relationships:
  can view [relationship] in [model]

  @param {Ability} ability the calling ability with valid owner
  @param {String} modelName
*/
export default function generateModelAbilities<MN extends ModelName>(
  ability: Ability<MN>,
  modelName: MN,
) {
  const store = getService(ability, 'store');
  const session = getOwner(ability).lookup('service:session') as SessionService;

  defineAbility(ability, modelName, session, 'create');
  defineAbility(ability, modelName, session, 'view');
  defineAbility(ability, modelName, session, 'edit');
  defineAbility(ability, modelName, session, 'delete');

  Object.keys(
    store
      // @ts-expect-error wrong store type
      .getSchemaDefinitionService()
      .attributesDefinitionFor({ type: modelName }),
  ).forEach((key) => {
    const property = camelize(store.serializeKey(key, modelName));

    defineAbility(ability, modelName, session, 'view', property);
    defineAbility(ability, modelName, session, 'edit', property);
    rejectAction(ability, modelName, 'create', property);
    rejectAction(ability, modelName, 'delete', property);
  });

  Object.keys(
    store
      // @ts-expect-error wrong store type
      .getSchemaDefinitionService()
      .relationshipsDefinitionFor({ type: modelName }),
  ).forEach((key) => {
    const relationship = camelize(store.serializeKey(key, modelName));

    defineAbility(ability, modelName, session, 'view', relationship, true);
    rejectAction(ability, modelName, 'create', relationship, true);
    rejectAction(ability, modelName, 'edit', relationship, true);
    rejectAction(ability, modelName, 'delete', relationship, true);
  });
}

function defineAbility<MN extends ModelName>(
  ability: Ability<MN>,
  modelName: MN,
  session: SessionService,
  actionName: Action,
  property?: string,
  isRelationship?: boolean,
): void {
  const abilityName = camelize(`can-${actionName}-${property ?? ''}`);

  if (abilityName in ability) {
    return;
  }

  defineProperty(ability, abilityName, {
    get() {
      return extractAbility(
        ability.model?.ability as AbilityModel | undefined,
        modelName as string,
        session,
        actionName,
        property,
        isRelationship,
      );
    },
    enumerable: true,
  });
}

const ACTION_DICTIONARY = {
  create: 'create' as const,
  view: 'read' as const,
  edit: 'update' as const,
  delete: 'destroy' as const,
};

function extractAbility(
  abilityModel: AbilityModel | undefined,
  modelName: string,
  session: SessionService,
  action: Action,
  property?: string,
  isRelationship?: boolean,
) {
  if (action === 'create') {
    return getStaticResourcePermission(session, modelName)?.create ?? true;
  }

  const permission = ACTION_DICTIONARY[action];
  const abilityEntry = abilityModel?.actions?.[permission];

  if (!property) {
    return (
      abilityEntry?.record.status ??
      getStaticResourcePermission(session, modelName)?.[permission] ??
      true
    );
  }

  if (!abilityEntry || permission === 'destroy') {
    return true;
  } else if (permission === 'update') {
    return isRelationship
      ? true
      : abilityEntry.attributes?.[property]?.status ?? true;
  } else {
    return abilityEntry.attributes?.[property]?.status ?? true;
  }
}

function getStaticResourcePermission(
  session: SessionService,
  modelName: string,
) {
  return session.staticResourcePermissions?.[
    pluralize(camelize(modelName as string))
  ] as AbilityRecord | undefined;
}

function rejectAction(
  ability: Ability<ModelName>,
  modelName: ModelName,
  action: Action,
  property: string,
  isRelationship?: boolean,
) {
  const abilityName = camelize(`can-${action}-${property}`);

  if (abilityName in ability) {
    return;
  }

  const message =
    `You tried to get permission for "${action} ${property}" in "${modelName}" model, but it does not exist.\n` +
    `Available actions for ${
      isRelationship ? 'relationships' : 'attributes'
    } are: ${isRelationship ? 'view' : 'view and edit'}`;

  defineProperty(ability, abilityName, {
    get() {
      throw new Error(message);
    },
  });
}
