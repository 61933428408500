import { get } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';
import type SessionService from 'smily-admin-ui/services/session-service';

class TemplatesChannelImportInProgress extends Component {
  @service intl!: IntlService;
  @service router!: RouterService;
  @service session!: SessionService;

  @tracked importTextEnding = '';

  texts = [
    this.intl.t('onboarding.channel_import_in_progress.import_text.rentals'),
    this.intl.t('onboarding.channel_import_in_progress.import_text.bookings'),
    this.intl.t('onboarding.channel_import_in_progress.import_text.reviews'),
  ];
  textIndex = 0;

  checkProgress = task(async () => {
    await timeout(20000);
    await this.session.sessionInfo?.reload();

    if (this.session.channelImportInProgress) {
      this.checkProgress.perform();
    } else {
      this.router.transitionTo('channel-import-complete');
    }
  });

  appendText = task(async (text: string, index: number) => {
    if (index > text.length) {
      this.textIndex = this.textIndex < 2 ? this.textIndex + 1 : 0;

      await timeout(7000);
      this.appendText.perform(this.texts[this.textIndex]!, 1);

      return;
    }

    await timeout(150);

    this.importTextEnding = text.slice(0, index);

    this.appendText.perform(text, index + 1);
  });

  <template>
    <div {{didInsert (perform this.checkProgress)}} class='h-100 p-3'>
      <Card class='min-h-100'>
        <div
          class='card-body p-3 p-lg-5 d-flex align-items-center justify-content-center'
        >
          <div class='position-absolute align-self-start w-100 px-3 px-lg-5'>
            <img
              src='/assets/images/success-pattern-pink.svg'
              alt=''
              class='w-100'
            />
          </div>

          <div class='d-flex flex-column align-items-center gap-3'>
            <div
              class='d-flex align-items-center justify-content-center mb-4 text-75 text-lg-100'
            >
              <Icon @icon='circle' class='text-primary position-absolute' />

              <Icon
                @icon='spinner-third'
                @style='fal'
                @spin={{true}}
                @transform='shrink-8'
                class='text-white'
              />
            </div>

            <h3 class='text-lg-28'>
              {{t 'onboarding.channel_import_in_progress.title'}}
            </h3>

            <div
              {{didInsert (perform this.appendText (get this.texts 0) 1)}}
              class='text-lg-18 text-center'
            >
              {{t 'onboarding.channel_import_in_progress.import_text.prefix'}}

              {{this.importTextEnding}}
              ...
            </div>
          </div>
        </div>
      </Card>
    </div>
  </template>
}

export default RouteTemplate(TemplatesChannelImportInProgress);
