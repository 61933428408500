import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import ReviewsAutomatedCommentForm from 'smily-admin-ui/components/reviews/automated-comment-form';
import type ReviewsHostAutomationCommentRoute from 'smily-admin-ui/routes/reviews/host/automation/comment';

const TemplatesReviewsHostAutomationComment: TOC<{
  Args: {
    model: ModelFor<ReviewsHostAutomationCommentRoute>;
  };
}> = <template><ReviewsAutomatedCommentForm @comment={{@model}} /></template>;

export default RouteTemplate(TemplatesReviewsHostAutomationComment);
