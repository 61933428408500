import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { isMobile } from 'ember-smily-base/utils/application';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class TasksTaskRoute extends BaseRoute {
  @service store!: StoreService;

  get title() {
    if (isMobile(this.media)) {
      return super.title;
    }

    return this.currentModel?.name;
  }

  model({ task_management_task_id: id }: { task_management_task_id: string }) {
    return this.store.findRecord(
      'task-management-task',
      id,
      this.store.generateQuery('task-management-task'),
    );
  }
}
