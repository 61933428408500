import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class AccountSetupUpgradeRoute extends Route {
  @service router!: RouterService;

  redirect(): void {
    this.router.transitionTo('upgrade-to-smily');
  }
}
