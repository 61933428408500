import { customAction } from 'ember-custom-actions';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ReservationsQuoteModel extends BaseModel {
  // attributes are generated manually in app/utils/bookings/get-quote.ts
  calculate = customAction<ReservationsQuoteModel, false>('calculate', {
    method: 'POST',
    pushToStore: false,
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reservations-quote': ReservationsQuoteModel;
  }
}
