import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type IntlService from 'ember-intl/services/intl';
import type MediaService from 'ember-responsive';
import type EngineApiService from 'ember-smily-base/services/engine-api';
import type SidebarService from 'ember-smily-base/services/sidebar';
import type StoreService from 'ember-smily-base/services/store';
import { isTesting } from 'ember-smily-base/utils/config';
import { getLongLocale } from 'ember-smily-base/utils/intl';
import type { Transition } from 'ember-smily-base/utils/routing';
import config from 'smily-admin-ui/config/environment';
import type CacheService from 'smily-admin-ui/services/cache';
import type FlutterLoggerService from 'smily-admin-ui/services/flutter-logger';
import type MobileDeviceService from 'smily-admin-ui/services/mobile-device';
import type SessionService from 'smily-admin-ui/services/session-service';
import { annotateState } from 'smily-admin-ui/utils/history';
import { createCustomActionProxy } from 'smily-admin-ui/utils/model';
import { configure as configSentry } from 'smily-admin-ui/utils/sentry';

const UNAUTHENTICATED_ROUTES = [
  'auth.logout',
  'auth.login',
  'signup.index',
  'confirm-email',
  'terms-of-service',
  'privacy-policy',
  'complete-channel-signup',
  '404',
];

function isUnauthenticatedRoute(routeName: string): boolean {
  return (
    UNAUTHENTICATED_ROUTES.includes(routeName) ||
    routeName.startsWith('signup.') ||
    routeName.startsWith('signup-')
  );
}

export default class ApplicationRoute extends Route {
  @service abilities!: AbilitiesService;
  @service cache!: CacheService;
  @service engineApi!: EngineApiService;
  @service flutterLogger!: FlutterLoggerService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service mobileDevice!: MobileDeviceService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service sidebar!: SidebarService;
  @service store!: StoreService;

  breadcrumb = null;

  async beforeModel(transition: Transition): Promise<void> {
    // session setup doesn't store attempted transition by default
    this.session.attemptedTransition = transition;

    await this.session.setup();

    if (
      !this.session.isAuthenticated &&
      isUnauthenticatedRoute(transition.to.name)
    ) {
      document.body.classList.add('theme-light');
      return;
    }

    if (this.session.needsAuthentication) {
      try {
        await this.session.authenticate(
          'authenticator:torii',
          'bookingsync-oauth2',
        );
      } catch (error) {
        if ((error as Error).message !== 'access_denied') {
          throw error;
        }
      }
    }

    this.session.requireAuthentication(transition, 'auth.login');
    annotateState(this.media);

    if (!this.session.sessionInfo) {
      await this.fetchSession();
    }
  }

  model(params: { locale?: string } = {}, transition: Transition): void {
    if (!isUnauthenticatedRoute(transition.to.name)) {
      // in case session request fails with 401
      this.session.requireAuthentication(transition, 'auth.login');
    }

    const locale = getLongLocale(
      params.locale ||
        this.session.user?.preferredLocale ||
        navigator.language.slice(0, 2),
    );

    this.intl.setLocale(locale);
  }

  async afterModel() {
    if (!isTesting(this)) {
      // remove initialization spinner defined in index.html
      document.getElementById('spinner')?.remove();
    }

    if (!this.session.isAuthenticated) {
      return;
    }

    this.cache.queryAccounts();

    if (!this.abilities.can('access app in account')) {
      this.router.transitionTo('account-suspended');
      return;
    }

    this.setupMobileNotifications();
    this._setupSidebarBadges();

    await this.cache.setupFeatureDiscovery();
  }

  async fetchSession() {
    const sessionInfo = await createCustomActionProxy(
      'accounts-user',
      'fetchSession',
      this.store,
    );
    this.session.sessionInfo = sessionInfo;

    await this.setupSession();
  }

  private async setupSession() {
    const account = this.session.account!;

    await this.session.confirmCurrentAccount();

    configSentry({
      user: this.session.user,
      extra: {
        account: {
          id: account.id,
        },
      },
    });

    if (account.isSmily) {
      document.body.classList.add('theme-light');
    }

    if (!this.abilities.can('access app in account')) {
      return;
    }

    this.engineApi.accountId = account.id;
    this.engineApi.coreAppsUrl = `${config.API.HOST}/`;
    this.engineApi.environment = config.environment;

    if (account.onboardingInProgress) {
      await this.session.setupOnboarding();
    }

    if (account.isFree || account.onboardingInProgress) {
      await this.session.setupRentalCount();
    }
  }

  async setupMobileNotifications() {
    const token = window.mobileFirebaseToken;
    const uid = window.mobileDeviceUid;
    const notificationsEnabled = window.notificationsEnabled;
    const key = `smily_mobile_${token}`;
    const keyValue = `${uid}_${token}_${notificationsEnabled ? 1 : 0}`;

    this.flutterLogger.log(`token: ${token}`);
    this.flutterLogger.log(`uid: ${uid}`);
    this.flutterLogger.log(`notificationsEnabled: ${notificationsEnabled}`);
    this.flutterLogger.log(`key: ${key}`);
    this.flutterLogger.log(`keyValue: ${keyValue}`);

    if (token && uid) {
      try {
        if (notificationsEnabled) {
          this.flutterLogger.log('registering device...');
          this.mobileDevice.register(uid, token);
        } else {
          this.flutterLogger.log('unregistering device...');
          this.mobileDevice.unregister(uid);
        }
        localStorage.setItem(key, keyValue);
      } catch (error) {
        console.log(error);
        this.flutterLogger.log('error');
        this.flutterLogger.log(String(error));
      }
    }

    return true;
  }

  private _setupSidebarBadges() {
    if (this.abilities.can('show inbox route')) {
      this.cache.updateInboxCounter();
      this.cache.updateInboxCounters();
    }
  }
}
