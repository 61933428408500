import Controller from '@ember/controller';

export default class CompleteChannelSignupController extends Controller {
  queryParams = ['token'];

  declare token: string;
}

declare module '@ember/controller' {
  interface Registry {
    'complete-channel-signup': CompleteChannelSignupController;
  }
}
