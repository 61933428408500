import { service } from '@ember/service';
import type CreditCardModel from 'smily-admin-ui/models/credit-card';
import CreditCardAuthBaseRoute from 'smily-admin-ui/routes/credit-card-auth/-base';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

export default class CreditCardAuth3DSRoute extends CreditCardAuthBaseRoute {
  @service coreLinks!: CoreLinksService;

  redirect(creditCard: CreditCardModel) {
    this.coreLinks.redirect('creditCard3DS', creditCard.id);
  }
}
