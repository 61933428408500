import type { TOC } from '@ember/component/template-only';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import Badge from 'ember-smily-base/components/badge';
import IconText from 'ember-smily-base/components/icon-text';
import type { Cell } from 'ember-smily-base/components/table';
import UserAvatar from 'ember-smily-base/components/user-avatar';
import ensure from 'ember-smily-base/helpers/ensure';
import InboxConversationStatus from 'smily-admin-ui/components/inbox/conversation/status';
import InboxLastMessage from 'smily-admin-ui/components/inbox/last-message';
import InboxPriorityBadge from 'smily-admin-ui/components/inbox/priority-badge';
import InboxSeverityBadge from 'smily-admin-ui/components/inbox/severity-badge';
import RentalsPrimaryPhoto from 'smily-admin-ui/components/rentals/primary-photo';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';

const InboxConversationTableItem: TOC<{
  Element: HTMLElement;
  Args: {
    model: InboxConversationModel;
    cell: Cell;
  };
}> = <template>
  <@cell @property='name' data-test-conversation-item={{@model.id}}>
    <div class='d-flex align-items-center gap-2'>
      <UserAvatar
        @name={{ensure @model.firstClientParticipant.member.fullName}}
      />

      <div class='fw-semibold text-truncate'>
        {{@model.firstClientParticipant.member.fullName}}
      </div>
    </div>
  </@cell>

  <@cell @property='bookingStage'>
    {{#if @model.currentBooking}}
      <div class='d-flex justify-content-start'>
        <Badge
          @label={{optionLabel
            @model.currentBooking.stage
            'bookings.stage_short_options'
          }}
          @color='body'
        />
      </div>
    {{/if}}
  </@cell>

  <@cell @property='message'>
    <InboxLastMessage @message={{@model.lastMessage}} />
  </@cell>

  <@cell @property='priority'>
    {{#if @model.priority}}
      <div class='d-flex justify-content-start'>
        <InboxPriorityBadge @priority={{@model.priority}} />
      </div>
    {{/if}}
  </@cell>

  <@cell @property='severity'>
    {{#if @model.severity}}
      <div class='d-flex justify-content-start'>
        <InboxSeverityBadge @severity={{@model.severity}} />
      </div>
    {{/if}}
  </@cell>

  <@cell @property='assignee'>
    {{#if @model.assignee}}
      <IconText
        @text={{@model.assignee.fullName}}
        @icon='user'
        @iconStyle='far'
        @gap={{1}}
        class='text-truncate'
      />
    {{/if}}
  </@cell>

  <@cell @property='rentalAndBookingDetails'>
    {{#if @model.currentBooking}}
      <div class='d-flex align-items-center gap-2 overflow-hidden'>
        <RentalsPrimaryPhoto
          @rental={{@model.currentBooking.rental}}
          @small={{true}}
          class='rounded'
        />

        <div class='flex-1 overflow-hidden'>
          <div class='fw-semibold text-truncate'>
            {{@model.currentBooking.rental.name}}
          </div>

          <div>
            {{t 'bookings.night_counter' counter=@model.currentBooking.nights}}
            •
            {{formatDate @model.currentBooking.startAt.date format='short'}}
            –
            {{formatDate @model.currentBooking.endAt.date format='short'}}
          </div>
        </div>
      </div>
    {{/if}}
  </@cell>

  <@cell @property='lastMessageAt'>
    <InboxConversationStatus @model={{@model}} />
  </@cell>
</template>;

export default InboxConversationTableItem;
