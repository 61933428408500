import type { ResourceObject, Response } from 'ember-smily-base/utils/json-api';
import type { Model } from 'ember-smily-base/utils/store';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class ReviewsHostAutomationSerializer extends ApplicationSerializer<'reviews-host-automation'> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  normalize(typeClass: Model, hash: {}) {
    const typedHash = hash as Response<ResourceObject>['data'];

    // We convert null to empty string so that dirty state is reflected
    // using form inputs
    if (typedHash?.attributes?.['private-comment'] === null) {
      typedHash.attributes['private-comment'] = '';
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    return super.normalize(typeClass, typedHash as {});
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'reviews-host-automation': ReviewsHostAutomationSerializer;
  }
}
