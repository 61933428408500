import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import { addYears, subDays } from 'date-fns';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import { dateString } from 'ember-smily-base/utils/date';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import DashboardBookingList from 'smily-admin-ui/components/dashboard/booking-list';
import type DashboardRoute from 'smily-admin-ui/routes/dashboard';

const today = dateString();
const oneWeekAgo = dateString(subDays(new Date(), 7));
const oneYearFromToday = dateString(addYears(new Date(), 1));
const todayFilter = `${today},${today}`;
const fromTodayFilter = `${today},${oneYearFromToday}`;
const oneWeekAgoFilter = `${oneWeekAgo},${oneYearFromToday}`;

const TemplatesDashboard: TOC<{
  Args: {
    model: ModelFor<DashboardRoute>;
  };
}> = <template>
  <div class='p-3 d-flex flex-column gap-3'>
    <DashboardBookingList
      @infiniteQuery={{@model.tentatives}}
      @title={{t 'dashboard.tentatives'}}
      @showMoreTitle={{t 'dashboard.view_all_tentatives'}}
      @showMoreFilter={{hash status='tentative' startAt=fromTodayFilter}}
      @emptyText={{t 'dashboard.empty_state.tentatives'}}
    />

    <DashboardBookingList
      @infiniteQuery={{@model.paymentDue}}
      @title={{t 'dashboard.payments_due'}}
      @showMoreTitle={{t 'dashboard.view_all_bookings'}}
      @showMoreFilter={{hash payments='paymentDue' endAt=oneWeekAgoFilter}}
      @emptyText={{t 'dashboard.empty_state.payments'}}
    />

    <DashboardBookingList
      @infiniteQuery={{@model.arrivingToday}}
      @title={{t 'dashboard.todays_check_in'}}
      @showMoreTitle={{t 'dashboard.view_all_bookings'}}
      @showMoreFilter={{hash status='booked' startAt=todayFilter}}
      @emptyText={{t 'dashboard.empty_state.arrivals'}}
    />

    <DashboardBookingList
      @infiniteQuery={{@model.leavingToday}}
      @title={{t 'dashboard.todays_check_out'}}
      @showMoreTitle={{t 'dashboard.view_all_bookings'}}
      @showMoreFilter={{hash status='booked' endAt=todayFilter}}
      @emptyText={{t 'dashboard.empty_state.departures'}}
    />
  </div>
</template>;

export default RouteTemplate(TemplatesDashboard);
