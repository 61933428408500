import { join } from 'ember-smily-base/utils/string';

export default function generateFullAddress(
  address1?: string,
  address2?: string,
  city?: string,
  zip?: string,
  state?: string,
): string {
  const address = join([address1, address2], ' ');
  const zipAndCity = join([zip, city], ' ');

  if (!zipAndCity) {
    return address;
  }

  return join([address, zipAndCity, state], ', ');
}
