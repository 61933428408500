import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OnboardingPhoneVerifyRoute extends Route {
  @service session!: SessionService;

  @action
  willTransition(): void {
    (this.session.onboarding as OnboardingModel).rollbackAttributes();
  }
}
