import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import ExternalLink from 'ember-smily-base/components/external-link';
import { getShortLocale } from 'ember-smily-base/utils/intl';

interface SignupHelperSignature {
  Element: HTMLDivElement;
  Args: {
    label: string;
    excludePrivacyPolicy?: boolean;
  };
}

export default class SignupHelper extends Component<SignupHelperSignature> {
  @service intl!: IntlService;

  get shortLocale(): string {
    return getShortLocale(this.intl.primaryLocale).toLowerCase();
  }

  get termsUrl(): string {
    return this.getLegalTermUrl('/terms-of-service');
  }

  get privacyUrl(): string {
    return this.getLegalTermUrl('/privacy-policy');
  }

  getLegalTermUrl(route: string): string {
    const url = new URL(route, window.location.origin);

    url.searchParams.append('locale', this.shortLocale);

    return url.toString();
  }

  <template>
    <div class='text-11' ...attributes>
      {{t 'onboarding.signup.signup_helper.1'}}{{@label}}{{t
        'onboarding.signup.signup_helper.2'
      }}

      <ExternalLink
        @label={{t 'onboarding.signup.signup_helper.3'}}
        @url={{this.termsUrl}}
      />

      {{#unless @excludePrivacyPolicy}}
        {{t 'onboarding.signup.signup_helper.4'}}

        <ExternalLink
          @label={{t 'onboarding.signup.signup_helper.5'}}
          @url={{this.privacyUrl}}
        />
      {{/unless}}
    </div>
  </template>
}
