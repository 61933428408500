import type { TOC } from '@ember/component/template-only';
import Badge from 'ember-smily-base/components/badge';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type { Severity } from 'smily-admin-ui/models/inbox-message';

const InboxSeverityBadge: TOC<{
  Args: {
    severity: Severity;
  };
}> = <template>
  <Badge
    @label={{optionLabel @severity 'inbox.message_severity_options'}}
    @skeleton={{true}}
    class='inbox-severity-{{@severity}}'
  />
</template>;

export default InboxSeverityBadge;
