import { attr, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { isPast } from 'date-fns';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type RentalModel from 'smily-admin-ui/models/rental';
import { BaseModel } from 'smily-admin-ui/utils/model';

export const VALUE_TYPE_OPTIONS = ['percentage', 'fixed_amount'];
export const RENTALS_ELIGIBILITY_OPTIONS = ['all_rentals', 'specific_rentals'];
export const MINIMUM_REQUIREMENTS_OPTIONS = [
  'none',
  'minimum_nights_booked',
  'minimum_spend',
];
export const USAGE_LIMIT_OPTIONS = ['none', 'number_of_times'];

export class BookingActivePeriod {
  @tracked startDate?: Date;
  @tracked endDate?: Date;

  constructor(startDate?: Date, endDate?: Date | null) {
    this.startDate = startDate;
    this.endDate = endDate ?? undefined;
  }
}

export default class DiscountCodeModel extends BaseModel {
  @attr('string', {
    readOnly(this: DiscountCodeModel) {
      return this.isEditingDisabled;
    },
  })
  discountCode?: string;
  @attr('number', {
    readOnly(this: DiscountCodeModel) {
      return (
        this.isEditingDisabled || this.discountValueType === 'fixed_amount'
      );
    },
  })
  percentage?: number;
  @attr('number', {
    readOnly(this: DiscountCodeModel) {
      return this.isEditingDisabled || this.discountValueType === 'percentage';
    },
  })
  fixedAmount?: number;
  @attr currency!: string;
  @attr('string', {
    readOnly(this: DiscountCodeModel) {
      return this.isEditingDisabled;
    },
  })
  discountValueType?: string;
  @attr rentalsEligibility?: string;
  @attr allRentals?: boolean;
  @attr minimumRequirements?: string;
  @attr('string', {
    readOnly(this: DiscountCodeModel) {
      return this.minimumRequirements !== 'minimum_nights_booked';
    },
  })
  minimumNightsBooked?: string;
  @attr('string', {
    readOnly(this: DiscountCodeModel) {
      return this.minimumRequirements !== 'minimum_spend';
    },
  })
  minimumSpend?: string;
  @attr usageLimit?: string;
  @attr('number', {
    readOnly(this: DiscountCodeModel) {
      return this.usageLimit !== 'number_of_times';
    },
  })
  numberOfTimes?: number;
  @attr('number') usageCount?: number;
  @attr('local-date') startDate!: Date;
  @attr('local-date') endDate?: Date;
  @attr('booking-active-periods') bookingActivePeriods?: BookingActivePeriod[];

  @hasMany('rental', {
    async: false,
    inverse: null,
    readOnly(this: DiscountCodeModel) {
      return this.rentalsEligibility === 'all_rentals';
    },
  })
  rentals!: SyncHasMany<RentalModel>;

  selectedRentals: RentalModel[] = [];

  get status(): string {
    if (this.haveUsedAll || (this.endDate && isPast(this.endDate))) {
      return 'expired';
    } else if (isPast(this.startDate)) {
      return 'active';
    } else {
      return 'scheduled';
    }
  }

  get amount(): number {
    const amountField = this.percentage ?? this.fixedAmount;
    return Number(amountField);
  }

  get haveUsedAll(): boolean {
    return !!this.numberOfTimes && this.usageCount === this.numberOfTimes;
  }

  get isEditingDisabled() {
    return !this.isNew && !!this.usageCount;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'discount-code': DiscountCodeModel;
  }
}
