import { attr, belongsTo } from '@ember-data/model';
import type RentalModel from 'smily-admin-ui/models/rental';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type PhotoSize =
  | 'microUrl'
  | 'thumbUrl'
  | 'smallUrl'
  | 'compactUrl'
  | 'mediumUrl'
  | 'largeUrl'
  | 'grandeUrl'
  | 'giantUrl';
export const PHOTO_SIZES: [PhotoSize, number, number][] = [
  ['microUrl', 48, 32],
  ['thumbUrl', 96, 64],
  ['smallUrl', 192, 128],
  ['compactUrl', 384, 256],
  ['mediumUrl', 768, 512],
  ['largeUrl', 1200, 800],
  ['grandeUrl', 1536, 1024],
  ['giantUrl', 2400, 1600],
];

export default class PhotoModel extends BaseModel {
  @attr('string', { readOnly: true }) microUrl?: string; // 48x32
  @attr('string', { readOnly: true }) thumbUrl?: string; // 96x64
  @attr('string', { readOnly: true }) smallUrl?: string; // 192x128
  @attr('string', { readOnly: true }) compactUrl?: string; // 384x256
  @attr('string', { readOnly: true }) mediumUrl?: string; // 768x512
  @attr('string', { readOnly: true }) largeUrl?: string; // 1200x800
  @attr('string', { readOnly: true }) grandeUrl?: string; // 1536x1024
  @attr('string', { readOnly: true }) giantUrl?: string; // 2400x1600
  @attr('date', { readOnly: true }) createdAt?: Date;
  @attr('date', { readOnly: true }) updatedAt?: Date;

  @belongsTo('rental', { async: false, inverse: 'photos' })
  rental!: RentalModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    photo: PhotoModel;
  }
}
