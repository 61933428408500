import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { InfiniteQuery, Model } from 'ember-smily-base/utils/store';
import type FinancePayoutsController from 'smily-admin-ui/controllers/finance/-payouts';
import type FinancePayoutsRoute from 'smily-admin-ui/routes/finance/-payouts';
import type SessionService from 'smily-admin-ui/services/session-service';

interface TemplatesFinancePayoutsSignature<
  MN extends 'upcoming-payout' | 'completed-payout',
  Controller extends FinancePayoutsController,
  Route extends FinancePayoutsRoute<MN>,
> {
  Args: {
    model: ModelFor<Route>;
    controller: Controller;
  };
}

export default abstract class TemplatesFinancePayoutsComponent<
  MN extends 'upcoming-payout' | 'completed-payout',
  Controller extends FinancePayoutsController,
  Route extends FinancePayoutsRoute<MN>,
> extends Component<TemplatesFinancePayoutsSignature<MN, Controller, Route>> {
  @service session!: SessionService;

  @tracked selectedPayout!: Model<MN>;

  emptyOptions = [] as unknown as InfiniteQuery<MN>;

  get dates(): string | undefined {
    return this.args.controller.from
      ? `${this.args.controller.from},${this.args.controller.to}`
      : undefined;
  }

  get needsOnboardingCompletion() {
    if (!this.session.account?.onboardingInProgress) {
      return false;
    }

    const onboarding = this.session.onboarding!;

    return !(onboarding.sirenFlowApplicable && onboarding.currentStep === 4);
  }

  @action
  setModalModel(payout: Model<MN>) {
    this.selectedPayout = payout!;
  }
}
