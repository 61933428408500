import { addDays, addWeeks, startOfWeek } from 'date-fns';
import { dateString } from 'ember-smily-base/utils/date';
import type { CompoundFilter } from 'smily-admin-ui/utils/store';

const TODAY = new Date();

function buildDates(dueAt: string) {
  if (dueAt === 'today') {
    return {
      startDate: TODAY,
      endDate: addDays(TODAY, 1),
    };
  }

  const nextWeek = startOfWeek(addWeeks(TODAY, 1), { weekStartsOn: 1 });

  if (dueAt === 'this_week') {
    return {
      startDate: TODAY,
      endDate: nextWeek,
    };
  } else {
    return {
      startDate: nextWeek,
      endDate: addWeeks(nextWeek, 1),
    };
  }
}

function buildDueAtFilter(dueAt: string) {
  const { startDate, endDate } = buildDates(dueAt);

  return [
    {
      op: 'gteq',
      value: dateString(startDate),
    },
    {
      op: 'lt',
      value: dateString(endDate),
    },
  ] as CompoundFilter[1];
}

export { buildDueAtFilter };
