import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class ReviewsHostAutomationIndexRoute extends BaseRoute {
  @service store!: StoreService;

  async model() {
    const automations = await this.store.findAll('reviews-host-automation');

    const automation =
      automations.firstObject ?? (await this.createAutomation());
    const comments = new InfiniteQuery(
      this.store,
      'reviews-host-automation-comment',
    );

    return { automation, comments };
  }

  async createAutomation() {
    const automation = this.store.createRecord('reviews-host-automation');

    await automation.save();

    return automation;
  }
}
