import { attr, belongsTo, hasMany } from '@ember-data/model';
import {
  addDays,
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { modelAction } from 'ember-custom-actions';
import type { LocalDatetime } from 'ember-smily-base/utils/date';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type BookingCommentModel from 'smily-admin-ui/models/booking-comment';
import type BookingFeeModel from 'smily-admin-ui/models/booking-fee';
import type BookingTaxModel from 'smily-admin-ui/models/booking-tax';
import type BookingsTagModel from 'smily-admin-ui/models/bookings-tag';
import type ClientModel from 'smily-admin-ui/models/client';
import type PaymentModel from 'smily-admin-ui/models/payment';
import type {
  DamageDepositCollectionMethod,
  DamageDepositCollectionTime,
} from 'smily-admin-ui/models/preferences-payment';
import type RentalModel from 'smily-admin-ui/models/rental';
import type RentalAgreementModel from 'smily-admin-ui/models/rental-agreement';
import type SourceModel from 'smily-admin-ui/models/source';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type Status = (typeof STATUSES)[number];
export type Stage = (typeof STAGES)[number];

export const STATUSES = [
  'Booked',
  'Tentative',
  'Unavailable',
  'Canceled',
] as const;
export const STAGES = [
  'past',
  'checkin',
  'ongoing',
  'checkout',
  'upcoming',
] as const;

export default class BookingModel extends BaseModel {
  @attr('string', { readOnly: true }) status!: Status;
  @attr('string', { readOnly: true }) reference?: string;
  @attr('number', { readOnly: true }) guestsCount?: number;
  @attr('date', { readOnly: true }) createdAt?: Date;
  @attr('local-datetime') startAt!: LocalDatetime;
  @attr('local-datetime') endAt!: LocalDatetime;
  @attr('boolean') booked?: boolean;
  @attr('boolean') unavailable?: boolean;
  @attr('date') tentativeExpiresAt?: Date;
  @attr expectedCheckinTime?: string;
  @attr expectedCheckoutTime?: string;
  @attr('number') adults?: number;
  @attr('number') children?: number;
  @attr('string', { defaultValue: 'on_arrival' })
  damageDepositCollectionTime!: DamageDepositCollectionTime;
  @attr('string', { defaultValue: 'with_smily' })
  damageDepositCollectionMethod!: DamageDepositCollectionMethod;
  @attr('array', { readOnly: true })
  damageDepositCollectionMethodValues!: string[];
  @attr('array', { readOnly: true })
  damageDepositCollectionTimeValues!: string[];
  @attr damageDepositExternalCollectionMethodName?: string;
  @attr notes?: string;
  @attr('string', { readOnly: true }) paymentUrl?: string;
  @attr('string', { readOnly: true }) contractUrl?: string;

  @attr currency?: string;
  @attr('number') initialPrice!: number;
  @attr('number') discount?: number;
  @attr('number') reconciliation?: number;
  @attr('number', { readOnly: true }) grossEarning!: number;
  // finalPrice can be undefined in case there are no permissions, see canViewPayments
  @attr('number') finalPrice!: number;
  @attr('number') downpayment!: number;
  @attr('number', { readOnly: true }) paidAmount!: number;
  @attr('number', { readOnly: true }) authorizedAmount?: number;
  @attr('number') damageDeposit!: number;
  @attr('number', { readOnly: true }) damageDepositPaid!: number;
  @attr('number', { readOnly: true }) damageDepositAuthorized!: number;
  @attr('number', { readOnly: true }) expensesTotal!: number;
  @attr('number', { readOnly: true }) taxesTotal!: number;
  @attr('number', { readOnly: true }) netIncome!: number;
  @attr('date') expectedInvoiceIssueDate!: Date;

  @attr('string', { readOnly: true }) sourceName?: string;
  @attr('string', { readOnly: true }) rentalName?: string;
  @attr('string', { readOnly: true }) clientFullname?: string;

  @attr('object', { readOnly: true }) lockedAttributes!: Record<
    string,
    unknown
  >;

  @belongsTo('client', { async: false, inverse: 'bookings' })
  client!: ClientModel;

  @belongsTo('rental', { async: false, inverse: 'bookings' })
  rental!: RentalModel;

  @belongsTo('source', { async: false, inverse: null })
  source!: SourceModel;

  @belongsTo('rental-agreement', {
    async: false,
    inverse: null,
    readOnly: true,
  })
  rentalAgreement!: RentalAgreementModel;

  @hasMany('payment', { async: false, inverse: 'booking' })
  payments!: SyncHasMany<PaymentModel>;

  @hasMany('booking-comment', { async: false, inverse: 'booking' })
  comments!: SyncHasMany<BookingCommentModel>;

  @hasMany('booking-fee', { async: false, inverse: 'booking' })
  bookingFees!: SyncHasMany<BookingFeeModel>;

  @hasMany('booking-tax', { async: false, inverse: 'booking', readOnly: true })
  bookingTaxes!: SyncHasMany<BookingTaxModel>;

  @hasMany('bookings-tag', { async: false, inverse: null })
  bookingsTags!: SyncHasMany<BookingsTagModel>;

  static compoundFields = [
    'isConfirmed',
    'isTentative',
    'isUnavailable',
    'nights',
    'statusOption',
    'tentativeExpiresIn',
    'isDamageDepositCollectedOutsideSmily',
  ];

  /* eslint-disable ember/no-get, ember/classic-decorator-no-classic-methods */
  get isConfirmed() {
    return this.get('status') === 'Booked';
  }

  get isTentative() {
    return this.get('status') === 'Tentative';
  }

  get isUnavailable() {
    return this.get('status') === 'Unavailable';
  }

  get isCanceled() {
    return this.get('status') === 'Canceled';
  }

  get nights() {
    const startAt = this.get('startAt');
    const endAt = this.get('endAt');

    if (!startAt || !endAt) {
      return null;
    }

    return differenceInCalendarDays(endAt.date, startAt.date);
  }

  get statusOption() {
    return this.get('status');
  }

  set statusOption(value: string) {
    this.set('status', value);

    if (value === 'Booked') {
      this.set('booked', true);
      this.set('tentativeExpiresAt', undefined);
      this.set('unavailable', false);
    } else if (value === 'Tentative') {
      this.set('booked', false);
      this.set('unavailable', false);
    } else if (value === 'Unavailable') {
      this.set('booked', false);
      this.set('tentativeExpiresAt', undefined);
      this.set('unavailable', true);
    }
  }

  // remaining expiration time in days
  get tentativeExpiresIn() {
    const tentativeExpiresAt = this.get('tentativeExpiresAt');

    if (!tentativeExpiresAt) {
      return undefined;
    }

    const ret = differenceInCalendarDays(
      tentativeExpiresAt,
      new Date(),
    ).toString();

    return ret;
  }

  set tentativeExpiresIn(value: string | undefined) {
    this.set(
      'tentativeExpiresAt',
      value ? addDays(new Date(), Number(value)) : undefined,
    );
  }

  get isDamageDepositCollectedOutsideSmily() {
    return this.get('damageDepositCollectionMethod') === 'external';
  }
  /* eslint-enable ember/no-get, ember/classic-decorator-no-classic-methods */

  get totalAmount() {
    return (this.authorizedAmount || 0) + this.paidAmount;
  }

  get totalToPay() {
    return this.finalPrice + this.damageDeposit;
  }

  get remainingToPay() {
    return Math.max(0, this.totalToPay - this.totalAmount);
  }

  get paidOfFinalPrice() {
    return Math.min(this.totalAmount, this.finalPrice);
  }

  get remains() {
    return Math.max(0, this.finalPrice - this.totalAmount);
  }

  get damageDepositTotalPaid() {
    return this.damageDepositPaid + this.damageDepositAuthorized;
  }

  get damageDepositRemains() {
    return this.remains
      ? this.damageDeposit
      : this.damageDeposit - this.damageDepositTotalPaid;
  }

  get isFinalPricePaid() {
    return this.paidAmount >= this.finalPrice;
  }

  get isPaid() {
    return this.paidAmount >= this.totalToPay;
  }

  get displayedCheckin() {
    return this.expectedCheckinTime || this.startAt.time;
  }

  get displayedCheckout() {
    return this.expectedCheckoutTime || this.endAt.time;
  }

  get checkinType() {
    if (this.expectedCheckinTime) {
      return 'expected';
    }

    const contractual = this.rental.checkinTime;

    if (
      !contractual ||
      contractual === Number(this.startAt.time?.split(':')[0])
    ) {
      return null;
    }

    return 'contractual';
  }

  get checkoutType() {
    if (this.expectedCheckoutTime) {
      return 'expected';
    }

    const contractual = this.rental.checkoutTime;

    if (
      !contractual ||
      contractual === Number(this.endAt.time?.split(':')[0])
    ) {
      return null;
    }

    return 'contractual';
  }

  get isArrivingToday() {
    return isSameDay(this.startAt.date, new Date());
  }

  get isLeavingToday() {
    return isSameDay(this.endAt.date, new Date());
  }

  get isAtProperty() {
    const { startAt, endAt, isArrivingToday, isLeavingToday } = this;
    const now = new Date();

    return (
      !isArrivingToday &&
      !isLeavingToday &&
      isAfter(now, startAt.date) &&
      isBefore(now, endAt.date)
    );
  }

  get isPastBooking() {
    return isBefore(this.endAt.date, startOfDay(new Date()));
  }

  get isFutureBooking() {
    return isAfter(this.startAt.date, startOfDay(new Date()));
  }

  get stage(): Stage {
    if (this.isArrivingToday) {
      return 'checkin';
    } else if (this.isLeavingToday) {
      return 'checkout';
    } else if (this.isPastBooking) {
      return 'past';
    } else if (this.isFutureBooking) {
      return 'upcoming';
    } else {
      return 'ongoing';
    }
  }

  get guestStatus() {
    if (this.isArrivingToday) {
      return 'arriving_today';
    } else if (this.isLeavingToday) {
      return 'leaving_today';
    } else if (this.isAtProperty) {
      return 'at_property';
    }

    return undefined;
  }

  get sortedPayments(): PaymentModel[] {
    return this.payments
      .filter((payment) => !payment.isNew)
      .slice()
      .sort((pv, nx) => {
        if (!pv.paymentSortDate) {
          return 1;
        } else if (!nx.paymentSortDate) {
          return -1;
        }

        return pv.paymentSortDate.getTime() - nx.paymentSortDate.getTime();
      });
  }

  get sortedActivePayments() {
    return this.sortedPayments.filter((payment) => !payment.isCanceled);
  }

  restore = modelAction<BookingModel>('restore', {
    method: 'PATCH',
    pushToStore: true,
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    booking: BookingModel;
  }
}
