import type IntlService from 'ember-intl/services/intl';
import type {
  ChartDataPoint,
  ValueUnitType,
} from 'smily-admin-ui/utils/performance';
import { formatValueWithUnitType } from 'smily-admin-ui/helpers/format-value-with-unit-type';

export function createTooltip(hasCompare: boolean) {
  const tooltipHTML = `
    <div id="performance-tooltip" class="smily-tooltip bg-white">
      <ul class="smily-list list-sm list-sticky">
        <li
          class="smily-list-item fw-semibold"
          data-performance-tooltip-title
        >
          June 11
        </li>

        <li class="smily-list-item d-flex flex-column gap-1">
          ${dataLine()}
          ${hasCompare ? dataLine(true) : ''}
        </li>
      </ul>
    </div>
  `;

  const tooltip = document.getElementById('performance-tooltip');
  if (tooltip) {
    tooltip.outerHTML = tooltipHTML;
  } else {
    document.body.insertAdjacentHTML('beforeend', tooltipHTML);
  }
}

function dataLine(isCompare?: boolean) {
  return `
    <div
      class="d-flex justify-content-between"
      data-performance-tooltip-line=${isCompare ? 'compare' : 'base'}
    >
      <div class='d-flex align-items-center gap-1'>
        <div class='performance-tooltip-legend'></div>

        <div data-performance-tooltip-year>
          year
        </div>
      </div>

      <div class="d-flex gap-1">
        ${
          isCompare
            ? ''
            : `
                <div
                  class="badge rounded-pill d-flex align-items-baseline"
                  data-performance-tooltip-badge
                >
                  difference
                </div>
              `
        }

        <div data-performance-tooltip-value>
          value
        </div>
      </div>
    </div>
  `;
}

export function showTooltip(
  intl: IntlService,
  dateFormat: string,
  valueType: ValueUnitType,
  data: ChartDataPoint,
  compareData?: ChartDataPoint,
) {
  const tooltip = document.getElementById('performance-tooltip')!;

  tooltip.style.visibility = 'visible';
  tooltip.querySelector('[data-performance-tooltip-title]')!.textContent =
    intl.formatDate(data.x, { format: dateFormat });

  const line = tooltip.querySelector('[data-performance-tooltip-line="base"]')!;

  line.querySelector('[data-performance-tooltip-year]')!.textContent = data.x
    .getFullYear()
    .toString();
  line.querySelector('[data-performance-tooltip-value]')!.textContent =
    formatValueWithUnitType(intl, data.y, valueType, true);

  if (!compareData) {
    return;
  }

  const compareLine = tooltip.querySelector(
    '[data-performance-tooltip-line="compare"]',
  )!;
  compareLine.querySelector('[data-performance-tooltip-year]')!.textContent =
    compareData.x.getFullYear().toString();
  compareLine.querySelector('[data-performance-tooltip-value]')!.textContent =
    formatValueWithUnitType(intl, compareData.y, valueType, true);

  const difference = Math.round(
    ((data.y - compareData.y) / compareData.y) * 100,
  );
  const badge = line.querySelector('[data-performance-tooltip-badge]')!;
  badge.textContent = `${difference < 0 ? '' : '+'}${difference}%`;

  if (isNaN(difference) || difference === Infinity) {
    badge.classList.add('visually-hidden');
  } else {
    badge.classList.remove('visually-hidden');
  }

  if (difference < 0) {
    badge.classList.remove('text-bg-success');
    badge.classList.add('text-bg-danger');
  } else {
    badge.classList.remove('text-bg-danger');
    badge.classList.add('text-bg-success');
  }
}

export function moveTooltip(event: PointerEvent) {
  const tooltip = document.getElementById('performance-tooltip')!;
  const { clientX, clientY } = event;

  const tooltipX =
    clientX + tooltip.offsetWidth > window.innerWidth
      ? clientX - tooltip.offsetWidth
      : clientX;
  const tooltipY =
    clientY + tooltip.offsetHeight > window.innerHeight
      ? clientY - tooltip.offsetHeight
      : clientY;

  tooltip.style.left = `${tooltipX}px`;
  tooltip.style.top = `${tooltipY}px`;
}

export function removeTooltip() {
  const tooltip = document.querySelector('.performance-tooltip');
  if (tooltip) {
    tooltip.remove();
  }
}
