import type { ModelFor, Transition } from 'ember-smily-base/utils/routing';
import type CalendarMonthController from 'smily-admin-ui/controllers/calendar/month';
import BaseRoute from 'smily-admin-ui/routes/calendar/-base';
import type { SetupController } from 'smily-admin-ui/utils/routing';

function hasController(
  controller?: object,
): controller is CalendarMonthController {
  return !!controller;
}

export default class CalendarMonthRoute extends BaseRoute {
  calendarType = 'full' as const;

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (hasController(this.controller)) {
      this.controller.isLoadingRentals = true;
    }
  }

  setupController(
    controller: SetupController<CalendarMonthController>,
    model: ModelFor<BaseRoute>,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);

    controller.isLoadingRentals = false;

    if (!controller.activeRental) {
      controller.activeRental = model.rentals.options[0]!.id;
    }
  }
}
