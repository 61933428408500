import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';

export default class InboxNewRoute extends Route {
  @service store!: StoreService;

  beforeModel(transition: Transition) {
    return this.store.loadModelsById([
      { modelName: 'client', ids: transition.to.queryParams.to },
    ]);
  }

  async afterModel(_model: null, transition: Transition): Promise<void> {
    const recipientId = transition.to.queryParams.to;

    if (!recipientId) {
      return;
    }

    const client = this.store.peekRecord('client', recipientId);

    if (client?.primaryEmail) {
      return;
    }

    await this.store.queryRecords(
      'client',
      this.store.generateQuery('client', 'nameAndEmail', { id: recipientId }),
    );
  }
}
