import t from 'ember-intl/helpers/t';
import IconText from 'ember-smily-base/components/icon-text';

const InboxSavedRepliesDesktopPlaceholder = <template>
  <IconText
    @icon='bookmark'
    @iconStyle='far'
    @text={{t 'inbox.saved_replies'}}
  />
</template>;

export default InboxSavedRepliesDesktopPlaceholder;
