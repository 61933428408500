import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class SmilyProspectAbility extends Ability<'smily-prospect'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'smily-prospect');
  }
}
