import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import can from 'ember-can/helpers/can';
import t from 'ember-intl/helpers/t';
import TopbarActions from 'ember-smily-base/components/topbar/actions';

const InboxNewConversationTopbarAction = <template>
  {{#if (can 'create inbox-conversation')}}
    <TopbarActions>
      <LinkTo
        @route='inbox.new'
        @query={{hash to=undefined}}
        class='btn btn-primary'
        data-test-new-conversation
      >
        {{t 'inbox.new_conversation'}}
      </LinkTo>
    </TopbarActions>
  {{/if}}
</template>;

export default InboxNewConversationTopbarAction;
