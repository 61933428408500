import FeatureDiscoveryVideoBanner from 'smily-admin-ui/components/feature-discovery/video-banner';

const FeatureDiscoveryFirstBanner = <template>
  <FeatureDiscoveryVideoBanner
    @en='https://player.vimeo.com/video/1006309750?badge=0&amp;autopause=0&amp;transparent=1&amp;player_id=0&amp;app_id=58479'
    @fr='https://player.vimeo.com/video/1006312010?badge=0&amp;autopause=0&amp;transparent=1&amp;player_id=0&amp;app_id=58479'
  />
</template>;

export default FeatureDiscoveryFirstBanner;
