import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type QueryParam =
  | 'rental'
  | 'destination'
  | 'availableOn'
  | 'sleeps'
  | 'bedrooms'
  | 'rentalTag'
  | 'rentalType'
  | 'amenities';

export const CALENDAR_SHARED_FILTERS: string[] = [
  'rental',
  'destination',
  'availableOn',
  'sleeps',
  'bedrooms',
  'rentalTag',
  'rentalType',
  'amenities',
];

export default class CalendarController extends Controller {
  queryParams = CALENDAR_SHARED_FILTERS as QueryParam[];

  @tracked rental?: string;
  @tracked destination?: string;
  @tracked availableOn?: string;
  @tracked sleeps?: string;
  @tracked bedrooms?: string;
  @tracked rentalTag?: string;
  @tracked rentalType?: string;
  @tracked amenities?: string;

  @action
  changeQueryParam(key: QueryParam, value?: string): void {
    this[key] = value;
  }
}

declare module '@ember/controller' {
  interface Registry {
    calendar: CalendarController;
  }
}
