import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

export default class InquiriesInquiryRoute extends Route {
  @service coreLinks!: CoreLinksService;

  beforeModel(transition: Transition): void {
    const { inquiry_id: id } = transition.to.params;
    this.coreLinks.redirect('inquiries', id);
  }
}
