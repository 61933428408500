import { action, setProperties } from '@ember/object';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Badge from 'ember-smily-base/components/badge';
import formatMoney from 'ember-smily-base/helpers/format-money';
import type { SavedCardData } from 'smily-admin-ui/components/credit-card-form';
import CreditCardForm from 'smily-admin-ui/components/credit-card-form';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingBilling extends TemplatesOnboardingTask {
  actionName = 'completeCreditCardDetails';

  get attributes(): string[] {
    return ['cardName', 'cardExpiration', 'cardNumber', 'cardType', 'aliasId'];
  }

  get email() {
    return this.session.onboarding!.companyEmail || this.session.user!.email!;
  }

  @action
  saveCardData(data: SavedCardData): Promise<void> {
    setProperties(this.onboardingChangeset, data);

    return this.save();
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <div class='d-flex align-items-center justify-content-between'>
            <h2>
              {{t 'onboarding.billing.title'}}
            </h2>

            <Badge
              @label={{t 'onboarding.billing.secured'}}
              @icon='lock'
              @color='success'
              @skeleton={{true}}
            />
          </div>

          <CreditCardForm
            @email={{this.email}}
            @afterSave={{this.saveCardData}}
            as |form|
          >
            <div class='d-flex flex-column gap-2'>
              <div class='d-flex justify-content-between fw-semibold'>
                <div>
                  {{t 'onboarding.billing.billed_now'}}
                </div>

                <div>
                  {{formatMoney 0 'EUR'}}
                </div>
              </div>

              <div>
                {{t 'onboarding.billing.card_form_helper'}}
              </div>
            </div>

            <hr class='w-100' />

            <div class='d-flex align-items-center justify-content-between'>
              <OnboardingBackToTaskList />

              <form.Submit>
                {{t 'common.save'}}
              </form.Submit>
            </div>
          </CreditCardForm>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.billing.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.billing.helper.p1'}}
          </div>

          <div>
            {{t 'onboarding.billing.helper.p2'}}
          </div>

          <div>
            {{t 'onboarding.billing.helper.p3'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingBilling);
