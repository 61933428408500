import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class AuthLoginRoute extends Route {
  @service coreLinks!: CoreLinksService;
  @service router!: RouterService;
  @service session!: SessionService;

  async beforeModel() {
    if (this.session.isAuthenticated) {
      this.router.transitionTo('index');
      return;
    }

    try {
      await this.login();

      this.router.transitionTo('index');
    } catch (error) {
      if ((error as Error).name === 'AuthError') {
        this.coreLinks.redirect('login');
      } else {
        throw error;
      }
    }

    if (this.session.isAuthenticated) {
      this.router.transitionTo('index');
    }
  }

  login(): Promise<void> {
    return this.session.authenticate(
      'authenticator:torii',
      'bookingsync-oauth2',
    );
  }
}
