import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import HorizontallyScrollable from 'ember-smily-base/components/horizontally-scrollable';
import Link from 'ember-smily-base/components/link';
import MediaGallery from 'ember-smily-base/components/media-gallery';
import MediaPlaceholder from 'ember-smily-base/components/media-placeholder';
import { isPDF, isVideo } from 'ember-smily-base/utils/file';
import or from 'ember-truth-helpers/helpers/or';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';

interface InboxComposeAttachmentsSignature {
  Args: {
    attachments: InboxAttachmentModel[];
    deleteAttachment: (attachment: InboxAttachmentModel) => Promise<void>;
  };
}

export default class InboxComposeAttachments extends Component<InboxComposeAttachmentsSignature> {
  get files() {
    return this.args.attachments.map((attachment) => attachment.file!);
  }

  deleteAttachment = task({ drop: true }, async (file: string) => {
    const attachment = this.args.attachments.find(
      (attachment) => attachment.file === file,
    )!;
    await this.args.deleteAttachment(attachment);
  });

  <template>
    <HorizontallyScrollable @containerClass='d-flex gap-3' class='w-100'>
      {{#each this.files as |file|}}
        <div class='d-flex flex-column align-items-center'>
          <MediaGallery
            @files={{this.files}}
            @deleteFile={{this.deleteAttachment}}
            as |Trigger|
          >
            <Trigger class='btn btn-link p-0'>
              {{#if (or (isPDF file) (isVideo file))}}
                <div class='media-thumbnail inbox-attachment on-gray'>
                  <MediaPlaceholder @file={{file}} />
                </div>
              {{else}}
                <img
                  src={{file}}
                  class='media-thumbnail inbox-attachment'
                  alt={{file}}
                />
              {{/if}}
            </Trigger>
          </MediaGallery>

          <Link
            @action={{perform this.deleteAttachment file}}
            disabled={{this.deleteAttachment.isRunning}}
            class='btn btn-link btn-sm'
          >
            {{t 'common.delete'}}
          </Link>
        </div>
      {{/each}}
    </HorizontallyScrollable>
  </template>
}
