import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import formatNumber from 'ember-intl/helpers/format-number';
import type IntlService from 'ember-intl/services/intl';
import Badge from 'ember-smily-base/components/badge';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import LoadingState from 'ember-smily-base/components/loading-state';
import Tooltip from 'ember-smily-base/components/tooltip';
import { eq } from 'ember-truth-helpers';
import formatValueWithUnitType from 'smily-admin-ui/helpers/format-value-with-unit-type';
import type { Subtab, TabHelper } from 'smily-admin-ui/utils/performance';
import { tabConfig } from 'smily-admin-ui/utils/performance';

function unitType({ name }: TabHelper) {
  return tabConfig.find((tab) => tab.name === name)!.type;
}

function getLinkColor(value: number) {
  return value < 0 ? 'danger' : 'success';
}

interface PerformanceTabSignature {
  Element: HTMLDivElement;
  Args: {
    tab: TabHelper;
    activeTab: Subtab;
    changeTab: (value: Subtab) => void;
  };
}
export default class PerformanceTab extends Component<PerformanceTabSignature> {
  @service intl!: IntlService;

  @action
  getTranslation(prop: string) {
    return this.intl.t(`performance.tabs.${this.args.tab.name}.${prop}`);
  }

  @action
  changeTab(event: PointerEvent | KeyboardEvent) {
    if ((event.target as HTMLElement).closest('[data-performance-tab-info]')) {
      event.stopPropagation();
      return;
    }

    this.args.changeTab(this.args.tab.name);
  }

  <template>
    <Link
      @action={{this.changeTab}}
      class='card-list-item performance-tab
        {{if (eq @activeTab @tab.name) "active"}}'
    >
      <div class='d-flex gap-1'>
        <div>{{this.getTranslation 'title'}}</div>

        <Tooltip
          @tooltipText={{this.getTranslation 'tooltip'}}
          @triggerClass='link-unstyled'
          data-performance-tab-info
        >
          <Icon @icon='info-circle' class='info' />
        </Tooltip>
      </div>

      {{#if @tab.isLoading}}
        <LoadingState isLoading={{true}} class='small align-self-start my-2' />
      {{else}}
        <div class='d-flex align-items-center gap-1'>
          <div class='text-24 fw-semibold'>
            {{formatValueWithUnitType @tab.value (unitType @tab)}}
          </div>

          {{#if @tab.difference}}
            <Badge
              @label={{formatNumber
                @tab.difference
                style='percent'
                signDisplay='always'
              }}
              @color={{getLinkColor @tab.difference}}
            />
          {{/if}}
        </div>
      {{/if}}
    </Link>
  </template>
}
