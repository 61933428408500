import { attr, belongsTo } from '@ember-data/model';
import { customAction } from 'ember-custom-actions';
import type AccountModel from 'smily-admin-ui/models/account';
import type HostModel from 'smily-admin-ui/models/host';
import type UserModel from 'smily-admin-ui/models/user';
import type { StaticResourcePermissions } from 'smily-admin-ui/utils/ability';
import { BaseModel } from 'smily-admin-ui/utils/model';

export const ROLES = ['admin', 'member', 'cleaner'] as const;
export type Role = (typeof ROLES)[number];

export default class AccountsUserModel extends BaseModel {
  @attr('object', { readOnly: true })
  staticResourcePermissions!: StaticResourcePermissions;
  @attr('object', { readOnly: true }) enabledReleaseToggles!: string[];

  @attr('string') role?: Role;
  @attr('array', { readOnly: true }) rentalTypesUsedInRentals!: string[];
  @attr('boolean', { readOnly: true }) owner?: boolean;
  @attr('boolean', { readOnly: true }) channelImportInProgress!: boolean;
  @attr('boolean', { readOnly: true }) privacyPolicyAccepted!: boolean;
  @attr('boolean', { readOnly: true }) financeDataAvailable!: boolean;

  @belongsTo('user', { async: false, inverse: 'accountsUser', readOnly: true })
  user!: UserModel;

  @belongsTo('account', {
    async: false,
    inverse: 'accountsUsers',
    readOnly: true,
  })
  account!: AccountModel;

  @belongsTo('host', { async: false, inverse: null, readOnly: true })
  host!: HostModel;

  fetchSession = customAction<AccountsUserModel>('fetchSession', {
    method: 'GET',
    pushToStore: true,
  });

  switchAccount = customAction<AccountsUserModel>('switchAccount', {
    method: 'POST',
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounts-user': AccountsUserModel;
  }
}
