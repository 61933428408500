import type { QueryMap } from 'ember-smily-base/utils/store';
import { MAX_PAGE_SIZE } from 'smily-admin-ui/utils/store';

const QUERY_MAP: QueryMap = {
  ability: { default: {} },

  amenity: {
    default: {
      filter: {
        usedInRentals: true,
      },
      sort: 'title',
    },
  },

  account: {
    default: {},
    accountSwitcher: {
      sort: 'businessName',
      fields: {
        accounts: 'businessName',
      },
    },
    settings: {
      include:
        'accountCompany.ability,accountsUsers,bankAccount,owner,preferencesGeneralSetting,subscription.systemBillingDetail.ability',
      reload: true,
    },
    preferencesPayment: {
      include: 'preferencesPayment',
    },
    meta: {
      fields: { accounts: '' },
      page: { number: 1, size: 1 },
    },
  },

  'account-channel-signup': { default: {} },
  'account-company': { default: {} },

  'accounting-statement': { default: {} },

  'accounts-user': {
    default: {},
    access: {
      include: 'user',
      reload: true,
    },
  },

  'bank-account': { default: {} },

  booking: {
    default: {
      include:
        'ability,bookingFees.rentalFee.fee,bookingTaxes,bookingsTags,comments.creator,client.addresses,client.emails,client.phones,payments,rental.primaryPhoto,rental.rentalFees.fee,source',
      reload: true,
    },
    listDefault: {
      include: 'ability,rental,bookingsTags',
      fields: {
        bookings:
          'startAt,endAt,startAtTime,endAtTime,expectedCheckinTime,expectedCheckoutTime,finalPrice,authorizedAmount,paidAmount,damageDeposit,damageDepositPaid,damageDepositAuthorized,guestsCount,adults,children,status,notes,currency,rentalName,sourceName,clientFullname,ability,bookingsTags,rental',
      },
      sort: 'startAt',
    },
    inboxRelatedRentalName: {
      fields: { bookings: 'rental-name' },
      sort: '-startAt',
      page: { number: 1, size: 1 },
    },
    calendar: {
      include: 'source,rental,bookingsTags',
      fields: {
        bookings:
          'startAt,endAt,startAtTime,endAtTime,guestsCount,adults,children,status,notes,clientFullname,finalPrice,authorizedAmount,paidAmount,damageDeposit,damageDepositPaid,damageDepositAuthorized,currency,sourceName,rental,source,bookingsTags',
      },
      filter: {
        status: 'booked,tentative,unavailable',
      },
    },
    calendarDetails: {
      include: 'ability,payments,source,rental.primaryPhoto,bookingsTags',
      fields: {
        bookings:
          'startAt,endAt,startAtTime,endAtTime,guestsCount,adults,children,status,notes,clientFullname,finalPrice,authorizedAmount,paidAmount,damageDeposit,damageDepositPaid,damageDepositAuthorized,currency,sourceName,ability,rental,source,bookingsTags',
        rentals:
          'bathroomsCount,expectedCheckinTime,expectedCheckoutTime,name,primaryPhoto,sleeps,sleepsMax,surface,surfaceUnit',
      },
      filter: {
        status: 'booked,tentative,unavailable',
      },
      reload: true,
    },
    calendarPlanning: {
      include: 'rental',
      fields: {
        bookings:
          'startAt,endAt,adults,children,status,notes,clientFullname,finalPrice,authorizedAmount,paidAmount,damageDeposit,damageDepositPaid,damageDepositAuthorized,sourceName,rental',
        rentals: '',
      },
      filter: {
        status: 'booked,tentative,unavailable',
      },
    },
    grossEarning: {
      fields: {
        bookings:
          'startAt,endAt,grossEarning,guestsCount,adults,children,status,currency,rentalName,clientFullname',
      },
      filter: {
        forGrossEarnings: true,
      },
      sort: '-endAt,rental.position',
    },
    taskEventSource: {
      fields: {
        bookings: 'reference',
      },
      sort: 'reference',
    },
  },

  'booking-comment': { default: {} },

  'booking-fee': {
    default: {
      include: 'rentalFee',
    },
  },

  'booking-gross-earning': { default: {} },

  'bookings-tag': {
    default: {},
    filter: {
      sort: 'name',
      fields: {
        bookingsTags: 'name',
      },
    },
  },

  calendar: {
    default: {
      page: { number: 1, size: MAX_PAGE_SIZE },
    },
    meta: {
      fields: { calendars: '' },
      meta: ['maximum_sleeps_count', 'maximum_bedrooms_count'],
      page: { number: 1, size: 1 },
    },
  },

  'completed-payout': {
    default: {
      include: 'bookingPayouts.booking.rental,paymentGateway,payments.booking',
    },
  },

  'discount-code': {
    default: {},
    listDefault: {
      fields: {
        discountCode:
          'discountCode,percentage,fixedAmount,currency,allRentals,usageCount,numberOfTimes,bookingActivePeriods,startDate,endDate,rentals',
      },
      include: 'rentals',
    },
    filter: {
      fields: {
        discountCode: 'discountCode',
      },
    },
  },

  'credit-card': { default: {} },
  currency: { default: {} },

  destination: {
    default: { filter: { usedInRentals: true } },
  },

  discount: { default: {} },

  client: {
    default: {
      include: 'ability,emails,phones,addresses,primaryEmail,primaryPhone',
      fields: {
        clients:
          'firstName,primaryEmail,primaryPhone,lastName,upcomingBookingsCount,confirmedBookingsCount,reviewsCount,reviewsRate,notes,birthday,language,company,vatNumber,passportNumber,passportCountry,emails,phones,addresses',
      },
    },
    listDefault: {
      include: 'primaryEmail',
      fields: {
        clients:
          'firstName,primaryEmail,lastName,upcomingBookingsCount,confirmedBookingsCount,reviewsCount,reviewsRate,notes,birthday,language,company,vatNumber,passportNumber,passportCountry,emails,phones,addresses',
      },
    },
    nameAndEmail: {
      include: 'primaryEmail',
      fields: {
        clients: 'firstName,lastName,primaryEmail',
      },
    },
    filter: {
      sort: 'firstName,lastName',
      fields: {
        clients: 'firstName,lastName',
      },
    },
  },

  'client-address': { default: {} },
  'client-email': { default: {} },
  'client-phone': { default: {} },

  host: {
    default: {},
    inboxAssignee: {
      filter: { withUsers: true },
      sort: 'selfFirst',
      include: 'user',
    },
  },

  'host-review': {
    default: {
      include:
        'booking.ability,booking.client,booking.rental,reviewReply,source',
    },
    listDefault: {
      include: 'booking.ability,booking.client,source',
      fields: {
        bookings: 'ability,client,endAt,rentalName,startAt',
        clients: 'firstName,lastName',
      },
      sort: '-booking.endAt',
    },
    actionable: {
      include: 'booking.ability,booking.client,source',
      fields: {
        bookings: 'ability,client,endAt,rentalName,startAt',
        clients: 'firstName,lastName',
      },
      sort: 'expired-last',
    },
    sideCard: {
      include: 'reviewReply,source',
    },
  },

  'inbox-attachment': { default: {} },

  'inbox-conversation': {
    default: {
      include:
        'assignee.user,bookings.ability,bookings.bookingsTags,bookings.payments,bookings.rental.primary-photo,first-client-participant.member(clients).emails,first-client-participant.member(clients).addresses,first-client-participant.member(clients).phones,last-message.sender.member,participants.member',
    },
    listDefault: {
      include:
        'assignee.user,bookings.ability,bookings.rental.primary-photo,first-client-participant.member(clients).emails,first-client-participant.member(clients).addresses,first-client-participant.member(clients).phones,last-message.sender.member',
    },
    unreadCounter: {
      filter: {
        read: false,
        lastMessageAt: {
          op: 'not_eq',
          value: 'null',
        },
      },
    },
    counters: {
      fields: { inboxConversations: '' },
      meta: ['counters'],
      page: { number: 1, size: 1 },
    },
    bookingLabelCounters: {
      fields: { inboxConversations: '' },
      filter: { closed: false, spam: false },
      meta: ['booking-label-counters'],
      page: { number: 1, size: 1 },
    },
  },

  'inbox-message': {
    default: {
      sort: '-sentAt',
      include: 'sender.member.ability,sender.member(hosts).user,attachments',
    },
  },

  'inbox-participant': { default: {} },
  'inbox-reply-template': { default: {} },
  inquiry: { default: {} },
  invitation: { default: {} },

  'legal-term': { default: {} },

  onboarding: { default: {} },

  payment: { default: {} },
  photo: { default: {} },
  'preferences-general-setting': { default: {} },

  rental: {
    default: {
      include: 'amenities,photos,primaryPhoto',
    },
    listDefault: {
      include: 'primaryPhoto',
    },
    filter: {
      sort: 'name',
      fields: {
        rentals: 'name',
      },
    },
    newBookingRelatedData: {
      include: 'primaryPhoto,rentalFees.fee',
      fields: {
        rentals: 'name,checkinTime,checkoutTime,primaryPhoto,rentalFees',
      },
    },
    newBookingRentalSelect: {
      sort: 'name',
      fields: {
        rentals: 'name',
      },
    },
    taskEventSource: {
      fields: { rentals: 'name' },
      sort: 'name',
    },
    meta: {
      fields: { rentals: '' },
      page: { number: 1, size: 1 },
    },
  },

  'rentals-tag': { default: {} },
  'rental-agreement': { default: {} },
  'rental-fee': { default: {} },
  'review-reply': { default: {} },

  review: {
    default: {
      include:
        'reviewReply,booking,booking.ability,booking.client,booking.rental,booking.bookingsTags,source',
      reload: true,
    },
    listDefault: {
      include: 'reviewReply,booking.ability,booking.client,source',
      fields: {
        bookings: 'ability,client,endAt,rentalName,startAt',
        clients: 'firstName,lastName',
      },
      sort: '-submittedAt',
    },
    actionable: {
      include: 'reviewReply,booking.ability,booking.client,source',
      filter: { withoutReply: true },
      fields: {
        bookings: 'ability,client,endAt,rentalName,startAt',
        clients: 'firstName,lastName',
      },
      sort: '-submittedAt',
    },
  },

  'service-fee-payment-invoice': { default: {} },
  'smily-prospect': { default: {} },

  source: {
    default: {},
    filter: {
      sort: 'name',
      fields: {
        sources: 'name',
      },
    },
  },

  subscription: { default: {} },
  'system-billing-detail': { default: {} },

  'upcoming-payout': {
    default: {
      include: 'bookingPayouts.booking.rental,paymentGateway,payments.booking',
    },
  },
  'task-management-task': {
    default: {
      include:
        'assignee,attachments,comments.attachments,comments.createdBy,eventSource(bookings).rental.primaryPhoto,eventSource(rentals).primaryPhoto,taskItems',
      reload: true,
    },
    listDefault: {
      fields: {
        taskManagementTasks:
          'assignee,comments,expectedDurationInMinutes,name,hasIssues,eventSource,eventSource(bookings),eventSource(rentals),dueAt,status,priority',
        taskManagementTaskComments: '',
      },
      include:
        'assignee,comments,eventSource(bookings).rental.primaryPhoto,eventSource(rentals).primaryPhoto',
    },
    meta: {
      fields: { taskManagementTasks: '' },
      page: { number: 1, size: 1 },
    },
  },
  'task-management-template': {
    default: {
      include: 'attachments,rentals,taskAssignee',
      reload: true,
    },
    listDefault: {
      include: 'rentals',
      fields: {
        taskManagementTemplates: 'name,rentals,status,taskDueAtAutomation',
      },
    },
    nameOnly: {
      fields: {
        taskManagementTemplates: 'name',
      },
    },
  },

  user: {
    default: {},
    nameOnly: {
      fields: {
        users: 'firstName,lastName',
      },
    },
  },
};

export default QUERY_MAP;
