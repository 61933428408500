import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import BaseService from 'ember-simple-auth/services/session';
import type StoreService from 'ember-smily-base/services/store';
import type TeleportService from 'ember-smily-base/services/teleport';
import config from 'smily-admin-ui/config/environment';
import type AccountModel from 'smily-admin-ui/models/account';
import type AccountsUserModel from 'smily-admin-ui/models/accounts-user';
import type { Role } from 'smily-admin-ui/models/accounts-user';
import type HostModel from 'smily-admin-ui/models/host';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type UserModel from 'smily-admin-ui/models/user';
import type CacheService from 'smily-admin-ui/services/cache';

export default class SessionService extends BaseService {
  @service cache!: CacheService;
  @service declare store: StoreService;
  @service teleport!: TeleportService;

  @tracked sessionInfo?: AccountsUserModel;

  get account(): AccountModel | undefined {
    return this.sessionInfo?.account;
  }

  get user(): UserModel | undefined {
    return this.sessionInfo?.user;
  }

  get host(): HostModel | undefined {
    return this.sessionInfo?.host;
  }

  get staticResourcePermissions() {
    return this.sessionInfo?.staticResourcePermissions;
  }

  get enabledReleaseToggles() {
    return this.sessionInfo?.enabledReleaseToggles;
  }

  get owner(): boolean {
    return !!this.sessionInfo?.owner;
  }

  get termsAndConditionsAccepted(): boolean | undefined {
    return this.account?.termsAndConditionsAccepted;
  }

  get privacyPolicyAccepted(): boolean | undefined {
    return !!this.sessionInfo?.privacyPolicyAccepted;
  }

  get role(): Role | undefined {
    return this.sessionInfo?.role;
  }

  get channelImportInProgress(): boolean {
    return !!this.sessionInfo?.channelImportInProgress;
  }

  get onboarding(): OnboardingModel | undefined {
    if (this.account?.onboardingInProgress) {
      return this.account.onboarding;
    }

    return undefined;
  }

  get features(): string[] | undefined {
    return (
      this.account && this.account.featureNames.map((name) => camelize(name))
    );
  }

  get needsAuthentication(): boolean {
    if (!this.isAuthenticated) {
      return true;
    }

    const info = this.data?.authenticated?.authorizationToken?.info;

    if (!info) {
      return true;
    }

    return info.expires_in_seconds === 0;
  }

  get isCleaner() {
    return this.role === 'cleaner';
  }

  get authorizationHeader(): { Authorization: string } | {} {
    const token = this.data?.authenticated?.authorizationToken?.access_token;

    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  async confirmCurrentAccount(): Promise<void> {
    const currentAccountId = this.user?.belongsTo('currentAccount').id();

    if (currentAccountId && currentAccountId !== this.account?.id) {
      await this.switchAccount.perform(currentAccountId);
    }
  }

  switchAccount = task({ drop: true }, async (accountId: string) => {
    const payload = { data: { 'account-id': accountId } };
    const isTesting = config.environment === 'test';

    this.teleport.disable();

    try {
      await this.sessionInfo?.switchAccount(payload);
    } catch (error) {
      this.teleport.enable();
      throw error;
    }

    this.invalidate();

    if (!isTesting) {
      window.location.reload();
    }
  });

  @tracked hasAnyRental = false;

  async setupRentalCount(): Promise<void> {
    const rentalQuery = this.store.queryRecords(
      'rental',
      this.store.generateQuery('rental', 'meta'),
    );

    const response = await rentalQuery;

    const { total } = response.meta.pagination;

    this.hasAnyRental = !!total;

    if (this.onboarding && total === 1) {
      this.onboarding.onlyRentalId = rentalQuery.firstObject!.id;
    }
  }

  async setupOnboarding() {
    const onboardings = await this.store.queryRecords('onboarding', {
      filter: {
        account: this.account?.id,
      },
    });

    const onboarding = onboardings.at(0);

    if (!onboarding) {
      assert(
        `${this.toString()} onboarding can't be found for account ${this.account
          ?.id}`,
      );
      return;
    }

    this.cache.updateOnboardingCompletionPercentage();

    await onboarding.setupCurrentStep();
  }
}
