import { attr, belongsTo } from '@ember-data/model';
import type ClientModel from 'smily-admin-ui/models/client';
import type HostModel from 'smily-admin-ui/models/host';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-message';
import { BaseModel } from 'smily-admin-ui/utils/model';

export type InboxParticipantMemberModel = ClientModel | HostModel;

export default class InboxParticipantModel extends BaseModel {
  @attr('date', { readOnly: true }) lastMessageSentAt?: Date;
  @attr('date') readAt?: Date;
  @attr('string', { readOnly: true }) fullName?: string;
  @attr('string', { readOnly: true }) avatarUrl?: string;
  @attr('date', { readOnly: true }) createdAt?: Date;

  @belongsTo('inbox-conversation', { async: false, inverse: 'participants' })
  conversation!: InboxConversationModel;

  @belongsTo('inbox-participant-member', {
    async: false,
    inverse: null,
    polymorphic: true,
  })
  member?: InboxParticipantMemberModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'inbox-participant': InboxParticipantModel;
  }
}
