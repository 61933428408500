import type { RouteIcons } from 'ember-smily-base/utils/routing';
import IconsHandHoldingEur from 'smily-admin-ui/components/icons/hand-holding-eur';

const routeIcons: RouteIcons = {
  onboarding: {
    icon: 'clipboard-check',
    iconStyle: 'far',
  },
  dashboard: {
    icon: 'tasks',
    iconStyle: 'far',
  },
  tasks: {
    icon: 'check-square',
    iconStyle: 'far',
  },
  performance: {
    icon: 'chart-line',
  },
  inbox: {
    icon: 'inbox',
    iconStyle: 'far',
  },
  calendar: {
    icon: 'calendar-alt',
    iconStyle: 'far',
  },
  'old-bookings': {
    icon: 'calendar-alt',
  },
  'old-bookings-with-calendar-name': {
    icon: 'calendar-alt',
  },
  bookings: {
    icon: 'book-open',
    iconStyle: 'far',
  },
  clients: {
    icon: 'users',
    iconStyle: 'far',
  },
  finance: {
    iconComponent: IconsHandHoldingEur,
  },
  discounts: {
    icon: 'tag',
    iconStyle: 'far',
  },
  rentals: {
    icon: 'home',
    iconStyle: 'far',
  },
  inquiries: {
    icon: 'envelope',
    iconStyle: 'far',
  },
  reviews: {
    icon: 'star',
    iconStyle: 'far',
  },
  apps: {
    icon: 'th',
    iconStyle: 'far',
  },
  profile: {
    icon: 'user',
    iconStyle: 'far',
  },
  settings: {
    icon: 'cog',
    iconStyle: 'far',
  },
  'auth.logout': {
    icon: 'power-off',
    iconStyle: 'far',
  },
  404: {
    icon: 'exclamation-triangle',
    iconStyle: 'far',
  },
};

export default routeIcons;
