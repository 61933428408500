import Model from '@ember-data/model';

export default class CurrencyModel extends Model {
  // @attr alternateSymbols: [];
  // @attr decimalMark!: string;
  // @attr disambiguateSymbol
  // @attr htmlEntity!: string;
  // @attr isoCode!: string;
  // @attr isoNumeric!: string;
  // @attr name!: string;
  // @attr priority: number;
  // @attr smallestDenomination: number;
  // @attr subunit: string;
  // @attr subunitToUnit: 100;
  // @attr symbol: string;
  // @attr symbolFirst?: true;
  // @attr format
  // @attr rate?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    currency: CurrencyModel;
  }
}
