import type { TOC } from '@ember/component/template-only';
import IconText from 'ember-smily-base/components/icon-text';
import optionLabel from 'smily-admin-ui/helpers/option-label';

type LabelArgs =
  | {
      text?: never;
      scope: string;
    }
  | {
      text: string;
      scope?: never;
    };

type StatusTextSignature = {
  modelName: string;
  status: string;
  reverse?: boolean;
} & LabelArgs;

const StatusText: TOC<StatusTextSignature> = <template>
  {{#if @scope}}
    <IconText
      @text={{optionLabel @status @scope}}
      @icon='circle'
      @iconSize='sm'
      @iconClass='status-color {{@modelName}}-{{@status}}'
      @gap={{1}}
      class={{if @reverse 'flex-row-reverse'}}
    />
  {{else}}
    <IconText
      @text={{@text}}
      @icon='circle'
      @iconSize='sm'
      @iconClass='status-color {{@modelName}}-{{@status}}'
      @gap={{1}}
      class={{if @reverse 'flex-row-reverse'}}
    />
  {{/if}}
</template>;

export default StatusText;
