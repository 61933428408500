import type { Snapshot } from '@ember-data/store';
import type { ModelName } from 'ember-smily-base/utils/store';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

const SESSION_INCLUDES =
  'user.current-application-metadata,account.ability,account.subscription.outstanding-payments.invoice,host';
const SESSION_FIELDS =
  'fields[accounting-payments]=invoice&fields[accounting-invoices]=issued-at,issue-number,total';

export default class AccountsUserAdapter extends ApplicationAdapter {
  urlForCustomAction(_: ModelName, _2: string, _3: Snapshot, actionId: string) {
    if (actionId === 'fetchSession') {
      return `${this.urlPrefix()}/me?include=${SESSION_INCLUDES}&${SESSION_FIELDS}`;
    }

    if (actionId === 'switchAccount') {
      return `${this.urlPrefix()}/me/switch-account`;
    }

    return '';
  }
}
