import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import { post } from 'ember-smily-base/utils/promise';
import UnauthenticatedScreen from 'smily-admin-ui/components/unauthenticated-screen';
import type ConfirmEmailController from 'smily-admin-ui/controllers/confirm-email';
import type CoreLinksService from 'smily-admin-ui/services/core-links';

interface TemplatesConfirmEmailSignature {
  Args: {
    controller: ConfirmEmailController;
  };
}

class TemplatesConfirmEmail extends Component<TemplatesConfirmEmailSignature> {
  @service coreLinks!: CoreLinksService;

  @action
  async resendConfirmation(): Promise<void> {
    const { email } = this.args.controller;
    const url = this.coreLinks.confirmation;

    post(
      this,
      url,
      { data: { user: { email } } },
      'Something happened while trying to resend confirmation email.',
    );
  }

  <template>
    <div class='vh-100 d-flex flex-column'>
      <UnauthenticatedScreen @hideLocaleSelect={{true}}>
        <div class='h-0 flex-1 p-3'>
          <Card class='h-100'>
            <div
              class='card-body d-flex align-items-center justify-content-center'
            >
              <div
                class='unauthenticated-section-container d-flex flex-column gap-3'
              >
                <h2 class='text-lg-28'>
                  {{t 'onboarding.confirm_email.title'}}
                </h2>

                <div class='d-flex flex-column text-lg-18'>
                  <div>
                    {{t
                      'onboarding.confirm_email.content.1'
                      email=@controller.email
                      htmlSafe=true
                    }}
                  </div>

                  <Link @action={{this.resendConfirmation}} class='fw-semibold'>
                    {{t 'onboarding.confirm_email.resend_confirmation'}}
                  </Link>
                </div>

                <div class='text-lg-18'>
                  {{t 'onboarding.confirm_email.content.2'}}
                </div>

                <hr class='w-100' />

                <LinkTo
                  @route={{@controller.backPath}}
                  class='text-lg-18 fw-semibold'
                >
                  <Icon @icon='arrow-left' class='text-primary' />

                  {{t 'onboarding.confirm_email.back_to_signup'}}
                </LinkTo>
              </div>
            </div>
          </Card>
        </div>
      </UnauthenticatedScreen>
    </div>
  </template>
}

export default RouteTemplate(TemplatesConfirmEmail);
