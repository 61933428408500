import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class ReviewsHostAutomationNewRoute extends BaseRoute {
  @service store!: StoreService;

  model() {
    const automation = this.store.peekAll('reviews-host-automation')
      .firstObject!;

    return this.store.createRecord('reviews-host-automation-comment', {
      automation,
    });
  }
}
