import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';
import LoadingButton from 'ember-smily-base/components/loading-button';
import IconsSmily from 'smily-admin-ui/components/icons/smily';
import type AccountModel from 'smily-admin-ui/models/account';
import type SessionService from 'smily-admin-ui/services/session-service';

class TemplatesOnboardingProfileCompleted extends Component {
  @service router!: RouterService;
  @service session!: SessionService;

  @action
  async completeOnboarding(): Promise<void> {
    await (this.session.account as AccountModel).reload();

    this.router.transitionTo('index');
  }

  <template>
    <div class='h-100 p-3'>
      <Card class='min-h-100'>
        <div
          class='card-body p-3 p-lg-5 d-flex align-items-center justify-content-center'
        >
          <div class='position-absolute align-self-start w-100 px-3 px-lg-5'>
            <img
              src='/assets/images/success-pattern.svg'
              alt=''
              class='w-100'
            />
          </div>

          <div class='d-flex flex-column align-items-center gap-3'>
            <Icon
              @icon='check'
              @style='fal'
              @mask='circle'
              @transform='shrink-8'
              class='mb-4 text-primary text-75 text-lg-100'
            />

            <h3 class='text-lg-28'>
              {{t 'onboarding.profile_completed.title'}}
            </h3>

            <div class='text-lg-18 text-center'>
              {{t 'onboarding.profile_completed.subtitle1'}}

              <br />

              {{t 'onboarding.profile_completed.subtitle2'}}

              <IconsSmily />
            </div>

            <LoadingButton
              @action={{this.completeOnboarding}}
              class='btn btn-primary'
            >
              {{t 'common.continue'}}
            </LoadingButton>
          </div>
        </div>
      </Card>
    </div>
  </template>
}

export default RouteTemplate(TemplatesOnboardingProfileCompleted);
