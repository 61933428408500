import { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'smily-admin-ui/models/account';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class InboxReplyTemplateModel extends BaseModel {
  @attr name?: string;
  @attr content?: string;
  @attr('date', { readOnly: true }) createdAt?: Date;

  @belongsTo('account', { async: false, inverse: null, readOnly: true })
  account!: AccountModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'inbox-reply-template': InboxReplyTemplateModel;
  }
}
