import type { TOC } from '@ember/component/template-only';
import { concat, fn } from '@ember/helper';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import t from 'ember-intl/helpers/t';
import Checkbox from 'ember-smily-base/components/checkbox';
import type { FormYield } from 'ember-smily-base/components/form';
import not from 'ember-truth-helpers/helpers/not';
import type { Channel } from 'smily-admin-ui/templates/onboarding/services';

const OnboardingServiceItem: TOC<{
  Args: {
    service: Channel;
    isChecked: boolean;
    form: FormYield;
    toggle: (service: Channel, value: boolean) => void;
  };
}> = <template>
  <div class='d-flex align-items-center justify-content-between'>
    <img
      src={{concat '/assets/images/' (lowercase @service) '.svg'}}
      alt={{@service}}
    />

    <div class='d-flex align-items center'>
      <Checkbox
        @value={{@isChecked}}
        @label={{t 'onboarding.services.yes_i_do'}}
        @onChange={{fn @toggle @service}}
      />
    </div>
  </div>

  <BsCollapse @collapsed={{not @isChecked}}>
    <div class='d-flex flex-column gap-3'>
      <@form.Input
        @property={{concat @service 'Login'}}
        @required={{@isChecked}}
      />

      <@form.Input
        @property={{concat @service 'Password'}}
        @required={{@isChecked}}
        @type='password'
      />
    </div>
  </BsCollapse>
</template>;

export default OnboardingServiceItem;
