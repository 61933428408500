import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import List from 'ember-smily-base/components/list';
import RentalsPrimaryPhoto from 'smily-admin-ui/components/rentals/primary-photo';
import RentalsProgress from 'smily-admin-ui/components/rentals/progress';
import RentalsRoomsAndGuests from 'smily-admin-ui/components/rentals/rooms-and-guests';
import RentalsStatus from 'smily-admin-ui/components/rentals/status';
import type RentalModel from 'smily-admin-ui/models/rental';

const RentalsRentalItemMobile: TOC<{
  Element: HTMLUListElement;
  Args: {
    model: RentalModel;
  };
}> = <template>
  <List @sticky={{true}} ...attributes class='p-3' as |Item|>
    <Item class='d-flex flex-column gap-3'>
      <RentalsPrimaryPhoto @rental={{@model}} />

      <div>
        <div class='h4'>
          {{@model.name}}
        </div>

        <RentalsRoomsAndGuests @rental={{@model}} />
      </div>
    </Item>

    <Item>
      <RentalsStatus @rental={{@model}} />
    </Item>

    <Item class='d-flex align-items-center gap-3'>
      <RentalsProgress @rental={{@model}} class='flex-1' />

      <LinkTo
        @route='rentals.rental'
        @model={{@model.id}}
        class='btn btn-sm {{if @model.isListed "btn-secondary" "btn-primary"}}'
      >
        {{t
          (if @model.isListed 'rentals.show_listing' 'rentals.finish_listing')
        }}
      </LinkTo>
    </Item>
  </List>
</template>;

export default RentalsRentalItemMobile;
