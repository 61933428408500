import type { TOC } from '@ember/component/template-only';
import can from 'ember-can/helpers/can';
import t from 'ember-intl/helpers/t';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import ensure from 'ember-smily-base/helpers/ensure';
import CopyUrl from 'smily-admin-ui/components/copy-url';
import PopoverFocused from 'smily-admin-ui/components/popover-focused';
import type BookingModel from 'smily-admin-ui/models/booking';

const BookingsRequestPayment: TOC<{
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}> = <template>
  {{#if (can 'request payment in booking' @booking)}}
    <Link class='btn btn-link px-0' as |id|>
      <IconText
        @text={{t 'finance.payment_bar.request_payment'}}
        @icon='link'
      />

      <PopoverFocused @id={{id}}>
        <CopyUrl
          @url={{ensure @booking.paymentUrl}}
          @inputLabel={{t 'rentals.export_ical.input_label'}}
          @buttonLabel={{t 'rentals.export_ical.copy_link'}}
        />
      </PopoverFocused>
    </Link>
  {{/if}}
</template>;

export default BookingsRequestPayment;
