import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class TasksTemplatesNewRoute extends BaseRoute {
  @service store!: StoreService;

  model() {
    return this.store.createRecord('task-management-template');
  }
}
