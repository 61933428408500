import { tracked } from '@glimmer/tracking';
import { all } from 'rsvp';
import type {
  ResponseValue,
  ChartTimeUnit,
  DataPoint,
  ScoreboardDataResponse,
  Subtab,
} from 'smily-admin-ui/utils/performance';
import { tabConfig } from 'smily-admin-ui/utils/performance';

export default class TabHelper {
  @tracked isLoading = true;

  basePromise;
  comparePromise;
  name;
  yUnit;
  value!: number;
  difference?: number;
  baseDataPoints!: DataPoint[];
  compareDataPoints?: DataPoint[];
  xUnit!: ChartTimeUnit;

  constructor(
    name: Subtab,
    basePromise: Promise<ScoreboardDataResponse>,
    comparePromise?: Promise<ScoreboardDataResponse>,
  ) {
    this.name = name;
    this.basePromise = basePromise;
    this.comparePromise = comparePromise;
    this.yUnit = tabConfig.find((t) => t.name === name)!.type;

    this.setup();
  }

  async setup() {
    let baseResponse: ScoreboardDataResponse;
    let compareResponse: ScoreboardDataResponse | undefined;

    if (!this.comparePromise) {
      baseResponse = await this.basePromise;
    } else {
      [baseResponse, compareResponse] = await all([
        this.basePromise,
        this.comparePromise,
      ]);
    }

    this.value = convertToNumber(baseResponse.meta.value);
    this.baseDataPoints = convertDataValuesToNumber(baseResponse.meta.points);
    this.xUnit = baseResponse.meta.points[0]!.aggregation;

    if (!compareResponse) {
      this.isLoading = false;
      return;
    }

    if (compareResponse.meta.value) {
      const compareValue = convertToNumber(compareResponse.meta.value);
      this.difference = (this.value - compareValue) / compareValue;
    }

    this.compareDataPoints = convertDataValuesToNumber(
      compareResponse.meta.points,
    );
    this.isLoading = false;
  }
}

function convertDataValuesToNumber(
  points: ScoreboardDataResponse['meta']['points'],
) {
  return points.map((point) => ({
    ...point,
    value: convertToNumber(point.value),
  }));
}

function convertToNumber(value: ResponseValue) {
  return value ? Number(value) : 0;
}
