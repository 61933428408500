import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import Link from 'ember-smily-base/components/link';

const SidebarMobileMenuSwitchAccountTrigger: TOC<{
  Args: {
    linkClass?: string;
  };
}> = <template>
  <Link class={{@linkClass}}>
    {{t 'common.switch_account'}}
  </Link>
</template>;

export default SidebarMobileMenuSwitchAccountTrigger;
