import type { TOC } from '@ember/component/template-only';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import t from 'ember-intl/helpers/t';
import formatMoney from 'ember-smily-base/helpers/format-money';

function repositionRemainingText(element: HTMLDivElement) {
  const paymentBar = element
    .closest('[data-payment-bar-detailed]')!
    .querySelector('.payment-progress')!;
  const lastPaymentDot = [
    ...paymentBar.querySelectorAll<HTMLDivElement>(
      '.payment-progress-milestone:not(.expected-milestone)',
    ),
  ].at(-1);

  if (!lastPaymentDot) {
    positionLeft(element);
    return;
  }

  const leftPercentage = Number(lastPaymentDot.style.left.slice(0, -1));
  const margin = element.clientWidth / 2;
  const barWidth = paymentBar.clientWidth;
  const left = (barWidth / 100) * leftPercentage;

  if (left < margin) {
    positionLeft(element);
  } else if (barWidth - left < margin) {
    element.style.left = `${barWidth - margin * 2}px`;
  } else {
    element.style.left = lastPaymentDot.style.left;
    element.style.transform = 'translate(-50%, 0)';
    element.style.transition = 'none';
  }
}

function positionLeft(element: HTMLDivElement) {
  element.style.left = '0';
  element.style.transform = 'none';
}

const BookingsPaymentBarDetailedRemainingText: TOC<{
  Element: HTMLDivElement;
  Args: {
    amount: number;
    currency: string;
  };
}> = <template>
  <div
    {{didInsert repositionRemainingText}}
    {{didUpdate repositionRemainingText @amount}}
    class='position-relative align-self-start text-11'
  >
    {{t
      'finance.payment_bar.amount_remaining'
      amount=(formatMoney @amount @currency)
    }}
  </div>
</template>;

export default BookingsPaymentBarDetailedRemainingText;
