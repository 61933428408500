import { attr, belongsTo } from '@ember-data/model';
import type { FileMeta } from 'ember-smily-base/utils/model';
import { fileMeta } from 'ember-smily-base/utils/model';
import type AccountModel from 'smily-admin-ui/models/account';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class BankAccountModel extends BaseModel {
  @attr declare iban?: string;
  @attr declare bic?: string;
  @attr declare ibanDocument?: string;
  @attr('boolean', { readOnly: true }) declare ibanDocumentPresent?: boolean;

  @belongsTo('account', {
    async: false,
    inverse: 'bankAccount',
    readOnly: true,
  })
  account!: AccountModel;

  @fileMeta('ibanDocument', 'ibanDocumentPresent') ibanDocumentFile!: FileMeta;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bank-account': BankAccountModel;
  }
}
