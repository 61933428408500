import { attr } from '@ember-data/model';
import { customAction, resourceAction } from 'ember-custom-actions';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class LegalTermModel extends BaseModel {
  @attr kind!: string;
  @attr content!: string;
  @attr('date') publishedAt!: Date;
  @attr subjectCategory!: string;

  termsAndConditions = resourceAction<LegalTermModel>('terms-and-conditions', {
    method: 'GET',
    pushToStore: true,
  });

  privacyPolicy = resourceAction<LegalTermModel>('privacy-policy', {
    method: 'GET',
    pushToStore: true,
  });

  acceptTermsAndConditions = resourceAction<LegalTermModel>(
    'terms-and-conditions/accept',
    { method: 'PATCH' },
  );

  acceptPrivacyPolicy = resourceAction<LegalTermModel>(
    'privacy-policy/accept',
    { method: 'PATCH' },
  );

  publicTermsAndConditions = customAction<LegalTermModel>(
    'terms-and-conditions',
  );

  publicSoftwareTermsAndConditions = customAction<LegalTermModel>(
    'terms-and-conditions/software',
  );

  publicPrivacyPolicy = customAction<LegalTermModel>('privacy-policy');

  publicSoftwarePrivacyPolicy = customAction<LegalTermModel>(
    'privacy-policy/software',
  );
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legal-term': LegalTermModel;
  }
}
