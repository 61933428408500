import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import List from 'ember-smily-base/components/list';
import { asString } from 'ember-smily-base/utils/template';
import not from 'ember-truth-helpers/helpers/not';
import { setupBackgroundImage } from 'smily-admin-ui/components/calendars/booking-dialog';
import type RentalModel from 'smily-admin-ui/models/rental';

interface RentalsFloatingCardSignature {
  Element: HTMLUListElement;
  Args: {
    rental: RentalModel;
  };
}

export default class RentalsFloatingCard extends Component<RentalsFloatingCardSignature> {
  @tracked isShowingMore = false;

  <template>
    <List class='border rounded shadow-sm' ...attributes as |Item|>
      <Item
        {{didInsert (fn setupBackgroundImage @rental)}}
        {{didUpdate (fn setupBackgroundImage @rental) @rental}}
        class='h-144-px background-cover'
      />

      <Item>
        <LinkTo
          @route='rentals.rental'
          @model={{@rental.id}}
          class='btn btn-link p-0'
        >
          {{@rental.name}}
        </LinkTo>
      </Item>

      <Item class='d-flex justify-content-end'>
        <Link
          @action={{fn (mut this.isShowingMore) (not this.isShowingMore)}}
          class='btn btn-link btn-sm py-0 position-relative smily-select-like with-smily-select-icon remove-right-pad'
          aria-expanded={{if this.isShowingMore 'true'}}
        >
          {{#if this.isShowingMore}}
            {{t 'common.show_less'}}
          {{else}}
            {{t 'common.show_more'}}
          {{/if}}

          <div class='smily-select-icon'>
            <Icon @icon='chevron-down' class='flex-row-reverse' />
          </div>
        </Link>
      </Item>

      <BsCollapse @collapsed={{not this.isShowingMore}}>
        <List class='border-top' as |CollapseItem|>
          <CollapseItem class='d-flex flex-column gap-2'>
            <div>
              {{@rental.fullAddress}}
            </div>

            <div class='d-flex gap-2'>
              <IconText
                @icon='user-friends'
                @text={{asString @rental.sleeps}}
                @gap={{1}}
              />

              <IconText
                @icon='bed-alt'
                @text={{asString @rental.bedroomsCount}}
                @gap={{1}}
              />

              <IconText
                @icon='bath'
                @text={{asString @rental.bathroomsCount}}
                @gap={{1}}
              />

              <IconText
                @icon='ruler-combined'
                @text={{asString @rental.area}}
                @gap={{1}}
              />
            </div>
          </CollapseItem>
        </List>
      </BsCollapse>
    </List>
  </template>
}
