import { getOwner } from '@ember/application';
import { concat } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import cannot from 'ember-can/helpers/cannot';
import Icon from 'ember-smily-base/components/icon';
import breadcrumb from 'ember-smily-base/helpers/breadcrumb';
import type { RouteIcons } from 'ember-smily-base/utils/routing';

interface SidebarMobileMenuFooterMenuItemSignature {
  Args: {
    route: string;
  };
}

export default class SidebarMobileMenuFooterMenuItem extends Component<SidebarMobileMenuFooterMenuItemSignature> {
  routeIcons = getOwner(this).resolveRegistration(
    'route-icons:main',
  ) as RouteIcons;

  get icon() {
    return this.routeIcons[this.args.route]!.icon!;
  }

  <template>
    {{#let (concat 'access ' @route ' route') as |accessAbility|}}
      <li class='nav-item'>
        <LinkTo
          @route={{@route}}
          class='nav-link dropdown-item'
          @disabled={{cannot accessAbility}}
        >
          <Icon @icon={{this.icon}} @fixedWidth={{true}} class='nav-icon' />

          <div class='nav-title'>
            {{breadcrumb @route}}
          </div>

          {{#if (cannot accessAbility)}}
            <div class='flex-1 pe-3 d-flex justify-content-end'>
              <Icon @icon='lock' />
            </div>
          {{/if}}
        </LinkTo>
      </li>
    {{/let}}
  </template>
}
