import { registerDeprecationHandler } from '@ember/debug';

const DISABLED_DEPRECATIONS = [
  'ember-can.deprecate-ability-computed',
  'ember-string.add-package',
  'ember-data:deprecate-early-static',
];

export function initialize() {
  registerDeprecationHandler((message, options, next) => {
    if (options && DISABLED_DEPRECATIONS.includes(options.id)) {
      return;
    } else {
      next(message, options);
    }
  });
}

export default { initialize };
