import { attr } from '@ember-data/model';
import type { LanguageCode } from 'ember-smily-base/utils/intl';
import { BaseModel } from 'smily-admin-ui/utils/model';

type TranslationAttr = {
  [index in LanguageCode]: string;
};

export default class PreferencesGeneralSettingModel extends BaseModel {
  @attr('object') privacyPolicyTranslations!: TranslationAttr;
  @attr('array', { readOnly: true }) selectedLocales!: string[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'preferences-general-setting': PreferencesGeneralSettingModel;
  }
}
