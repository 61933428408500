import Transform from '@ember-data/serializer/transform';

interface MoneySerialized {
  fractional: string;
  currency: {
    id: string;
  };
}

export interface MoneyAttr {
  amount: number;
  currency: string;
}

export default class MoneyTransform extends Transform {
  deserialize(serialized: MoneySerialized) {
    return {
      amount: Number(serialized.fractional),
      currency: serialized.currency.id,
    } as MoneyAttr;
  }

  serialize() {
    throw new Error('money attribute serialization is not yet supported');
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    money: MoneyAttr;
  }
}
