import { service } from '@ember/service';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type RentalsRentalRoute from 'smily-admin-ui/routes/rentals/rental';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class RentalsRentalTaxesRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;

  beforeModel() {
    const rentalId = (
      this.modelFor('rentals.rental') as ModelFor<RentalsRentalRoute>
    ).id;

    this.coreLinks.redirect('rentalTaxes', rentalId);
  }
}
