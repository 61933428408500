import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import LoadingScreen from 'smily-admin-ui/components/loading-screen';
import type CreditCardModel from 'smily-admin-ui/models/credit-card';
import type SessionService from 'smily-admin-ui/services/session-service';

interface CreditCardAuthSignature {
  Args: {
    creditCard: CreditCardModel;
    isPending?: boolean;
  };
}

export default class CreditCardAuth extends Component<CreditCardAuthSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;
  @service session!: SessionService;

  constructor(owner: unknown, args: CreditCardAuthSignature['Args']) {
    super(owner, args);

    this.fetchCardState.perform();
  }

  get returnRoute() {
    return this.session.account?.onboardingInProgress
      ? 'onboarding'
      : 'settings.billing';
  }

  fetchCardState = task(async () => {
    await this.args.creditCard.reload();

    const successStatus = this.args.isPending
      ? 'pending_authentication'
      : 'ready_to_use';
    const errorStatus = 'transaction_failed';

    if (this.args.creditCard.status === successStatus) {
      this.handleSuccess();
    } else if (this.args.creditCard.status === errorStatus) {
      this.router.transitionTo(this.returnRoute);
      this.notify.error(this.intl.t('finance.credit_card.auth_failed'));
    } else {
      await timeout(5000);
      await this.fetchCardState.perform();
    }
  });

  handleSuccess() {
    if (this.args.isPending) {
      this.router.transitionTo('credit-card-auth.3ds', this.args.creditCard.id);
    } else {
      this.router.transitionTo(this.returnRoute);
      this.notify.success(this.intl.t('finance.credit_card.added'));
    }
  }

  <template>
    <LoadingScreen />
  </template>
}
