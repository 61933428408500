import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import formatMoney from 'ember-smily-base/helpers/format-money';

const BookingsPaymentBarDetailedPaidText: TOC<{
  Element: HTMLDivElement;
  Args: {
    amount: number;
    total: number;
    currency: string;
    isDamageDeposit?: boolean;
  };
}> = <template>
  <div>
    <span class='fw-semibold'>
      {{t
        'finance.payment_bar.amount_paid_of.1'
        type=(t
          (if
            @isDamageDeposit
            'finance.payment_bar.payment_types.damage_deposit'
            'finance.payment_bar.payment_types.paid'
          )
        )
        amount=(formatMoney @amount @currency)
      }}
    </span>

    {{t
      'finance.payment_bar.amount_paid_of.2'
      total=(formatMoney @total @currency)
    }}
  </div>
</template>;

export default BookingsPaymentBarDetailedPaidText;
