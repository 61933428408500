import Component from '@glimmer/component';
import ensure from 'ember-smily-base/helpers/ensure';
import not from 'ember-truth-helpers/helpers/not';
import BookingsPaymentBarDetailedPaidText from 'smily-admin-ui/components/bookings/payment-bar/detailed/paid-text';
import type { PaymentBarItem } from 'smily-admin-ui/components/bookings/payment-bar/detailed/progress';
import BookingsPaymentBarDetailedProgress from 'smily-admin-ui/components/bookings/payment-bar/detailed/progress';
import BookingsPaymentBarDetailedRemainingText from 'smily-admin-ui/components/bookings/payment-bar/detailed/remaining-text';
import type BookingModel from 'smily-admin-ui/models/booking';

interface BookingsPaymentBarDamageDepositSignature {
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
  Blocks: { default: [] };
}

export default class BookingsPaymentBarDamageDeposit extends Component<BookingsPaymentBarDamageDepositSignature> {
  get paymentItems() {
    const items: PaymentBarItem[] = [];

    let total = 0;
    let isDamageDepositPayment = false;

    for (const payment of this.args.booking.sortedActivePayments) {
      total += payment.amount;

      if (isDamageDepositPayment) {
        items.push({
          amount: payment.amount,
          isAuthorized: payment.isAuthorized,
          payment,
        });
      } else if (total > this.args.booking.finalPrice) {
        isDamageDepositPayment = true;

        items.push({
          amount: total - this.args.booking.finalPrice,
          isAuthorized: payment.isAuthorized,
          payment,
        });
      }
    }

    return items;
  }

  <template>
    <div class='d-flex flex-column gap-1' data-payment-bar-detailed>
      <div class='d-flex align-items-center gap-1'>
        {{#if (has-block)}}
          {{yield}}
        {{/if}}

        <BookingsPaymentBarDetailedPaidText
          @amount={{@booking.damageDepositTotalPaid}}
          @total={{@booking.damageDeposit}}
          @currency={{ensure @booking.currency}}
          @isDamageDeposit={{true}}
        />
      </div>

      <BookingsPaymentBarDetailedProgress
        @booking={{@booking}}
        @items={{this.paymentItems}}
        @total={{@booking.damageDeposit}}
        @isPaid={{not @booking.damageDepositRemains}}
        @isDamageDeposit={{true}}
      />

      {{#if @booking.damageDepositRemains}}
        <BookingsPaymentBarDetailedRemainingText
          @amount={{@booking.damageDepositRemains}}
          @currency={{ensure @booking.currency}}
        />
      {{/if}}
    </div>
  </template>
}
