import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { Model } from 'ember-smily-base/utils/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { QueryParam } from 'smily-admin-ui/controllers/finance/-payouts';
import type SessionService from 'smily-admin-ui/services/session-service';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export type Range = {
  from?: string;
  to?: string;
};

export default abstract class FinancePayoutsRoute<
  MN extends 'upcoming-payout' | 'completed-payout',
> extends BaseRoute {
  @service session!: SessionService;
  @service store!: StoreService;

  abstract modelName: MN;
  abstract dateField: keyof Model<MN>;

  queryParams = {
    page: { refreshModel: true },
    from: { refreshModel: true },
    to: { refreshModel: true },
  };

  model(params: Record<QueryParam, string>): {
    payouts: InfiniteQuery<MN>;
  } {
    const { from, to } = params;
    const page = params.page as unknown as number;
    const query = this.store.generateQuery(this.modelName);

    if (from) {
      query.filter = {
        [this.dateField]: [
          {
            op: 'gteq',
            value: `${from} 00:00:00`,
          },
          {
            op: 'lteq',
            value: `${to} 23:59:59`,
          },
        ],
      };
    }

    const payouts = new InfiniteQuery(this.store, this.modelName, query, {
      page,
    });

    return { payouts };
  }
}
