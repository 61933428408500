import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import SignupLayout from 'smily-admin-ui/components/signup/layout';
import type SignupBusinessController from 'smily-admin-ui/controllers/signup/business';
import type SignupBusinessRoute from 'smily-admin-ui/routes/signup/business';

const TemplatesSignupBusiness: TOC<{
  Args: {
    model: ModelFor<SignupBusinessRoute>;
    controller: SignupBusinessController;
  };
}> = <template>
  <SignupLayout
    @onboarding={{@model}}
    @airbnbError={{@controller.airbnbButtonError}}
    @isBusiness={{true}}
  />
</template>;

export default RouteTemplate(TemplatesSignupBusiness);
