import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type SessionService from 'smily-admin-ui/services/session-service';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class LegalTermAbility extends Ability<'legal-term'> {
  @service session!: SessionService;

  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'legal-term');
  }

  get canAccept(): boolean {
    return this.session.isAuthenticated && !this.session.user?.superuser;
  }
}
