import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export type QueryParam = 'rental' | 'start' | 'end';

export default class BookingsNewController extends Controller {
  queryParams: QueryParam[] = ['rental', 'start', 'end'];

  @tracked rental?: string;
  @tracked start?: string;
  @tracked end?: string;
}

declare module '@ember/controller' {
  interface Registry {
    'bookings.new': BookingsNewController;
  }
}
