import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';

interface FeatureDiscoveryVideoBannerSignature {
  Args: {
    en: string;
    fr: string;
  };
}

export default class FeatureDiscoveryVideoBanner extends Component<FeatureDiscoveryVideoBannerSignature> {
  @service private intl!: IntlService;

  private get url() {
    return this.intl.primaryLocale === 'fr-fr' ? this.args.fr : this.args.en;
  }

  <template>
    <div class='feature-discovery-banner'>
      <iframe
        src={{this.url}}
        frameborder='0'
        allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
        title={{t 'user.feature_discovery.discover_feature' feature='Feature'}}
      ></iframe>
    </div>
  </template>
}
