import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { callAction, createChangeset } from 'ember-smily-base/utils/model';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OnboardingIndexRoute extends Route {
  @service cache!: CacheService;
  @service session!: SessionService;
  @service router!: RouterService;

  async beforeModel(transition: Transition): Promise<void> {
    const callBookedAtStr = transition.to.queryParams?.event_start_time;

    if (!callBookedAtStr) {
      return;
    }

    const onboarding = this.session.onboarding!;

    onboarding.callBookedAt = new Date(callBookedAtStr);

    await callAction(createChangeset(onboarding), 'bookACall', [
      'callBookedAt',
    ]);

    this.cache.updateOnboardingCompletionPercentage();
    this.router.transitionTo('onboarding.index');
  }
}
