import { attr, belongsTo } from '@ember-data/model';
import config from 'smily-admin-ui/config/environment';
import type TaskManagementTaskModel from 'smily-admin-ui/models/task-management-task';
import type TaskManagementTaskCommentModel from 'smily-admin-ui/models/task-management-task-comment';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';
import { BaseModel } from 'smily-admin-ui/utils/model';

const {
  API: { HOST, NAMESPACE },
} = config;
export const DIRECT_UPLOADS_URL = new URL(
  `${NAMESPACE}/task-management-attachments/direct-uploads`,
  HOST,
).toString();

export interface TaskManagementAttachmentFile {
  original: string;
  signedId: string;
}

export type AttachmentTarget =
  | TaskManagementTaskModel
  | TaskManagementTemplateModel
  | TaskManagementTaskCommentModel;

export default class TaskManagementAttachmentModel extends BaseModel {
  @attr('object') file!: TaskManagementAttachmentFile;

  @belongsTo('target', { polymorphic: true, async: false, inverse: null })
  target!: AttachmentTarget;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'task-management-attachment': TaskManagementAttachmentModel;
  }
}
