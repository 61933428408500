import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class HostSerializer extends ApplicationSerializer<'host'> {
  attrs = {
    firstName: 'firstname',
    lastName: 'lastname',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    host: HostSerializer;
  }
}
