import Transform from '@ember-data/serializer/transform';
import { ReviewCriteria } from 'smily-admin-ui/utils/model';

export default class CriteriaTransform extends Transform {
  deserialize(serialized: Record<string, unknown>): ReviewCriteria {
    return new ReviewCriteria(serialized);
  }

  serialize(
    deserialized?: ReviewCriteria,
  ): Record<string, unknown> | undefined {
    return deserialized && deserialized.serialize();
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    criteria: ReviewCriteria;
  } // eslint-disable-line semi
}
