import { attr, belongsTo } from '@ember-data/model';
import type FeeModel from 'smily-admin-ui/models/fee';
import type RentalModel from 'smily-admin-ui/models/rental';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class RentalFeeModel extends BaseModel {
  @attr('boolean') alwaysApplied!: boolean;
  @attr('number') maximumBookable?: number;
  @attr('boolean') public!: boolean;
  @attr('boolean') required!: boolean;
  @attr('date') startDate?: Date;
  @attr('date') endDate?: Date;

  @belongsTo('fee', { async: false, inverse: 'rentalFee' })
  fee!: FeeModel;
  @belongsTo('rental', { async: false, inverse: 'rentalFees' })
  rental!: RentalModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rental-fee': RentalFeeModel;
  }
}
