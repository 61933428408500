import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import CopyButton from 'ember-cli-clipboard/components/copy-button';
import { notifyCopySuccessful } from 'smily-admin-ui/utils/intl';

function getTargetId(context: CopyUrl) {
  return `#${guidFor(context)}`;
}

interface CopyUrlSignature {
  Element: HTMLDivElement;
  Args: {
    url: string;
    inputLabel: string;
    buttonLabel: string;
    buttonClass?: string;
  };
}

export default class CopyUrl extends Component<CopyUrlSignature> {
  get id() {
    return guidFor(this);
  }

  selectAllText({ target }: Event) {
    (target as HTMLInputElement).setSelectionRange(0, -1);
  }

  @action
  copySuccessful() {
    notifyCopySuccessful(this);
  }

  <template>
    <div class='d-flex gap-2' ...attributes>
      <div class='flex-1 form-group smily-form-field filled m-0'>
        <label class='form-label' for='calendar-ical-export'>
          {{@inputLabel}}
        </label>

        <input
          {{on 'click' this.selectAllText}}
          id={{guidFor this}}
          value={{@url}}
          class='form-control'
          type='text'
          readonly
        />
      </div>

      <CopyButton
        @target={{getTargetId this}}
        @onSuccess={{this.copySuccessful}}
        class={{if @buttonClass @buttonClass 'btn btn-secondary'}}
        data-test-copy-button
      >
        {{@buttonLabel}}
      </CopyButton>
    </div>
  </template>
}
