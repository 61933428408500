import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import SmilyLayoutSidebarSubmenuBody from 'ember-smily-base/components/smily-layout/sidebar/submenu/body';
import SmilyLayoutSidebarSubmenuHeader from 'ember-smily-base/components/smily-layout/sidebar/submenu/header';

interface SidebarSubmenusSettingsSignature {
  Args: {
    isTemporarySubmenu?: boolean;
  };
}

export default class SidebarSubmenusSettings extends Component<SidebarSubmenusSettingsSignature> {
  @service private abilities!: AbilitiesService;

  private get routes() {
    let routes = this.abilities.can('access x settings billing route')
      ? ['x-settings.billing']
      : [];

    routes = [
      ...routes,
      'x-settings.general',
      'x-settings.users',
      'x-settings.accounts-users',
      'x-settings.payments',
      'x-settings.contacts',
      'x-settings.fees',
      'x-settings.taxes',
      'x-settings.rental-links',
      'x-settings.rentals-tags',
      'x-settings.bookings-tags',
      'x-settings.sources',
      'x-settings.bookings',
      'x-settings.notifications',
      'x-settings.public-calendars',
      'x-settings.inquiries',
      'x-settings.exports',
    ];

    if (this.abilities.can('access x settings split payments route')) {
      routes.push('x-settings.split-payments');
    }

    return routes;
  }

  <template>
    <SmilyLayoutSidebarSubmenuHeader
      @route='settings'
      @isTemporarySubmenu={{@isTemporarySubmenu}}
    />

    <SmilyLayoutSidebarSubmenuBody @routes={{this.routes}} />
  </template>
}
