import type { TOC } from '@ember/component/template-only';
import StatusText from 'smily-admin-ui/components/status-text';
import type { Status } from 'smily-admin-ui/models/task-management-template';

const TasksTemplateStatus: TOC<{
  Element: HTMLDivElement;
  Args: {
    status: Status;
  };
}> = <template>
  <StatusText
    @modelName='task-management-template'
    @status={{@status}}
    @scope='tasks.template_status_options'
  />
</template>;

export default TasksTemplateStatus;
