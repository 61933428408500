import type { Snapshot } from '@ember-data/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import { isNew } from 'ember-smily-base/utils/serializer';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class TaskManagementAttachmentSerializer extends ApplicationSerializer<'task-management-attachment'> {
  serialize(snapshot: Snapshot, options?: {}) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;

    if (isNew(snapshot, options)) {
      json.data.attributes!['file'] = (
        json.data.attributes!['file'] as { 'signed-id': string }
      )['signed-id'];
    }

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'task-management-attachment': TaskManagementAttachmentSerializer;
  }
}
