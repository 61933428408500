import Service from '@ember/service';

export default class FlutterLogger extends Service {
  log(message: string): void {
    // messageHandler only exists when the app is wrapped by Flutter
    // When it is running as standalone in browser there is nothing to log
    if (window.messageHandler) {
      window.messageHandler.postMessage(message);
    }
  }
}
