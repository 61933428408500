import type { TOC } from '@ember/component/template-only';
import type { PromiseObject } from '@ember-data/model';
import MarkdownToHtml from 'ember-cli-showdown/components/markdown-to-html';
import LoadingState from 'ember-smily-base/components/loading-state';
import ensure from 'ember-smily-base/helpers/ensure';
import type LegalTermModel from 'smily-admin-ui/models/legal-term';

const LegalTerms: TOC<{
  Element: HTMLDivElement;
  Args: {
    legalTerm: PromiseObject<LegalTermModel>;
  };
}> = <template>
  <LoadingState @model={{@legalTerm}} class='h-100'>
    <div class='h-100 overflow-auto'>
      <div ...attributes>
        <MarkdownToHtml
          @markdown={{ensure @legalTerm.content.content}}
          @classNames='legal-terms'
        />
      </div>
    </div>
  </LoadingState>
</template>;

export default LegalTerms;
