import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';

const TasksTaskListEmpty: TOC<{
  Element: HTMLElement;
}> = <template>
  <div class='d-flex justify-content-center'>
    <div class='table-empty d-flex flex-column align-items-center gap-3'>
      <Icon @icon='check-square' @style='far' class='text-36' />

      <div class='d-flex flex-column align-items-center gap-1'>
        <div class='text-16 fw-semibold'>
          {{t 'tasks.task_list.empty_title'}}
        </div>

        <div class='text-center'>
          {{t 'tasks.task_list.empty_description'}}
        </div>
      </div>

      <LinkTo @route='tasks.new' class='btn btn-primary'>
        {{t 'tasks.task_list.empty_action_create'}}
      </LinkTo>
    </div>
  </div>
</template>;

export default TasksTaskListEmpty;
