import Component from '@glimmer/component';
import style from 'ember-style-modifier';
import SourceText from 'smily-admin-ui/components/source-text';
import type BookingModel from 'smily-admin-ui/models/booking';

const EMPTY_CIRCLE_DASHOFFSET = 250;
const FULL_CIRCLE_DASHOFFSET = 187;
const DIFFERENCE = EMPTY_CIRCLE_DASHOFFSET - FULL_CIRCLE_DASHOFFSET;

interface CalendarsStatusCircleSignature {
  Args: {
    booking: BookingModel;
    hideSource?: boolean;
  };
}

export default class CalendarsStatusCircle extends Component<CalendarsStatusCircleSignature> {
  get statusColorClass() {
    const { booking } = this.args;

    if (booking.isFinalPricePaid) {
      return 'success';
    } else if (booking.totalAmount) {
      return 'warning';
    } else {
      return undefined;
    }
  }

  get sourceClass() {
    const source = this.args.booking.sourceName;

    if (source === 'Booking.com') {
      return 'bg-booking-com';
    } else if (source === 'Airbnb') {
      return 'bg-airbnb';
    } else {
      return undefined;
    }
  }

  get strokeDashoffset() {
    const { booking } = this.args;

    if (!booking.totalAmount || booking.isFinalPricePaid) {
      return `${FULL_CIRCLE_DASHOFFSET}`;
    }

    const percentage = booking.paidOfFinalPrice / booking.finalPrice;

    return `${EMPTY_CIRCLE_DASHOFFSET - DIFFERENCE * percentage}`;
  }

  <template>
    <div
      class='calendar-status-circle {{this.statusColorClass}}'
      data-test-status-circle
    >
      {{#unless @hideSource}}
        <SourceText
          @source={{@booking.sourceName}}
          @iconClass='text-white'
          @hideText={{true}}
          class='source-badge justify-content-center {{this.sourceClass}}'
        />
      {{/unless}}

      <svg class='payment-ring' height='24' width='24'>
        <circle
          {{style strokeDashoffset=this.strokeDashoffset}}
          cx='12'
          cy='12'
          r='10'
        />
      </svg>
    </div>
  </template>
}
