import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type IntlService from 'ember-intl/services/intl';
import type SessionService from 'smily-admin-ui/services/session-service';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

const LOCALES_USING_EURO_ONLY = ['fr-fr'];

export default class OnboardingAbility extends Ability<'onboarding'> {
  @service intl!: IntlService;
  @service session!: SessionService;

  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'onboarding');
  }

  get isUsingEuroOnly(): boolean {
    return LOCALES_USING_EURO_ONLY.includes(this.intl.primaryLocale);
  }

  get canDisplayCurrencyBanner(): boolean {
    const { onboarding } = this.session;

    return !!(
      onboarding &&
      !onboarding.smilyProspectBannerHidden &&
      !this.isUsingEuroOnly
    );
  }
}
