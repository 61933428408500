import { dateString } from 'ember-smily-base/utils/date';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { FilterParams } from 'smily-admin-ui/routes/reviews/-base-index';
import ReviewsBaseIndexRoute from 'smily-admin-ui/routes/reviews/-base-index';

export default class ReviewsHostIndexRoute extends ReviewsBaseIndexRoute {
  isHostReview = true;

  model(params: FilterParams & { tab: string; page: number }) {
    const { tab, page } = params;
    const query = {
      ...this.store.generateQuery(
        'host-review',
        tab === 'all' ? 'listDefault' : 'actionable',
      ),
    };

    query.filter = this.applyFilters(query.filter, params);

    if (tab === 'actionable') {
      query.filter.expiresAt = {
        op: 'gt',
        value: dateString(),
      };
    }

    return new InfiniteQuery(this.store, 'host-review', query, { page });
  }
}
