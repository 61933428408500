import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class ClientAbility extends Ability<'client'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'client');
  }

  canEdit = true;
  canRemove = true;
  canCreateClientEmail = true;
  canCreateClientPhone = true;
  canCreateClientAddress = true;
}
