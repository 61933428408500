import { attr, belongsTo } from '@ember-data/model';
import { resourceAction } from 'ember-custom-actions';
import type HostReviewModel from 'smily-admin-ui/models/host-review';
import type ReviewModel from 'smily-admin-ui/models/review';
import { BaseModel } from 'smily-admin-ui/utils/model';

export const CHARACTER_LIMIT = 2000;

export default class ReviewReplyModel extends BaseModel {
  @attr message?: string;
  @attr source?: string;
  @attr('date', { readOnly: true }) createdAt?: Date;
  @attr('date') submittedAt?: Date;

  @belongsTo('review', { async: false, inverse: 'reviewReply' })
  review!: ReviewModel;

  @belongsTo('host-review', { async: false, inverse: 'reviewReply' })
  hostReview!: HostReviewModel;

  suggest = resourceAction<ReviewReplyModel, false>('suggest', {
    method: 'POST',
    pushToStore: false,
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'review-reply': ReviewReplyModel;
  }
}
