import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BsAlert from 'ember-bootstrap/components/bs-alert';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import TopbarAbove from 'ember-smily-base/components/topbar/above';
import ensure from 'ember-smily-base/helpers/ensure';
import formatMoney from 'ember-smily-base/helpers/format-money';
import not from 'ember-truth-helpers/helpers/not';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class AccountSuspensionBanner extends Component {
  @service session!: SessionService;

  get subscription() {
    return this.session.account!.subscription;
  }

  get isDisplayed() {
    const hasExpectedSuspension = !!this.subscription.expectedSuspension?.date;
    const dataIsLoaded =
      !this.hasMorePayments ||
      !!this.subscription.outstandingPayments.firstObject!.invoice;

    return hasExpectedSuspension && dataIsLoaded;
  }

  get hasMorePayments() {
    const numberOfPayments = this.subscription
      .hasMany('outstandingPayments')
      .ids().length;

    return !!numberOfPayments && numberOfPayments > 1;
  }

  get relatedInvoice() {
    if (this.hasMorePayments) {
      return undefined;
    }

    return this.subscription.outstandingPayments.firstObject!.invoice;
  }

  <template>
    {{#if this.isDisplayed}}
      <TopbarAbove>
        <BsAlert
          @type='danger'
          @dismissible={{false}}
          class='mb-0 d-flex justify-content-between align-items-center gap-3'
        >
          <div class='d-flex flex-column gap-1'>
            <div class='fw-semibold'>
              {{#if this.hasMorePayments}}
                {{t
                  'finance.account_suspension_banner.outstanding_payments.multiple.title'
                  count=this.subscription.outstandingPayments.length
                }}
              {{else}}
                {{t
                  'finance.account_suspension_banner.outstanding_payments.single.title'
                }}
              {{/if}}

            </div>

            {{#let (ensure this.relatedInvoice) as |invoice|}}
              <div>
                {{#if this.hasMorePayments}}
                  {{t
                    'finance.account_suspension_banner.outstanding_payments.multiple.p1'
                  }}
                {{else}}
                  {{t
                    'finance.account_suspension_banner.outstanding_payments.single.p1'
                    issueNumber=invoice.issueNumber
                    date=(formatDate (ensure invoice.issuedAt) format='long')
                    amount=(formatMoney invoice.total invoice.currency)
                  }}
                {{/if}}

                <br />

                {{t
                  'finance.account_suspension_banner.outstanding_payments.p2'
                }}
              </div>
            {{/let}}
          </div>

          <div class='d-flex flex-column gap-1'>
            <LinkTo
              @route='x-settings.invoices'
              @disabled={{not this.session.owner}}
              class='btn btn-secondary btn-sm'
            >
              {{t
                'finance.account_suspension_banner.outstanding_payments.button'
              }}
            </LinkTo>

            {{#unless this.session.owner}}
              <div class='text-11 text-center'>
                {{t
                  'finance.account_suspension_banner.outstanding_payments.button_helper'
                }}
              </div>
            {{/unless}}
          </div>
        </BsAlert>
      </TopbarAbove>
    {{/if}}
  </template>
}
