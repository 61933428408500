import { differenceInSeconds } from 'date-fns';
import type { SessionMeta } from 'ember-simple-auth/services/session';
import CookieSessionStore from 'ember-simple-auth/session-stores/cookie';

export interface TimestampedSessionMeta extends SessionMeta {
  persisted_at: number;
}

/**
  Backend provides expiration time in remaining seconds, so we need to store the
  time of persistence and adjust the expiration time on restoration to avoid
  trying to make a request with an expired token.
*/
export default class ApplicationSessionStore extends CookieSessionStore {
  persist(data: SessionMeta) {
    const persistedAt = Number(new Date());
    return super.persist({ ...data, persisted_at: persistedAt });
  }

  async restore() {
    const data = (await super.restore()) as TimestampedSessionMeta;

    if (!data?.authenticated) {
      return {};
    }

    if (!data?.persisted_at || !data?.authenticated.authorizationToken) {
      return data;
    }

    const secondsSincePersisting = differenceInSeconds(
      new Date(),
      data.persisted_at,
    );
    const adjustedExpiration = Math.max(
      0,
      data.authenticated.authorizationToken.info.expires_in_seconds -
        secondsSincePersisting,
    );

    data.authenticated.authorizationToken.info.expires_in_seconds =
      adjustedExpiration;

    return data;
  }
}
