import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { QueryParam } from 'smily-admin-ui/controllers/rentals/index';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class RentalsIndexRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;
  @service store!: StoreService;

  checkAbilities = true;
  requiredFeatureAbility = 'access rentals feature';

  queryParams = {
    page: { refreshModel: true },
    name: { refreshModel: true },
  };

  model(params: Record<QueryParam, string> & { page: number }) {
    const { page } = params;

    return new InfiniteQuery(
      this.store,
      'rental',
      this.store.generateQuery('rental', 'listDefault'),
      { page },
    );
  }

  featureNotAccessible() {
    this.coreLinks.redirect('rentals');
  }
}
