import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class ReviewAbility extends Ability<'review'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'review');
  }

  get canReply(): boolean {
    // TODO check with Product what the condition is
    return this.model.booking.sourceName === 'Booking.com';
  }
}
