import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type CurrencyModel from 'smily-admin-ui/models/currency';

export default abstract class DiscountsCurrencyRoute extends Route {
  @service store!: StoreService;

  currencies: CurrencyModel[] = [];

  async loadCurrencies(): Promise<CurrencyModel[]> {
    if (!this.currencies.length) {
      const currencies = await this.store.queryRecords('currency', {
        used_in_rentals: true,
      });

      this.currencies = currencies.slice();
    }

    return this.currencies;
  }
}
