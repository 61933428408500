import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type MediaService from 'ember-responsive';
import IconText from 'ember-smily-base/components/icon-text';
import LoadingButton from 'ember-smily-base/components/loading-button';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import SelectInfinite from 'ember-smily-base/components/select/infinite';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import { isMobile } from 'ember-smily-base/utils/application';
import type TasksTemplateModel from 'smily-admin-ui/models/task-management-template';

export default class TasksCreateTask extends Component {
  @service media!: MediaService;
  @service router!: RouterService;

  @action
  createFromScratch() {
    this.router.transitionTo('tasks.new');
  }

  @action
  createFromTemplate(template?: TasksTemplateModel) {
    this.router.transitionTo('tasks.new-from-template', template!.id);
  }

  <template>
    <ModalDialog @type='bottom' as |dialog|>
      <dialog.toggle class='btn btn-primary'>
        <div class='d-flex align-items-center gap-2'>
          <IconText
            @icon='plus'
            @text={{t
              (if
                (isMobile this.media)
                'common.create'
                'tasks.task_list.create_a_new_task'
              )
            }}
            @iconStyle='far'
          />
        </div>
      </dialog.toggle>

      <dialog.modal as |modal|>
        <modal.header>
          <h4>
            {{t 'tasks.task_list.create_a_new_task'}}
          </h4>
        </modal.header>

        <modal.body>
          <div class='d-flex flex-column gap-3'>
            <div>
              {{t 'tasks.task_list.create_task_description'}}
            </div>

            <div class='d-flex flex-column text-center'>
              <LoadingButton
                @action={{this.createFromScratch}}
                @label={{t 'common.create_from_scratch'}}
                class='btn btn-primary'
              />

              <div class='d-flex align-items-center'>
                <hr class='w-100' />

                <div class='mx-2'>
                  {{t 'common.or'}}
                </div>

                <hr class='w-100' />
              </div>

              <div>
                <SelectInfinite
                  @modelName='task-management-template'
                  @query={{generateQuery 'task-management-template' 'nameOnly'}}
                  @searchField='name'
                  @searchEnabled={{true}}
                  @placeholder='Prefill from existing template'
                  @triggerClass='form-control'
                  @onChange={{this.createFromTemplate}}
                  as |item|
                >
                  {{item.name}}
                </SelectInfinite>
              </div>
            </div>
          </div>
        </modal.body>
      </dialog.modal>
    </ModalDialog>
  </template>
}
