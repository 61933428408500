import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class BankAccountAbility extends Ability<'bank-account'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'bank-account');
  }
}
