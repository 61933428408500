import type { Transition } from 'ember-smily-base/utils/routing';
import { BaseRoute, disableRouteForMobile } from 'smily-admin-ui/utils/routing';

export default class InquiriesRoute extends BaseRoute {
  checkAbilities = true;
  requiredRouteAbility = 'access inquiries route';

  beforeModel(transition: Transition) {
    if (disableRouteForMobile(this, transition)) {
      return;
    }

    super.beforeModel(transition);
  }
}
