import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type { Changeset, SyncHasMany } from 'ember-smily-base/utils/model';
import type ClientAddressModel from 'smily-admin-ui/models/client-address';
import type ClientEmailModel from 'smily-admin-ui/models/client-email';
import type ClientPhoneModel from 'smily-admin-ui/models/client-phone';

interface ClientsClientItemSignature {
  Element: HTMLDivElement;
  Args: {
    changeset: Changeset;
    models: SyncHasMany<
      ClientAddressModel | ClientEmailModel | ClientPhoneModel
    >;
    isNew?: boolean;
    disablePrompt: boolean;
    destroyItem: () => void;
  };
}

export default class ClientsClientItem extends Component<ClientsClientItemSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;

  declare itemName: 'address' | 'email' | 'phone';

  get needsConfirmationToDelete(): boolean {
    const isDirty = !!this.args.changeset.changes.find(
      ({ key }) => key !== 'primary',
    );
    const isNew = this.args.changeset.get('isNew') as boolean;

    return !isNew || isDirty;
  }

  @action
  deleteItem() {
    const primary = this.args.changeset.get('primary');

    this.args.destroyItem();

    if (!this.args.isNew && primary) {
      this.args.models.reload();
    }
  }

  @action
  async setAsPrimary(): Promise<void> {
    const previousPrimary = this.args.models.findBy('primary', true);

    if (previousPrimary) {
      previousPrimary.primary = false;
    }

    this.args.changeset.set('primary', true);
    await this.save.perform();

    previousPrimary?.reload?.();
  }

  save = task({ drop: true }, async () => {
    await this.args.changeset.save();

    this.notify.success(this.intl.t(`guests.${this.itemName}_updated`));
  });
}
