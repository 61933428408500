import type { Model } from 'ember-smily-base/utils/store';

export default function euro<Target extends Model>(centsField: keyof Target) {
  return function (_target: Target, _propertyKey: string) {
    return {
      get(this: Target) {
        const cents = this[centsField] as number | undefined;
        return cents && cents / 100;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  };
}
