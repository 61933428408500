import { attr } from '@ember-data/model';
import { BaseModel } from 'ember-smily-base/utils/model';
import type { AbilityActions } from 'smily-admin-ui/utils/ability';

export default class AbilityModel extends BaseModel {
  @attr('object', { readOnly: true }) actions?: AbilityActions;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    ability: AbilityModel;
  }
}
