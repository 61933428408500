import { attr, belongsTo } from '@ember-data/model';
import type ReviewsHostAutomationModel from 'smily-admin-ui/models/reviews-host-automation';
import { BaseModel } from 'smily-admin-ui/utils/model';

export const CHARACTER_LIMIT = 1000;

export default class ReviewsHostAutomationCommentModel extends BaseModel {
  @attr content?: string;

  @belongsTo('reviews-host-automation', { async: false, inverse: 'comments' })
  automation!: ReviewsHostAutomationModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reviews-host-automation-comment': ReviewsHostAutomationCommentModel;
  }
}
