import type { TOC } from '@ember/component/template-only';
import RentalsPrimaryPhoto from 'smily-admin-ui/components/rentals/primary-photo';
import { getRental } from 'smily-admin-ui/components/tasks/task/card';
import type TaskManagementTaskModel from 'smily-admin-ui/models/task-management-task';
import { isBookingSource } from 'smily-admin-ui/utils/tasks';

const TasksTaskListItemEventSource: TOC<{
  Element: HTMLElement;
  Args: {
    task: TaskManagementTaskModel;
  };
}> = <template>
  {{#let (getRental @task.eventSource) as |rental|}}
    {{#if rental}}
      <div class='d-flex align-items-center gap-2 overflow-hidden'>
        <RentalsPrimaryPhoto
          @rental={{rental}}
          @small={{true}}
          class='rounded'
        />

        <div class='flex-1 overflow-hidden'>
          <div class='fw-semibold text-truncate'>
            {{rental.name}}
          </div>

          <div class='text-truncate'>
            {{rental.fullAddress}}
          </div>

          {{#if (isBookingSource @task.eventSource)}}
            <div>
              {{@task.eventSource.reference}}
            </div>
          {{/if}}
        </div>
      </div>
    {{/if}}
  {{/let}}
</template>;

export default TasksTaskListItemEventSource;
