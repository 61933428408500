import { later } from '@ember/runloop';
import EmberError from '@ember/error';
import { Promise } from 'rsvp';

class TimeoutError extends EmberError {
  declare code: number;
  declare meta: Record<string, unknown>;
  declare isTimeoutError: boolean;

  constructor(message: string, meta: Record<string, unknown>) {
    super(message);

    this.code = 408;
    this.meta = meta;
    this.name = 'TimeoutError';
    this.isTimeoutError = true;
  }
}

/**
 * Throws TimeoutError if promise will take more than specified
 * @param  {Promise} promise
 * @param  {Object} [meta={}] meta information for error
 * @param  {Number} [interval=10000] time after the promise will be rejected
 * @return {Promise}                 promise result
 */
export default function promiseTimeout(
  promise: Promise<void>,
  meta: Record<string, unknown> = {},
  interval = 10000,
): Promise<void> {
  const timeoutError = new TimeoutError(
    `Promise timeout! It took more than ${interval}ms to resolve the promise.`,
    meta,
  );

  return new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    later(() => reject(timeoutError), interval);
  });
}
