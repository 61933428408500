import { fn } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type MediaService from 'ember-responsive';
import Badge from 'ember-smily-base/components/badge';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import breadcrumb from 'ember-smily-base/helpers/breadcrumb';
import ensure from 'ember-smily-base/helpers/ensure';
import { isMobile } from 'ember-smily-base/utils/application';
import { isTesting } from 'ember-smily-base/utils/config';
import { loadScript, removeElement } from 'ember-smily-base/utils/dom';
import and from 'ember-truth-helpers/helpers/and';
import not from 'ember-truth-helpers/helpers/not';
import FeatureDiscoveryFirstBanner from 'smily-admin-ui/components/feature-discovery/first-banner';
import FeatureDiscoveryFirstContent from 'smily-admin-ui/components/feature-discovery/first-content';
import FeatureDiscoveryLastBanner from 'smily-admin-ui/components/feature-discovery/last-banner';
import { getHeadwayUrl } from 'smily-admin-ui/components/sidebar/footer/headway';
import type CacheService from 'smily-admin-ui/services/cache';
import type { FeatureDiscoveryConfigEntry } from 'smily-admin-ui/utils/config';
import { featureDiscoveryConfig } from 'smily-admin-ui/utils/config';

export default class FeatureDiscovery extends Component {
  @service private abilities!: AbilitiesService;
  @service private cache!: CacheService;
  @service private intl!: IntlService;
  @service private media!: MediaService;

  private features!: FeatureDiscoveryConfigEntry[];

  @tracked private page = 0;

  private get isFirstPage() {
    return this.page === 0;
  }

  private get isLastPage() {
    return this.page === this.features.length + 1;
  }

  private get currentPage() {
    return this.features.at(this.page - 1)!;
  }

  private get badgeLabel() {
    if (this.isFirstPage || this.isLastPage) {
      return this.intl.t('user.feature_discovery.our_new_releases');
    } else {
      return this.intl.t('user.feature_discovery.introducing');
    }
  }

  private get icon() {
    if (this.isFirstPage) {
      return 'rocket';
    } else if (this.isLastPage) {
      return 'bullhorn';
    } else {
      return this.currentPage.icon;
    }
  }

  private get iconStyle() {
    return this.currentPage?.iconStyle ?? 'far';
  }

  private get title() {
    if (this.isFirstPage) {
      return this.intl.t('user.feature_discovery.new_releases_on_smily');
    } else if (this.isLastPage) {
      return this.intl.t('user.feature_discovery.and_thats_it');
    } else {
      return this.intl.t(
        `user.feature_discovery.features.${this.currentPage.name}.name`,
      );
    }
  }

  private get pagination() {
    return `${this.page}/${this.features.length}`;
  }

  private get isMobile() {
    return isMobile(this.media);
  }

  private get BannerComponent() {
    if (this.isFirstPage) {
      return FeatureDiscoveryFirstBanner;
    } else if (this.isLastPage) {
      return FeatureDiscoveryLastBanner;
    } else {
      return this.currentPage.banner;
    }
  }

  @action
  private async setup(toggleModal: () => void) {
    if (isTesting(this)) {
      return;
    }

    this.features = this.cache.newFeatureSessionCounts
      .filter(([_, sessionCount]) => sessionCount < 3)
      .map(([name]) => featureDiscoveryConfig.find((f) => f.name === name)!)
      .filter(
        (entry) =>
          !entry.route || this.abilities.can(`access ${entry.route} route`),
      );

    if (!this.features.length) {
      return;
    }

    await new Promise((resolve) =>
      loadScript('https://player.vimeo.com/api/player.js', resolve),
    );
    toggleModal();
  }

  @action
  private previousPage() {
    this.page -= 1;
  }

  @action
  private nextPage() {
    this.page += 1;
  }

  <template>
    <ModalDialog @type='bottom' as |dialog|>
      <div
        {{didInsert removeElement}}
        {{didInsert (fn this.setup dialog.toggleModal)}}
      >
      </div>

      <dialog.modal
        class='feature-discovery-modal {{if this.isMobile "mobile"}}'
        as |modal|
      >
        {{#if this.isMobile}}
          <this.BannerComponent />
        {{/if}}

        <modal.body class='p-0 d-flex'>
          {{#unless this.isMobile}}
            <this.BannerComponent />
          {{/unless}}

          <div
            class='feature-discovery-content p-3 d-flex flex-column justify-content-between'
          >
            <div class='d-flex flex-column align-items-start gap-3'>
              <div class='d-flex flex-column align-items-start gap-2'>
                <Badge
                  @label={{this.badgeLabel}}
                  @color='attention'
                  @skeleton={{true}}
                />

                <h4 class='d-flex gap-1'>
                  <Icon @icon={{this.icon}} @style={{this.iconStyle}} />

                  {{this.title}}
                </h4>

                {{#if this.isFirstPage}}
                  <FeatureDiscoveryFirstContent @features={{this.features}} />
                {{else if this.isLastPage}}
                  <div>
                    {{t
                      'user.feature_discovery.whats_new_description'
                      title=(t 'general.whats_new')
                    }}
                  </div>
                {{else}}
                  <this.currentPage.content />
                {{/if}}
              </div>

              {{#if this.isLastPage}}
                <Link
                  href={{getHeadwayUrl this}}
                  target='_blank'
                  class='btn btn-secondary btn-sm'
                >
                  <IconText
                    @icon='arrow-right'
                    @iconStyle='far'
                    @text={{t
                      'user.feature_discovery.go_to'
                      feature=(t 'general.whats_new')
                    }}
                    class='flex-row-reverse'
                  />
                </Link>
              {{else if (and this.currentPage.route (not this.isFirstPage))}}
                <LinkTo
                  @route={{ensure this.currentPage.route}}
                  class='btn btn-secondary btn-sm'
                >
                  <IconText
                    @icon='arrow-right'
                    @iconStyle='far'
                    @text={{t
                      'user.feature_discovery.go_to'
                      feature=(breadcrumb (ensure this.currentPage.route))
                    }}
                    class='flex-row-reverse'
                  />
                </LinkTo>
              {{/if}}
            </div>

            <div class='d-flex flex-column gap-3'>
              <hr />

              {{#if this.isFirstPage}}
                <Link
                  @action={{this.nextPage}}
                  class='btn btn-primary d-flex justify-content-center'
                >
                  <IconText
                    @icon='arrow-right'
                    @iconStyle='far'
                    @text={{t 'user.feature_discovery.see_whats_new'}}
                    class='flex-row-reverse'
                  />
                </Link>
              {{else if this.isLastPage}}
                <div class='d-flex gap-3'>
                  <Link
                    @action={{this.previousPage}}
                    class='flex-1 btn btn-secondary'
                  >
                    {{t 'common.back'}}
                  </Link>

                  <Link
                    @action={{dialog.toggleModal}}
                    class='flex-1 btn btn-primary'
                  >
                    {{t 'common.close'}}
                  </Link>
                </div>
              {{else}}
                <div class='d-flex'>
                  <Link
                    @action={{this.previousPage}}
                    class='flex-1 btn btn-secondary'
                  >
                    {{t 'common.back'}}
                  </Link>

                  <div
                    class='flex-1 d-flex align-items-center justify-content-center'
                  >
                    {{this.pagination}}
                  </div>

                  <Link
                    @action={{this.nextPage}}
                    class='flex-1 btn btn-primary'
                  >
                    {{t 'common.next'}}
                  </Link>
                </div>
              {{/if}}
            </div>
          </div>

        </modal.body>
      </dialog.modal>
    </ModalDialog>
  </template>
}
