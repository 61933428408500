import { attr, belongsTo } from '@ember-data/model';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import type ClientModel from 'smily-admin-ui/models/client';
import { BaseModel, generateFullAddress } from 'smily-admin-ui/utils/model';

export default class ClientAddressModel extends BaseModel {
  @attr('string', { defaultValue: '' }) label?: string;
  @attr('boolean') primary?: boolean;

  @attr('string', { defaultValue: '' }) address1?: string;
  @attr('string', { defaultValue: '' }) address2?: string;
  @attr('string', { defaultValue: '' }) city?: string;
  @attr('string', { defaultValue: '' }) state?: string;
  @attr('string', { defaultValue: '' }) zip?: string;
  @attr('string', { defaultValue: '' }) countryCode?: CountryCode;

  @belongsTo('client', { async: false, inverse: 'addresses' })
  client!: ClientModel;

  get fullAddress() {
    return generateFullAddress(
      this.address1,
      this.address2,
      this.city,
      this.zip,
      this.state,
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'client-address': ClientAddressModel;
  }
}
