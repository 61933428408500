import { service } from '@ember/service';
import type { PromiseObject } from '@ember-data/model';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import type ClientModel from 'smily-admin-ui/models/client';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class ClientsClientRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;
  @service store!: StoreService;

  checkAbilities = true;
  requiredFeatureAbility = 'access guests feature';

  get title() {
    return (
      this.currentModel?.fullName || this.intl.t('internals.models.client')
    );
  }

  model({ client_id: id }: { client_id: string }): PromiseObject<ClientModel> {
    return this.store.findRecord('client', id, {
      ...this.store.generateQuery('client'),
      reload: true,
    });
  }

  featureNotAccessible(transition: Transition): void {
    this.coreLinks.redirect('guests', transition.to.parent!.params.client_id);
  }
}
