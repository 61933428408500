import type { TOC } from '@ember/component/template-only';
import { fn, hash } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import type { BsPopoverSignature } from 'ember-bootstrap/components/bs-popover';
import BsPopover from 'ember-bootstrap/components/bs-popover';
import call from 'ember-composable-helpers/helpers/call';
import { focusTrap } from 'ember-focus-trap';

function deactivateWhenNotClickingTrigger(
  triggerId: string | undefined,
  event: MouseEvent | TouchEvent,
) {
  if (!triggerId) {
    return false;
  }

  const target = event.target as HTMLElement;
  const trigger = target.closest(`#${triggerId}`);

  return !trigger;
}

function wrapperClass(containerClass?: string, disableFocus?: boolean) {
  if (containerClass && !disableFocus) {
    return containerClass;
  }

  return disableFocus ? '' : 'd-flex flex-column gap-2';
}

const PopoverFocused: TOC<{
  Element: HTMLDivElement;
  Args: {
    id?: string;
    disableFocus?: boolean;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    containerClass?: string;
  };
  Blocks: BsPopoverSignature['Blocks'];
}> = <template>
  <BsPopover
    @placement={{if @placement @placement 'top'}}
    ...attributes
    as |popover|
  >
    <div
      {{focusTrap
        focusTrapOptions=(hash
          onDeactivate=popover.close
          clickOutsideDeactivates=(fn deactivateWhenNotClickingTrigger @id)
        )
      }}
      class={{htmlSafe (call (fn wrapperClass @containerClass @disableFocus))}}
    >
      {{#if @disableFocus}}
        {{! give focus-trap to focus something }}
        <div
          tabindex='0'
          class={{if
            @containerClass
            @containerClass
            'd-flex flex-column gap-2'
          }}
        >
          {{yield popover}}
        </div>
      {{else}}
        {{yield popover}}
      {{/if}}
    </div>
  </BsPopover>
</template>;

export default PopoverFocused;
