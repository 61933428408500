import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import DiscountsForm from 'smily-admin-ui/components/discounts/form';
import type DiscountsDiscountRoute from 'smily-admin-ui/routes/discounts/discount';

const TemplatesDiscountsDiscount: TOC<{
  Element: HTMLDivElement;
  Args: {
    model: ModelFor<DiscountsDiscountRoute>;
  };
}> = <template>
  <DiscountsForm
    @discountCode={{@model.discountCode}}
    @currencies={{@model.currencies}}
  />
</template>;

export default RouteTemplate(TemplatesDiscountsDiscount);
