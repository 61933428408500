import type { TOC } from '@ember/component/template-only';
import { array } from '@ember/helper';
import SmilyLayoutSidebarSubmenuBody from 'ember-smily-base/components/smily-layout/sidebar/submenu/body';
import SmilyLayoutSidebarSubmenuHeader from 'ember-smily-base/components/smily-layout/sidebar/submenu/header';

const SidebarSubmenusCalendar: TOC<{
  Args: {
    isTemporarySubmenu?: boolean;
  };
}> = <template>
  <SmilyLayoutSidebarSubmenuHeader
    @route='calendar'
    @isTemporarySubmenu={{@isTemporarySubmenu}}
  />

  <SmilyLayoutSidebarSubmenuBody
    @routes={{array 'calendar.legacy' 'calendar.planning' 'calendar.month'}}
  />
</template>;

export default SidebarSubmenusCalendar;
