import type { Changeset, SyncHasMany } from 'ember-smily-base/utils/model';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';

export default async function removeAttachment(
  messageChangeset: Changeset,
  attachment: InboxAttachmentModel,
) {
  await attachment.destroyRecord();

  messageChangeset.set(
    'attachments',
    (messageChangeset.attachments as SyncHasMany<InboxAttachmentModel>).filter(
      (a) => a !== attachment,
    ),
  );
}
