import { fn } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import CopyButton from 'ember-cli-clipboard/components/copy-button';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import List from 'ember-smily-base/components/list';
import MultilineText from 'ember-smily-base/components/multiline-text';
import { asString } from 'ember-smily-base/utils/template';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';
import BookingsClientCounter from 'smily-admin-ui/components/bookings/client-counter';
import { setupBackgroundImage } from 'smily-admin-ui/components/calendars/booking-dialog';
import type BookingModel from 'smily-admin-ui/models/booking';
import { notifyCopySuccessful } from 'smily-admin-ui/utils/intl';

interface TasksBookingCardSignature {
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}

export default class TasksBookingCard extends Component<TasksBookingCardSignature> {
  @tracked isCollapsed = false;

  @action
  copySuccessful() {
    notifyCopySuccessful(this);
  }

  <template>
    <Card class='booking-card-modern' ...attributes>
      <div
        {{didInsert (fn setupBackgroundImage @booking)}}
        class='card-header booking-card-header background-cover'
      >
        <div
          class='position-relative d-flex flex-column gap-2 text-white z-index-1'
        >
          <div class='d-flex flex-column gap-1'>
            <div class='fw-semibold'>
              {{@booking.rental.name}}
            </div>

            <div>
              {{@booking.rental.fullAddress}}
            </div>
          </div>

          <div class='d-flex gap-2'>
            <IconText
              @icon='user-friends'
              @iconStyle='far'
              @text={{asString
                (or @booking.rental.sleepsMax @booking.rental.sleeps)
              }}
              @gap={{1}}
            />

            <IconText
              @icon='bath'
              @iconStyle='far'
              @text={{asString @booking.rental.bathroomsCount}}
              @gap={{1}}
            />

            <IconText
              @icon='ruler-combined'
              @iconStyle='far'
              @text={{@booking.rental.area}}
              @gap={{1}}
            />

            <IconText
              @icon='bed-alt'
              @iconStyle='far'
              @text={{asString @booking.rental.bedroomsCount}}
              @gap={{1}}
            />
          </div>
        </div>
      </div>

      <div class='card-body p-0'>
        <List @justified={{true}} @style='light' as |Item|>
          <Item>
            <div class='fw-semibold'>
              {{t 'bookings.booking_reference'}}
            </div>

            <div>
              <CopyButton
                @text={{@booking.reference}}
                @onSuccess={{this.copySuccessful}}
                class='btn btn-link btn-sm link-unstyled p-0'
              >
                {{@booking.reference}}

                <Icon @icon='copy' @style='far' />
              </CopyButton>
            </div>
          </Item>

          <Item class='align-items-start gap-2'>
            <div>
              <Icon @icon='calendar' @style='far' @fixedWidth={{true}} />
            </div>

            <div class='flex-1 booking-card-checkin-checkout'>
              <div>
                <div>
                  {{#if @booking.isUnavailable}}
                    {{t 'internals.fields.startDate'}}
                  {{else}}
                    {{t 'bookings.check_in'}}
                  {{/if}}
                </div>

                <div class='text-14 fw-semibold'>
                  {{formatDate @booking.startAt.date format='longWithWeek'}}
                </div>

                {{#unless @booking.isUnavailable}}
                  <div>
                    {{t 'bookings.after_time' time=@booking.displayedCheckin}}
                  </div>
                {{/unless}}
              </div>

              <div>
                <div class='text-end'>
                  {{#if @booking.isUnavailable}}
                    {{t 'internals.fields.endDate'}}
                  {{else}}
                    {{t 'bookings.check_out'}}
                  {{/if}}
                </div>

                <div class='text-14 fw-semibold text-end'>
                  {{formatDate @booking.endAt.date format='longWithWeek'}}
                </div>

                {{#unless @booking.isUnavailable}}
                  <div class='text-end'>
                    {{t 'bookings.before_time' time=@booking.displayedCheckout}}
                  </div>
                {{/unless}}
              </div>
            </div>
          </Item>

          <Item>
            <Link
              @action={{fn (mut this.isCollapsed) (not this.isCollapsed)}}
              class='btn btn-link ps-0 py-0 smily-select-like with-smily-select-icon'
              aria-expanded={{if this.isCollapsed 'true'}}
            >
              <div>
                {{t 'tasks.task_form.more_rental_and_booking_details'}}
              </div>

              <div class='smily-select-icon'>
                <Icon @icon='chevron-down' />
              </div>
            </Link>
          </Item>
        </List>

        <BsCollapse @collapsed={{not this.isCollapsed}}>
          <List @style='light' as |Item|>
            <Item class='d-flex gap-2'>
              <div>
                <Icon @icon='home-lg-alt' @style='far' @fixedWidth={{true}} />
              </div>

              <div class='flex-1 d-flex flex-column gap-2'>
                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'internals.fields.bedroomsCount'}}
                  </div>

                  <div>
                    {{@booking.rental.bedroomsCount}}
                  </div>
                </div>

                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'internals.fields.bathroomsCount'}}
                  </div>

                  <div>
                    {{@booking.rental.bathroomsCount}}
                  </div>
                </div>
              </div>
            </Item>

            <Item class='d-flex gap-2'>
              <div>
                <Icon @icon='book-open' @style='far' @fixedWidth={{true}} />
              </div>

              <div class='flex-1 d-flex flex-column gap-2'>
                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'common.nights'}}
                  </div>

                  <div>
                    {{@booking.nights}}
                  </div>
                </div>

                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'common.people'}}
                  </div>

                  <BookingsClientCounter @booking={{@booking}} />
                </div>

                <div class='d-flex justify-content-between gap-3'>
                  <div>
                    {{t 'internals.fields.clientFullname'}}
                  </div>

                  <div>
                    {{@booking.clientFullname}}
                  </div>
                </div>
              </div>
            </Item>

            {{#if @booking.notes}}
              <Item class='d-flex gap-2'>
                <div>
                  <Icon @icon='pencil' @style='far' @fixedWidth={{true}} />
                </div>

                <MultilineText @text={{@booking.notes}} class='flex-1' />
              </Item>
            {{/if}}
          </List>
        </BsCollapse>
      </div>
    </Card>
  </template>
}
