import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import List from 'ember-smily-base/components/list';
import formatMoney from 'ember-smily-base/helpers/format-money';
import BookingsBookingStatus from 'smily-admin-ui/components/bookings/booking-status';
import BookingsClientCounter from 'smily-admin-ui/components/bookings/client-counter';
import BookingsDates from 'smily-admin-ui/components/bookings/dates';
import SourceText from 'smily-admin-ui/components/source-text';
import type BookingPayoutModel from 'smily-admin-ui/models/booking-payout';
import {
  getAmountForComponent,
  getComponent,
  getCurrencyForComponent,
} from 'smily-admin-ui/models/booking-payout';

const FinanceBookingPayoutItem: TOC<{
  Element: HTMLUListElement;
  Args: {
    bookingPayout: BookingPayoutModel;
  };
}> = <template>
  <List
    @small={{true}}
    @justified={{true}}
    @sticky={{true}}
    role='listbox'
    ...attributes
    as |Item|
  >
    <Item>
      <div class='w-100'>
        <div class='fw-semibold'>
          {{@bookingPayout.booking.rentalName}}
        </div>

        <BookingsDates @booking={{@bookingPayout.booking}} />
      </div>
    </Item>

    <Item>
      <BookingsBookingStatus @booking={{@bookingPayout.booking}} />

      <SourceText @source={{@bookingPayout.booking.sourceName}} />
    </Item>

    <Item>
      <div>
        {{@bookingPayout.booking.clientFullname}}
      </div>

      <BookingsClientCounter @booking={{@bookingPayout.booking}} />
    </Item>

    <Item>
      <div>
        {{t 'finance.price_builder.total_price'}}
      </div>

      <div>
        {{formatMoney
          @bookingPayout.booking.finalPrice
          @bookingPayout.booking.currency
        }}
      </div>
    </Item>

    <Item>
      <div>
        {{t 'finance.payment_amount'}}
      </div>

      <div>
        {{formatMoney
          @bookingPayout.paymentsBalance
          @bookingPayout.paymentsBalanceCurrency
        }}
      </div>
    </Item>

    {{#if (getComponent 'smily_gateway_fees' @bookingPayout)}}
      <Item>
        <div>
          {{t 'finance.channel_fee' channel='SmilyPay'}}
        </div>

        <div>
          {{formatMoney
            (getAmountForComponent 'smily_gateway_fees' @bookingPayout)
            (getCurrencyForComponent 'smily_gateway_fees' @bookingPayout)
          }}
        </div>
      </Item>
    {{/if}}

    {{#if (getComponent 'owner_payout' @bookingPayout)}}
      <Item>
        <div>
          {{t 'finance.owner_payout'}}
        </div>

        <div>
          {{formatMoney
            (getAmountForComponent 'owner_payout' @bookingPayout)
            (getCurrencyForComponent 'owner_payout' @bookingPayout)
          }}
        </div>
      </Item>
    {{/if}}

    {{#if (getComponent 'property_manager_deductions' @bookingPayout)}}
      <Item>
        <div>
          {{t 'finance.property_manager_deductions'}}
        </div>

        <div>
          {{formatMoney
            (getAmountForComponent 'property_manager_deductions' @bookingPayout)
            (getCurrencyForComponent
              'property_manager_deductions' @bookingPayout
            )
          }}
        </div>
      </Item>
    {{/if}}

    <Item class='fw-semibold'>
      <div>
        {{t 'finance.payout'}}
      </div>

      <div>
        {{formatMoney
          (getAmountForComponent 'payout' @bookingPayout)
          (getCurrencyForComponent 'payout' @bookingPayout)
        }}
      </div>
    </Item>
  </List>
</template>;
export default FinanceBookingPayoutItem;
