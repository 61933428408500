import {
  hide as hideChat,
  show as showChat,
} from 'smily-admin-ui/utils/hubspot';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class OnboardingRoute extends BaseRoute {
  checkAbilities = true;
  requiredRouteAbility = 'access onboarding route';

  get title() {
    return this.intl.t('onboarding.breadcrumb');
  }

  afterModel(): void {
    showChat();
  }

  deactivate(): void {
    hideChat();
  }
}
