import { service } from '@ember/service';
import type MediaService from 'ember-responsive';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import type { ModelName } from 'ember-smily-base/utils/store';
import { all } from 'rsvp';
import type { InboxSidebarSubmenuCategory } from 'smily-admin-ui/components/inbox/sidebar-submenu-item';
import type { QueryParam } from 'smily-admin-ui/controllers/inbox';
import { FILTER_PARAMS } from 'smily-admin-ui/controllers/inbox';
import type CacheService from 'smily-admin-ui/services/cache';
import {
  buildQuery,
  ConversationInfiniteQuery,
} from 'smily-admin-ui/utils/inbox';
import { FeatureIndexRoute } from 'smily-admin-ui/utils/routing';

export const BASE_CATEGORIES: InboxSidebarSubmenuCategory[] = [
  {
    key: 'open',
    query: {
      status: 'open',
      bookingLabel: undefined,
    },
    count: 'inboxOpenCounter',
  },
  {
    key: 'inquiries',
    query: {
      status: 'open',
      bookingLabel: 'inquiries',
    },
    count: 'inboxInquiriesCounter',
  },
  {
    key: 'reserved',
    query: {
      status: 'open',
      bookingLabel: 'reserved',
    },
    count: 'inboxReservedCounter',
  },
  {
    key: 'checkin_today',
    query: {
      status: 'open',
      bookingLabel: 'checkin_today',
    },
    count: 'inboxCheckinTodayCounter',
  },
  {
    key: 'at_property',
    query: {
      status: 'open',
      bookingLabel: 'at_property',
    },
    count: 'inboxAtPropertyCounter',
  },
  {
    key: 'checkout_today',
    query: {
      status: 'open',
      bookingLabel: 'checkout_today',
    },
    count: 'inboxCheckoutTodayCounter',
  },
  {
    key: 'post_stay',
    query: {
      status: 'open',
      bookingLabel: 'post_stay',
    },
    count: 'inboxPostStayCounter',
  },
  {
    key: 'canceled',
    query: {
      status: 'open',
      bookingLabel: 'canceled',
    },
    count: 'inboxCanceledCounter',
  },
];
export const STATUS_CATEGORIES: InboxSidebarSubmenuCategory[] = [
  {
    key: 'archived',
    query: {
      status: 'archived',
      bookingLabel: undefined,
    },
    count: 'inboxArchivedCounter',
  },
  {
    key: 'spam',
    query: {
      status: 'spam',
      bookingLabel: undefined,
    },
    count: 'inboxSpamCounter',
  },
];
const ALL_CATEGORIES = [...BASE_CATEGORIES, ...STATUS_CATEGORIES];

export default class InboxRoute extends FeatureIndexRoute {
  @service cache!: CacheService;
  @service media!: MediaService;
  @service store!: StoreService;

  declare currentModel: ConversationInfiniteQuery;

  queryParams = {
    page: { refreshModel: true },
    status: { refreshModel: true },
    read: { refreshModel: true },
    assignee: { refreshModel: true },
    client: { refreshModel: true },
    stage: { refreshModel: true },
    source: { refreshModel: true },
    bookingStatus: { refreshModel: true },
    bookingLabel: { refreshModel: true },
    rental: { refreshModel: true },
    bookingTag: { refreshModel: true },
    rentalTag: { refreshModel: true },
    priority: { refreshModel: true },
    severity: { refreshModel: true },
    sort: { refreshModel: true },
  };

  feature = 'inbox';
  hasRailsCounterpart = false;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    const filterModels = [
      {
        modelName: 'client' as ModelName,
        ids: transition.to.queryParams.client,
        queryType: 'nameAndEmail',
      },
      {
        modelName: 'source' as ModelName,
        ids: transition.to.queryParams.source,
        queryType: 'filter',
      },
      {
        modelName: 'rental' as ModelName,
        ids: transition.to.queryParams.rental,
        queryType: 'filter',
      },
      {
        modelName: 'bookings-tag' as ModelName,
        ids: transition.to.queryParams.bookingTag,
        queryType: 'filter',
      },
    ];

    if (!this.session.account?.isSmily) {
      filterModels.push({
        modelName: 'rentals-tag' as ModelName,
        ids: transition.to.queryParams.rentalTag,
        queryType: 'default',
      });
    }

    const promises: Promise<unknown>[] = [
      this.store.loadModelsById(filterModels),
    ];

    if (transition.to.queryParams.assignee) {
      promises.push(
        this.store.queryRecords('host', {
          ...this.store.generateQuery('host', 'inboxAssignee', {
            id: transition.to.queryParams.assignee,
          }),
        }),
      );
    }

    return all(promises);
  }

  model(params: Record<QueryParam, string> & { page: number }) {
    const query = buildQuery(this);

    return new ConversationInfiniteQuery(
      this.store,
      'inbox-conversation',
      query,
      { page: params.page },
    );
  }

  afterModel(model: ConversationInfiniteQuery, transition: Transition) {
    if (transition.to.name !== 'inbox.new') {
      this._updateCounter(model);
    }
  }

  async _updateCounter(model: ConversationInfiniteQuery) {
    const params = this.paramsFor('inbox') as Record<
      QueryParam,
      string | undefined
    >;

    if (FILTER_PARAMS.some((param) => params[param])) {
      return;
    }

    const category = ALL_CATEGORIES.find(({ query }) => {
      const { status, bookingLabel } = query;

      return status === params.status && bookingLabel === params.bookingLabel;
    });

    if (!category) {
      return;
    }

    const result = await model.promise;

    this.cache[category.count] = result.meta.pagination.total;
  }
}
