import { getOwner } from '@ember/application';
import type MediaService from 'ember-responsive';
import { getService, isMobile } from 'ember-smily-base/utils/application';
import type { Transition } from 'ember-smily-base/utils/routing';

export default function disableRouteForMobile(
  context: object,
  transition: Transition,
  additionalCondition = true,
) {
  const media = getOwner(context).lookup('service:media') as MediaService;

  if (!isMobile(media) || !additionalCondition) {
    return false;
  }

  const intl = getService(context, 'intl');
  const notify = getService(context, 'notify');

  transition.abort();
  notify.info(intl.t('general.not_available_on_mobile'));

  return true;
}
