import Component from '@glimmer/component';
import Icon from 'ember-smily-base/components/icon';
import type BookingModel from 'smily-admin-ui/models/booking';

interface CalendarsDamageDepositBadgeSignature {
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}

export default class CalendarsDamageDepositBadge extends Component<CalendarsDamageDepositBadgeSignature> {
  get badgeClass() {
    if (!this.args.booking.damageDepositRemains) {
      return 'success';
    } else {
      return 'danger';
    }
  }

  get icon() {
    if (!this.args.booking.damageDepositRemains) {
      return 'check';
    } else {
      return 'times';
    }
  }

  <template>
    <div
      class='calendar-dd-badge rounded-circle d-flex align-items-center justify-content-center
        {{this.badgeClass}}'
      ...attributes
    >
      <Icon @icon={{this.icon}} class='text-9' />
    </div>
  </template>
}
