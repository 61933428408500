import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import BsTooltip from 'ember-bootstrap/components/bs-tooltip';
import optional from 'ember-composable-helpers/helpers/optional';
import Badge from 'ember-smily-base/components/badge';
import Icon from 'ember-smily-base/components/icon';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import style from 'ember-style-modifier/modifiers/style';
import type BookingsTagModel from 'smily-admin-ui/models/bookings-tag';

export const BookingsBookingsTagsList: TOC<{
  Element: HTMLDivElement;
  Args: {
    bookingsTags: SyncHasMany<BookingsTagModel>;
    iconOnly?: boolean;
    isRemovable?: boolean;
    onRemove?: (tag: BookingsTagModel) => void;
  };
}> = <template>
  <div class='d-flex gap-1' ...attributes>
    {{#each @bookingsTags as |tag|}}
      {{#if @iconOnly}}
        <div
          {{style backgroundColor=tag.color}}
          class='bookings-tag rounded-circle d-flex align-items-center justify-content-center'
        >
          <BsTooltip @placement='top'>
            {{tag.mappedName}}
          </BsTooltip>

          {{#if tag.compatIcon}}
            <Icon
              {{style color=tag.fgColor}}
              @icon={{tag.compatIcon}}
              @style={{tag.compatIconStyle}}
              class='text-9'
            />
          {{/if}}
        </div>
      {{else}}
        <Badge
          @label={{tag.mappedName}}
          @icon={{tag.compatIcon}}
          @iconStyle={{tag.compatIconStyle}}
          @color={{tag.color}}
          @removable={{@isRemovable}}
          @onRemove={{fn (optional @onRemove) tag}}
        />
      {{/if}}
    {{/each}}
  </div>
</template>;

export default BookingsBookingsTagsList;
