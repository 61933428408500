import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StoreService from 'ember-smily-base/services/store';
import { isMobile } from 'ember-smily-base/utils/application';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute, getReturnParams } from 'smily-admin-ui/utils/routing';

export default class BookingsBookingRoute extends BaseRoute {
  @service abilities!: AbilitiesService;
  @service coreLinks!: CoreLinksService;
  @service store!: StoreService;

  checkAbilities = true;
  requiredFeatureAbility = 'access bookings feature';
  requiredReleaseAbility = 'access bookings management route';

  get title() {
    const model = this.currentModel;

    if (!model) {
      return super.title;
    } else if (!isMobile(this.media)) {
      return model.reference!;
    } else {
      return this.intl.t('mobile_breadcrumbs.bookings.booking.index', {
        reference: model.reference,
      });
    }
  }

  model({ booking_id: id }: { booking_id: string }) {
    return this.store.findRecord(
      'booking',
      id,
      this.store.generateQuery('booking'),
    );
  }

  featureNotAccessible(transition: Transition) {
    this.redirectToOldBookingShow(transition);
  }

  releaseNotAccessible(transition: Transition) {
    this.redirectToOldBookingShow(transition);
  }

  redirectToOldBookingShow(transition: Transition) {
    this.coreLinks.redirect(
      'bookings',
      transition.to.parent!.params.booking_id,
      getReturnParams(transition),
    );
  }
}
