import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import AnnotatedCard from 'ember-smily-base/components/annotated-card';
import InfiniteList from 'ember-smily-base/components/infinite-list';
import type StoreService from 'ember-smily-base/services/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import SettingsInvoiceListItem from 'smily-admin-ui/components/settings/invoice-list-item';

class TemplatesSettingsBillingPayments extends Component {
  @service store!: StoreService;

  invoices = new InfiniteQuery(this.store, 'service-fee-payment-invoice', {
    sort: '-invoiced-at',
  });

  <template>
    <div class='p-3 d-flex flex-column gap-3'>
      <AnnotatedCard @title={{t 'settings.billing.payment_history'}}>
        <InfiniteList
          @infiniteQuery={{this.invoices}}
          @type='ul'
          @yieldItem={{false}}
          class='smily-list list-justified'
          as |invoice|
        >
          <li class='smily-list-item px-0'>
            <SettingsInvoiceListItem @invoice={{invoice}} />
          </li>
        </InfiniteList>
      </AnnotatedCard>
    </div>
  </template>
}

export default RouteTemplate(TemplatesSettingsBillingPayments);
