import { service } from '@ember/service';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import type { SelectOption } from 'ember-smily-base/utils/select';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingContact extends TemplatesOnboardingTask {
  @service intl!: IntlService;

  actionName = 'completeContactDetails';

  get attributes(): string[] {
    return [
      'gender',
      'firstName',
      'lastName',
      'address1',
      'address2',
      'zip',
      'city',
      'countryCode',
      'phone',
      'phoneCountryCode',
      'birthdate',
      'countryOfBirth',
      'placeOfBirth',
      'nationality',
    ];
  }

  get genderOptions(): SelectOption<string>[] {
    return ['male', 'female'].map((value) => ({
      label: this.intl.t(`onboarding.contact.gender_options.${value}`),
      value,
    }));
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.contact.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @onSubmit={{this.save}}
            as |F|
          >
            <F.Radio
              @property='gender'
              @options={{this.genderOptions}}
              @required={{true}}
              class='d-flex gap-2'
            />

            <div class='d-flex flex-column flex-lg-row gap-3'>
              <F.Input
                @property='firstName'
                @required={{true}}
                @autocomplete='given-name'
                class='flex-1'
              />

              <F.Input
                @property='lastName'
                @required={{true}}
                @autocomplete='family-name'
                class='flex-1'
              />
            </div>

            <F.Input
              @property='address1'
              @autocomplete='address-line1'
              @required={{true}}
            />

            <F.Input @property='address2' @autocomplete='address-line2' />

            <div class='d-flex flex-column flex-lg-row gap-3'>
              <F.Input
                @property='zip'
                @required={{true}}
                @autocomplete='postal-code'
                class='flex-1'
              />

              <F.Input
                @property='city'
                @required={{true}}
                @autocomplete='city'
                class='flex-1'
              />
            </div>

            <F.SelectCountry
              @property='countryCode'
              @searchEnabled={{true}}
              @required={{true}}
            />

            <F.Phone
              @property='phoneField'
              @label={{t 'onboarding.contact.mobile'}}
            />

            <F.Birthdate @property='birthdate' @required={{true}} />

            <div class='d-flex flex-column flex-lg-row gap-3'>
              <F.SelectCountry
                @property='countryOfBirth'
                @searchEnabled={{true}}
                @required={{true}}
                class='flex-1'
              />

              <F.Input
                @property='placeOfBirth'
                class='flex-1'
                @required={{true}}
              />
            </div>

            <F.SelectCountry
              @property='nationality'
              @searchEnabled={{true}}
              @required={{true}}
            />

            <hr class='w-100 my-3' />

            <div class='d-flex align-items-center justify-content-between'>
              <OnboardingBackToTaskList />

              <F.Submit
                @label={{t 'common.save'}}
                @disabled={{this.onboardingChangeset.isPristine}}
              />
            </div>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.contact.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.contact.helper.p1'}}
          </div>

          <div>
            {{t 'onboarding.contact.helper.p2'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingContact);
