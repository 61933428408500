import { attr, belongsTo } from '@ember-data/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import { BaseModel, euro } from 'smily-admin-ui/utils/model';

export default class AccountingStatementModel extends BaseModel {
  @attr('number') hostAmountInCents?: number;
  @attr('number') paymentAmountInCents?: number;
  @attr('number') totalOtaCommissionAmountInCents!: number;
  @attr('number') subscriptionCommissionAmountInCents?: number;
  @attr('number') gatewayFeesInCents!: number;
  @attr('string') currency!: string;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @euro('hostAmountInCents') hostAmount?: number;
  @euro('paymentAmountInCents') paymentAmount!: number;
  @euro('totalOtaCommissionAmountInCents') totalOtaCommissionAmount!: number;
  @euro('subscriptionCommissionAmountInCents')
  subscriptionCommissionAmount?: number;
  @euro('gatewayFeesInCents') gatewayFees!: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-statement': AccountingStatementModel;
  }
}
