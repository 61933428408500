/**
 * Convenience wrapper for `array.splice` to remove an object from an array.
 */
export default function removeObject<T>(array: T[], object: T) {
  const index = array.indexOf(object);

  if (index !== -1) {
    array.splice(index, 1);
  }
}
