import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import IconsSmily from 'smily-admin-ui/components/icons/smily';

const SignupCdiscountHeader: TOC<{
  Element: HTMLDivElement;
  Args: {
    brand: string;
  };
}> = <template>
  {{! template-lint-disable no-bare-strings }}

  <div class='d-flex flex-column gap-2 mb-3' ...attributes>
    <div
      class='letter-spacing-n1 d-flex align-items-center gap-1 text-40 fw-semibold'
    >
      <IconsSmily />

      Smily
    </div>

    <div class='w-75 align-self-end'>
      <div class='text-16'>
        {{t 'onboarding.branded_signup.in_partnership_with'}}
      </div>

      <img src='/assets/images/cdiscount.png' alt='CDiscount' class='w-100' />
    </div>
  </div>
</template>;

export default SignupCdiscountHeader;
