import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class BookingGrossEarningModel extends BaseModel {
  @attr('number') grossAmount!: number;
  @attr('string') currency?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-gross-earning': BookingGrossEarningModel;
  }
}
