import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import FormTextarea from 'ember-smily-base/components/form/textarea';
import LoadingButton from 'ember-smily-base/components/loading-button';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import Textarea from 'ember-smily-base/components/textarea';
import { asString } from 'ember-smily-base/utils/template';
import not from 'ember-truth-helpers/helpers/not';
import InboxAddAttachment from 'smily-admin-ui/components/inbox/add-attachment';
import type { InboxComposeAreaSignature } from 'smily-admin-ui/components/inbox/compose/area-desktop';
import InboxComposeAttachments from 'smily-admin-ui/components/inbox/compose/attachments';
import InboxComposeHeader from 'smily-admin-ui/components/inbox/compose/header';
import InboxDraftWithAi from 'smily-admin-ui/components/inbox/draft-with-ai';
import InboxSavedReplies from 'smily-admin-ui/components/inbox/saved-replies';
import InboxSendButton from 'smily-admin-ui/components/inbox/send-button';
import { asAttachments } from 'smily-admin-ui/models/inbox-message';

function getFirstIcon() {
  return document.querySelector<HTMLElement>(
    '[data-mobile-composer-area] .btn',
  );
}

export default class InboxComposeAreaMobile extends Component<InboxComposeAreaSignature> {
  @action
  addAttachment(toggleModal: () => void, event: Event) {
    toggleModal();
    return this.args.addAttachment(event);
  }

  <template>
    <div
      class='px-3 py-2 border-top d-flex justify-content-center'
      data-mobile-composer-area
    >
      <ModalDialog @type='bottom' as |dialog|>
        <div class='w-mobile-content d-flex gap-3'>
          <InboxSavedReplies @changeset={{@changeset}} @compact={{true}} />

          <InboxAddAttachment
            @isAddingAttachments={{@isAddingAttachments}}
            @compact={{true}}
            @onChange={{fn this.addAttachment dialog.toggleModal}}
          />

          <InboxDraftWithAi @draftWithAi={{@draftWithAi}} @compact={{true}} />

          <Textarea
            {{on 'click' dialog.toggleModal}}
            @containerClass='flex-1 d-flex align-items-center'
            @height='1rem'
            value={{asString @changeset.content}}
            class='form-control form-control-sm resize-none'
            placeholder={{t 'inbox.write_something'}}
            aria-label={{t 'inbox.message'}}
          />

          <dialog.modal
            @autofocus='[data-mobile-composer-input] textarea'
            @returnFocus={{getFirstIcon}}
            as |modal|
          >
            <modal.body class='d-flex justify-content-center'>
              <div
                class='w-mobile-content d-flex flex-column align-items-start gap-3'
              >
                <InboxComposeHeader
                  @changeset={{@changeset}}
                  @changeVisibility={{@changeVisibility}}
                />

                {{#let (asAttachments @changeset.attachments) as |attachments|}}
                  {{#if attachments.length}}
                    <InboxComposeAttachments
                      @attachments={{attachments}}
                      @deleteAttachment={{@deleteAttachment}}
                    />
                  {{/if}}
                {{/let}}

                <div class='w-100 d-flex flex-column'>
                  <FormTextarea
                    @model={{@changeset}}
                    @modelGuid={{guidFor @changeset._content}}
                    @property='content'
                    @renderLabel={{false}}
                    @placeholder={{if
                      @changeset.isNote
                      (t 'inbox.write_a_note')
                      (t 'inbox.write_a_reply')
                    }}
                    @inputClass='message-composer-input {{if
                      @changeset.isNote
                      "note"
                    }}'
                    class=''
                    data-mobile-composer-input
                  />

                  <div class='d-flex gap-3'>
                    <InboxSavedReplies
                      @changeset={{@changeset}}
                      @compact={{true}}
                    />

                    <InboxAddAttachment
                      @isAddingAttachments={{@isAddingAttachments}}
                      @compact={{true}}
                      @onChange={{@addAttachment}}
                    />

                    <InboxDraftWithAi
                      @draftWithAi={{@draftWithAi}}
                      @compact={{true}}
                    />

                    {{#if @changeset.isNote}}
                      <LoadingButton
                        @action={{fn @send dialog.toggleModal}}
                        @label={{t 'inbox.save_note'}}
                        @disabled={{not @changeset.content}}
                        class='flex-1 btn btn-primary'
                      />
                    {{else}}
                      <InboxSendButton
                        @changeset={{@changeset}}
                        @send={{fn @send dialog.toggleModal}}
                        class='flex-1'
                      />
                    {{/if}}
                  </div>
                </div>
              </div>
            </modal.body>
          </dialog.modal>
        </div>
      </ModalDialog>
    </div>
  </template>
}
