import { attr, belongsTo, hasMany } from '@ember-data/model';
import { filledArray } from 'ember-smily-base/utils/array';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import { BaseModel } from 'ember-smily-base/utils/model';

export default class DestinationModel extends BaseModel {
  @attr name!: string;

  @belongsTo('destination', { async: false, inverse: '_children' })
  parent!: DestinationModel;
  @hasMany('destination', { async: false, inverse: 'parent' })
  _children!: SyncHasMany<DestinationModel>;

  get children() {
    return filledArray(this._children.length).map(
      (index) => (this._children as unknown as DestinationModel[]).at(index)!,
    );
  }

  get allChildren(): DestinationModel[] {
    let children = [...this.children];

    this.children.forEach((destination) => {
      children = [...children, ...destination.allChildren];
    });

    return children;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    destination: DestinationModel;
  }
}
