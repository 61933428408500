import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from 'ember-smily-base/services/store';
import type { Query } from 'ember-smily-base/utils/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { QueryParam } from 'smily-admin-ui/controllers/discounts/index';

function buildFilters(params: Record<QueryParam, string>) {
  const filters: Query['filter'] = {};

  if (params.rental) {
    filters.rentalIds = params.rental;
  }

  if (params.status) {
    filters.status = params.status;
  }

  return filters;
}

export default class DiscountsIndexRoute extends Route {
  @service store!: Store;

  queryParams = {
    page: { refreshModel: true },
    status: { refreshModel: true },
    rental: { refreshModel: true },
  };

  async model(
    params: Record<QueryParam | 'page', string>,
  ): Promise<InfiniteQuery<'discount-code'>> {
    const page = params.page as unknown as number;

    if (params.rental) {
      await this.store.loadModelsById([
        { modelName: 'rental', ids: params.rental },
      ]);
    }

    return new InfiniteQuery(
      this.store,
      'discount-code',
      this.store.generateQuery(
        'discount-code',
        'listDefault',
        buildFilters(params),
        'startDate',
      ),
      {
        page,
      },
    );
  }
}
