import type { Transition } from 'ember-smily-base/utils/routing';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import SignupIndexRoute from 'smily-admin-ui/routes/signup/index';

const FRENCH_SIGNUP_BRANDS = ['CDISCOUNT', 'MAEVA'];

export default class SignupCobrandedSignupRoute extends SignupIndexRoute {
  afterModel(model: OnboardingModel, transition: Transition): void {
    super.afterModel(model, transition);

    if (
      !transition.to.queryParams.locale &&
      model.referralCode &&
      FRENCH_SIGNUP_BRANDS.includes(model.referralCode)
    ) {
      this.intl.setLocale('fr-fr');
    }
  }
}
