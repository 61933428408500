import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';

const FeatureDiscoveryUnifiedInboxContent: TOC<{}> = <template>
  <div>
    <p>
      {{t 'user.feature_discovery.features.unified_inbox.p1.1'}}<span
        class='fw-semibold'
      >{{t 'user.feature_discovery.features.unified_inbox.p1.2'}}</span>{{t
        'user.feature_discovery.features.unified_inbox.p1.3'
      }}
    </p>

    <ul>
      <li>
        <span class='fw-semibold'>{{t
            'user.feature_discovery.features.unified_inbox.li1.1'
          }}</span>

        {{t 'user.feature_discovery.features.unified_inbox.li1.2'}}
      </li>

      <li>
        <span class='fw-semibold'>{{t
            'user.feature_discovery.features.unified_inbox.li2.1'
          }}</span>

        {{t 'user.feature_discovery.features.unified_inbox.li2.2'}}
      </li>

      <li>
        <span class='fw-semibold'>{{t
            'user.feature_discovery.features.unified_inbox.li3.1'
          }}</span>

        {{t 'user.feature_discovery.features.unified_inbox.li3.2'}}
      </li>
    </ul>

    <p>
      {{t 'user.feature_discovery.features.unified_inbox.p2'}}
    </p>
  </div>
</template>;

export default FeatureDiscoveryUnifiedInboxContent;
