import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class SmilyProspectModel extends BaseModel {
  @attr fullName?: string;
  @attr email?: string;
  @attr country?: string;
  @attr locale?: string;
  @attr('boolean', { defaultValue: true }) privacyPolicyAccepted?: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'smily-prospect': SmilyProspectModel;
  }
}
