import { attr, belongsTo } from '@ember-data/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type RentalModel from 'smily-admin-ui/models/rental';
import type SourceModel from 'smily-admin-ui/models/source';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class InquiryModel extends BaseModel {
  @attr('date') startAt?: Date;
  @attr('date') endAt?: Date;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @belongsTo('rental', { async: false, inverse: null })
  rental!: RentalModel;

  @belongsTo('source', { async: false, inverse: null })
  source!: SourceModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    inquiry: InquiryModel;
  }
}
