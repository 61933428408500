import { getOwner } from '@ember/application';
import type AbilitiesService from 'ember-can/services/abilities';
import { getService } from 'ember-smily-base/utils/application';
import type { Transition } from 'ember-smily-base/utils/routing';
import type { Model } from 'ember-smily-base/utils/store';

type MethodName =
  | 'routeNotAccessible'
  | 'featureNotAccessible'
  | 'releaseNotAccessible';
type RouteWithPermissionMeta = {
  requiredRouteAbility?: string;
  routeAbilityModel?: Model;
  routeNotAccessible?: unknown;
  requiredFeatureAbility?: string;
  featureNotAccessible?: unknown;
  requiredReleaseAbility?: string;
  releaseNotAccessible?: unknown;
};

const DEFAULT_ROUTES = {
  importInProgress: 'channel-import-in-progress',
  routeNotAccessible: 'index',
  featureNotAccessible: 'index',
  releaseNotAccessible: 'index',
};

export default function checkAbilities(
  context: RouteWithPermissionMeta,
  transition: Transition,
): void {
  const abilities = getOwner(context).lookup(
    'service:abilities',
  ) as AbilitiesService;

  const {
    requiredRouteAbility,
    routeAbilityModel,
    requiredFeatureAbility,
    requiredReleaseAbility,
  } = context;

  const abilityMap = {
    importInProgress: abilities.can('access channel import in account'),
    featureNotAccessible:
      requiredFeatureAbility && abilities.cannot(requiredFeatureAbility),
    releaseNotAccessible:
      requiredReleaseAbility && abilities.cannot(requiredReleaseAbility),
    routeNotAccessible:
      requiredRouteAbility &&
      abilities.cannot(requiredRouteAbility, routeAbilityModel),
  };

  for (const [methodName, isNotAccessible] of Object.entries(abilityMap)) {
    if (isNotAccessible) {
      const typedMethodName = methodName as MethodName;
      const method = context[typedMethodName];

      transition.abort();

      if (method && typeof method === 'function') {
        method.call(context, transition);
      } else {
        const router = getService(context, 'router');

        router.transitionTo(DEFAULT_ROUTES[typedMethodName]);
      }

      break;
    }
  }
}
