import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import inc from 'ember-composable-helpers/helpers/inc';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Link from 'ember-smily-base/components/link';
import { getShortLocale } from 'ember-smily-base/utils/intl';
import IconsHandHoldingEur from 'smily-admin-ui/components/icons/hand-holding-eur';
import config from 'smily-admin-ui/config/environment';
import type SessionService from 'smily-admin-ui/services/session-service';

interface Card {
  labelKey: string;
  bgSrc: Record<string, string>;
  fontSize: Record<string, string>;
}

const {
  API: { HOST },
} = config;

const CARDS: Card[] = [
  {
    labelKey: 'finance.discover.cards.1',
    bgSrc: {
      'en-us': '/assets/images/smilypay-ob-1.jpg',
      'fr-fr': '/assets/images/smilypay-ob-1.jpg',
    },
    fontSize: {
      'en-us': '12',
      'fr-fr': '12',
    },
  },
  {
    labelKey: 'finance.discover.cards.2',
    bgSrc: {
      'en-us': '/assets/images/smilypay-ob-2-en.jpg',
      'fr-fr': '/assets/images/smilypay-ob-2-fr.jpg',
    },
    fontSize: {
      'en-us': '12',
      'fr-fr': '10',
    },
  },
  {
    labelKey: 'finance.discover.cards.3',
    bgSrc: {
      'en-us': '/assets/images/smilypay-ob-3.jpg',
      'fr-fr': '/assets/images/smilypay-ob-3.jpg',
    },
    fontSize: {
      'en-us': '12',
      'fr-fr': '12',
    },
  },
  {
    labelKey: 'finance.discover.cards.4',
    bgSrc: {
      'en-us': '/assets/images/smilypay-ob-4.jpg',
      'fr-fr': '/assets/images/smilypay-ob-4.jpg',
    },
    fontSize: {
      'en-us': '12',
      'fr-fr': '10',
    },
  },
];

class TemplatesFinanceDiscover extends Component {
  @service private intl!: IntlService;
  @service private session!: SessionService;

  get createAccountLink() {
    const locale = getShortLocale(this.intl.primaryLocale).toLowerCase();
    const id = this.session.account!.id;

    return `${HOST}/${locale}/admin/v2/${id}/preferences/payment`;
  }

  @action
  getFontSize(card: Card) {
    return `text-${card.fontSize[this.intl.primaryLocale]!}`;
  }

  @action
  getCardSrc(card: Card) {
    return card.bgSrc[this.intl.primaryLocale]!;
  }

  <template>
    <div class='p-3 pt-5 d-flex flex-column align-items-center gap-3'>
      <IconsHandHoldingEur class='text-40' />

      <h3>
        {{t 'finance.discover.section1.title'}}
      </h3>

      <div class='d-flex flex-column align-items-center'>
        <div>
          <span class='fw-semibold'>{{t
              'finance.discover.section1.p1.1'
            }}</span>{{t 'finance.discover.section1.p1.2'}}
        </div>

        <div>
          <span class='fw-semibold'>{{t
              'finance.discover.section1.p2.1'
            }}</span>{{t 'finance.discover.section1.p2.2'}}
        </div>

        <div>
          <span class='fw-semibold'>{{t
              'finance.discover.section1.p3.1'
            }}</span>{{t 'finance.discover.section1.p3.2'}}
        </div>

        <div>
          {{t 'finance.discover.section1.p4'}}
        </div>
      </div>

      <hr class='w-100' />

      <h3>
        {{t 'finance.discover.section2.title'}}
      </h3>

      <div class='d-flex flex-column align-items-center'>
        <div>
          {{t 'finance.discover.section2.p1.1'}}
        </div>

        <div>
          <span class='fw-semibold'>{{t
              'finance.discover.section2.p1.2'
            }}</span>

          {{t 'finance.discover.section2.p1.3'}}
        </div>
      </div>

      <div class='d-flex flex-wrap justify-content-center gap-3'>
        {{#each CARDS as |card index|}}
          <div class='finance-ob-card'>
            <img src={{this.getCardSrc card}} alt={{t card.labelKey}} />

            <div
              class='w-100 h-100 p-2 pe-4 d-flex flex-column justify-content-between'
            >
              <div class='text-18 text-secondary fw-semibold'>
                {{inc index}}
              </div>

              <div class='{{this.getFontSize card}} fw-semibold'>
                {{t card.labelKey}}
              </div>
            </div>
          </div>

        {{/each}}
      </div>

      <Link href={{this.createAccountLink}} class='btn btn-secondary'>
        {{t 'finance.discover.button'}}

        {{! template-lint-disable no-bare-strings }}
        <img
          src='/assets/images/smilypay-logo.svg'
          class='smilypay-logo-button'
          alt='SmilyPay'
        />
        {{! template-lint-enable no-bare-strings }}
      </Link>
    </div>
  </template>
}

export default RouteTemplate(TemplatesFinanceDiscover);
