import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BsAlert from 'ember-bootstrap/components/bs-alert';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import Form from 'ember-smily-base/components/form';
import LoadingButton from 'ember-smily-base/components/loading-button';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import type StoreService from 'ember-smily-base/services/store';
import { getShortLocale } from 'ember-smily-base/utils/intl';
import { createChangeset } from 'ember-smily-base/utils/model';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type SessionService from 'smily-admin-ui/services/session-service';

interface OnboardingCurrencyBannerSignature {
  Element: HTMLDivElement;
}

export default class OnboardingCurrencyBanner extends Component<OnboardingCurrencyBannerSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service session!: SessionService;
  @service store!: StoreService;

  get onboarding(): OnboardingModel {
    return this.session.onboarding as OnboardingModel;
  }

  smilyProspectChangeset = createChangeset(
    this.store.createRecord('smily-prospect', {
      fullName: this.onboarding.fullName,
      country: this.onboarding.countryOfBirth,
      email: this.onboarding.email,
      locale: getShortLocale(this.intl.primaryLocale).toLowerCase(),
    }),
  );

  @action
  async notifyMe(): Promise<void> {
    await this.smilyProspectChangeset.save();

    this.onboarding.smilyProspectBannerHidden = true;
    await this.onboarding.save();

    this.notify.success(
      this.intl.t('onboarding.currency_banner.notify_success'),
    );
  }

  @action
  async dontShowAgain(): Promise<void> {
    this.onboarding.smilyProspectBannerHidden = true;
    await this.onboarding.save();
  }

  <template>
    <BsAlert
      @type='info'
      @dismissible={{false}}
      class='mb-0 d-flex flex-column gap-1'
    >
      <div class='fw-semibold'>
        {{t 'onboarding.currency_banner.title'}}
      </div>

      <div>
        {{t 'onboarding.currency_banner.subtitle'}}
      </div>

      <div class='d-flex gap-2'>
        <ModalDialog as |dialog|>
          <dialog.toggle
            class='btn btn-primary btn-sm'
            data-test-currency-modal-trigger
          >
            {{t 'onboarding.currency_banner.notify_me'}}
          </dialog.toggle>

          <dialog.modal as |modal|>
            <modal.header>
              <h4>
                {{t 'onboarding.currency_banner.modal.title'}}
              </h4>
            </modal.header>

            <modal.body class='d-flex flex-column gap-3'>
              <div>
                {{t 'onboarding.currency_banner.modal.subtitle'}}
              </div>

              <Form @model={{this.smilyProspectChangeset}} @gap={{2}} as |F|>
                <F.Input @property='fullName' @autocomplete='name' />

                <F.SelectCountry @property='country' />

                <F.Input @property='email' @autocomplete='email' />
              </Form>
            </modal.body>

            <modal.footer>
              <LoadingButton
                @action={{this.notifyMe}}
                class='btn btn-primary w-100'
                data-test-notify-me
              >
                {{t 'onboarding.currency_banner.notify_me'}}
              </LoadingButton>
            </modal.footer>
          </dialog.modal>
        </ModalDialog>

        <LoadingButton
          @action={{this.dontShowAgain}}
          class='btn btn-secondary btn-sm'
          data-test-dont-show-again
        >
          {{t 'onboarding.currency_banner.dont_show_again'}}
        </LoadingButton>
      </div>
    </BsAlert>
  </template>
}
