import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { Changeset } from 'ember-smily-base/utils/model';
import type { TimeUnit } from 'smily-admin-ui/utils/tasks';

export default class DurationChangeset {
  changeset;
  durationPropName;

  constructor(changeset: Changeset, durationPropName: string) {
    this.changeset = changeset;
    this.durationPropName = durationPropName;

    this.setupDuration();
  }

  @tracked value!: string;
  @tracked unit!: TimeUnit;

  setupDuration() {
    const minutes = this.changeset.get(this.durationPropName) as
      | number
      | undefined;

    if (!minutes) {
      this.value = '';
      this.unit = 'minute';
    } else if (!(minutes % 1440)) {
      this.value = String(minutes / 1440);
      this.unit = 'day';
    } else if (!(minutes % 60)) {
      this.value = String(minutes / 60);
      this.unit = 'hour';
    } else {
      this.value = String(minutes);
      this.unit = 'minute';
    }
  }

  @action
  changeDuration() {
    if (!this.value) {
      this.changeset.set(this.durationPropName, undefined);
      return;
    }

    const value = Number(this.value);
    const minutes =
      this.unit === 'day'
        ? value * 1440
        : this.unit === 'hour'
          ? value * 60
          : value;

    this.changeset.set(this.durationPropName, minutes);
  }
}
