import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class OnboardingSerializer extends ApplicationSerializer<'onboarding'> {
  attrs = {
    currentTask: 'current-step',
    registeredProfessional:
      'registered-as-professional-at-trade-commercial-register',
    declaresRevenue: 'declares-revenue-as-professional-for-direct-tax-purposes',
    airbnbLogin: 'airbnb-email',
    homeawayLogin: 'homeaway-email',
  };

  formAttrs = {
    phone: 'phoneField',
    companyPhone: 'companyPhoneField',
    primaryIdentityProof: 'primaryIdentityProofFile',
    primaryIdentityProofBack: 'primaryIdentityProofBackFile',
    secondaryIdentityProof: 'secondaryIdentityProofFile',
    secondaryIdentityProofBack: 'secondaryIdentityProofBackFile',
    profilePicture: 'profilePictureFile',
    ibanDocument: 'ibanDocumentFile',
    companyLegalStatusDocument: 'companyLegalStatusDocumentFile',
    incorporationCertificate: 'incorporationCertificateFile',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    onboarding: OnboardingSerializer;
  }
}
