import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class SettingsIndexRoute extends Route {
  @service abilities!: AbilitiesService;
  @service router!: RouterService;

  redirect(): void {
    if (this.abilities.can('access owner in user')) {
      this.router.transitionTo('settings.owner.index');
    } else {
      this.router.transitionTo('settings.billing.index');
    }
  }
}
