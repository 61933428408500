import type { TOC } from '@ember/component/template-only';
import Icon from 'ember-smily-base/components/icon';
import SmilyLayoutSidebarLink from 'ember-smily-base/components/smily-layout/sidebar/link';
import type { MenuConfigEntry } from 'ember-smily-base/utils/routing';

const SidebarLockedMenuItem: TOC<{
  Args: {
    item: MenuConfigEntry;
  };
}> = <template>
  <SmilyLayoutSidebarLink @item={{@item}} @disabled={{true}}>
    <Icon @icon='lock' />
  </SmilyLayoutSidebarLink>
</template>;

export default SidebarLockedMenuItem;
