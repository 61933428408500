import type { WordGenderMap } from 'ember-smily-base/utils/intl';

const WORD_GENDER_MAP: WordGenderMap = {
  'fr-fr': {
    default: {
      name: 'm',
      description: 'f',
      expectedDurationInMinutes: 'f',
      category: 'f',
      priority: 'f',
      assignee: 'm',
    },
    models: {
      'task-management-task': 'f',
    },
  },
};

export default WORD_GENDER_MAP;
