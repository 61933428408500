import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type { ModelFor, Transition } from 'ember-smily-base/utils/routing';
import type BookingsBookingRoute from 'smily-admin-ui/routes/bookings/booking';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute, getReturnParams } from 'smily-admin-ui/utils/routing';

export default class BookingsBookingIndexRoute extends BaseRoute {
  @service abilities!: AbilitiesService;
  @service coreLinks!: CoreLinksService;

  checkAbilities = true;
  requiredReleaseAbility = 'access bookings management route';

  model() {
    return this.modelFor('bookings.booking') as ModelFor<BookingsBookingRoute>;
  }

  releaseNotAccessible(transition: Transition): void {
    window.history.pushState(window.history.state, document.title);

    const id =
      transition.to.params.booking_id ??
      transition.to.parent!.params.booking_id;

    this.coreLinks.redirect('bookings', id, getReturnParams(transition));
  }
}
