import { getOwner } from '@ember/application';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import findBy from 'ember-composable-helpers/helpers/find-by';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import type IntlService from 'ember-intl/services/intl';
import FormGroup from 'ember-smily-base/components/form/group';
import SelectInfinite from 'ember-smily-base/components/select/infinite';
import { getSelect } from 'ember-smily-base/components/select/simple';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import type StoreService from 'ember-smily-base/services/store';
import type { SelectOption } from 'ember-smily-base/utils/select';
import InboxAssigneeOption from 'smily-admin-ui/components/inbox/assignee-option';
import type InboxController from 'smily-admin-ui/controllers/inbox';
import type { QueryParam } from 'smily-admin-ui/controllers/inbox';
import type HostModel from 'smily-admin-ui/models/host';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';
import type { Category } from 'smily-admin-ui/models/inbox-conversation';
import { CATEGORIES } from 'smily-admin-ui/models/inbox-conversation';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { HostOption } from 'smily-admin-ui/utils/inbox';

const SelectCategory = getSelect<SelectOption<Category>>();

function asHost(option: HostOption) {
  return option as HostModel;
}

function asHosts(options: HostOption[]) {
  return options as HostModel[];
}

function asHostSelect(taskInstance: (host: HostOption) => Promise<void>) {
  return taskInstance as (host: HostModel | undefined) => void;
}

interface InboxConversationActionsSignature {
  Element: HTMLDivElement;
  Args: {
    conversation: InboxConversationModel;
  };
}

export default class InboxConversationActions extends Component<InboxConversationActionsSignature> {
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service session!: SessionService;
  @service store!: StoreService;

  get modelGuid() {
    return guidFor(this.args.conversation);
  }

  get unassignedOption(): HostOption {
    return {
      fullName: this.intl.t('inbox.host_options.unassigned'),
      custom: true,
      id: undefined,
    };
  }

  get selectedHost(): HostOption {
    return this.args.conversation.assignee ?? this.unassignedOption;
  }

  get constantHosts(): HostOption[] {
    return [
      {
        fullName: this.intl.t('inbox.host_options.unassigned'),
        custom: true,
        id: undefined,
      },
      this.session.host as HostModel,
    ];
  }

  get categories(): SelectOption<Category>[] {
    return CATEGORIES.map((value) => ({
      label: this.intl.t(`inbox.category_options.${value}`),
      value,
    }));
  }

  assign = task({ drop: true }, async (host: HostOption) => {
    const { conversation } = this.args;

    conversation.set('assignee', host.id ? host : undefined);

    await conversation.save().catch(conversation.rollbackAttributes);
    this.notifyController('assignee');
  });

  moveTo = task({ drop: true }, async ({ value }: SelectOption<Category>) => {
    const { conversation } = this.args;

    conversation.spam = value === 'spam';
    conversation.archived = value === 'archived';

    await conversation.save().catch(conversation.rollbackAttributes);

    this.notifyController('status', value);
  });

  notifyController(key: QueryParam, value?: string): void {
    (
      getOwner(this).lookup('controller:inbox') as InboxController
    ).handleInboxAction(key, value);
  }

  <template>
    <div ...attributes>
      <FormGroup
        @model={{@conversation}}
        @modelGuid={{this.modelGuid}}
        @property='assignee'
        @labelKey='internals.fields.assignee'
        class='flex-1 smily-form-select'
        as |field|
      >
        <div id={{field.id}} class='form-control-container'>
          <SelectInfinite
            @value={{asHost this.selectedHost}}
            @modelName='host'
            @query={{generateQuery 'host' 'inboxAssignee'}}
            @constantOptions={{asHosts this.constantHosts}}
            @displayField='fullName'
            @searchField='fullname'
            @loose={{true}}
            @triggerClass='form-control form-control-sm bg-white'
            @onChange={{asHostSelect (perform this.assign)}}
            as |item|
          >
            <InboxAssigneeOption @option={{item}} />
          </SelectInfinite>
        </div>
      </FormGroup>

      <FormGroup
        @model={{@conversation}}
        @modelGuid={{this.modelGuid}}
        @property='category'
        @labelKey='internals.fields.category'
        class='flex-1 smily-form-select'
        as |field|
      >
        <div id={{field.id}} class='form-control-container'>
          <SelectCategory
            @value={{findBy 'value' @conversation.category this.categories}}
            @options={{this.categories}}
            @displayField='label'
            @triggerClass='form-control form-control-sm bg-white'
            @onChange={{perform this.moveTo}}
          />
        </div>
      </FormGroup>
    </div>
  </template>
}
