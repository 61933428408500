import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';

export default class InboxOldConversationRoute extends Route {
  @service router!: RouterService;

  beforeModel(transition: Transition): void {
    this.router.transitionTo(
      'inbox.conversation',
      transition.to.params.inbox_conversation_id as string,
    );
  }
}
