import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class BankAccountSerializer extends ApplicationSerializer<'bank-account'> {
  formAttrs = {
    ibanDocument: 'ibanDocumentFile',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'bank-account': BankAccountSerializer;
  }
}
