import type { LocalDatetime } from 'ember-smily-base/utils/date';
import type { Changeset } from 'ember-smily-base/utils/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import type RentalModel from 'smily-admin-ui/models/rental';

export default function populateCheckinCheckout(
  model: BookingModel | Changeset,
) {
  const rental = model.get('rental') as RentalModel | undefined;

  if (!rental) {
    return;
  }

  const startAt = model.get('startAt') as LocalDatetime | undefined;
  const endAt = model.get('endAt') as LocalDatetime | undefined;

  if (startAt && startAt.date && !startAt.time && rental.checkinTime) {
    startAt.time = `${rental.checkinTime}:00`;
  }

  if (endAt && endAt.date && !endAt.time && rental.checkoutTime) {
    endAt.time = `${rental.checkoutTime}:00`;
  }
}
