import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import { getShortLocale } from 'ember-smily-base/utils/intl';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import config from 'smily-admin-ui/config/environment';
import type AmenityModel from 'smily-admin-ui/models/amenity';
import type BookingModel from 'smily-admin-ui/models/booking';
import type PhotoModel from 'smily-admin-ui/models/photo';
import type RentalFeeModel from 'smily-admin-ui/models/rental-fee';
import { BaseModel, generateFullAddress } from 'smily-admin-ui/utils/model';
import type { TranslatedField } from 'smily-admin-ui/utils/model/types';

export type RentalType =
  | 'apartment'
  | 'bastide'
  | 'bed-and-breakfast'
  | 'boat'
  | 'bungalow'
  | 'cabin'
  | 'castle'
  | 'cave'
  | 'chalet'
  | 'condominium'
  | 'cottage'
  | 'dormitory'
  | 'earth-house'
  | 'farmhouse'
  | 'gite'
  | 'holiday-home'
  | 'house'
  | 'hut'
  | 'igloo'
  | 'island'
  | 'lighthouse'
  | 'loft'
  | 'longere'
  | 'manor'
  | 'mas'
  | 'mill'
  | 'plane'
  | 'private-room-in-apartment'
  | 'private-room-in-house'
  | 'private-room'
  | 'recreational-vehicle'
  | 'studio'
  | 'tent'
  | 'tipi'
  | 'townhouse'
  | 'train'
  | 'treehouse'
  | 'villa'
  | 'yurt';
export type SurfaceUnit = 'metric' | 'imperial';

export interface CompletionProgressRequirement {
  completed: boolean;
  key: string;
}

export default class RentalModel extends BaseModel {
  @attr name?: string;
  @attr headline?: string;
  @attr summary?: string;
  @attr rentalType?: string;
  @attr residencyCategory?: string;
  @attr('number') sleeps?: number;
  @attr('number') sleepsMax?: number;
  @attr('number') bedroomsCount?: number;
  @attr('number') bathroomsCount?: number;
  @attr('number') livingRoomsCount?: number;
  @attr('number') surface?: number;
  @attr surfaceUnit?: string;
  @attr('number') storiesCount?: number;
  @attr floor?: string;
  @attr city?: string;
  @attr countryCode?: CountryCode;
  @attr checkinType?: string;
  @attr('number') checkinTime?: number;
  @attr('number') checkoutTime?: number;
  @attr checkinDetails?: string;
  @attr('object') checkinDetailsTranslations!: TranslatedField;
  @attr checkoutDetails?: string;
  @attr('object') checkoutDetailsTranslations!: TranslatedField;
  @attr cancelationPolicyPreset?: string;
  @attr('number') advanceNotice?: number;
  @attr permitNumber?: string;
  @attr('date') permitIssueDate?: Date;
  @attr baseRateKind?: string;
  @attr minPrice?: string;
  @attr maxPrice?: string;
  @attr currency?: string;
  @attr damageDeposit?: string;
  @attr cleaningFee?: string;
  @attr linenFee?: string;
  @attr('number') position?: number;
  @attr notes?: string;
  @attr baseRate?: string;
  @attr('number') lat?: number;
  @attr('number') lng?: number;
  @attr address1?: string;
  @attr address2?: string;
  @attr state?: string;
  @attr zip?: string;
  @attr('date') publishedAt?: Date;
  @attr absoluteMinPrice?: string;
  @attr roundingKind?: string;
  @attr('boolean') bookableOnline!: boolean;
  @attr guestsInteractionKind?: string;
  @attr checkinEndTime?: string;
  @attr('date') createdAt!: Date;
  @attr('date') updatedAt!: Date;
  @attr('date') completedAt?: Date;
  @attr icalUrl?: string;
  @attr('object') completionProgress!: {
    percentage: number;
    requirements: CompletionProgressRequirement[];
  };

  @belongsTo('photo', { async: false, inverse: null })
  primaryPhoto?: PhotoModel;

  @hasMany('amenity', { async: false, inverse: null })
  amenities!: SyncHasMany<AmenityModel>;
  @hasMany('booking', { async: false, inverse: 'rental' })
  bookings!: SyncHasMany<BookingModel>;
  @hasMany('photo', { async: false, inverse: 'rental' })
  photos!: SyncHasMany<PhotoModel>;
  @hasMany('rental-fee', { async: false, inverse: 'rental' })
  rentalFees!: SyncHasMany<RentalFeeModel>;

  get area() {
    // Intl.NumberFormat can't handle square units and English/French is using
    // the same values anyway
    return `${this.surface} ${this.surfaceUnit === 'metric' ? 'm²' : 'ft²'}`;
  }

  get fullAddress() {
    return generateFullAddress(
      this.address1,
      this.address2,
      this.city,
      this.zip,
    );
  }

  get completionPercentage() {
    return this.completionProgress.percentage;
  }

  get isListed() {
    return this.completionPercentage === 100;
  }

  get checkinTimeFormatted() {
    if (!this.checkinTime) {
      return undefined;
    }

    const meridiem = this.checkinTime < 12 ? 'AM' : 'PM';
    const time = this.checkinTime % 12 || 12;

    return `${time}:00 ${meridiem}`;
  }

  get checkoutTimeFormatted() {
    if (!this.checkoutTime) {
      return undefined;
    }

    const meridiem = this.checkoutTime < 12 ? 'AM' : 'PM';
    const time = this.checkoutTime % 12 || 12;

    return `${time}:00 ${meridiem}`;
  }

  get mapUrl() {
    if (!this.lat || !this.lng) {
      return null;
    }

    const lang = getShortLocale(this.intl.primaryLocale);

    return `https://www.google.com/maps/embed/v1/view?center=${this.lat},${this.lng}&language=${lang}&zoom=14&key=${config.APP.GOOGLE_MAPS_API_KEY}`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    rental: RentalModel;
  }
}
