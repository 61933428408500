import type { TOC } from '@ember/component/template-only';
import { isSameDay } from 'date-fns';
import formatTime from 'ember-intl/helpers/format-time';
import timestamp from 'ember-smily-base/helpers/timestamp';

const isMessageToday = (sentAt: Date) => isSameDay(new Date(), sentAt);

const MessageTimestamp: TOC<{
  Args: {
    sentAt: Date;
  };
}> = <template>
  {{#if (isMessageToday @sentAt)}}
    {{timestamp @sentAt}}
  {{else}}
    {{formatTime @sentAt format='hhmm'}}
  {{/if}}
</template>;

export default MessageTimestamp;
