import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type MediaService from 'ember-responsive';
import { isMobile } from 'ember-smily-base/utils/application';
import { menuConfig } from 'smily-admin-ui/utils/config';

export default class IndexRoute extends Route {
  @service abilities!: AbilitiesService;
  @service media!: MediaService;
  @service router!: RouterService;

  redirect(): void {
    if (this.abilities.can('access channel import in account')) {
      this.router.transitionTo('channel-import-in-progress');
      return;
    }

    const orderedMenus = isMobile(this.media)
      ? menuConfig.sort((a, b) => {
          if (!b.order) {
            return -1;
          }

          if (!a.order) {
            return 0;
          }

          return a.order > b.order ? 1 : -1;
        })
      : menuConfig;
    const routes = orderedMenus.map(({ route }) => route);

    const firstAvailableRoute = routes.find((route) =>
      this.abilities.can(`access ${route} route`),
    )!;

    this.router.transitionTo(firstAvailableRoute);
  }
}
