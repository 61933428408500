import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class CompleteChannelSignupRoute extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  beforeModel(transition: Transition): void {
    this.session.prohibitAuthentication('index');

    if (!transition.to.queryParams.token) {
      this.router.transitionTo('index');
    }
  }
}
