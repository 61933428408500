import type { Snapshot } from '@ember-data/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import { isNew } from 'ember-smily-base/utils/serializer';
import type { TaskManagementAttachmentFile } from 'smily-admin-ui/models/task-management-attachment';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class TaskManagementTaskCommentSerializer extends ApplicationSerializer<'task-management-task-comment'> {
  serialize(snapshot: Snapshot, options?: {}) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;

    if (isNew(snapshot, options)) {
      const newAttachments = snapshot.attr(
        'newAttachmentFiles',
      ) as TaskManagementAttachmentFile[];

      if (newAttachments.length) {
        json.data.attributes!['attachments'] = newAttachments.map(
          ({ signedId }) => ({ file: signedId }),
        );
      }
    }

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'task-management-task-comment': TaskManagementTaskCommentSerializer;
  }
}
