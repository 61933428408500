import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import { or } from 'ember-truth-helpers';

const CounterText: TOC<{
  Element: HTMLDivElement;
  Args: {
    text?: string;
    counter?: number | string;
    gap?: string | number;
  };
}> = <template>
  <div
    class='d-flex align-items-center {{if @gap (concat "gap-" @gap) "gap-1"}}'
    ...attributes
  >
    <div class='fw-semibold'>{{or @counter '0'}}</div>
    <div class='fw-normal'>{{@text}}</div>
  </div>
</template>;

export default CounterText;
