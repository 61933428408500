import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import pick from 'ember-composable-helpers/helpers/pick';
import t from 'ember-intl/helpers/t';
import StarsRating from 'ember-smily-base/components/stars-rating';
import Textarea from 'ember-smily-base/components/textarea';
import type { ReviewCriterion } from 'smily-admin-ui/utils/model';

interface ReviewsHostReviewCriterionSignature {
  Element: HTMLDivElement;
  Args: {
    criterion: ReviewCriterion;
    label: string;
  };
}

export default class ReviewsHostReviewCriterion extends Component<ReviewsHostReviewCriterionSignature> {
  @tracked hasComment = false;

  @action
  toggleComment(): void {
    this.hasComment = !this.hasComment;

    if (!this.hasComment) {
      this.args.criterion.comment = undefined;
    }
  }

  <template>
    <div class='flex-column' ...attributes>
      <div class='d-flex w-100 justify-content-between'>
        <div class='fw-semibold'>
          {{@label}}
        </div>

        <StarsRating
          @value={{@criterion.rating}}
          @gap={{2}}
          @onChange={{fn (mut @criterion.rating)}}
        />
      </div>

      <button
        {{on 'click' this.toggleComment}}
        type='button'
        class='btn btn-link p-0'
      >
        {{t
          (concat
            'reviews.private_comment_button.'
            (if this.hasComment 'remove' 'add')
          )
        }}
      </button>

      {{#if this.hasComment}}
        <Textarea
          {{on 'input' (pick 'target.value' (fn (mut @criterion.comment)))}}
          value={{@criterion.comment}}
          placeholder={{t 'reviews.write_a_private_comment'}}
          class='form-control w-100 mt-2'
          aria-label={{t 'reviews.write_a_private_comment'}}
        />
      {{/if}}
    </div>
  </template>
}
