import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import IconText from 'ember-smily-base/components/icon-text';
import type HostReviewModel from 'smily-admin-ui/models/host-review';

const ReviewsHostReviewExpiration: TOC<{
  Args: {
    review: HostReviewModel;
  };
}> = <template>
  {{#if @review.isExpired}}
    <IconText
      @icon='ban'
      @text={{t 'reviews.expired_label'}}
      @iconClass='text-icon'
      @gap={{1}}
    />
  {{else}}
    <div class='d-flex gap-2'>
      <IconText
        @icon='pencil-alt'
        @text={{t 'reviews.write_a_review'}}
        @gap={{1}}
        class='fw-semibold text-primary'
      />

      <IconText
        @icon={{if @review.isAlmostExpired 'exclamation-triangle' 'clock'}}
        @text={{t 'reviews.days_left' counter=@review.daysLeft}}
        @iconClass={{if @review.isAlmostExpired 'text-danger' 'text-icon'}}
        @iconStyle={{if @review.isAlmostExpired 'fas' 'far'}}
        @gap={{1}}
        class={{if @review.isAlmostExpired 'text-danger fw-semibold'}}
      />
    </div>
  {{/if}}
</template>;

export default ReviewsHostReviewExpiration;
