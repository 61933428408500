import type { TOC } from '@ember/component/template-only';
import Icon from 'ember-smily-base/components/icon';
import type { IconName, IconPrefix } from 'ember-smily-base/utils/icons';
import type { Channel } from 'smily-admin-ui/models/inbox-message';

interface ChannelIconEntry {
  channel: Channel;
  icon: IconName;
  iconStyle: IconPrefix;
}

const CHANNEL_ICONS: ChannelIconEntry[] = [
  {
    channel: 'airbnb',
    icon: 'airbnb',
    iconStyle: 'fab',
  },
  {
    channel: 'booking-com',
    icon: 'booking-com',
    iconStyle: 'fak',
  },
  {
    channel: 'email',
    icon: 'envelope',
    iconStyle: 'far',
  },
  {
    channel: 'expedia',
    icon: 'expedia',
    iconStyle: 'fak',
  },
  {
    channel: 'homeaway',
    icon: 'homeaway',
    iconStyle: 'fak',
  },
  {
    channel: 'vrbo',
    icon: 'vrbo',
    iconStyle: 'fak',
  },
];

function getIconEntry(channel: Channel) {
  return CHANNEL_ICONS.find((iconEntry) => iconEntry.channel === channel)!;
}

const InboxChannelIcon: TOC<{
  Args: {
    channel: Channel;
    wrapped?: boolean;
    iconClass?: string;
  };
}> = <template>
  {{#let (getIconEntry @channel) as |iconEntry|}}
    {{#if @wrapped}}
      <div class='channel-icon-wrapper'>
        <Icon
          @icon={{iconEntry.icon}}
          @style={{iconEntry.iconStyle}}
          class={{@iconClass}}
          aria-label={{@channel}}
        />
      </div>
    {{else}}
      <Icon
        @icon={{iconEntry.icon}}
        @style={{iconEntry.iconStyle}}
        class={{@iconClass}}
        aria-label={{@channel}}
      />
    {{/if}}
  {{/let}}
</template>;

export default InboxChannelIcon;
