import type { Snapshot } from '@ember-data/store';
import type { ModelName } from 'ember-smily-base/utils/store';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

export default class TaskManagementTemplateAdapter extends ApplicationAdapter {
  urlForCreateRecord(modelName: ModelName, snapshot: Snapshot<ModelName>) {
    const url = super.urlForCreateRecord(modelName, snapshot);

    return `${url}?include=rentals`;
  }

  urlForUpdateRecord(
    id: string,
    modelName: ModelName,
    snapshot: Snapshot<ModelName>,
  ) {
    const url = super.urlForUpdateRecord(id, modelName, snapshot);

    return `${url}?include=rentals`;
  }
}
