import Service from '@ember/service';
import { service } from '@ember/service';
import config from 'smily-admin-ui/config/environment';
import type FlutterLoggerService from 'smily-admin-ui/services/flutter-logger';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class MobileDevice extends Service {
  @service session!: SessionService;
  @service flutterLogger!: FlutterLoggerService;

  async register(deviceUid: string, firebaseToken: string): Promise<void> {
    const payload = {
      data: {
        type: 'device',
        attributes: {
          token: firebaseToken,
          device_uid: deviceUid,
        },
      },
    };

    const response = await fetch(
      `${config.APP.BSA_NOTIFICATIONS_URL}/api/v1/devices`,
      {
        method: 'POST',
        headers: {
          ...this.session.authorizationHeader,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(payload),
      },
    );

    this.flutterLogger.log('register response');
    this.flutterLogger.log(String(JSON.stringify(response)));
  }

  async unregister(deviceUid: string): Promise<void> {
    const response = await fetch(
      `${config.APP.BSA_NOTIFICATIONS_URL}/api/v1/devices/${deviceUid}`,
      {
        method: 'DELETE',
        headers: {
          ...this.session.authorizationHeader,
          'Content-Type': 'application/vnd.api+json',
        },
      },
    );

    this.flutterLogger.log('unregister response');
    this.flutterLogger.log(String(response));
  }
}
