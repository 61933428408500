import { on } from '@ember/modifier';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import Form from 'ember-smily-base/components/form';
import IconText from 'ember-smily-base/components/icon-text';
import LoadingButton from 'ember-smily-base/components/loading-button';
import not from 'ember-truth-helpers/helpers/not';
import ClientsClientItem from 'smily-admin-ui/components/clients/-client-item';

export default class ClientsEmail extends ClientsClientItem {
  itemName = 'email' as const;

  <template>
    <div class='d-flex'>
      <div class='flex-1'>
        {{! @glint-expect-error changeset.label exists }}
        <div class='fw-semibold'>{{@changeset.label}}</div>

        {{#if @changeset.primary}}
          <div>
            <IconText
              @icon='circle'
              @iconClass='text-8 text-primary'
              @text={{t 'guests.primary_email'}}
              @gap={{1}}
            />
          </div>
        {{else if (not @changeset.isNew)}}
          <button
            {{on 'click' this.setAsPrimary}}
            type='button'
            class='btn btn-sm btn-link text-primary p-0'
          >
            {{t 'guests.set_as_primary'}}
          </button>
        {{/if}}
      </div>

      <div class='flex-2'>
        <Form
          @model={{@changeset}}
          @disablePrompt={{@disablePrompt}}
          @onSubmit={{perform this.save}}
          autocomplete='off'
          as |F|
        >
          <F.Input @property='label' @required={{true}} />

          <F.Input @property='email' @type='email' @required={{true}} />

          <div class='d-flex justify-content-between align-items-center'>
            <LoadingButton
              @action={{this.deleteItem}}
              @needsConfirmation={{this.needsConfirmationToDelete}}
              class='btn btn-sm btn-link text-danger p-0'
            >
              <IconText
                @icon='trash-alt'
                @text={{t 'guests.remove_email'}}
                @iconStyle='far'
                @iconClass='text-danger'
                @gap={{1}}
              />
            </LoadingButton>

            {{#unless @isNew}}
              <F.Submit
                @label={{t 'common.save_changes'}}
                class='btn-sm'
                disabled={{@changeset.isPristine}}
              />
            {{/unless}}
          </div>
        </Form>
      </div>
    </div>
  </template>
}
