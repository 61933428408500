import Transform from '@ember-data/serializer/transform';
import { dateString, parseDateString } from 'ember-smily-base/utils/date';
import { BookingActivePeriod } from 'smily-admin-ui/models/discount-code';

// TODO remove snake case support
interface SerializedPeriod {
  'start-date': string;
  'end-date'?: string;
}

export default class BookingActivePeriodsTransform extends Transform {
  deserialize(
    serialized?: SerializedPeriod[],
  ): undefined | BookingActivePeriod[] {
    if (!serialized) {
      return serialized;
    }

    return serialized.map((period) => {
      const startDate = parseDateString(period['start-date']);
      const endDate = period['end-date']
        ? parseDateString(period['end-date'])
        : undefined;
      return new BookingActivePeriod(startDate, endDate);
    });
  }

  serialize(
    deserialized?: BookingActivePeriod[],
  ): SerializedPeriod[] | undefined {
    if (!deserialized) {
      return deserialized;
    }

    return deserialized.map((period) => ({
      'start-date': dateString(period.startDate),
      'end-date': period.endDate ? dateString(period.endDate) : undefined,
    }));
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'booking-active-periods': string;
  }
}
