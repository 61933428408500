import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import includes from 'ember-composable-helpers/helpers/includes';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import Form from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import type StoreService from 'ember-smily-base/services/store';
import type { Changeset } from 'ember-smily-base/utils/model';
import { createChangeset } from 'ember-smily-base/utils/model';
import BookingsComment from 'smily-admin-ui/components/bookings/comment';
import type BookingModel from 'smily-admin-ui/models/booking';
import type BookingCommentModel from 'smily-admin-ui/models/booking-comment';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { TrackedArray } from 'tracked-built-ins';
import { tracked } from 'tracked-built-ins';

interface BookingsActivitiesSignature {
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}

export default class BookingsActivities extends Component<BookingsActivitiesSignature> {
  @service store!: StoreService;
  @service session!: SessionService;

  @tracked newComment!: Changeset;
  editedComments: TrackedArray<BookingCommentModel> = tracked([]);

  constructor(owner: unknown, args: BookingsActivitiesSignature['Args']) {
    super(owner, args);

    // need to defer the reset to avoid same runloop render error
    next(this, this.resetComment);
  }

  get isCleaner() {
    return this.session.role === 'cleaner';
  }

  get comments() {
    return this.args.booking.comments
      .slice()
      .filter((comment) => !comment.isNew)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  }

  resetComment() {
    this.newComment = createChangeset(
      this.store.createRecord('booking-comment', {
        content: '',
        visibleForCleaners: true,
        booking: this.args.booking,
      }),
    );
  }

  saveComment = task({ drop: true }, async () => {
    await this.newComment.save();
    this.resetComment();
  });

  @action
  editComment(comment: BookingCommentModel) {
    this.editedComments.push(comment);
  }

  @action
  cancelEditComment(comment: BookingCommentModel) {
    this.editedComments.splice(this.editedComments.indexOf(comment), 1);
  }

  <template>
    <List as |Item|>
      <Item>
        {{#if this.newComment}}
          <Form
            @model={{this.newComment}}
            @onSubmit={{perform this.saveComment}}
            @gap={{2}}
            as |F|
          >
            <div class='w-100 d-flex align-items-center gap-3'>
              <F.Textarea
                @property='content'
                @label={{t 'bookings.activities.leave_a_comment'}}
                @height='100px'
                @required={{true}}
                class='flex-1'
                data-test-new-comment
              />

              <F.Submit
                @label={{t 'bookings.activities.post'}}
                @buttonType='secondary'
                class='btn btn-secondary btn-sm'
                data-test-post-comment
              />
            </div>

            {{#unless this.isCleaner}}
              <F.Checkbox
                @property='visibleForCleaners'
                data-test-visible-for-cleaners
              />
            {{/unless}}
          </Form>
        {{/if}}
      </Item>

      {{#if this.comments.length}}
        <Item class='p-3'>
          <div class='flex-1 h-0 d-flex flex-column justify-content-end'>
            <div class='messenger'>
              {{#each this.comments as |comment|}}
                <BookingsComment
                  @comment={{comment}}
                  @isEditing={{includes comment this.editedComments}}
                  @editComment={{this.editComment}}
                  @cancelEditComment={{this.cancelEditComment}}
                />
              {{/each}}
            </div>
          </div>
        </Item>
      {{/if}}
    </List>
  </template>
}
