/**
  Asserts that all combinations of items in an array pass a given assertion.
  Returns true if the callback returns true to any of the combinations of two items.
*/
export default function assertAllItemCombinations<T>(
  items: T[],
  assertCb: (a: T, b: T) => boolean,
) {
  for (let i = 0, x = items.length; i < x; i++) {
    for (let j = i + 1, x = items.length; j < x; j++) {
      if (assertCb(items[i]!, items[j]!)) {
        return true;
      }
    }
  }

  return false;
}
