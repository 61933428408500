import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type MetricsService from 'ember-metrics/services/metrics';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import Icon from 'ember-smily-base/components/icon';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import { callAction } from 'ember-smily-base/utils/model';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingPhoneConfirm extends TemplatesOnboardingTask {
  @service metrics!: MetricsService;

  actionName = 'verifyOTP';

  @tracked counter = 0;

  get attributes(): string[] {
    return ['otp'];
  }

  sendCodeAgain = task({ drop: true }, async () => {
    await callAction(this.onboardingChangeset, 'requestOTP', [
      'phone',
      'phoneCountryCode',
    ]);

    this.counter = 60;
    this.countdown.perform();
  });

  countdown = task(async () => {
    if (!this.counter) {
      return;
    }

    await timeout(1000);

    this.counter -= 1;
    this.countdown.perform();
  });

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t
              'onboarding.phone.confirm.title'
              phoneCountryCode=this.onboardingChangeset.phoneCountryCode
              phone=this.onboardingChangeset.phone
            }}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @disablePrompt={{true}}
            @onSubmit={{this.save}}
            autocomplete='off'
            as |F|
          >
            <List @large={{true}} @sticky={{true}} as |Item|>
              <Item class='d-flex flex-column gap-2'>
                <F.Input @property='otp' @required={{true}} />

                {{#if this.counter}}
                  {{t
                    'onboarding.phone.confirm.send_again_in'
                    counter=this.counter
                  }}
                {{else}}
                  <LoadingButton
                    @label={{t 'onboarding.phone.confirm.send_code_again'}}
                    @action={{perform this.sendCodeAgain}}
                    class='btn btn-link p-0 align-self-start'
                  />
                {{/if}}
              </Item>

              <Item class='d-flex align-items-center justify-content-between'>
                <LinkTo @route='onboarding.phone.verify' class='btn-link'>
                  <Icon @icon='arrow-left' />

                  {{t 'common.back'}}
                </LinkTo>

                <F.Submit
                  @label={{t 'onboarding.phone.confirm.confirm_code'}}
                />
              </Item>
            </List>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.phone.confirm.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.phone.confirm.helper.p1'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingPhoneConfirm);
