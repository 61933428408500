import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isAfter, isBefore } from 'date-fns';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import LoadingButton from 'ember-smily-base/components/loading-button';
import gt from 'ember-truth-helpers/helpers/gt';
import BookingsFloatingCard from 'smily-admin-ui/components/bookings/floating-card';
import ClientsFloatingCard from 'smily-admin-ui/components/clients/floating-card';
import RentalsFloatingCard from 'smily-admin-ui/components/rentals/floating-card';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type ClientModel from 'smily-admin-ui/models/client';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';

interface InboxConversationRelatedInfoSignature {
  Args: {
    conversation: InboxConversationModel;
  };
}

export default class InboxConversationRelatedInfo extends Component<InboxConversationRelatedInfoSignature> {
  @tracked bookingId = this.args.conversation.bookings.length - 1;

  get booking() {
    return this.args.conversation.bookings.at(this.bookingId);
  }

  get isFirstBooking() {
    return this.bookingId === 0;
  }

  get isLastBooking() {
    return this.bookingId === this.args.conversation.bookings.length - 1;
  }

  get relativeLabel() {
    const { currentBooking } = this.args.conversation;
    if (!this.booking || !currentBooking) {
      return undefined;
    }

    if (isBefore(this.booking.startAt.date, currentBooking.startAt.date)) {
      return 'previous';
    } else if (
      isAfter(this.booking.startAt.date, currentBooking.startAt.date)
    ) {
      return 'future';
    } else {
      return 'current';
    }
  }

  @action
  goToPreviousBooking() {
    this.bookingId -= 1;
  }

  @action
  goToNextBooking() {
    this.bookingId += 1;
  }

  <template>
    <div class='d-flex flex-column gap-3'>
      {{#let (getClient @conversation) as |client|}}
        <ClientsFloatingCard
          @client={{client}}
          data-test-client-card={{client.id}}
        />
      {{/let}}

      {{#if this.booking}}
        <RentalsFloatingCard
          @rental={{this.booking.rental}}
          data-test-rental-card={{this.booking.rental.id}}
        />

        <div class='d-flex flex-column gap-2'>
          {{#if (gt @conversation.bookings.length 1)}}
            <div
              class='px-3 d-flex align-items-center justify-content-between gap-3'
            >
              <div>
                {{optionLabel
                  this.relativeLabel
                  'bookings.relative_booking_options'
                }}
              </div>

              <div class='d-flex gap-2'>
                <LoadingButton
                  @action={{this.goToPreviousBooking}}
                  @label={{t 'common.previous'}}
                  @disabled={{this.isFirstBooking}}
                  @iconOnly={{true}}
                  class='btn btn-secondary btn-sm'
                  data-test-previous-booking
                >
                  <Icon @icon='chevron-left' />
                </LoadingButton>

                <LoadingButton
                  @action={{this.goToNextBooking}}
                  @label={{t 'common.next'}}
                  @disabled={{this.isLastBooking}}
                  @iconOnly={{true}}
                  class='btn btn-secondary btn-sm'
                  data-test-next-booking
                >
                  <Icon @icon='chevron-right' />
                </LoadingButton>
              </div>
            </div>
          {{/if}}

          <BookingsFloatingCard
            @booking={{this.booking}}
            data-test-booking-card={{this.booking.id}}
          />
        </div>
      {{/if}}
    </div>
  </template>
}

function getClient(conversation: InboxConversationModel) {
  return conversation.firstClientParticipant.member as ClientModel;
}
