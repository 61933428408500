import { service } from '@ember/service';
import type { PromiseObject } from '@ember-data/model';
import type StoreService from 'ember-smily-base/services/store';
import type AccountModel from 'smily-admin-ui/models/account';
import type SessionService from 'smily-admin-ui/services/session-service';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class SettingsRoute extends BaseRoute {
  @service session!: SessionService;
  @service store!: StoreService;

  checkAbilities = true;
  requiredRouteAbility = 'access settings route';

  model(): PromiseObject<AccountModel> {
    const account = this.session.account!;

    return this.store.findRecord('account', account.id, {
      reload: true,
      ...this.store.generateQuery('account', 'settings'),
    });
  }
}
