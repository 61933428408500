export default function getLocalizedHourLabel(
  hour: number | undefined,
  locale: string,
) {
  if (!hour) {
    return '';
  }

  return new Date(0, 0, 0, hour).toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: 'numeric',
  });
}
