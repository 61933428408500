import type { TOC } from '@ember/component/template-only';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import type DiscountCodeModel from 'smily-admin-ui/models/discount-code';

const DiscountsDates: TOC<{
  Element: HTMLDivElement;
  Args: {
    discountCode: DiscountCodeModel;
  };
}> = <template>
  <div ...attributes>
    {{#if @discountCode.endDate}}
      {{formatDate @discountCode.startDate format='longWithWeek'}}
      ➝
      {{formatDate @discountCode.endDate format='longWithWeek'}}
    {{else}}
      {{t
        'finance.discounts.list.from_date'
        date=(formatDate @discountCode.startDate format='longWithWeek')
      }}
    {{/if}}
  </div>
</template>;

export default DiscountsDates;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discounts::Dates': typeof DiscountsDates;
  }
}
