import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingTaxInformation extends TemplatesOnboardingTask {
  actionName = 'completeTaxpayerInformation';

  get attributes() {
    return ['taxIdentificationNumber'];
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.tax_information.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @onSubmit={{this.save}}
            as |F|
          >
            <F.Input @property='taxIdentificationNumber' @required={{true}} />

            <hr class='w-100' />

            <div class='d-flex align-items-center justify-content-between'>
              <OnboardingBackToTaskList />

              <F.Submit
                @label={{t 'common.save'}}
                @disabled={{this.onboardingChangeset.isPristine}}
              />
            </div>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.tax_information.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.tax_information.helper.p1'}}
          </div>

          <div>
            {{t 'onboarding.tax_information.helper.p2'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingTaxInformation);
