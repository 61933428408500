import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Transition } from 'ember-smily-base/utils/routing';
import type SessionService from 'smily-admin-ui/services/session-service';

export default abstract class UnauthenticatedRoute extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  beforeModel(_transition: Transition): void {
    if (this.session.isAuthenticated) {
      this.router.transitionTo('index');
      return;
    }
  }
}
