import { attr, belongsTo, hasMany } from '@ember-data/model';
import {
  addDays,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  startOfDay,
} from 'date-fns';
import { modelAction } from 'ember-custom-actions';
import { LocalDatetime } from 'ember-smily-base/utils/date';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type TaskManagementAttachmentModel from 'smily-admin-ui/models/task-management-attachment';
import type { TaskManagementAttachmentFile } from 'smily-admin-ui/models/task-management-attachment';
import type TaskManagementTaskCommentModel from 'smily-admin-ui/models/task-management-task-comment';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';
import type UserModel from 'smily-admin-ui/models/user';
import { BaseModel } from 'smily-admin-ui/utils/model';
import type { EventSource } from 'smily-admin-ui/utils/tasks';

export const CATEGORIES = [
  'cleaning',
  'midstay_cleaning',
  'pool_cleaning',
  'garden',
  'maintenance',
  'inspection',
  'shopping',
  'check_in',
  'issue',
  'other',
] as const;
export const PRIORITIES = ['low', 'medium', 'high'] as const;
export const STATUSES = ['todo', 'in_progress', 'done', 'canceled'] as const;
export const DUE_AT_OPTIONS = ['today', 'this_week', 'next_week'];

export type EventSourceType = 'Booking';
export type Category = (typeof CATEGORIES)[number];
export type Priority = (typeof PRIORITIES)[number];
export type Status = (typeof STATUSES)[number];
export type DueAt = (typeof DUE_AT_OPTIONS)[number];

export default class TaskManagementTaskModel extends BaseModel {
  @attr name!: string;
  @attr description?: string;
  @attr category!: Category;
  @attr({ defaultValue: 'low' }) priority!: Priority;
  @attr('string', { defaultValue: 'todo' }) status!: Status;
  @attr('local-datetime', {
    defaultValue: () =>
      new LocalDatetime({
        date: startOfDay(addDays(new Date(), 1)),
        time: '09:00',
      }),
  })
  dueAt!: LocalDatetime;
  @attr('number') expectedDurationInMinutes!: number;
  @attr('number') actualDurationInMinutes?: number;
  @attr notes?: string;
  @attr('boolean') hasIssues?: boolean;
  @attr('checklist', { defaultValue: () => '' }) checklist!: string;
  @attr('json-string', { defaultValue: () => '[{}]' }) taskItems!: string;
  @attr('json-string', { defaultValue: () => '[]' })
  checkedTaskItemIds!: string;
  @attr('array', { readOnly: true, defaultValue: () => [] })
  newAttachmentFiles!: TaskManagementAttachmentFile[];

  @belongsTo('event-source', {
    async: false,
    polymorphic: true,
    inverse: null,
  })
  eventSource?: EventSource;

  @belongsTo('user', { async: false, inverse: null })
  assignee?: UserModel;

  @belongsTo('task-management-template', { async: false, inverse: null })
  template?: TaskManagementTemplateModel;

  @hasMany('task-management-task-comment', { async: false, inverse: 'task' })
  comments!: SyncHasMany<TaskManagementTaskCommentModel>;

  @hasMany('task-management-attachment', {
    readOnly: true,
    async: false,
    inverse: null,
  })
  attachments!: SyncHasMany<TaskManagementAttachmentModel>;

  get bulkManagePath() {
    return 'bulk/manage';
  }

  get priorityColor() {
    switch (this.priority) {
      case 'low':
        return 'priority-low';
      case 'medium':
        return 'priority-medium';
      case 'high':
        return 'priority-high';
    }
  }

  get hasTaskItems() {
    return this.taskItems !== '[]';
  }

  get sortedComments() {
    return (this.comments as TaskManagementTaskCommentModel[])
      .filter(({ isNew }) => !isNew)
      .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  }

  get lastComments() {
    return this.sortedComments;
  }

  get overdueDuration():
    | {
        amount: number;
        unit: 'minute' | 'hour' | 'day';
      }
    | undefined {
    if (this.status === 'done' || this.status === 'canceled') {
      return undefined;
    }

    const now = new Date();
    const dueAt = this.dueAt.toDate();
    const minutes = differenceInMinutes(now, dueAt);
    const hours = differenceInHours(now, dueAt);

    if (hours < 0) {
      return undefined;
    } else if (minutes < 60) {
      return { amount: minutes, unit: 'minute' };
    } else if (hours < 24) {
      return { amount: hours, unit: 'hour' };
    }

    return { amount: differenceInCalendarDays(now, dueAt), unit: 'day' };
  }

  handle = modelAction<TaskManagementTaskModel, true>(
    'handle?include=task-items',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );
  manage = modelAction<TaskManagementTaskModel, true>(
    'manage?include=task-items',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'task-management-task': TaskManagementTaskModel;
  }
}
