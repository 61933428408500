import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type BookingPayoutModel from 'smily-admin-ui/models/booking-payout';
import type PaymentModel from 'smily-admin-ui/models/payment';
import type PaymentGatewayModel from 'smily-admin-ui/models/payment-gateway';
import { BaseModel, euro } from 'smily-admin-ui/utils/model';

export default class UpcomingPayoutModel extends BaseModel {
  @attr('number') amountInCents!: number;
  @attr('string') currency?: string;
  @attr('date') estimatedArrivalOn!: Date;
  @attr('date') estimatedPayoutDate!: Date;

  @belongsTo('payment-gateway', { async: false, inverse: null })
  paymentGateway!: PaymentGatewayModel;

  @hasMany('booking-payout', { async: false, inverse: null })
  bookingPayouts!: SyncHasMany<BookingPayoutModel>;

  @hasMany('payment', { async: false, inverse: null })
  payments!: SyncHasMany<PaymentModel>;

  @euro('amountInCents') amount!: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'upcoming-payout': UpcomingPayoutModel;
  }
}
