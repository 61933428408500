import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class ClientSerializer extends ApplicationSerializer<'client'> {
  attrs = {
    firstName: 'firstname',
    lastName: 'lastname',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    client: ClientSerializer;
  }
}
