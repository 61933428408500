import type { Snapshot } from '@ember-data/store';
import type { ModelName } from 'ember-smily-base/utils/store';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

export default class TaskManagementTaskCommentAdapter extends ApplicationAdapter {
  urlForCreateRecord(modelName: ModelName, snapshot: Snapshot) {
    const url = super.urlForCreateRecord(modelName, snapshot);

    return `${url}?include=attachments`;
  }
}
