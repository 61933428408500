import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import Card from 'ember-smily-base/components/card';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import List from 'ember-smily-base/components/list';
import { asString } from 'ember-smily-base/utils/template';
import or from 'ember-truth-helpers/helpers/or';
import { setupBackgroundImage } from 'smily-admin-ui/components/calendars/booking-dialog';
import type RentalModel from 'smily-admin-ui/models/rental';

interface TasksRentalCardSignature {
  Element: HTMLDivElement;
  Args: {
    rental: RentalModel;
  };
}

export default class TasksRentalCard extends Component<TasksRentalCardSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;

  <template>
    <Card class='booking-card-modern' ...attributes>
      <div
        {{didInsert (fn setupBackgroundImage @rental)}}
        class='card-header booking-card-header background-cover'
      >
        <div
          class='position-relative d-flex flex-column gap-2 text-white z-index-1'
        >
          <div class='d-flex flex-column gap-1'>
            <div class='fw-semibold'>
              {{@rental.name}}
            </div>

            <div>
              {{@rental.fullAddress}}
            </div>
          </div>

          <div class='d-flex gap-2'>
            <IconText
              @icon='user-friends'
              @iconStyle='far'
              @text={{asString (or @rental.sleepsMax @rental.sleeps)}}
              @gap={{1}}
            />

            <IconText
              @icon='bath'
              @iconStyle='far'
              @text={{asString @rental.bathroomsCount}}
              @gap={{1}}
            />

            <IconText
              @icon='ruler-combined'
              @iconStyle='far'
              @text={{@rental.area}}
              @gap={{1}}
            />

            <IconText
              @icon='bed-alt'
              @iconStyle='far'
              @text={{asString @rental.bedroomsCount}}
              @gap={{1}}
            />
          </div>
        </div>
      </div>

      <div class='card-body p-0'>
        <List @style='light' as |Item|>
          <Item class='d-flex gap-2'>
            <div>
              <Icon @icon='home-lg-alt' @style='far' @fixedWidth={{true}} />
            </div>

            <div class='flex-1 d-flex flex-column gap-2'>
              <div class='d-flex justify-content-between gap-3'>
                <div>
                  {{t 'internals.fields.bedroomsCount'}}
                </div>

                <div>
                  {{@rental.bedroomsCount}}
                </div>
              </div>

              <div class='d-flex justify-content-between gap-3'>
                <div>
                  {{t 'internals.fields.bathroomsCount'}}
                </div>

                <div>
                  {{@rental.bathroomsCount}}
                </div>
              </div>
            </div>
          </Item>
        </List>
      </div>
    </Card>
  </template>
}
