import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import type { IntlService } from 'ember-intl';
import t from 'ember-intl/helpers/t';
import media from 'ember-responsive/helpers/media';
import RouteTemplate from 'ember-route-template';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import InfiniteList from 'ember-smily-base/components/infinite-list';
import Link from 'ember-smily-base/components/link';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import TopbarActions from 'ember-smily-base/components/topbar/actions';
import type StoreService from 'ember-smily-base/services/store';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import InboxComposeAreaDesktop from 'smily-admin-ui/components/inbox/compose/area-desktop';
import InboxComposeAreaMobile from 'smily-admin-ui/components/inbox/compose/area-mobile';
import InboxConversationActions from 'smily-admin-ui/components/inbox/conversation/actions';
import InboxConversationCard from 'smily-admin-ui/components/inbox/conversation/card';
import InboxConversationFilters from 'smily-admin-ui/components/inbox/conversation/filters';
import InboxConversationRelatedInfo from 'smily-admin-ui/components/inbox/conversation/related-info';
import InboxMessages from 'smily-admin-ui/components/inbox/messages';
import InboxNewConversationTopbarAction from 'smily-admin-ui/components/inbox/new-conversation-topbar-action';
import type InboxConversationController from 'smily-admin-ui/controllers/inbox/conversation';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type { Visibility } from 'smily-admin-ui/models/inbox-message';
import type InboxConversationRoute from 'smily-admin-ui/routes/inbox/conversation';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';
import { addAttachment, deleteAttachment } from 'smily-admin-ui/utils/inbox';
import { isError, queryAI } from 'smily-admin-ui/utils/promise';

interface TemplatesInboxConversationSignature {
  Args: {
    model: ModelFor<InboxConversationRoute>;
    controller: InboxConversationController;
  };
}

class TemplatesInboxConversation extends Component<TemplatesInboxConversationSignature> {
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: StoreService;

  private get route() {
    return getOwner(this).lookup(
      'route:inbox.conversation',
    ) as InboxConversationRoute;
  }

  @action
  private changeVisibility(visibility: Visibility) {
    this.route.refreshModel({
      visibility,
    });
  }

  @action
  private backToTableView() {
    this.router.transitionTo('inbox.index', {
      queryParams: {
        page: this.args.model.conversations.getPageByModel(
          this.args.model.conversation,
        ),
      },
    });
  }

  private send = task({ drop: true }, async (toggleModal?: () => void) => {
    await this.args.model.message.save();

    this.route.refreshModel({
      clearCache: true,
    });
    toggleModal?.();
  });

  private draftWithAi = task({ drop: true }, async () => {
    const response = await queryAI(this, 'inbox-messages/suggest', {
      data: {
        type: 'inbox-messages',
        attributes: {},
        relationships: {
          conversation: {
            data: {
              type: 'inbox-conversations',
              id: this.args.model.conversation.id,
            },
          },
        },
      },
    });

    if (isError(response)) {
      return;
    }

    this.args.model.message.set(
      'content',
      response.data.attributes!.content as string,
    );
  });

  private addAttachment = task({ drop: true }, async (event: Event) => {
    await addAttachment(this.args.model.message, this.store, event);
  });

  private deleteAttachment = task(
    { drop: true },
    async (attachment: InboxAttachmentModel) => {
      await deleteAttachment(this.args.model.message, attachment);
    },
  );

  <template>
    {{#if (media 'isSmallScreen')}}
      <TopbarActions>
        <div class='d-flex align-items-center gap-3'>
          <ModalDialog @type='bottom' as |dialog|>
            <dialog.toggle
              class='btn btn-secondary text-20 p-0 border-0 interactive-icon'
            >
              <Icon @icon='book-open' @style='far' />
            </dialog.toggle>

            <dialog.modal as |modal|>
              <modal.body>
                <InboxConversationRelatedInfo
                  @conversation={{@model.conversation}}
                />
              </modal.body>
            </dialog.modal>
          </ModalDialog>

          <ModalDialog @type='bottom' as |dialog|>
            <dialog.toggle class='btn btn-secondary'>
              <IconText
                @icon='pencil'
                @iconStyle='far'
                @text={{t 'common.manage'}}
              />
            </dialog.toggle>

            <dialog.modal as |modal|>
              <modal.body>
                <InboxConversationActions
                  @conversation={{@model.conversation}}
                />
              </modal.body>
            </dialog.modal>
          </ModalDialog>
        </div>
      </TopbarActions>

      <div class='full-screen-layout h-100 d-flex flex-column'>
        <InboxMessages @messages={{@model.messages}} />

        <InboxComposeAreaMobile
          @changeset={{@model.message}}
          @isAddingAttachments={{this.addAttachment.isRunning}}
          @send={{perform this.send}}
          @draftWithAi={{perform this.draftWithAi}}
          @addAttachment={{perform this.addAttachment}}
          @deleteAttachment={{perform this.deleteAttachment}}
          @changeVisibility={{this.changeVisibility}}
        />
      </div>
    {{else}}
      <InboxNewConversationTopbarAction />

      <div class='full-screen-layout h-100 d-flex'>
        <div class='inbox-fixed-column ps-3 border-end'>
          <Link @action={{this.backToTableView}} class='btn btn-link'>
            <IconText
              @icon='chevron-left'
              @text={{t 'inbox.back_to_table_view'}}
            />
          </Link>

          <InboxConversationFilters
            @model={{@model.conversations}}
            @controller={{@controller}}
            class='border-top'
          />

          <InfiniteList
            @infiniteQuery={{@model.conversations}}
            class='w-100 card-list'
            as |conversation|
          >
            <LinkTo
              @route='inbox.conversation'
              @model={{conversation.id}}
              class='card-list-item p-3'
              data-test-conversation-item={{conversation.id}}
            >
              <InboxConversationCard @model={{conversation}} />
            </LinkTo>
          </InfiniteList>
        </div>

        <div
          class='inbox-conversation-column flex-1 border-end d-flex flex-column'
        >
          <InboxConversationActions
            @conversation={{@model.conversation}}
            class='d-flex gap-3 p-3 pb-0 border-bottom'
          />

          <div class='h-0 flex-1 d-flex flex-column'>
            <InboxMessages @messages={{@model.messages}} />

            <InboxComposeAreaDesktop
              @changeset={{@model.message}}
              @isAddingAttachments={{this.addAttachment.isRunning}}
              @send={{perform this.send}}
              @draftWithAi={{perform this.draftWithAi}}
              @addAttachment={{perform this.addAttachment}}
              @deleteAttachment={{perform this.deleteAttachment}}
              @changeVisibility={{this.changeVisibility}}
            />
          </div>
        </div>

        <div class='inbox-fixed-column p-3'>
          <InboxConversationRelatedInfo @conversation={{@model.conversation}} />
        </div>
      </div>
    {{/if}}
  </template>
}

export default RouteTemplate(TemplatesInboxConversation);
