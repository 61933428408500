import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { Changeset } from 'ember-smily-base/utils/model';
import { createChangeset } from 'ember-smily-base/utils/model';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import { hash } from 'rsvp';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';
import type { Visibility } from 'smily-admin-ui/models/inbox-message';
import type InboxRoute from 'smily-admin-ui/routes/inbox';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';
import { getSender } from 'smily-admin-ui/utils/inbox';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export interface InternalQueryParams {
  visibility?: Visibility;
  clearCache?: boolean;
}

export default class InboxConversationRoute extends BaseRoute {
  @service private cache!: CacheService;
  @service private session!: SessionService;
  @service private store!: StoreService;

  modelParams: InternalQueryParams | undefined;

  get title() {
    return (
      this.currentModel?.conversation.firstClientParticipant?.member
        ?.fullName ?? this.intl.t('breadcrumbs.loading')
    );
  }

  async model({
    inbox_conversation_id: id,
  }: {
    inbox_conversation_id: string;
  }): Promise<{
    conversations: ModelFor<InboxRoute>;
    conversation: InboxConversationModel;
    messages: InfiniteQuery<'inbox-message'>;
    message: Changeset;
  }> {
    // Use cached conversation if no model change
    const conversation =
      id === this.currentModel?.conversation.id
        ? this.currentModel!.conversation
        : await this.store.findRecord('inbox-conversation', id, {
            ...this.store.generateQuery('inbox-conversation'),
            reload: true,
          });
    const conversations = this.modelFor('inbox') as ModelFor<InboxRoute>;
    const cacheEntry = this.cache.inboxCache.get(id);

    // Use cached messages if no model change (only visibility changes)
    const cachedMessages = this.modelParams?.visibility && cacheEntry?.messages;
    const messages =
      cachedMessages ||
      new InfiniteQuery(
        this.store,
        'inbox-message',
        this.store.generateQuery('inbox-message', 'default', {
          conversation: id,
        }),
      );
    const message = this._getNewMessageChangeset(
      conversation,
      this.modelParams?.visibility ?? 'external',
    );

    return hash({ conversations, conversation, messages, message });
  }

  afterModel({ conversation, message }: ModelFor<this>) {
    if (!conversation.read) {
      conversation.read = true;
      conversation.save();
    }

    // Restore message content from cache
    const cache = this.cache.inboxCache.get(conversation.id)?.[
      message.get('visibility') as Visibility
    ];

    if (cache) {
      message.set('content', cache.content);
      message.set('attachments', cache.attachments);
    }

    this.modelParams = undefined;
  }

  @action
  willTransition() {
    const {
      conversation: { id },
      message,
      messages,
    } = this.currentModel!;

    // Save messages to cache
    if (!this.cache.inboxCache.get(id)) {
      this.cache.inboxCache.set(id, { messages });
    }

    const cacheEntry = this.cache.inboxCache.get(id)!;

    cacheEntry.messages = messages;

    const visibility = message.get('visibility') as Visibility;

    if (this.modelParams?.clearCache) {
      // When sending a message, clear the cache for it
      delete cacheEntry[visibility];
    } else {
      // Save message content to cache
      cacheEntry[visibility] = {
        content: message.get('content') as string,
        attachments: message.get('attachments') as InboxAttachmentModel[],
      };
    }
  }

  refreshModel(modelParams: InternalQueryParams) {
    this.modelParams = modelParams;
    return super.refresh();
  }

  private async _getNewMessageChangeset(
    conversation: InboxConversationModel,
    visibility: Visibility,
  ) {
    const sender = await getSender(this, conversation, this.session.host);

    return createChangeset(
      this.store.createRecord('inbox-message', {
        channel:
          visibility === 'external' ? conversation.defaultChannel : undefined,
        visibility,
        conversation,
        sender,
      }),
    );
  }
}
