import type { TOC } from '@ember/component/template-only';
import Icon from 'ember-smily-base/components/icon';
import type RentalModel from 'smily-admin-ui/models/rental';

const RentalsPrimaryPhoto: TOC<{
  Element: HTMLDivElement | HTMLImageElement;
  Args: {
    rental: RentalModel | undefined;
    small?: boolean;
  };
}> = <template>
  {{#if @rental.primaryPhoto}}
    <img
      src={{@rental.primaryPhoto.smallUrl}}
      alt={{@rental.name}}
      class='rental-list-photo {{if @small "small"}}'
      ...attributes
    />
  {{else}}
    <div
      class='rental-list-photo no-photo bg-body d-flex align-items-center justify-content-center
        {{if @small "small"}}'
      ...attributes
    >
      <Icon @icon='camera' class='text-32 text-white' />
    </div>
  {{/if}}
</template>;

export default RentalsPrimaryPhoto;
