import type { TimeUnit } from 'smily-admin-ui/utils/tasks';

export default function serializeUnitDataToMinutes(
  value: string,
  unit: TimeUnit,
) {
  if (!value) {
    return undefined;
  }

  const minutes = Number(value);

  switch (unit) {
    case 'day':
      return minutes * 1440;
    case 'hour':
      return minutes * 60;
    case 'minute':
      return minutes;
  }
}
