import type { TOC } from '@ember/component/template-only';
import BsAlert from 'ember-bootstrap/components/bs-alert';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import LoadingStateLoading from 'ember-smily-base/components/loading-state/loading';

const BookingsFormCalculationPlaceholder: TOC<{
  Element: HTMLDivElement;
  Args: {
    showPlaceholder: boolean;
    isLoading: boolean;
  };
  Blocks: { default: [] };
}> = <template>
  {{#if @isLoading}}
    <BsAlert @type='warning' @dismissible={{false}} class='smily-alert'>
      <div class='d-flex align-items-center gap-2'>
        <LoadingStateLoading class='small' />

        <span>
          {{t 'bookings.calculation_loading'}}
        </span>
      </div>
    </BsAlert>
  {{else if @showPlaceholder}}
    <BsAlert @type='info' @dismissible={{false}} class='smily-alert'>
      <div class='d-flex align-items-center gap-2'>
        <Icon @icon='info-circle' @size='lg' />

        <span>
          {{t 'bookings.calculation_placeholder'}}
        </span>
      </div>
    </BsAlert>
  {{else}}
    {{yield}}
  {{/if}}
</template>;

export default BookingsFormCalculationPlaceholder;
