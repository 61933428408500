import { helper } from '@ember/component/helper';

/**
  Convert bytes into human readable size
*/
const fileSizeHelper = helper(
  ([bytes]: [number?], { si = true }: { si?: boolean }) => {
    const thresh = si ? 1000 : 1024;

    if (!bytes || Math.abs(bytes) < thresh) {
      return `${bytes}B`;
    }

    const siTable = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const nonSiTable = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const units = si ? siTable : nonSiTable;
    let u = -1;
    let byteShifter = bytes;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      byteShifter /= thresh;
      u += 1;

      if (!(Math.abs(byteShifter) >= thresh && u < units.length - 1)) {
        break;
      }
    }

    return `${byteShifter.toFixed(1)}${units[u]}`;
  },
);

export default fileSizeHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-size': typeof fileSizeHelper;
  }
}
