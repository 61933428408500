/* eslint-disable @typescript-eslint/ban-types */

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { callAction, createChangeset } from 'ember-smily-base/utils/model';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';

interface TemplatesOnboardingTaskSignature<Args extends {} = {}> {
  Args: Args;
}

export default abstract class TemplatesOnboardingTask<
  Args extends {} = {},
> extends Component<TemplatesOnboardingTaskSignature<Args>> {
  @service cache!: CacheService;
  @service router!: RouterService;
  @service session!: SessionService;

  abstract actionName: string;
  declare nextRoute: string;
  afterSave?(): Promise<void>;

  get attributes(): string[] {
    return [];
  }

  onboardingChangeset = createChangeset(this.session.onboarding!);

  @action
  async save(): Promise<void> {
    await callAction(
      this.onboardingChangeset,
      this.actionName,
      this.attributes,
    );
    await this.afterSave?.();

    this.cache.updateOnboardingCompletionPercentage();
    this.router.transitionTo(this.nextRoute || 'onboarding.index');
  }
}
