import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class SystemBillingDetailSerializer extends ApplicationSerializer<'system-billing-detail'> {
  attrs = {
    fullName: 'fullname',
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'system-billing-detail': SystemBillingDetailSerializer;
  }
}
