import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type { TaskConfigEntry } from './step-config';

export default class Task {
  route!: string;
  onboarding!: OnboardingModel;
  isComplete!: boolean;
  isHidden!: boolean;
  isLocked!: boolean;

  constructor(
    onboarding: OnboardingModel,
    { route, condition, hidden, locked }: TaskConfigEntry,
  ) {
    this.route = route;
    this.onboarding = onboarding;

    Object.defineProperty(this, 'isComplete', {
      enumerable: true,
      configurable: false,
      get(): boolean {
        // TODO remove when migration to 3RI is completely done
        if (condition.includes(',')) {
          return condition
            .split(',')
            .some((c) => onboarding.completedTasks.includes(c));
        }

        return onboarding.completedTasks.includes(condition);
      },
    });

    Object.defineProperty(this, 'isHidden', {
      enumerable: true,
      configurable: false,
      get(): boolean {
        return !!onboarding[hidden as never];
      },
    });

    Object.defineProperty(this, 'isLocked', {
      enumerable: true,
      configurable: false,
      get(): boolean {
        return !!onboarding[locked as never];
      },
    });
  }
}
