import { fn } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { service } from '@ember/service';
import { differenceInMonths, subMonths } from 'date-fns';
import perform from 'ember-concurrency/helpers/perform';
import FullCalendar from 'ember-planning/components/full-calendar';
import type MediaService from 'ember-responsive';
import RouteTemplate from 'ember-route-template';
import InfiniteList from 'ember-smily-base/components/infinite-list';
import Link from 'ember-smily-base/components/link';
import LoadingState from 'ember-smily-base/components/loading-state';
import SelectInfinite from 'ember-smily-base/components/select/infinite';
import { isMobileApp } from 'ember-smily-base/utils/application';
import { filledArray } from 'ember-smily-base/utils/array';
import { removeElement } from 'ember-smily-base/utils/dom';
import eq from 'ember-truth-helpers/helpers/eq';
import CalendarsBookingDialog from 'smily-admin-ui/components/calendars/booking-dialog';
import CalendarsIcalExport from 'smily-admin-ui/components/calendars/ical-export';
import CalendarsLayout from 'smily-admin-ui/components/calendars/layout';
import type CalendarMonthController from 'smily-admin-ui/controllers/calendar/month';
import type CalendarModel from 'smily-admin-ui/models/calendar';
import type CalendarMonthRoute from 'smily-admin-ui/routes/calendar/month';
import type SessionService from 'smily-admin-ui/services/session-service';
import TemplatesCalendarBase from 'smily-admin-ui/templates/calendar/-base';

class TemplatesCalendarMonth extends TemplatesCalendarBase<
  CalendarMonthRoute,
  CalendarMonthController
> {
  @service media!: MediaService;
  @service session!: SessionService;

  get isMobile() {
    return this.media.isSmallScreen || isMobileApp;
  }

  get bookingQuery() {
    return this.store.generateQuery('booking', 'calendar');
  }

  get rental(): CalendarModel | undefined {
    if (!this.args.controller.activeRental) {
      return undefined;
    }

    return this.store.peekRecord(
      'calendar',
      this.args.controller.activeRental,
    ) as CalendarModel;
  }

  get cols(): 1 | 2 | 3 | 4 {
    const { isSmallScreen, isTablet, isDesktop } = this.media;

    if (isSmallScreen) {
      return 1;
    } else if (isTablet) {
      return 2;
    } else if (isDesktop) {
      return 3;
    } else {
      return 4;
    }
  }

  get monthDates(): Date[] {
    const dates = super.monthDates;
    const { cols, date } = this;
    const { minDate } = this.args.model;

    const diff = cols - (differenceInMonths(date, minDate) % cols);

    if (!diff) {
      return dates;
    }

    const extraMonths = filledArray(diff)
      .map((index) => subMonths(minDate, index + 1))
      .reverse();

    return [...extraMonths, ...dates];
  }

  @action
  handleRentalChange(rental?: CalendarModel) {
    if (rental) {
      if (this.args.controller.activeRental === rental.id) {
        return;
      }

      this.args.controller.changeQueryParam('activeRental', rental.id);
    }

    this.calendarAPI.scrollToDate(new Date());
  }

  <template>
    <div
      {{didInsert removeElement}}
      {{didUpdate
        this.refreshRoute
        @controller.rental
        @controller.destination
        @controller.availableOn
        @controller.sleeps
        @controller.bedrooms
        @controller.rentalTag
        @controller.rentalType
        @controller.amenities
      }}
    >
    </div>

    <FullCalendar
      @date={{this.date}}
      @rentalId={{@controller.activeRental}}
      @minDate={{this.minDate}}
      @maxDate={{@model.maxDate}}
      @cols={{this.cols}}
      @preferredWeekOffset={{this.session.user.preferredWeekOffset}}
      @allBookings={{this.allBookings}}
      @getBookingPillClass={{this.getBookingPillClass}}
      @findBooking={{this.findBooking}}
      @onBookingClick={{perform this.showBookingDetails}}
      @onChange={{this.updateCalendarState}}
      @onSelect={{this.transitionToNewBooking}}
      as |Calendar publicAPI|
    >
      <CalendarsLayout
        {{didInsert (fn this.setupAPI publicAPI)}}
        {{didUpdate
          (fn this.handleRentalChange undefined)
          @controller.activeRental
        }}
        @rentals={{@model.rentals}}
        @date={{@controller.date}}
        @months={{this.months}}
        @rental={{@controller.rental}}
        @destination={{@controller.destination}}
        @availableOn={{@controller.availableOn}}
        @sleeps={{@controller.sleeps}}
        @bedrooms={{@controller.bedrooms}}
        @rentalTag={{@controller.rentalTag}}
        @rentalType={{@controller.rentalType}}
        @amenities={{@controller.amenities}}
        @belowCalendarComponent={{component
          CalendarsIcalExport
          url=this.rental.icalUrl
        }}
        @scrollToDate={{fn this.scrollToDate publicAPI.scrollToDate}}
        @changeQueryParam={{@controller.changeQueryParam}}
      >
        <:aboveCalendar>
          {{#if this.isMobile}}
            <SelectInfinite
              @value={{this.rental}}
              @modelName='calendar'
              @query={{@model.query}}
              @displayField='name'
              @triggerClass='calendar-month-rental-select'
              @onChange={{this.handleRentalChange}}
            />
          {{/if}}
        </:aboveCalendar>

        <:calendar>
          {{#if this.isMobile}}
            <Calendar class='h-100' />
          {{else}}
            <div class='d-flex h-100'>
              <LoadingState
                @isLoading={{@controller.isLoadingRentals}}
                class='px-4 bg-body border-end full-calendar-rental-column'
              >
                <div class='d-flex flex-column overflow-y-auto'>
                  <InfiniteList
                    @infiniteQuery={{@model.rentals}}
                    @type='list'
                    @linked={{true}}
                    @style='alternate'
                    @yieldItem={{false}}
                    class='flex-shrink-0 p-0 overflow-auto full-calendar-rental-column'
                    as |rental|
                  >
                    <Link
                      @action={{fn this.handleRentalChange rental}}
                      class='link-unstyled link-open-right text-truncate
                        {{if (eq rental.id @controller.activeRental) "active"}}'
                    >
                      {{rental.name}}
                    </Link>
                  </InfiniteList>

                  <div class='flex-1 w-100 bg-body border-end border-top'></div>
                </div>
              </LoadingState>

              <Calendar />
            </div>
          {{/if}}
        </:calendar>
      </CalendarsLayout>
    </FullCalendar>

    <CalendarsBookingDialog
      @booking={{this.booking}}
      @isOpen={{this.isModalOpen}}
      @isLoading={{this.showBookingDetails.isRunning}}
      @closeModal={{fn (mut this.isModalOpen) false}}
      @transitionToBooking={{this.transitionToBooking}}
    />
  </template>
}

export default RouteTemplate(TemplatesCalendarMonth);
