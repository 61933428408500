import { addMonths, addYears, getDaysInMonth } from 'date-fns';
import type IntlService from 'ember-intl/services/intl';
import type { SelectOption } from 'ember-smily-base/utils/select';

const TENTATIVE_EXPIRATION_OPTIONS = [
  { value: 1, unit: 'day', key: '1_day' },
  { value: 2, unit: 'day', key: '2_days' },
  { value: 3, unit: 'day', key: '3_days' },
  { value: 7, unit: 'day', key: '1_week' },
  { value: 14, unit: 'day', key: '2_weeks' },
  { value: 1, unit: 'month', key: '1_month' },
  { value: 2, unit: 'month', key: '2_months' },
  { value: 1, unit: 'year', key: '1_year' },
];

export default function getTentativeExpirationOptions(intl: IntlService) {
  const daysInNextMonth = getDaysInMonth(addMonths(new Date(), 1));
  const daysInNextNextMonth = getDaysInMonth(addMonths(new Date(), 2));
  const daysInYear = getDaysInMonth(addYears(new Date(), 1));

  return TENTATIVE_EXPIRATION_OPTIONS.map(({ value, unit, key }) => {
    const label = intl.t(`bookings.tentative_expiration_options.${key}`);

    let strValue;

    if (unit === 'day') {
      strValue = value.toString();
    } else if (key === '1_month') {
      strValue = daysInNextMonth.toString();
    } else if (key === '2_months') {
      strValue = (daysInNextMonth + daysInNextNextMonth).toString();
    } else {
      strValue = daysInYear.toString();
    }

    return {
      label,
      value: strValue,
    } as SelectOption<string>;
  });
}
