import { fn } from '@ember/helper';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import MediaPlaceholder from 'ember-smily-base/components/media-placeholder';
import MultilineText from 'ember-smily-base/components/multiline-text';
import UserAvatar from 'ember-smily-base/components/user-avatar';
import ensure from 'ember-smily-base/helpers/ensure';
import type MediaViewerService from 'ember-smily-base/services/media-viewer';
import { isPDF, isVideo } from 'ember-smily-base/utils/file';
import or from 'ember-truth-helpers/helpers/or';
import InboxChannelIcon from 'smily-admin-ui/components/inbox/channel-icon';
import MessageTimestamp from 'smily-admin-ui/components/message-timestamp';
import type { Channel } from 'smily-admin-ui/models/inbox-message';

interface MessageSignature {
  Args: {
    content?: string;
    sentAt: Date;
    channel?: Channel;
    files: string[];
    isOwned: boolean;
    isNote?: boolean;
    isByMe?: boolean;
    senderName?: string;
    senderProfilePicture?: string;
  };
}

export default class Message extends Component<MessageSignature> {
  @service intl!: IntlService;
  @service mediaViewer!: MediaViewerService;

  get senderMetaName() {
    return this.args.isByMe ? this.intl.t('common.me') : this.args.senderName;
  }

  <template>
    <div class='message {{if @isOwned "owned"}} {{if @isNote "note"}}'>
      <div class='message-avatar'>
        <UserAvatar
          @name={{ensure @senderName}}
          @image={{@senderProfilePicture}}
          @small={{true}}
          @color={{if @isOwned 'teal-50' 'white'}}
        />
      </div>

      <div class='message-content'>
        {{#if @content}}
          <MultilineText @text={{@content}} class='message-bubble' />
        {{/if}}

        {{#if @files.length}}
          <div class='d-flex flex-wrap {{if @isOwned "justify-content-end"}}'>
            {{#each @files as |file index|}}
              <Link
                @action={{fn this.mediaViewer.open @files index}}
                class='btn btn-link p-0'
              >
                {{#if (or (isPDF file) (isVideo file))}}
                  <div class='media-thumbnail inbox-attachment on-gray'>
                    <MediaPlaceholder @file={{file}} />
                  </div>
                {{else}}
                  <img
                    src={{file}}
                    class='media-thumbnail inbox-attachment'
                    alt={{file}}
                  />
                {{/if}}
              </Link>
            {{/each}}
          </div>
        {{/if}}

        <ul
          class='list-inline dotted message-meta text-12'
          data-test-message-meta
        >
          {{#if @isOwned}}
            <li>
              <MessageTimestamp @sentAt={{@sentAt}} />
            </li>

            {{#if @isNote}}
              <li>
                <IconText
                  @icon='eye-slash'
                  @iconStyle='far'
                  @text={{t 'inbox.message_visibiliy_types.internal'}}
                  @gap={{1}}
                />
              </li>
            {{else if @channel}}
              <li>
                <InboxChannelIcon @channel={{@channel}} @iconClass='text-16' />
              </li>
            {{/if}}

            <li>
              {{this.senderMetaName}}
            </li>
          {{else}}
            <li>
              {{this.senderMetaName}}
            </li>

            {{#if @channel}}
              <li>
                <InboxChannelIcon @channel={{@channel}} @iconClass='text-16' />
              </li>
            {{/if}}

            <li>
              <MessageTimestamp @sentAt={{@sentAt}} />
            </li>
          {{/if}}
        </ul>
      </div>
    </div>
  </template>
}
