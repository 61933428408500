import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import { isMobileApp } from 'ember-smily-base/utils/application';
import type { Transition } from 'ember-smily-base/utils/routing';
import type ReviewModel from 'smily-admin-ui/models/review';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class ReviewsGuestReviewRoute extends BaseRoute {
  @service coreLinks!: CoreLinksService;
  @service store!: StoreService;

  get title() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const name = this.currentModel?.booking.client.fullName!;
    return isMobileApp || this.media.isSmallScreen
      ? name
      : this.intl.t('reviews.guest_review_breadcrumb', {
          name: this.currentModel?.booking.client.fullName,
        });
  }

  model({ review_id: id }: { review_id: string }) {
    return this.store.findRecord(
      'review',
      id,
      this.store.generateQuery('review'),
    );
  }

  async afterModel(model: ReviewModel) {
    await this.store.query(
      'host-review',
      this.store.generateQuery('host-review', 'sideCard', {
        booking: model.booking.id,
      }),
    );

    if (!model.reviewReply) {
      model.reviewReply = this.store.createRecord('review-reply');
    }
  }

  releaseNotAccessible(transition: Transition): void {
    this.coreLinks.redirect('reviews', transition.to.params.review_id);
  }

  @action
  async willTransition(transition: Transition) {
    await transition;

    this.store
      .peekAll('review-reply')
      .filter((reply) => reply.isNew)
      .forEach((reply) => {
        reply.destroyRecord();
      });
  }
}
