import { attr } from '@ember-data/model';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ServiceFeePaymentInvoiceModel extends BaseModel {
  @attr('number') amount!: number;
  @attr currency?: string;
  @attr url?: string;
  @attr('date') invoicedAt!: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'service-fee-payment-invoice': ServiceFeePaymentInvoiceModel;
  }
}
