import { attr, belongsTo } from '@ember-data/model';
import type ClientModel from 'smily-admin-ui/models/client';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ClientPhoneModel extends BaseModel {
  @attr('string', { defaultValue: '' }) label?: string;
  @attr('string', { defaultValue: '' }) number?: string;
  @attr('boolean') primary?: boolean;
  @attr('object', { readOnly: true }) lock?: Record<string, unknown>;

  @belongsTo('client', { async: false, inverse: 'phones' })
  client!: ClientModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'client-phone': ClientPhoneModel;
  }
}
