import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import NavTabs from 'ember-smily-base/components/nav-tabs';

export default class FinanceListingScreenTabs extends Component {
  @service abilities!: AbilitiesService;

  get tabs() {
    const tabs = ['finance.completed-payouts', 'finance.upcoming-payouts'];

    if (this.abilities.can('access finance gross earnings route')) {
      tabs.push('finance.gross-earnings');
    }

    return tabs;
  }

  <template>
    <NavTabs
      @config={{this.tabs}}
      class='border-bottom px-3 px-lg-0 mb-lg-3 mt-2 mt-lg-0'
    />
  </template>
}
