import type MediaService from 'ember-responsive';
import { isMobile } from 'ember-smily-base/utils/application';

export default function annotateState(media: MediaService) {
  if (!isMobile(media) || window.history.state.isSmilyEntryPoint) {
    return;
  }

  window.history.replaceState(
    { ...window.history.state, isSmilyEntryPoint: true },
    '',
  );
}
