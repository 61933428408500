import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OnboardingIndexRoute extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  afterModel() {
    const onboarding = this.session.onboarding as OnboardingModel;

    if (onboarding.completedAt) {
      this.router.transitionTo('onboarding.profile-completed');
    } else if (onboarding.isAwaitingSirenSetup) {
      this.router.transitionTo('onboarding.payment-account-verification');
    }
  }
}
