import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import InboxPriorityBadge from 'smily-admin-ui/components/inbox/priority-badge';
import { PRIORITIES } from 'smily-admin-ui/models/inbox-message';

const InboxPriorityInfoModal: TOC<{}> = <template>
  <div class='d-flex flex-column gap-3'>
    <h3>
      {{t 'inbox.ai_tag_info_modal_title'}}
    </h3>

    <div>
      {{t 'inbox.ai_tag_info_modal_description'}}
    </div>

    <hr class='w-100' />

    <div>
      <span class='fw-semibold'>
        {{t 'inbox.ai_tag_priority_info_modal_item.1'}}
      </span>

      {{t 'inbox.ai_tag_priority_info_modal_item.2'}}
    </div>

    <div class='d-flex gap-1'>
      {{#each PRIORITIES as |priority|}}
        <InboxPriorityBadge @priority={{priority}} />
      {{/each}}
    </div>

    <hr class='w-100' />
  </div>
</template>;

export default InboxPriorityInfoModal;
