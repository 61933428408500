import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import media from 'ember-responsive/helpers/media';
import List from 'ember-smily-base/components/list';
import LoadingState from 'ember-smily-base/components/loading-state';
import Pagination from 'ember-smily-base/components/pagination';
import type { InfiniteQuery } from 'ember-smily-base/utils/store';
import BookingsBookingItemDesktop from 'smily-admin-ui/components/bookings/booking-item/desktop';
import BookingsBookingItemMobile from 'smily-admin-ui/components/bookings/booking-item/mobile';

const ClientsPastBookingsTab: TOC<{
  Element: HTMLUListElement;
  Args: {
    pastBookings: InfiniteQuery<'booking'>;
    pastBookingsPage: number;
    loadPastBookings: (page?: number) => void;
  };
}> = <template>
  <LoadingState @model={{@pastBookings}} class='h-100 py-3'>
    <List @linked={{true}} as |Item|>
      {{#each @pastBookings.options as |booking|}}
        <Item>
          <LinkTo
            @route='bookings.booking'
            @model={{booking.id}}
            class='link-unstyled'
          >
            {{#if (media 'isSmallScreen')}}
              <BookingsBookingItemMobile @booking={{booking}} />
            {{else}}
              <BookingsBookingItemDesktop @booking={{booking}} />
            {{/if}}
          </LinkTo>
        </Item>
      {{/each}}
    </List>

    <Pagination
      @page={{@pastBookingsPage}}
      @total={{@pastBookings.pages}}
      @onChange={{@loadPastBookings}}
      class='p-3 border-top'
    />
  </LoadingState>
</template>;

export default ClientsPastBookingsTab;
