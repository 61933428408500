import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type MediaService from 'ember-responsive';
import type { Transition } from 'ember-smily-base/utils/routing';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { disableRouteForMobile } from 'smily-admin-ui/utils/routing';

export default class XSettingsRoute extends Route {
  @service coreLinks!: CoreLinksService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service notify!: NotifyService;

  beforeModel(transition: Transition): void {
    if (disableRouteForMobile(this, transition)) {
      return;
    }

    const targetRoute = transition.to.find(
      (route) => route.parent?.name === 'x-settings',
    );

    if (!targetRoute) {
      return;
    }

    this.coreLinks.redirect(targetRoute.name);
  }
}
