import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class TaskManagementTemplateAbility extends Ability<'task-management-template'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'task-management-template');
  }
}
