import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingBank extends TemplatesOnboardingTask {
  actionName = 'completeBankDetails';

  get attributes(): string[] {
    return ['iban', 'bic', 'ibanDocument'];
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.bank.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @onSubmit={{this.save}}
            as |F|
          >
            <List @large={{true}} @sticky={{true}} as |Item|>
              <Item class='d-flex flex-column gap-3'>
                <div><i>{{t 'onboarding.bank.helper.1.p1'}}</i></div>

                <div><i>{{t 'onboarding.bank.helper.1.p2'}}</i></div>

                <div class='d-flex flex-column flex-lg-row gap-3'>
                  <F.Input @property='iban' @required={{true}} class='flex-2' />

                  <F.Input @property='bic' @required={{true}} class='flex-1' />
                </div>

                <F.File @property='ibanDocumentFile' @required={{true}} />
              </Item>

              <Item class='d-flex align-items-center justify-content-between'>
                <OnboardingBackToTaskList />

                <F.Submit
                  @label={{t 'common.save'}}
                  @disabled={{this.onboardingChangeset.isPristine}}
                />
              </Item>
            </List>
          </Form>
        </div>
      </:content>

      <:helper>
        {{! TODO check other multi-section onboarding task helpers for gap-5 }}
        <div class='d-flex flex-column gap-5'>
          <div class='d-flex flex-column gap-2'>
            <div class='font-weight-semibold'>
              {{t 'onboarding.bank.helper.2.title'}}
            </div>

            <div>{{t 'onboarding.bank.helper.2.p1'}}</div>

            <div>{{t 'onboarding.bank.helper.2.p2'}}</div>
          </div>

          <div class='d-flex flex-column gap-2'>
            <div class='font-weight-semibold'>
              {{t 'onboarding.bank.helper.3.title'}}
            </div>

            <div>{{t 'onboarding.bank.helper.3.p1'}}</div>

            <div>{{t 'onboarding.bank.helper.3.p2' htmlSafe=true}}</div>

            <div>{{t 'onboarding.bank.helper.3.p3' htmlSafe=true}}</div>
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingBank);
