import { action, setProperties } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import RouteTemplate from 'ember-route-template';
import AnnotatedCard from 'ember-smily-base/components/annotated-card';
import ExternalLink from 'ember-smily-base/components/external-link';
import { getShortLocale } from 'ember-smily-base/utils/intl';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import type { SavedCardData } from 'smily-admin-ui/components/credit-card-form';
import CreditCardForm from 'smily-admin-ui/components/credit-card-form';
import config from 'smily-admin-ui/config/environment';
import type SettingsBillingCreditCardRoute from 'smily-admin-ui/routes/settings/billing/credit-card';
import type SessionService from 'smily-admin-ui/services/session-service';

interface TemplatesSettingsBillingCreditCardSignature {
  Args: {
    model: ModelFor<SettingsBillingCreditCardRoute>;
  };
}

class TemplatesSettingsBillingCreditCard extends Component<TemplatesSettingsBillingCreditCardSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;
  @service session!: SessionService;

  get email() {
    return (
      this.args.model.subscription.systemBillingDetail.email ||
      this.session.user!.email!
    );
  }

  get shortLocale(): string {
    return getShortLocale(this.intl.primaryLocale).toLowerCase();
  }

  get privacyUrl(): string {
    return new URL(
      `${this.shortLocale}/privacy-policy`,
      config.API.HOST,
    ).toString();
  }

  get termsUrl(): string {
    return new URL(
      `${this.shortLocale}/terms-of-service`,
      'https://smily.com',
    ).toString();
  }

  @action
  async saveAndNotify(data: SavedCardData): Promise<void> {
    setProperties(this.args.model.subscription, data);

    await this.args.model.subscription.save();
    await this.router.transitionTo('settings.billing.index');

    this.notify.success(this.intl.t('settings.billing.credit_card_updated'));
  }

  <template>
    <div class='p-3 d-flex flex-column gap-3'>
      <AnnotatedCard @title={{t 'common.credit_card'}}>
        <CreditCardForm
          @email={{this.email}}
          @afterSave={{this.saveAndNotify}}
          as |form|
        >
          <div class='d-flex justify-content-between'>
            <div>
              <div>
                {{t 'settings.billing.credit_card_legal.1'}}

                <ExternalLink
                  @url={{this.termsUrl}}
                  @label={{t 'settings.billing.credit_card_legal.2'}}
                />

                {{t 'settings.billing.credit_card_legal.3'}}

                <ExternalLink
                  @url={{this.privacyUrl}}
                  @label={{t 'settings.billing.credit_card_legal.4'}}
                />
              </div>

              <div>
                {{t 'settings.billing.credit_card_legal.5'}}
              </div>
            </div>

            <form.Submit>
              {{t 'common.save_changes'}}
            </form.Submit>
          </div>
        </CreditCardForm>
      </AnnotatedCard>
    </div>
  </template>
}

export default RouteTemplate(TemplatesSettingsBillingCreditCard);
