import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import SelectInfinite from 'ember-smily-base/components/select/infinite';
import type StoreService from 'ember-smily-base/services/store';
import type { CalculatePosition } from 'ember-smily-base/utils/dropdown';
import type { AnyComponent } from 'ember-smily-base/utils/glimmer';
import type AccountModel from 'smily-admin-ui/models/account';
import type SessionService from 'smily-admin-ui/services/session-service';

interface AccountSwitcherSignature {
  Element: HTMLDivElement;
  Args: {
    triggerClass?: string;
    positionAccountSwitcher?: CalculatePosition;
    renderInPlace?: boolean;
    triggerComponent: AnyComponent;
  };
  Blocks: { default: [AccountModel] };
}

export default class AccountSwitcher extends Component<AccountSwitcherSignature> {
  @service session!: SessionService;
  @service store!: StoreService;

  get accountSwitcherQuery() {
    return {
      ...this.store.generateQuery('account', 'accountSwitcher'),
      filter: {
        id: {
          op: 'not_eq',
          value: this.session.account!.id,
        },
      },
    };
  }

  @action
  switchAccount(account: AccountModel | undefined) {
    this.session.switchAccount.perform(account!.id);
  }

  <template>
    <SelectInfinite
      @modelName='account'
      @query={{this.accountSwitcherQuery}}
      @displayField='businessName'
      @searchField={{if this.session.user.superuser 'businessName'}}
      @loose={{true}}
      @matchTriggerWidth={{true}}
      @renderInPlace={{@renderInPlace}}
      @triggerClass={{@triggerClass}}
      @dropdownClass='account-switcher-dropdown'
      @triggerComponent={{@triggerComponent}}
      @onChange={{this.switchAccount}}
      @calculatePosition={{@positionAccountSwitcher}}
      as |account|
    >
      {{yield account}}
    </SelectInfinite>
  </template>
}
