import type { PromiseObject } from '@ember-data/model';
import MarkdownToHtml from 'ember-cli-showdown/components/markdown-to-html';
import findBy from 'ember-composable-helpers/helpers/find-by';
import Breadcrumbs from 'ember-smily-base/components/breadcrumbs';
import LoadingState from 'ember-smily-base/components/loading-state';
import Notifications from 'ember-smily-base/components/notifications';
import Select from 'ember-smily-base/components/select/simple';
import SmilyLayoutRouteIcon from 'ember-smily-base/components/smily-layout/route-icon';
import ensure from 'ember-smily-base/helpers/ensure';
import UnauthenticatedScreen from 'smily-admin-ui/components/unauthenticated-screen';
import type LegalTermModel from 'smily-admin-ui/models/legal-term';

interface Args {
  legalTerm: PromiseObject<LegalTermModel>;
}

export default class SignupLegalTerms extends UnauthenticatedScreen<Args> {
  <template>
    <div class='h-100 d-flex flex-column'>
      <div class='smily-topbar'>
        <div class='container-xl d-flex justify-content-between'>
          <div class='d-flex align-items-center'>
            <div class='smily-topbar-icon'>
              <SmilyLayoutRouteIcon />
            </div>

            <Breadcrumbs class='flex-1' />
          </div>

          <div class='d-flex align-items-center'>
            <Select
              @value={{findBy 'value' this.intl.primaryLocale this.languages}}
              @options={{this.languages}}
              @triggerClass='btn btn-link link-unstyled btn-sm with-smily-select-icon'
              @onChange={{this.changeLocale}}
              as |item|
            >
              <div class='d-flex align-items-center gap-1'>
                <div class='smily-flag {{item.flag}}'></div>

                <div>{{item.label}}</div>
              </div>
            </Select>
          </div>
        </div>
      </div>

      <div class='flex-1 overflow-hidden bg-white'>
        <LoadingState @model={{@legalTerm}} class='h-100'>
          <div class='h-100 overflow-auto'>
            <div class='container-xl'>
              <MarkdownToHtml
                @markdown={{ensure @legalTerm.content.content}}
                @classNames='legal-terms'
              />
            </div>
          </div>
        </LoadingState>
      </div>
    </div>

    <Notifications />
  </template>
}
