import type { TOC } from '@ember/component/template-only';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import StatusText from 'smily-admin-ui/components/status-text';
import bookingStatus from 'smily-admin-ui/helpers/booking-status';
import type BookingModel from 'smily-admin-ui/models/booking';

const BookingsBookingStatus: TOC<{
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
    verbose?: boolean;
  };
}> = <template>
  {{#let (lowercase @booking.status) as |status|}}
    <StatusText
      @modelName='booking'
      @status={{status}}
      @text={{bookingStatus @booking verbose=@verbose}}
    />
  {{/let}}
</template>;

export default BookingsBookingStatus;
