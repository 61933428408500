import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import eq from 'ember-truth-helpers/helpers/eq';
import type RentalModel from 'smily-admin-ui/models/rental';

interface RentalsRoomsAndGuestsSignature {
  Element: HTMLDivElement;
  Args: {
    rental: RentalModel;
  };
}

export default class RentalsRoomsAndGuests extends Component<RentalsRoomsAndGuestsSignature> {
  get guestsCount() {
    const { rental } = this.args;
    const sleeps = rental.sleeps ?? 0;

    if (!rental.sleepsMax) {
      return `${sleeps}`;
    }

    return `${sleeps}–${rental.sleepsMax}`;
  }

  <template>
    <div ...attributes>
      <span class='font-weight-semibold'>
        {{@rental.bedroomsCount}}
      </span>

      {{t 'rentals.bedrooms_count' count=@rental.bedroomsCount}},

      <span class='font-weight-semibold'>
        {{@rental.bathroomsCount}}
      </span>

      {{t 'rentals.bathrooms_count' count=@rental.bathroomsCount}},

      <span class='font-weight-semibold'>
        {{this.guestsCount}}
      </span>

      {{#if (eq this.guestsCount '1')}}
        {{t 'rentals.guest'}},
      {{else}}
        {{t 'rentals.guests'}},
      {{/if}}

      <span class='font-weight-semibold'>
        {{@rental.area}}
      </span>
    </div>
  </template>
}
