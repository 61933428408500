import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class ApplicationController extends Controller {
  @service router!: RouterService;

  queryParams = ['locale', 'returnPath'];

  declare locale?: string;
  declare returnPath?: string;

  constructor() {
    super(...arguments); // eslint-disable-line

    this.router.on('routeDidChange', this.showSubmenu);
  }

  showSubmenu() {
    const submenuContainer = document.getElementById(
      'submenu-scroll-container',
    );

    if (submenuContainer) {
      submenuContainer.style.display = 'block';
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    application: ApplicationController;
  }
}
