import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import LoadingState from 'ember-smily-base/components/loading-state';

interface TasksTemplateAddFilesSignature {
  Element: HTMLDivElement;
  Args: {
    disabled: boolean;
    onChange: (event: Event) => Promise<void>;
  };
}

export default class TasksTemplateAddFiles extends Component<TasksTemplateAddFilesSignature> {
  get id() {
    return guidFor(this);
  }

  @action
  clickInput() {
    document.getElementById(this.id)!.click();
  }

  <template>
    <div class='file-input' ...attributes>
      {{! template-lint-disable require-input-label }}
      <input
        {{on 'change' @onChange}}
        type='file'
        id={{this.id}}
        accept='image/*,video/*,.pdf'
        multiple
        aria-label={{t 'tasks.task_form.add_files_and_media'}}
        tabindex='-1'
      />
      {{! template-lint-enable require-input-label }}

      <Link
        @action={{this.clickInput}}
        class='tasks-add-media file-input-mask'
        disabled={{@disabled}}
      >
        <div class='d-flex align-items-center gap-1'>
          {{#if @disabled}}
            <LoadingState @isLoading={{true}} class='small' />
          {{else}}
            <Icon @icon='plus' @style='far' />
            <div class='fw-semibold'>
              {{t 'tasks.task_form.add_files_and_media'}}
            </div>
            <Icon @icon='image' @style='far' />
          {{/if}}
        </div>
      </Link>
    </div>
  </template>
}
