export default function serializeMinutesToUnitData(minutes: number) {
  if (!(minutes % 1440)) {
    return {
      value: String(minutes / 1440),
      unit: 'day',
    };
  } else if (!(minutes % 60)) {
    return {
      value: String(minutes / 60),
      unit: 'hour',
    };
  } else {
    return {
      value: String(minutes),
      unit: 'minute',
    };
  }
}
