import Controller from '@ember/controller';

export default class ConfirmEmailController extends Controller {
  queryParams = ['email', 'backPath'];

  declare email: string;
  declare backPath: string;
}

declare module '@ember/controller' {
  interface Registry {
    'confirm-email': ConfirmEmailController;
  }
}
