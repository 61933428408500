import type { TOC } from '@ember/component/template-only';
import IconsSmily from 'smily-admin-ui/components/icons/smily';

const SignupSkisetHeader: TOC<{
  Element: HTMLDivElement;
  Args: {
    brand: string;
  };
}> = <template>
  {{! template-lint-disable no-bare-strings }}

  <div class='d-flex flex-column gap-2 mb-2' ...attributes>
    <img src='/assets/images/{{@brand}}.svg' alt={{@brand}} />

    <div class='d-flex justify-content-end'>
      <div>
        <div class='text-16 fw-semibold'>
          powered by
        </div>

        <div
          class='powered-by-smily-logo letter-spacing-n1 d-flex align-items-center gap-1 text-40 fw-semibold'
        >
          <IconsSmily />

          Smily
        </div>
      </div>
    </div>
  </div>
</template>;

export default SignupSkisetHeader;
