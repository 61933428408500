import { loadScript } from 'ember-smily-base/utils/dom';
import config from 'smily-admin-ui/config/environment';

export default function show(): void {
  if (config.environment === 'development') {
    return;
  }

  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load();
  } else {
    loadScript(`//js.hs-scripts.com/${config.hubspot.id}.js`);
  }
}
