import { nextRender } from 'ember-smily-base/utils/runloop';
import { getService } from 'ember-smily-base/utils/application';
import HostModel from 'smily-admin-ui/models/host';
import type InboxConversationModel from 'smily-admin-ui/models/inbox-conversation';
import type { InboxParticipantMemberModel } from 'smily-admin-ui/models/inbox-participant';

export default async function getSender(
  context: object,
  conversation: InboxConversationModel,
  member?: InboxParticipantMemberModel,
) {
  if (!member) {
    return undefined;
  }

  const existingParticipant = conversation.participants.find(
    (participant) => participant.member === member,
  );

  if (existingParticipant || isSuperuser(member)) {
    return existingParticipant;
  }

  const store = getService(context, 'store');

  await nextRender();

  const participant = store.createRecord('inbox-participant', {
    conversation,
    member,
  });

  await participant.save();

  return participant;
}

function isSuperuser(member: InboxParticipantMemberModel) {
  return member instanceof HostModel && !!member.user?.superuser;
}
