import { getOwner } from '@ember/application';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import Icon from 'ember-smily-base/components/icon';
import type { Owner } from 'ember-smily-base/utils/application';
import { getService } from 'ember-smily-base/utils/application';
import { loadScript } from 'ember-smily-base/utils/dom';
import type { AvailableLocaleLong } from 'ember-smily-base/utils/intl/dictionary';
import config from 'smily-admin-ui/config/environment';
import type SessionService from 'smily-admin-ui/services/session-service';

declare global {
  interface Window {
    Headway: {
      init(headwayConfig: HeadwayConfig): void;
    };
  }
}

export interface HeadwayConfig {
  selector: string;
  account: string;
  translations: {
    [index: string]: string;
  };
}

export function getHeadwayUrl(context: object) {
  const intl = getService(context, 'intl');
  const session = getOwner(context).lookup('service:session') as SessionService;
  const locale = intl.primaryLocale as AvailableLocaleLong;
  const isSmily = session.account?.isSmily;

  return config.headway[isSmily ? 'smily' : 'bookingsync']!.urls![locale];
}

interface SidebarFooterHeadwaySignature {
  Element: HTMLLIElement;
}

export default class SidebarFooterHeadway extends Component<SidebarFooterHeadwaySignature> {
  @service intl!: IntlService;
  @service session!: SessionService;

  constructor(owner: Owner, args: Record<string, unknown>) {
    super(owner, args);

    if (config.environment === 'test') {
      return;
    }

    if (!window.Headway) {
      loadScript(
        '//cdn.headwayapp.co/widget.js',
        this.initHeadwayWidget.bind(this),
      );
    } else {
      this.initHeadwayWidget();
    }
  }

  initHeadwayWidget() {
    window.Headway.init(this.headwayConfig);
  }

  get config() {
    const isSmily = this.session.account?.isSmily;
    return config.headway[isSmily ? 'smily' : 'bookingsync']!;
  }

  get headwayConfig() {
    const locale = this.intl.primaryLocale as AvailableLocaleLong;
    const account = this.config.accounts![locale];
    const title = this.config.titles![locale];

    return {
      selector: '.headway-container',
      account,
      translations: { title },
    } as HeadwayConfig;
  }

  <template>
    <li class='nav-item' ...attributes>
      <a
        href={{getHeadwayUrl this}}
        class='nav-link'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon
          @icon='bullhorn'
          @style='far'
          @fixedWidth={{true}}
          class='nav-icon'
        />

        <div class='nav-title'>
          {{t 'general.whats_new'}}
        </div>

        <div class='headway-container flex-1 pe-3 d-flex justify-content-end'>
        </div>
      </a>
    </li>
  </template>
}
