import { PHOTO_SIZES } from 'smily-admin-ui/models/photo';

import type { PhotoSize } from 'smily-admin-ui/models/photo';

export default function getPhotoTypeByWidth(width: number) {
  return PHOTO_SIZES.reduce((acc, [type, w]) => {
    if (width <= w) {
      return type;
    }
    return acc;
  }, 'giantUrl' as PhotoSize);
}
