import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class SourceAbility extends Ability<'source'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'source');
  }
}
