import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export const FILTER_PARAMS = [
  'dueAt',
  'hasIssues',
  'rental',
  'priority',
  'status',
  'assignee',
  'template',
  'name',
] as const;
export type QueryParam = (typeof FILTER_PARAMS)[number];

export default class TasksIndexController extends Controller {
  queryParams = [...FILTER_PARAMS, 'page'];

  @tracked page = 1;
  @tracked sort = '';
  @tracked hasIssues?: string;
  @tracked dueAt?: string;
  @tracked rental?: string;
  @tracked priority?: string;
  @tracked status?: string;
  @tracked assignee?: string;
  @tracked template?: string;
  @tracked name?: string;

  @action
  changeQueryParam(key: QueryParam | 'sort', value: string): void {
    this.page = 1;
    this[key] = value;
  }

  @action
  changePage(page: number): void {
    this.page = page;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'tasks.index': TasksIndexController;
  }
}
