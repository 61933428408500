import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type { ValueUnitType } from 'smily-admin-ui/utils/performance';

export function formatValueWithUnitType(
  intl: IntlService,
  value: number,
  type: ValueUnitType,
  short = false,
  format?: string,
) {
  if (type === 'currency') {
    return intl.formatNumber(value, {
      style: 'currency',
      currency: format ?? 'EUR',
    });
  } else if (type === 'percentage') {
    return intl.formatNumber(value / 100, { style: 'percent' });
  } else if (type === 'day' && !short) {
    return intl.t('common.day_counter', { count: value });
  } else {
    return intl.formatNumber(value, { style: 'decimal' });
  }
}

interface FormatValueWithUnitTypeSignature {
  Args: {
    Positional: [number, ValueUnitType, boolean?, string?];
  };
  Return: string;
}

export default class FormatValueWithUnitTypeHelper extends Helper<FormatValueWithUnitTypeSignature> {
  @service intl!: IntlService;

  compute([
    value,
    type,
    short,
    format,
  ]: FormatValueWithUnitTypeSignature['Args']['Positional']) {
    return formatValueWithUnitType(this.intl, value, type, short, format);
  }
}
