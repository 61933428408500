import { array, fn } from '@ember/helper';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import FilterBar from 'ember-smily-base/components/filter-bar';
import FiltersSearchInput from 'ember-smily-base/components/filters/search-input';
import FiltersSelectInfiniteMultiple from 'ember-smily-base/components/filters/select-infinite-multiple';
import FiltersSelectMultiple from 'ember-smily-base/components/filters/select-multiple';
import TableListingScreen from 'ember-smily-base/components/table-listing-screen';
import TopbarActions from 'ember-smily-base/components/topbar/actions';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import TasksCreateTemplate from 'smily-admin-ui/components/tasks/create-template';
import TasksTemplateCard from 'smily-admin-ui/components/tasks/template/card';
import TasksTemplateListItem from 'smily-admin-ui/components/tasks/template/list-item';
import type TasksTemplatesIndexController from 'smily-admin-ui/controllers/tasks/templates/index';
import {
  CATEGORIES,
  PRIORITIES,
} from 'smily-admin-ui/models/task-management-task';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';
import { STATUSES } from 'smily-admin-ui/models/task-management-template';
import type TasksTemplatesIndexRoute from 'smily-admin-ui/routes/tasks/templates/index';

interface TemplatesTasksTemplatesIndexSignature {
  Args: {
    model: ModelFor<TasksTemplatesIndexRoute>;
    controller: TasksTemplatesIndexController;
  };
}

class TemplatesTasksTemplatesIndex extends Component<TemplatesTasksTemplatesIndexSignature> {
  @service intl!: IntlService;
  @service router!: RouterService;

  @tracked isModalOpen: boolean = false;

  tableConfig = [
    { label: this.intl.t('internals.fields.name') },
    { label: this.intl.t('tasks.template_list.type_and_status') },
    { label: this.intl.t('tasks.template_list.rentals_applied') },
    { label: this.intl.t('tasks.template_list.due_date_and_time') },
  ];
  tableActions = [
    { value: 'edit', label: this.intl.t('common.edit') },
    { value: 'duplicate', label: this.intl.t('common.duplicate') },
  ];

  get categoryOptions() {
    this.intl.primaryLocale;

    return CATEGORIES.map((value) => ({
      label: this.intl.t(`tasks.category_options.${value}`),
      value,
    }));
  }

  get priorityOptions() {
    this.intl.primaryLocale;

    return PRIORITIES.map((value) => ({
      label: this.intl.t(`tasks.priority_options.${value}`),
      value,
    }));
  }

  get statusOptions() {
    this.intl.primaryLocale;

    return STATUSES.map((value) => ({
      label: this.intl.t(`tasks.template_status_options.${value}`),
      value,
    }));
  }

  @action
  handleAction(action: string, template: TaskManagementTemplateModel) {
    if (action === 'edit') {
      this.router.transitionTo('tasks.templates.template', template.id);
    } else if (action === 'duplicate') {
      this.router.transitionTo('tasks.templates.duplicate', template.id);
    }
  }

  <template>
    <TopbarActions>
      <TasksCreateTemplate />
    </TopbarActions>

    <TableListingScreen
      @model={{@model}}
      @itemRoute='tasks.templates.template'
      @config={{this.tableConfig}}
      @tableActions={{this.tableActions}}
      @page={{@controller.page}}
      @cardLinkClass='px-3'
      @cardComponent={{TasksTemplateCard}}
      @tableItemComponent={{TasksTemplateListItem}}
      @changePage={{@controller.changePage}}
      @onTableAction={{this.handleAction}}
    >
      <:filters as |options|>
        <FilterBar
          @filters={{array
            @controller.taskCategory
            @controller.taskPriority
            @controller.status
            @controller.name
          }}
          @model={{@model}}
          @isTableFilters={{options.isTableFilters}}
        >
          <FiltersSelectMultiple
            @label={{t 'internals.fields.taskCategory'}}
            @value={{@controller.taskCategory}}
            @displayField='label'
            @options={{this.categoryOptions}}
            @onChange={{fn @controller.changeQueryParam 'taskCategory'}}
          />

          <FiltersSelectInfiniteMultiple
            @label={{t 'internals.models.rental'}}
            @value={{@controller.rental}}
            @modelName='rental'
            @query={{generateQuery 'rental' 'filter'}}
            @onChange={{fn @controller.changeQueryParam 'rental'}}
          />

          <FiltersSelectMultiple
            @label={{t 'internals.fields.taskPriority'}}
            @value={{@controller.taskPriority}}
            @displayField='label'
            @options={{this.priorityOptions}}
            @onChange={{fn @controller.changeQueryParam 'taskPriority'}}
          />

          <FiltersSelectMultiple
            @label={{t 'internals.fields.status'}}
            @value={{@controller.status}}
            @displayField='label'
            @options={{this.statusOptions}}
            @onChange={{fn @controller.changeQueryParam 'status'}}
          />

          <FiltersSelectInfiniteMultiple
            @label={{t 'common.assignee'}}
            @value={{@controller.taskAssignee}}
            @modelName='user'
            @query={{generateQuery 'user' 'filter'}}
            @displayField='fullName'
            @searchField='fullname'
            @onChange={{fn @controller.changeQueryParam 'taskAssignee'}}
          />

          <FiltersSearchInput
            @label={{t 'internals.fields.name'}}
            @value={{@controller.name}}
            @onChange={{fn @controller.changeQueryParam 'name'}}
          />
        </FilterBar>
      </:filters>
    </TableListingScreen>
  </template>
}

export default RouteTemplate(TemplatesTasksTemplatesIndex);
