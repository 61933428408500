import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { dateString } from 'ember-smily-base/utils/date';
import type { QueryParam } from 'smily-admin-ui/controllers/performance';

const TODAY = new Date();

export default function buildQuery(
  params: Record<QueryParam, string | undefined>,
  isCompare?: boolean,
) {
  const filters = Object.entries(params)
    .map(([param, value]) => {
      if (param === 'period') {
        return buildPeriodQuery(params.period!, isCompare);
      } else if (param === 'compare' || !value) {
        return undefined;
      } else {
        return `filter%5B${param}%5D=${value}`;
      }
    })
    .filter(Boolean);
  const meta = ['meta%5B%5D=value', 'meta%5B%5D=points'];

  return [...filters, ...meta].join('&');
}

function buildPeriodQuery(period: string, isCompare?: boolean) {
  let baseStartDate = TODAY;
  let baseEndDate = TODAY;

  switch (period) {
    case 'next_year':
      baseStartDate = startOfYear(addYears(TODAY, 1));
      baseEndDate = endOfYear(addYears(TODAY, 1));
      break;
    case 'year_from_today':
      baseEndDate = endOfYear(TODAY);
      break;
    case 'next_6m':
      baseEndDate = endOfMonth(addMonths(TODAY, 5));
      break;
    case 'next_3m':
      baseEndDate = endOfMonth(addMonths(TODAY, 2));
      break;
    case 'next_30d':
      baseEndDate = addDays(TODAY, 29);
      break;
    case 'this_month':
      baseEndDate = endOfMonth(TODAY);
      break;
    case 'next_14d':
      baseEndDate = addDays(TODAY, 13);
      break;
    case 'next_7d':
      baseEndDate = addDays(TODAY, 6);
      break;
    case 'this_week':
      baseStartDate = startOfWeek(TODAY, { weekStartsOn: 1 });
      baseEndDate = endOfWeek(TODAY, { weekStartsOn: 1 });
      break;
    case 'today':
      break;
    case 'yesterday':
      baseStartDate = subDays(TODAY, 1);
      baseEndDate = subDays(TODAY, 1);
      break;
    case 'last_7d':
      baseStartDate = subDays(TODAY, 6);
      break;
    case 'last_14d':
      baseStartDate = subDays(TODAY, 13);
      break;
    case 'last_30d':
      baseStartDate = subDays(TODAY, 29);
      break;
    case 'last_3m':
      baseStartDate = startOfMonth(subMonths(TODAY, 2));
      break;
    case 'last_6m':
      baseStartDate = startOfMonth(subMonths(TODAY, 5));
      break;
    case 'year_to_today':
      baseStartDate = startOfYear(TODAY);
      break;
    case 'last_year':
      baseStartDate = startOfYear(subYears(TODAY, 1));
      baseEndDate = endOfYear(subYears(TODAY, 1));
      break;
    default:
      break;
  }

  const startDate = isCompare ? subYears(baseStartDate, 1) : baseStartDate;
  const endDate = isCompare ? subYears(baseEndDate, 1) : baseEndDate;

  return `filter%5Bstart-date%5D=${dateString(
    startDate,
  )}&filter%5Bend-date%5D=${dateString(endDate)}`;
}
