import { attr, belongsTo, hasMany } from '@ember-data/model';
import { modelAction } from 'ember-custom-actions';
import type { LanguageCode } from 'ember-smily-base/utils/intl';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type AccountCompanyModel from 'smily-admin-ui/models/account-company';
import type AccountsUserModel from 'smily-admin-ui/models/accounts-user';
import type BankAccountModel from 'smily-admin-ui/models/bank-account';
import type OnboardingModel from 'smily-admin-ui/models/onboarding';
import type PreferencesGeneralSettingModel from 'smily-admin-ui/models/preferences-general-setting';
import type PreferencesPaymentModel from 'smily-admin-ui/models/preferences-payment';
import type SubscriptionModel from 'smily-admin-ui/models/subscription';
import type UserModel from 'smily-admin-ui/models/user';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class AccountModel extends BaseModel {
  @attr('string') declare businessName: string;
  @attr('string') declare status: string;
  @attr('string') declare subscriptionType: string;
  @attr('boolean') termsAndConditionsAccepted!: boolean;

  @attr('array', { readOnly: true }) declare languages: LanguageCode[];
  @attr('array') declare featureNames: string[];
  @attr('string') declare referralCode: string;

  @attr('boolean', { readOnly: true }) declare onboardingInProgress: boolean;

  @belongsTo('account-company', { async: false, inverse: null })
  accountCompany!: AccountCompanyModel;

  @belongsTo('bank-account', { async: false, inverse: 'account' })
  bankAccount?: BankAccountModel;

  @belongsTo('onboarding', { async: false, inverse: null })
  onboarding!: OnboardingModel;

  @belongsTo('user', { async: false, inverse: null })
  owner!: UserModel;

  @belongsTo('subscription', { async: false, inverse: 'account' })
  subscription!: SubscriptionModel;

  @belongsTo('preferences-general-setting', { async: false, inverse: null })
  preferencesGeneralSetting!: PreferencesGeneralSettingModel;

  @belongsTo('preferences-payment', { async: false, inverse: null })
  preferencesPayment!: PreferencesPaymentModel;

  @hasMany('accounts-user', { async: false, inverse: 'account' })
  accountsUsers!: SyncHasMany<AccountsUserModel>;

  @hasMany('user', { async: false, inverse: null })
  users!: SyncHasMany<UserModel>;

  get isFree(): boolean {
    return this.subscriptionType === 'free';
  }

  get isSmily(): boolean {
    return ['free', 'light'].includes(this.subscriptionType);
  }

  startLightOnboarding = modelAction('start-light-onboarding', {
    method: 'POST',
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
