import { attr } from '@ember-data/model';
import { getContrastTextColor } from 'ember-smily-base/utils/color';
import type { IconName, IconPrefix } from 'ember-smily-base/utils/icons';
import { getCompatIcon } from 'ember-smily-base/utils/icons';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class BookingsTagModel extends BaseModel {
  @attr('object') name!: Record<string, string>;
  @attr declare color: string;
  @attr declare logo: string;

  get mappedName(): string {
    const translation = this.name[this.intl.primaryLocale.split('-')[0]!];

    return translation || this.name.en!;
  }

  get fgColor(): string {
    return getContrastTextColor(this.color || '000');
  }

  get compatIcon() {
    const compatIcon = this.logo && getCompatIcon(this.logo);
    return compatIcon ? compatIcon.icon : (this.logo as IconName);
  }

  get compatIconStyle(): IconPrefix | undefined {
    const compatIcon = this.logo && getCompatIcon(this.logo);
    return compatIcon ? compatIcon.style : undefined;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bookings-tag': BookingsTagModel;
  }
}
