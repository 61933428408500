import { service } from '@ember/service';
import { addYears } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import type StoreService from 'ember-smily-base/services/store';
import { dateString } from 'ember-smily-base/utils/date';
import type { Transition } from 'ember-smily-base/utils/routing';
import type { Query } from 'ember-smily-base/utils/store';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type BookingsIndexController from 'smily-admin-ui/controllers/bookings';
import type { QueryParam } from 'smily-admin-ui/controllers/bookings';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import { BaseRoute } from 'smily-admin-ui/utils/routing';
import type {
  CompoundFilter,
  CompoundFilterValue,
} from 'smily-admin-ui/utils/store';

type QueryWithFilters = Query & Required<Pick<Query, 'filter'>>;
type BookingAttrQueryParam = Exclude<QueryParam, 'page'>;
type Controller = Parameters<BaseRoute['setupController']>[0];

const FILTER_PARAMS: BookingAttrQueryParam[] = [
  'status',
  'source',
  'rental',
  'client',
  'bookingsTag',
  'startAt',
  'endAt',
  'atProperty', // atProperty must come after startAt & endAt
  'payments',
  'discountCode',
  'reference',
];

function valueExists(value: unknown) {
  return value !== undefined;
}

function buildFieldMap(params: Record<string, unknown>) {
  const map: [string, unknown][] = [];

  FILTER_PARAMS.forEach((key) => {
    const value = params[key];

    if (!value) {
      map.push([key, value]);
    } else if (key === 'reference') {
      map.push([key, { op: 'matches', value }]);
    } else if (key === 'payments' && value === 'paymentDue') {
      map.push(['paymentDue', true]);
    } else if (key === 'startAt' || key === 'endAt') {
      const [start, end] = (value as string).split(',') as [string, string];

      map.push([key, buildRange(start, end)]);
      return;
    } else if (key === 'atProperty') {
      // atProperty extends existing startAt & endAt filters
      const [start, end] = (value as string).split(',');
      const startField = map.find(
        ([key]) => key === 'startAt',
      ) as CompoundFilter;
      const endField = map.find(([key]) => key === 'endAt') as CompoundFilter;
      const startValue: CompoundFilterValue = {
        op: 'lteq',
        value: `${start} 23:59:59`,
      };
      const endValue: CompoundFilterValue = {
        op: 'gteq',
        value: `${end} 00:00:00`,
      };

      startField[1] = [...(startField[1] || []), startValue];
      endField[1] = [...(endField[1] || []), endValue];

      return;
    } else {
      map.push([key, value]);
    }
  });

  return map;
}

function buildRange(start: string, end: string): CompoundFilterValue[] {
  return [
    { op: 'gteq', value: `${start} 00:00:00` },
    { op: 'lteq', value: `${end} 23:59:59` },
  ];
}

export default class BookingsIndexRoute extends BaseRoute {
  @service abilities!: AbilitiesService;
  @service coreLinks!: CoreLinksService;
  @service store!: StoreService;

  checkAbilities = true;
  requiredFeatureAbility = 'access bookings feature';

  queryParams = {
    page: { refreshModel: true },
    status: { refreshModel: true },
    source: { refreshModel: true },
    rental: { refreshModel: true },
    client: { refreshModel: true },
    bookingsTag: { refreshModel: true },
    startAt: { refreshModel: true },
    endAt: { refreshModel: true },
    atProperty: { refreshModel: true },
    payments: { refreshModel: true },
    discountCode: { refreshModel: true },
    reference: { refreshModel: true },
  };

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    return this.store.loadModelsById([
      {
        modelName: 'source',
        ids: transition.to.queryParams.source,
      },
      {
        modelName: 'rental',
        ids: transition.to.queryParams.rental,
      },
      {
        modelName: 'client',
        ids: transition.to.queryParams.client,
      },
      {
        modelName: 'bookings-tag',
        ids: transition.to.queryParams.bookingsTag,
      },
      {
        modelName: 'discount-code',
        ids: transition.to.queryParams.discountCode,
      },
    ]);
  }

  model(params: Record<string, unknown>) {
    const { page } = params;
    const fieldMap = buildFieldMap(params);
    const hasParam = !!fieldMap.filter(([, value]) => valueExists(value))
      .length;
    const filteredFields = fieldMap.filter(([, value]) => value);

    const query: QueryWithFilters = {
      ...this.store.generateQuery('booking', 'listDefault'),
      filter: Object.fromEntries(filteredFields),
    };

    if (!hasParam) {
      this.setDefaultParams(query.filter);
    }

    return new InfiniteQuery(this.store, 'booking', query, {
      page: page as number,
    });
  }

  setupController(
    controller: Controller & BookingsIndexController,
    model: unknown,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);

    const hasParams = FILTER_PARAMS.map((key) => controller[key]).filter(
      (value) => valueExists(value),
    ).length;

    if (hasParams) {
      return;
    }

    this.setDefaultParams(controller, true);
  }

  setDefaultParams(
    object: BookingsIndexController | QueryWithFilters['filter'],
    isController?: boolean,
  ) {
    const now = new Date();
    const start = dateString(now);
    const end = dateString(addYears(now, 1));

    object.status = 'booked,tentative';
    object.endAt = isController ? `${start},${end}` : buildRange(start, end);
  }

  featureNotAccessible() {
    this.coreLinks.redirect('bookings');
  }
}
