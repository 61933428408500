import type { IconName, IconPrefix } from 'ember-smily-base/utils/icons';
import type { AnyComponent } from 'ember-smily-base/utils/glimmer';
import FeatureDiscoveryNewColorsBanner from 'smily-admin-ui/components/feature-discovery/new-colors/banner';
import FeatureDiscoveryNewColorsContent from 'smily-admin-ui/components/feature-discovery/new-colors/content';
import FeatureDiscoveryInboxAiBanner from 'smily-admin-ui/components/feature-discovery/inbox-ai/banner';
import FeatureDiscoveryInboxAiContent from 'smily-admin-ui/components/feature-discovery/inbox-ai/content';
import FeatureDiscoveryTaskManagementBanner from 'smily-admin-ui/components/feature-discovery/task-management/banner';
import FeatureDiscoveryTaskManagementContent from 'smily-admin-ui/components/feature-discovery/task-management/content';
import FeatureDiscoveryUnifiedInboxBanner from 'smily-admin-ui/components/feature-discovery/unified-inbox/banner';
import FeatureDiscoveryUnifiedInboxContent from 'smily-admin-ui/components/feature-discovery/unified-inbox/content';

export type FeatureName =
  | 'new_colors'
  | 'task_management'
  | 'unified_inbox'
  | 'inbox_ai';

export interface FeatureDiscoveryConfigEntry {
  name: FeatureName;
  icon: IconName;
  iconStyle?: IconPrefix;
  route?: string;
  until: string;
  banner: AnyComponent;
  content: AnyComponent;
}

const featureDiscoveryConfig: FeatureDiscoveryConfigEntry[] = [
  {
    name: 'new_colors',
    icon: 'palette',
    until: '2025-01-01',
    banner: FeatureDiscoveryNewColorsBanner,
    content: FeatureDiscoveryNewColorsContent,
  },
  {
    name: 'task_management',
    icon: 'check-square',
    route: 'tasks',
    until: '2025-01-01',
    banner: FeatureDiscoveryTaskManagementBanner,
    content: FeatureDiscoveryTaskManagementContent,
  },
  {
    name: 'unified_inbox',
    icon: 'inbox',
    route: 'inbox',
    until: '2025-01-01',
    banner: FeatureDiscoveryUnifiedInboxBanner,
    content: FeatureDiscoveryUnifiedInboxContent,
  },
  {
    name: 'inbox_ai',
    icon: 'sparkles',
    iconStyle: 'fas',
    route: 'inbox',
    until: '2025-01-01',
    banner: FeatureDiscoveryInboxAiBanner,
    content: FeatureDiscoveryInboxAiContent,
  },
];

export default featureDiscoveryConfig;
