import * as Sentry from '@sentry/ember';

type ConfigureParams = {
  user?: {
    id: string;
    email?: string;
  };
  tags?: Record<string, unknown>;
  extra?: Record<string, unknown>;
};

export default function configure({
  user,
  tags,
  extra,
}: ConfigureParams): void {
  Sentry.configureScope(function (scope) {
    if (user) {
      const { id, email } = user;
      scope.setUser({ id, email });
    }

    if (tags) {
      Object.entries(tags).map(([tag, value]) => {
        scope.setTag(tag, value as string);
      });
    }

    if (extra) {
      Object.entries(extra).map(([name, hash]) => {
        scope.setContext(name, hash as Record<string, unknown>);
      });
    }
  });
}
