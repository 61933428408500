import { hash } from 'rsvp';
import type CurrencyModel from 'smily-admin-ui/models/currency';
import type DiscountCodeModel from 'smily-admin-ui/models/discount-code';
import DiscountsCurrencyRoute from 'smily-admin-ui/routes/discounts/-currency-route';

export default class DiscountsNewRoute extends DiscountsCurrencyRoute {
  async model(): Promise<{
    currencies: CurrencyModel[];
    discountCode: DiscountCodeModel;
  }> {
    const currencies = await this.loadCurrencies();
    const discountCode = this.store.createRecord('discount-code', {
      discountValueType: 'percentage',
      currency: currencies.at(0)!.id.toUpperCase(),
      rentalsEligibility: 'all_rentals',
      minimumRequirements: 'none',
      usageLimit: 'none',
    });

    return hash({ currencies, discountCode });
  }
}
