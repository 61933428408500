import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type CalendarController from 'smily-admin-ui/controllers/calendar';
import type { QueryParam as CalendarQueryParam } from 'smily-admin-ui/controllers/calendar';
import { CALENDAR_SHARED_FILTERS } from 'smily-admin-ui/controllers/calendar';

type QueryParam = 'date';

function shouldProxyParam(key: string): key is CalendarQueryParam {
  return CALENDAR_SHARED_FILTERS.includes(key);
}

export default class CalendarBaseController extends Controller {
  queryParams = ['date'];

  @tracked declare date: string;

  get calendarController(): CalendarController {
    return getOwner(this).lookup('controller:calendar') as CalendarController;
  }

  get rental(): string | undefined {
    return this.calendarController.rental;
  }

  get destination(): string | undefined {
    return this.calendarController.destination;
  }

  get sleeps(): string | undefined {
    return this.calendarController.sleeps;
  }

  get availableOn(): string | undefined {
    return this.calendarController.availableOn;
  }

  get bedrooms(): string | undefined {
    return this.calendarController.bedrooms;
  }

  get rentalTag(): string | undefined {
    return this.calendarController.rentalTag;
  }

  get rentalType(): string | undefined {
    return this.calendarController.rentalType;
  }

  get amenities(): string | undefined {
    return this.calendarController.amenities;
  }

  @action
  changeQueryParam(key: string, value: string): void {
    const typedKey = key as QueryParam | CalendarQueryParam;

    if (shouldProxyParam(typedKey)) {
      this.calendarController[typedKey] = value;
    } else {
      this[typedKey] = value as string;
    }
  }
}
