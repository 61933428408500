import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import Cleave from 'cleave.js';
import { task, timeout } from 'ember-concurrency';
import { isInvalidResponse } from 'ember-fetch/errors';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import Form from 'ember-smily-base/components/form';
import type StoreService from 'ember-smily-base/services/store';
import type { Error as JSONAPIError } from 'ember-smily-base/utils/json-api';
import {
  clearAllFeedback,
  createChangeset,
  handleErrors,
} from 'ember-smily-base/utils/model';
import type { FetchResponse } from 'fetch';
import fetch from 'fetch';
import CreditCardFormSubmit from 'smily-admin-ui/components/credit-card-form/submit';
import config from 'smily-admin-ui/config/environment';
import type AccountModel from 'smily-admin-ui/models/account';
import type SessionService from 'smily-admin-ui/services/session-service';
// import type SubscriptionSerializer from 'smily-admin-ui/serializers/subscription';

const SHORT_EXPIRATION_RE = /^([0-1][0-9])(\d{2})$/;

const POINTER_MAP = {
  first_name: 'firstName',
  last_name: 'lastName',
  'credit_card.month': 'cardExpiration',
  'credit_card.year': 'cardExpiration',
  'credit_card.number': 'cardNumber',
  'credit_card.verification_value': 'cvc',
} as const;
type PointerAPIKey = keyof typeof POINTER_MAP;

const DETAIL_MAP = {
  'First name is too short': 'too_short',
  'Last name is too short': 'too_short',
  'Month is not valid': 'invalid',
  'is not a valid month': 'invalid',
  'is not a valid credit card number': 'invalid',
  'Credit card verification code is not valid': 'invalid',
  'should be 3 digits': 'should_be_3_digits',
  expired: 'card_expired',
} as const;
type DetailAPIKey = keyof typeof DETAIL_MAP;

type AuthErrorEntry = {
  [index in PointerAPIKey]: DetailAPIKey[];
};

interface AuthError extends JSONAPIError {
  authResponse: FetchResponse;
}

export interface SavedCardData {
  cardName: string;
  cardExpiration: string;
  cardNumber: string;
  cardType: string;
  aliasId: string;
}

function deserializeErrors(errorPayload: AuthErrorEntry) {
  if (!errorPayload || !Object.keys(errorPayload).length) {
    return null;
  }

  const errors: JSONAPIError[] = [];

  Object.entries(errorPayload).forEach(([field, details]) => {
    details.forEach((detail) => {
      const deserialized = deserializeError(field, detail);

      if (deserialized) {
        errors.push(deserialized);
      }
    });
  });

  return errors.length ? { errors } : null;
}

function deserializeError(field: string, detail: string) {
  const pointer = POINTER_MAP[field as PointerAPIKey];
  const code = DETAIL_MAP[detail as DetailAPIKey];

  if (!pointer || !code) {
    return null;
  }

  return {
    source: {
      pointer: `/data/attributes/${pointer}`,
    },
    detail,
    status: '422',
    code,
  } as JSONAPIError;
}

interface CreditCardFormSignature {
  Element: HTMLDivElement;
  Args: {
    email: string;
    afterSave: (savedCardData: SavedCardData) => Promise<void>;
  };
  Blocks: {
    default: [
      {
        Submit: WithBoundArgs<typeof CreditCardFormSubmit, 'save'>;
      },
    ];
  };
}

export default class CreditCardForm extends Component<CreditCardFormSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: StoreService;

  formModel = this.store.createRecord('subscription');
  formChangeset = createChangeset(this.formModel);
  declare cleaves: Record<string, Cleave>;

  @action
  setupCleave() {
    this.cleaves = {
      number: new Cleave('.cc-number', { creditCard: true }),
      expiration: new Cleave('.cc-expiration', {
        numericOnly: true,
        blocks: [2, 4],
        delimiter: '/',
      }),
      cvc: new Cleave('.cc-cvc', {
        numericOnly: true,
        blocks: [4],
      }),
    };
  }

  get cardExpiration(): { month: string; year: string } {
    const [month, year] = this.cleaves
      .expiration!.getFormattedValue()
      .split('/') as [string, string];

    return { month, year };
  }

  @action
  formatExpiration() {
    const expiration = this.cleaves.expiration!;
    const value = expiration.getRawValue();
    const matches = value.match(SHORT_EXPIRATION_RE);

    if (matches) {
      expiration.setRawValue(`${matches[1]}20${matches[2]}`);
    }
  }

  @action
  async save() {
    const authUrl = new URL(
      'api/bookingsync/credit_card_authorizations',
      config.APP.BSA_SECURE_URL,
    ).toString();
    const formData = this.getFormData();

    clearAllFeedback(guidFor(this.formModel));

    const authResponse = await fetch(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: new URLSearchParams(
        formData as unknown as URLSearchParams,
      ).toString(),
    });

    if (isInvalidResponse(authResponse)) {
      this.handleAuthErrors(authResponse);
      return;
    } else if (!authResponse.ok) {
      const error = new Error(
        'Something happened while authorizing credit card',
      ) as unknown as AuthError;
      error.authResponse = authResponse;

      this.notify.error(this.intl.t('internals.errors.default'));

      throw error;
    }

    const payload = await authResponse.json();

    // due to backend implementation this is the only way to tell if 3RI is enabled
    const threeRIEnabled = !!payload.credit_card_token?.id;

    if (threeRIEnabled) {
      await this.start3RIAuth.perform(payload.credit_card_token.id);
    } else {
      const savedCardData: SavedCardData = {
        cardName: payload.card_name,
        cardExpiration: payload.card_expiration,
        cardNumber: payload.card_number,
        cardType: payload.card_type,
        aliasId: payload.alias_id,
      };

      this.args.afterSave(savedCardData);
    }
  }

  getFormData() {
    const { month, year } = this.cardExpiration;
    const number = this.cleaves.number!.getRawValue();
    const cvc = this.cleaves.cvc!.getRawValue();
    const formData = new FormData();
    const account = this.session.account as AccountModel;

    formData.append('credit_card_authorization[email]', this.args.email);
    formData.append(
      'credit_card_authorization[first_name]',
      this.formChangeset.get('firstName'),
    );
    formData.append(
      'credit_card_authorization[last_name]',
      this.formChangeset.get('lastName'),
    );
    formData.append('credit_card_authorization[number]', number);
    formData.append('credit_card_authorization[month]', month);
    formData.append('credit_card_authorization[year]', year);
    formData.append('credit_card_authorization[verification_value]', cvc);
    formData.append('credit_card_authorization[account_id]', account.id);
    formData.append(
      'credit_card_authorization[payment_gateway_name]',
      'booking_pay',
    );

    return formData;
  }

  async handleAuthErrors(response: FetchResponse) {
    const errors = await response.json();
    const deserializedErrors = deserializeErrors(errors);
    // const serializer = this.store.serializerFor(
    //   'subscription' as never,
    // ) as SubscriptionSerializer;
    const { intl, notify } = this;

    if (!deserializedErrors) {
      this.notify.error(this.intl.t('internals.errors.default'));
      return;
    }

    handleErrors(this.formModel, deserializedErrors, {
      intl,
      notify,
      // serializer,
    });
  }

  start3RIAuth = task(async (cardTokenId: string) => {
    const creditCards = await this.store.queryRecords('credit-card', {
      filter: {
        byTokenId: cardTokenId,
      },
    });

    if (creditCards.firstObject) {
      this.router.transitionTo(
        'credit-card-auth.pending',
        creditCards.firstObject.id,
      );
    } else {
      await timeout(2000);
      await this.start3RIAuth.perform(cardTokenId);
    }
  });

  <template>
    <Form
      {{didInsert this.setupCleave}}
      @model={{this.formChangeset}}
      @disablePrompt={{true}}
      as |F|
    >
      <div class='d-flex gap-3'>
        <F.Input
          @property='firstName'
          @required={{true}}
          @autocomplete='cc-given-name'
          class='flex-1'
        />

        <F.Input
          @property='lastName'
          @required={{true}}
          @autocomplete='cc-family-name'
          class='flex-1'
        />
      </div>

      <F.Input
        @property='cardNumber'
        @required={{true}}
        @inputClass='cc-number'
        @autocomplete='cc-number'
      />

      <div class='d-flex gap-3'>
        <F.Input
          @property='cardExpiration'
          @required={{true}}
          @placeholder='MM / YYYY'
          @inputClass='cc-expiration'
          @autocomplete='cc-exp'
          @onBlur={{this.formatExpiration}}
          class='flex-2'
        />

        <F.Input
          @property='cvc'
          @required={{true}}
          @inputClass='cc-cvc'
          @autocomplete='cc-csc'
          class='flex-1'
        />
      </div>

      {{yield (hash Submit=(component CreditCardFormSubmit save=this.save))}}
    </Form>
  </template>
}
