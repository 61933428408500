import { Ability } from 'ember-can';
import { generateModelAbilities } from 'smily-admin-ui/utils/ability';

export default class TaxAbility extends Ability<'tax'> {
  constructor(owner: object) {
    super(owner);

    generateModelAbilities(this, 'tax');
  }
}
