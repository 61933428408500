import type { Transition } from 'ember-smily-base/utils/routing';
import UnauthenticatedRoute from 'smily-admin-ui/routes/-unauthenticated';

export default abstract class SignupProxyRoute extends UnauthenticatedRoute {
  redirectToSignup(transition: Transition, type?: string): void {
    const options = { queryParams: transition.to.queryParams };

    if (type) {
      this.router.transitionTo('signup.cobranded-signup', type, options);
    } else {
      this.router.transitionTo('signup.free', options);
    }
  }
}
