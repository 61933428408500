import { service } from '@ember/service';
import { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { modelAction } from 'ember-custom-actions';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import type { FileMeta, PhoneMeta } from 'ember-smily-base/utils/model';
import { fileMeta, phoneMeta } from 'ember-smily-base/utils/model';
import { join } from 'ember-smily-base/utils/string';
import type CacheService from 'smily-admin-ui/services/cache';
import { BaseModel, generateFullAddress } from 'smily-admin-ui/utils/model';

export type ActivityAge = (typeof ACTIVITY_AGE_OPTIONS)[number];
export type CompanyLegalStatus = (typeof COMPANY_LEGAL_STATUS_OPTIONS)[number];
export type EnrollmentType = 'individual' | 'selfEmployed' | 'company';

type SaveParams = Parameters<BaseModel['save']>;
type SaveRet = ReturnType<BaseModel['save']>;

export const ACTIVITY_AGE_OPTIONS = ['lessThanYear', 'moreThanYear'] as const;
export const COMPANY_LEGAL_STATUS_OPTIONS = [
  'charity',
  'publicOrganization',
  'individualCompany',
  'limitedCompany',
  'listedCompany',
  'nonLimitedCompany',
] as const;

export default class OnboardingModel extends BaseModel {
  @service cache!: CacheService;
  @service abilities!: AbilitiesService;

  @attr('object') utmVisit?: Record<string, string>;

  @attr('string') firstName?: string;
  @attr('string') lastName?: string;
  @attr('string') email?: string;
  @attr('string') password?: string;
  @attr('boolean', { defaultValue: false }) smilyProspectBannerHidden?: boolean;
  @attr('string') referralCode?: string;
  @attr('date', { readOnly: true }) completedAt?: Date;
  @attr('array', { readOnly: true }) completedTasks!: string[];
  @attr('number', { readOnly: true, defaultValue: 0 })
  completionPercentage?: number;
  @attr('object', { readOnly: true }) accessToken?: unknown;
  @attr('boolean', {
    readOnly(this: OnboardingModel) {
      return this.abilities.can('access legal terms modal release');
    },
  })
  termsAccepted?: boolean;

  @attr('string') airbnbLogin?: string;
  @attr('string') airbnbPassword?: string;
  @attr('string') homeawayLogin?: string;
  @attr('string') homeawayPassword?: string;
  @attr('string') bookingComLogin?: string;
  @attr('string') bookingComPassword?: string;

  @attr('string') primaryIdentityProof?: string;
  @attr('string') primaryIdentityProofBack?: string;
  @attr('underscore') primaryIdentityProofType?: string;
  @attr('boolean', { readOnly: true }) primaryIdentityProofPresent?: boolean;
  @attr('string') secondaryIdentityProof?: string;
  @attr('boolean', { readOnly: true }) secondaryIdentityProofPresent?: boolean;
  @attr('string') secondaryIdentityProofBack?: string;
  @attr('underscore') secondaryIdentityProofType?: string;
  @attr('string') profilePicture?: string;
  @attr('boolean', { readOnly: true }) profilePicturePresent?: boolean;
  @attr('string') ibanDocument?: string;
  @attr('boolean', { readOnly: true }) ibanDocumentPresent?: boolean;
  @attr('string') bic?: string;
  @attr('string') iban?: string;

  @attr('string') placeOfBirth?: string;
  @attr('string') countryOfBirth?: string;
  @attr('string') nationality?: string;
  @attr('local-date') birthdate?: Date;
  @attr('string') locale?: string;

  @attr('string') gender?: string;
  @attr('string') address1?: string;
  @attr('string') address2?: string;
  @attr('string') city?: string;
  @attr('string') zip?: string;
  @attr('string') countryCode?: CountryCode;
  @attr('string') phoneCountryCode?: string;
  @attr('string') phone?: string;

  @attr('string') cardName?: string;
  @attr('string') cardType?: string;
  @attr('string') cardNumber?: string;
  @attr('string') cardExpiration?: string;
  @attr('string') aliasId?: string;

  @attr('string') companyName?: string;
  @attr('string') companyAddress1?: string;
  @attr('string') companyAddress2?: string;
  @attr('string') companyZip?: string;
  @attr('string') companyCity?: string;
  @attr('string') companyCountryCode?: CountryCode;
  @attr('string') companyPhoneCountryCode?: string;
  @attr('string') companyPhone?: string;
  @attr('string') companyEmail?: string;
  @attr('string') companyWebsite?: string;

  @attr('underscore') enrollmentType?: EnrollmentType;
  @attr('underscore') activityAge?: ActivityAge;
  @attr('string') companyRegistrationNumber?: string;
  @attr('boolean', { readOnly: true })
  companyLegalStatusDocumentPresent?: boolean;
  @attr('underscore') companyLegalStatus?: CompanyLegalStatus;
  @attr('string') companyLegalStatusDocument?: string;
  @attr('string') vatNumber?: string;
  @attr('string') incorporationCertificate?: string;
  @attr('boolean', { readOnly: true })
  incorporationCertificatePresent?: boolean;
  @attr('boolean') registeredProfessional?: boolean;
  @attr('boolean') declaresRevenue?: boolean;
  @attr taxIdentificationNumber?: string;

  @attr('date') callBookedAt?: Date;
  // this is only for verifying OTP in custom action, field isn't saved to db
  @attr otp?: string;
  @attr('boolean') sirenFlowApplicable?: boolean;

  @phoneMeta('phoneCountryCode', 'phone') phoneField!: PhoneMeta;
  @phoneMeta('companyPhoneCountryCode', 'companyPhone')
  companyPhoneField!: PhoneMeta;

  @fileMeta('primaryIdentityProof', 'primaryIdentityProofPresent')
  primaryIdentityProofFile!: FileMeta;
  @fileMeta('primaryIdentityProofBack', 'primaryIdentityProofPresent')
  primaryIdentityProofBackFile!: FileMeta;
  @fileMeta('secondaryIdentityProof', 'secondaryIdentityProofPresent')
  secondaryIdentityProofFile!: FileMeta;
  @fileMeta('profilePicture', 'profilePicturePresent')
  profilePictureFile!: FileMeta;
  @fileMeta('ibanDocument', 'ibanDocumentPresent') ibanDocumentFile!: FileMeta;
  @fileMeta('companyLegalStatusDocument', 'companyLegalStatusDocumentPresent')
  companyLegalStatusDocumentFile!: FileMeta;
  @fileMeta('incorporationCertificate', 'incorporationCertificatePresent')
  incorporationCertificateFile!: FileMeta;

  onlyRentalId: string | null = null;

  get fullName(): string {
    return join([this.firstName, this.lastName], ' ');
  }

  get fullAddress(): string {
    return generateFullAddress(
      this.address1,
      this.address2,
      this.city,
      this.zip,
    );
  }

  get companyFullAddress(): string {
    return generateFullAddress(
      this.companyAddress1,
      this.companyAddress2,
      this.companyCity,
      this.companyZip,
    );
  }

  get companyFullPhone(): string {
    return this.companyPhoneField.join('');
  }

  @tracked currentStep!: 2 | 3 | 4;

  async setupCurrentStep() {
    const rentals = await this.store.queryRecords('rental', {
      ...this.store.generateQuery('rental', 'meta'),
      filter: {
        completedAt: {
          op: 'not_eq',
          value: 'null',
        },
      },
    });

    if (rentals.meta.pagination.total) {
      const isStep3Completed =
        this.completedTasks.includes('verify_otp') ||
        this.completedTasks.includes('complete_siren_enrollment');

      this.currentStep = isStep3Completed ? 4 : 3;
    } else {
      this.currentStep = 2;
    }
  }

  get isCompanyInfoFilled(): boolean {
    if (this.enrollmentType === 'individual') {
      return true;
    } else if (this.enrollmentType === 'selfEmployed') {
      return !!this.companyRegistrationNumber;
    }

    return !!this.companyRegistrationNumber && !!this.companyName;
  }

  get isVatRegistered(): boolean {
    return this.vatNumber !== undefined && this.vatNumber !== null;
  }

  /**
   * When onboarding step 3 first three tasks are completed and account is not
   * eligible for SIREN flow, CentralPay enrollment is triggered.
   */
  get hasFirstThreeTasksCompleted() {
    return !!(this.placeOfBirth && this.isCompanyInfoFilled && this.iban);
  }

  get hasFirstFourTasksCompleted() {
    return this.hasFirstThreeTasksCompleted && !!this.callBookedAt;
  }

  get isAwaitingSirenSetup() {
    return (
      !!this.sirenFlowApplicable &&
      this.hasFirstFourTasksCompleted &&
      !this.completedTasks.includes('complete_siren_enrollment')
    );
  }

  get isPhoneTaskLocked(): boolean {
    return !this.hasFirstFourTasksCompleted;
  }

  get isIndividual(): boolean {
    return this.enrollmentType === 'individual';
  }

  get isNotIndividual() {
    return !this.isIndividual;
  }

  // @ts-expect-error to avoid hassle
  async save(...args: SaveParams): SaveRet {
    const { isNew } = this;

    await super.save(...args);

    if (!isNew) {
      this.cache.updateOnboardingCompletionPercentage();
    }

    return this;
  }

  completeContactDetails = modelAction<OnboardingModel>(
    'complete-contact-details',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );

  completeCompanyDetails = modelAction<OnboardingModel>(
    'complete-company-details',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );

  completeBankDetails = modelAction<OnboardingModel>('complete-bank-details', {
    method: 'PATCH',
    pushToStore: true,
  });

  configureChannels = modelAction<OnboardingModel>('configure-channels', {
    method: 'PATCH',
    pushToStore: true,
  });

  bookACall = modelAction<OnboardingModel>('book-a-call', {
    method: 'PATCH',
    pushToStore: true,
  });

  requestOTP = modelAction<OnboardingModel>('request-otp', {
    method: 'PATCH',
    pushToStore: true,
  });

  verifyOTP = modelAction<OnboardingModel>('verify-otp', {
    method: 'PATCH',
    pushToStore: true,
  });

  completeCreditCardDetails = modelAction<OnboardingModel>(
    'complete-credit-card-details',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );

  completeCompanyDocuments = modelAction<OnboardingModel>(
    'complete-company-documents',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );

  completeIdentityDocuments = modelAction<OnboardingModel>(
    'complete-identity-documents',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );

  completeTaxpayerInformation = modelAction<OnboardingModel>(
    'complete-taxpayer-information',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );

  completeProfilePicture = modelAction<OnboardingModel>(
    'complete-profile-picture',
    {
      method: 'PATCH',
      pushToStore: true,
    },
  );
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    onboarding: OnboardingModel;
  }
}
