import { attr, belongsTo } from '@ember-data/model';
import type RentalFeeModel from 'smily-admin-ui/models/rental-fee';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class FeeModel extends BaseModel {
  @attr name!: string;
  @attr kind!: string;
  @attr('number') rate!: number;
  @attr rateKind!: string;
  @attr currency!: string;

  @belongsTo('rental-fee', { async: false, inverse: 'fee' })
  rentalFee!: RentalFeeModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    fee: FeeModel;
  }
}
