import { concat } from '@ember/helper';
import { service } from '@ember/service';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import eq from 'ember-truth-helpers/helpers/eq';
import notEq from 'ember-truth-helpers/helpers/not-eq';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import type { ActivityAge } from 'smily-admin-ui/models/onboarding';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingCompanyDocuments extends TemplatesOnboardingTask {
  @service intl!: IntlService;

  actionName = 'completeCompanyDocuments';

  get attributes(): string[] {
    if (this.companyType === 'lessThanYear') {
      return ['incorporationCertificate', 'companyLegalStatusDocument'];
    } else {
      return ['incorporationCertificate'];
    }
  }

  get companyType(): ActivityAge | 'selfEmployed' {
    const onboarding = this.session.onboarding!;

    if (onboarding.enrollmentType === 'selfEmployed') {
      return 'selfEmployed';
    }

    return onboarding.activityAge!;
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.company_documents.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @onSubmit={{this.save}}
            as |F|
          >
            {{#if (eq this.companyType 'lessThanYear')}}
              <F.File
                @property='companyLegalStatusDocumentFile'
                @required={{true}}
              />
            {{/if}}

            <F.File
              @property='incorporationCertificateFile'
              @labelKey={{if
                (eq this.companyType 'selfEmployed')
                'onboarding.company_documents.self_employed_doc_label'
              }}
              @required={{true}}
            />

            <hr class='w-100' />

            <div class='d-flex align-items-center justify-content-between'>
              <OnboardingBackToTaskList />

              <F.Submit
                @label={{t 'common.save'}}
                @disabled={{this.onboardingChangeset.isPristine}}
              />
            </div>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.company_documents.helper.why_do_we_need.title'}}
          </div>

          <div>
            {{t 'onboarding.company_documents.helper.why_do_we_need.p1'}}
          </div>

          <div>
            {{t 'onboarding.company_documents.helper.why_do_we_need.p2'}}
          </div>

          <div>
            {{t 'onboarding.company_documents.helper.why_do_we_need.p3'}}
          </div>

          <div class='fw-semibold'>
            {{t 'onboarding.company_documents.helper.requirements.title'}}
          </div>

          <div class='fw-semibold'>
            {{t
              'onboarding.company_documents.helper.requirements.incorporation_certificate_title'
            }}
          </div>

          {{#let
            (concat 'onboarding.company_documents.helper.' this.companyType '.')
            as |helperSuffix|
          }}
            <div>
              {{t
                (concat
                  helperSuffix 'requirements.incorporation_certificate_p1'
                )
              }}
            </div>

            {{#let
              (t
                (concat
                  helperSuffix 'requirements.incorporation_certificate_p2'
                )
              )
              as |incorpCertP2|
            }}
              {{#if incorpCertP2}}
                <div>
                  {{incorpCertP2}}
                </div>
              {{/if}}
            {{/let}}

            {{#if (eq this.intl.primaryLocale 'fr-fr')}}
              <div class='fw-semibold'>
                {{t (concat helperSuffix 'how_to_obtain.title')}}
              </div>

              <div>
                <div>
                  {{t (concat helperSuffix 'how_to_obtain.p1')}}
                </div>

                {{! template-lint-disable no-bare-strings }}
                <a
                  href='https://www.infogreffe.fr/documents-officiels/demande-kbis.html'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  https://www.infogreffe.fr/documents-officiels/demande-kbis.html
                </a>
                {{! template-lint-enable no-bare-strings }}
              </div>
            {{/if}}

            {{#if (notEq this.companyType 'selfEmployed')}}
              <div class='fw-semibold'>
                {{t
                  (concat helperSuffix 'requirements.legal_status_doc_title')
                }}
              </div>

              {{#let
                (t (concat helperSuffix 'requirements.legal_status_doc_req'))
                as |legalStatusReq|
              }}
                {{#if legalStatusReq}}
                  <div>
                    {{legalStatusReq}}
                  </div>
                {{/if}}
              {{/let}}

              <div>
                {{t (concat helperSuffix 'requirements.legal_status_doc_p1')}}
              </div>

              <div>
                <div>
                  {{t (concat helperSuffix 'requirements.legal_status_doc_p2')}}
                </div>

                <ul class='mb-0'>
                  <li>
                    {{t
                      (concat helperSuffix 'requirements.legal_status_doc_li1')
                    }}
                  </li>

                  <li>
                    {{t
                      (concat helperSuffix 'requirements.legal_status_doc_li2')
                    }}
                  </li>
                </ul>
              </div>
            {{/if}}

            <div class='fw-semibold'>
              {{t (concat helperSuffix 'incorrect_type.title')}}
            </div>

            <div>
              {{t (concat helperSuffix 'incorrect_type.p1')}}
            </div>
          {{/let}}
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingCompanyDocuments);
