import { belongsTo } from '@ember-data/model';
import type AccountingInvoiceModel from 'smily-admin-ui/models/accounting-invoice';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class AccountingPaymentModel extends BaseModel {
  // we don't care about fields for now

  @belongsTo('accounting-invoice', {
    async: false,
    inverse: 'accountingPayment',
  })
  invoice!: AccountingInvoiceModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-payment': AccountingPaymentModel;
  }
}
