import type { TOC } from '@ember/component/template-only';
import InboxChannelIcon from 'smily-admin-ui/components/inbox/channel-icon';
import type InboxMessageModel from 'smily-admin-ui/models/inbox-message';

const InboxLastMessage: TOC<{
  Element: HTMLDivElement;
  Args: {
    message: InboxMessageModel;
  };
}> = <template>
  <div class='d-flex align-items-center gap-1' ...attributes>
    {{#if @message.channel}}
      <InboxChannelIcon @channel={{@message.channel}} @iconClass='text-20' />
    {{/if}}

    <div class='text-truncate'>
      {{@message.content}}
    </div>
  </div>
</template>;

export default InboxLastMessage;
