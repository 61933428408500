import { attr, hasMany } from '@ember-data/model';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type BookingFeeModel from 'smily-admin-ui/models/booking-fee';
import type BookingTaxModel from 'smily-admin-ui/models/booking-tax';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class ReservationsQuoteResultModel extends BaseModel {
  @attr('number') downpayment!: number;
  @attr('number') finalPrice!: number;
  @attr('number') initialPrice!: number;

  @hasMany('booking-fee', { async: false, inverse: null })
  bookingFees!: SyncHasMany<BookingFeeModel>;
  @hasMany('booking-tax', { async: false, inverse: null })
  bookingTaxes!: SyncHasMany<BookingTaxModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reservations-quote-result': ReservationsQuoteResultModel;
  }
}
