import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import Card from 'ember-smily-base/components/card';
import type { FormYield } from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import TasksBookingCard from 'smily-admin-ui/components/tasks/booking-card';
import TasksRentalCard from 'smily-admin-ui/components/tasks/rental-card';
import type { EventSource } from 'smily-admin-ui/utils/tasks';
import { isBookingSource, isRentalSource } from 'smily-admin-ui/utils/tasks';

const TasksEventSourceCard: TOC<{
  Element: HTMLDivElement;
  Args: {
    eventSource: EventSource;
    form?: FormYield;
  };
}> = <template>
  {{#if (isBookingSource @eventSource)}}
    <TasksBookingCard @booking={{@eventSource}} ...attributes />
  {{else if (isRentalSource @eventSource)}}
    <TasksRentalCard @rental={{@eventSource}} ...attributes />
  {{/if}}

  {{#if @form}}
    <Card>
      <div class='card-body'>
        <List @large={{true}} @sticky={{true}} @style='light' as |Item|>
          <Item>
            <@form.Section
              @icon='clock'
              @title={{t 'tasks.template_form.due_date_title'}}
              @subtitle={{t 'tasks.template_form.due_date_subtitle'}}
              @vertical={{true}}
              @contentClasses='gap-0'
            >
              <@form.Date
                @property='dueAt'
                @label={{t 'tasks.task_form.due_date'}}
                @datetime={{true}}
              />

              <@form.Time
                @property='dueAt'
                @label={{t 'tasks.task_form.due_time'}}
                @datetime={{true}}
              />
            </@form.Section>
          </Item>
        </List>
      </div>
    </Card>
  {{/if}}
</template>;

export default TasksEventSourceCard;
