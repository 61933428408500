import { concat } from '@ember/helper';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type { FormYield } from 'ember-smily-base/components/form';
import type { Changeset } from 'ember-smily-base/utils/model';
import type { SelectOption } from 'ember-smily-base/utils/select';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';

type DocumentType = 'idCard' | 'passport' | 'other';

interface OnboardingIdentityDocumentSignature {
  Element: HTMLDivElement;
  Args: {
    type: string;
    options: DocumentType[];
    onboardingChangeset: Changeset;
    form: FormYield;
  };
}

export default class OnboardingIdentityDocument extends Component<OnboardingIdentityDocumentSignature> {
  @service intl!: IntlService;

  get radioPropertyPath(): string {
    return `${this.args.type}IdentityProofType`;
  }

  get radioProperty(): string {
    return this.args.onboardingChangeset.get(this.radioPropertyPath);
  }

  get options(): SelectOption<DocumentType>[] {
    return this.args.options.map((option) => this.generateOption(option));
  }

  get filePropertyPath(): string {
    return `${this.args.type}IdentityProofFile`;
  }

  get fileBackPropertyPath(): string {
    return `${this.args.type}IdentityProofBackFile`;
  }

  generateOption(value: DocumentType): SelectOption<DocumentType> {
    return {
      label: this.intl.t(`onboarding.identity.identity_proof_options.${value}`),
      value,
    };
  }

  <template>
    {{#let @form as |F|}}
      <div class='d-flex flex-column gap-2' ...attributes>
        <F.Radio
          @property={{this.radioPropertyPath}}
          @options={{this.options}}
          @required={{true}}
          class='d-flex gap-2'
        />

        <BsCollapse @collapsed={{not this.radioProperty}}>
          <div class='d-flex flex-column gap-2'>
            <F.File
              @property={{this.filePropertyPath}}
              @label={{t
                (concat
                  'onboarding.identity.identity_proof_document_types.'
                  this.radioProperty
                )
              }}
              @required={{true}}
            />

            {{#if (eq this.radioProperty 'idCard')}}
              <F.File
                @property={{this.fileBackPropertyPath}}
                @label={{t
                  'onboarding.identity.identity_proof_document_types.back'
                }}
                @required={{true}}
              />
            {{/if}}
          </div>
        </BsCollapse>
      </div>
    {{/let}}
  </template>
}
