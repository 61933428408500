import { isArray } from '@ember/array';
import type JSONAPISerializer from '@ember-data/serializer/json-api';
import type StoreService from 'ember-smily-base/services/store';
import type { ResourceObject, Response } from 'ember-smily-base/utils/json-api';
import {
  BaseSerializer,
  serializeAttribute,
  serializeBelongsTo,
  serializeHasMany,
} from 'ember-smily-base/utils/serializer';
import type { Model, ModelName } from 'ember-smily-base/utils/store';

function removeNullishRelationships(data: ResourceObject) {
  if (!data.relationships) {
    return;
  }

  for (const [key, value] of Object.entries(data.relationships)) {
    if (!value.data) {
      delete data.relationships[key];
    }
  }
}

function cleanupPayload(payload: Response) {
  if (payload.data) {
    if (isArray(payload.data)) {
      for (const data of payload.data) {
        removeNullishRelationships(data);
      }
    } else {
      removeNullishRelationships(payload.data);
    }
  }

  if (payload.included) {
    for (const included of payload.included) {
      removeNullishRelationships(included);
    }
  }
}

export default class ApplicationSerializer<
  MN extends ModelName,
> extends BaseSerializer<MN> {
  serializeAttribute = serializeAttribute(
    super.serializeAttribute,
  ) as JSONAPISerializer['serializeAttribute'];
  serializeBelongsTo = serializeBelongsTo(
    super.serializeBelongsTo,
  ) as JSONAPISerializer['serializeBelongsTo'];
  serializeHasMany = serializeHasMany(
    super.serializeHasMany,
  ) as JSONAPISerializer['serializeHasMany'];

  normalizeResponse(
    store: StoreService,
    primaryModelClass: Model,
    payload: Response,
    id: string | number,
    requestType: string,
  ) {
    cleanupPayload(payload);

    return super.normalizeResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    );
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    application: ApplicationSerializer<ModelName>;
  }
}
