import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { Transition } from 'ember-smily-base/utils/routing';
import { InfiniteQuery } from 'ember-smily-base/utils/store';
import type { QueryParam } from 'smily-admin-ui/controllers/tasks/templates/index';
import { FILTER_PARAMS } from 'smily-admin-ui/controllers/tasks/templates/index';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

type RouteParams = Record<QueryParam, string | undefined> & { page: number };

function buildFilters(params: RouteParams) {
  const filter = {} as Record<string, unknown>;

  FILTER_PARAMS.forEach((key) => {
    const value = params[key];

    if (value) {
      filter[key] = key === 'name' ? { op: 'matches', value: value } : value;
    }
  });

  return filter;
}

export default class TasksTemplatesIndexRoute extends BaseRoute {
  @service store!: StoreService;

  queryParams = {
    ...Object.fromEntries(
      [...FILTER_PARAMS, 'page'].map((key) => [key, { refreshModel: true }]),
    ),
  };

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    const params = transition.to.queryParams as Record<
      QueryParam,
      string | undefined
    >;

    return this.store.loadModelsById([
      {
        modelName: 'rental',
        ids: params.rental,
      },
      {
        modelName: 'user',
        ids: params.taskAssignee,
      },
    ]);
  }

  model(params: RouteParams) {
    const { page } = params;

    const query = this.store.generateQuery(
      'task-management-template',
      'listDefault',
      buildFilters(params),
    );

    return new InfiniteQuery(this.store, 'task-management-template', query, {
      page,
    });
  }
}
