/* eslint-disable @typescript-eslint/no-explicit-any */

import type JSONAPIAdapter from '@ember-data/adapter/json-api';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

function errorsNeedingTransform(payload: any): any[] {
  return (
    payload?.errors?.length &&
    payload.errors.filter((error: any) => {
      return error.source.pointer.startsWith(
        '/data/attributes/privacy-policy-',
      );
    })
  );
}

export default class PreferencesGeneralSettingAdapter extends ApplicationAdapter {
  handleResponse(
    status: number,
    headers: Record<string, unknown>,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    payload: any,
    requestData: Record<string, unknown>,
  ): ReturnType<JSONAPIAdapter['handleResponse']> {
    const privacyPolicyErrors = errorsNeedingTransform(payload);
    if (!privacyPolicyErrors?.length) {
      return super.handleResponse(status, headers, payload, requestData);
    }

    const codes: any = privacyPolicyErrors.map(({ code }: any) => code);
    const transformedPayload = {
      errors: [
        ...payload.errors.filter(
          (error: any) => !privacyPolicyErrors.includes(error),
        ),
        ...codes.uniq().map((code: string) => ({
          code: code === 'blank' ? 'privacy_policy_blank' : code,
          source: {
            pointer: '/data/attributes/privacy-policy',
          },
        })),
      ],
    };

    return super.handleResponse(
      status,
      headers,
      transformedPayload,
      requestData,
    );
  }
}
