import { attr } from '@ember-data/model';
import type { CountryCode } from 'ember-smily-base/utils/intl';
import type { FileMeta } from 'ember-smily-base/utils/model';
import { fileMeta } from 'ember-smily-base/utils/model';
import { BaseModel, generateFullAddress } from 'smily-admin-ui/utils/model';

export default class AccountCompanyModel extends BaseModel {
  @attr('string') name?: string;
  @attr('string') city?: string;
  @attr('string') zip?: string;
  @attr('string') countryCode?: CountryCode;
  @attr('string') address1?: string;
  @attr('string') address2?: string;
  @attr('string') phone?: string;
  @attr('string') email?: string;
  @attr('string') website?: string;
  @attr('string') vatNumber?: string;

  @attr('boolean', { readOnly: true })
  incorporationCertificatePresent?: boolean;

  @attr('string') incorporationCertificate?: string;
  @attr('boolean') registeredProfessional?: boolean;
  @attr('boolean') declaresRevenue?: boolean;

  @fileMeta('incorporationCertificate', 'incorporationCertificatePresent')
  incorporationCertificateFile!: FileMeta;

  get fullAddress(): string {
    return generateFullAddress(
      this.address1,
      this.address2,
      this.city,
      this.zip,
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-company': AccountCompanyModel;
  }
}
