import type { TOC } from '@ember/component/template-only';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import Icon from 'ember-smily-base/components/icon';
import type { SelectOption } from 'ember-smily-base/utils/select';
import StatusText from 'smily-admin-ui/components/status-text';

const BookingsBookingStatusOption: TOC<{
  Args: {
    item: SelectOption<string>;
    locked?: boolean;
  };
}> = <template>
  <StatusText
    @modelName='booking'
    @status={{lowercase @item.value}}
    @text={{@item.label}}
  />

  {{#if @locked}}
    <Icon @icon='lock' @style='far' class='lock-icon' />
  {{/if}}
</template>;

export default BookingsBookingStatusOption;
