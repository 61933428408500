import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { getLanguagesLabelOfTranslatedField } from 'smily-admin-ui/utils/intl';
import type { TranslatedField } from 'smily-admin-ui/utils/model/types';

interface AvailableFieldLanguagesSignature {
  Args: {
    Positional: [TranslatedField];
  };
  Return: string;
}

export default class AvailableFieldLanguages extends Helper<AvailableFieldLanguagesSignature> {
  @service intl!: IntlService;

  compute([
    field,
  ]: AvailableFieldLanguagesSignature['Args']['Positional']): string {
    return getLanguagesLabelOfTranslatedField(this.intl, field);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    /**
      Convenience wrapper for `getLanguagesLabelOfTranslatedField` util. Returns
      a string listing what languages the provided translated field is available
      in.

      ```handlebars
      {{available-field-languages @rental.checkinDetailsTranslations}}
      {{!-- English, French and 1 more --}}
      {{!-- Anglais, français et 1 autre --}}
      ```
    */
    'available-field-languages': typeof AvailableFieldLanguages;
  }
}
