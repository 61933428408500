import { attr } from '@ember-data/model';
import { customAction } from 'ember-custom-actions';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class AccountChannelSignupModel extends BaseModel {
  @attr('object') accessToken!: Record<string, unknown>;
  @attr('date') completedAt!: Date;

  completeChannelSignup = customAction<AccountChannelSignupModel>(
    'completeChannelSignup',
    {
      method: 'POST',
      pushToStore: true,
    },
  );
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-channel-signup': AccountChannelSignupModel;
  }
}
