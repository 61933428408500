import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import Form from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import OnboardingBackToTaskList from 'smily-admin-ui/components/onboarding/back-to-task-list';
import OnboardingWizardLayout from 'smily-admin-ui/components/onboarding/wizard-layout';
import TemplatesOnboardingTask from 'smily-admin-ui/templates/onboarding/-task';

class TemplatesOnboardingPhoneVerify extends TemplatesOnboardingTask {
  actionName = 'requestOTP';
  nextRoute = 'onboarding.phone.confirm';

  get attributes(): string[] {
    return ['phone', 'phoneCountryCode'];
  }

  <template>
    <OnboardingWizardLayout>
      <:content>
        <div class='d-flex flex-column gap-3'>
          <h2>
            {{t 'onboarding.phone.verify.title'}}
          </h2>

          <Form
            @model={{this.onboardingChangeset}}
            @onSubmit={{this.save}}
            as |F|
          >
            <List @large={{true}} @sticky={{true}} as |Item|>
              <Item class='d-flex flex-column gap-2'>
                <F.Phone @property='phoneField' @required={{true}} />

                <div><i>{{t 'onboarding.phone.verify.title_helper'}}</i></div>
              </Item>

              <Item class='d-flex align-items-center justify-content-between'>
                <OnboardingBackToTaskList />

                <F.Submit>
                  {{t 'onboarding.phone.verify.send_me_the_code'}}
                </F.Submit>
              </Item>
            </List>
          </Form>
        </div>
      </:content>

      <:helper>
        <div class='d-flex flex-column gap-2'>
          <div class='fw-semibold'>
            {{t 'onboarding.phone.verify.helper.title'}}
          </div>

          <div>
            {{t 'onboarding.phone.verify.helper.p1'}}
          </div>

          <div>
            {{t 'onboarding.phone.verify.helper.p2'}}
          </div>
        </div>
      </:helper>
    </OnboardingWizardLayout>
  </template>
}

export default RouteTemplate(TemplatesOnboardingPhoneVerify);
