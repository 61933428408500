import { action } from '@ember/object';
import Component from '@glimmer/component';
import PowerSelectPlaceholder from 'ember-power-select/components/power-select/placeholder';
import Badge from 'ember-smily-base/components/badge';
import FormPreIcon from 'ember-smily-base/components/form/pre-icon';
import SelectInfiniteMultiple from 'ember-smily-base/components/select/infinite-multiple';
import searchText from 'ember-smily-base/helpers/search-text';
import type { SyncHasMany } from 'ember-smily-base/utils/model';
import type { Changeset } from 'ember-smily-base/utils/model';
import { isChangeset } from 'ember-smily-base/utils/model/changeset';
import BookingsBookingsTagsList from 'smily-admin-ui/components/bookings/bookings-tags-list';
import type BookingModel from 'smily-admin-ui/models/booking';
import type BookingsTagModel from 'smily-admin-ui/models/bookings-tag';
import { removeObject } from 'smily-admin-ui/utils/array';

function getModelNameAsConst() {
  return 'bookings-tag' as const;
}

function asBookingTags(tags: unknown) {
  return tags as BookingsTagModel[];
}

interface BookingsBookingTagSelectSignature {
  Element: HTMLDivElement;
  Args: {
    booking: Changeset | BookingModel;
    iconOnly?: boolean;
    isRemovable?: boolean;
    onRemove?: (tag: BookingsTagModel) => void;
  };
}

export default class BookingsBookingTagSelect extends Component<BookingsBookingTagSelectSignature> {
  SelectComponent: typeof SelectInfiniteMultiple<
    'bookings-tag',
    BookingsTagModel
  > = SelectInfiniteMultiple;

  get model() {
    return isChangeset(this.args.booking)
      ? (this.args.booking._content as BookingModel)
      : this.args.booking;
  }

  get bookingTags() {
    return this.args.booking.bookingsTags as SyncHasMany<BookingsTagModel>;
  }

  @action
  changeBookingTags(tags?: BookingsTagModel[]) {
    this.bookingTags.length = 0;

    if (tags) {
      this.bookingTags.push(...tags);
    }
  }

  @action
  removeBookingTag(tag: BookingsTagModel) {
    removeObject(this.bookingTags, tag);
  }

  <template>
    <div class='d-flex flex-column gap-3' ...attributes>
      <div class='smily-form-field smily-form-select'>
        <div class='form-control-container'>
          <this.SelectComponent
            @value={{asBookingTags this.bookingTags}}
            @placeholder={{searchText model='bookings-tag'}}
            @modelName={{(getModelNameAsConst)}}
            {{!-- @query={{@query}} --}}
            @searchField='name'
            @loose={{true}}
            @triggerClass='form-control pre-iconed'
            @triggerComponent={{PowerSelectPlaceholder}}
            @onChange={{this.changeBookingTags}}
            as |item|
          >
            <Badge
              @icon={{item.compatIcon}}
              @iconStyle={{item.compatIconStyle}}
              @label={{item.mappedName}}
              @color={{item.color}}
            />
          </this.SelectComponent>

          <FormPreIcon @icon='search' />
        </div>
      </div>

      {{#if this.bookingTags.length}}
        <BookingsBookingsTagsList
          @bookingsTags={{this.bookingTags}}
          @isRemovable={{true}}
          @onRemove={{this.removeBookingTag}}
        />
      {{/if}}
    </div>
  </template>
}
