import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { Subtab, Tab } from 'smily-admin-ui/utils/performance';
import { tabConfig } from 'smily-admin-ui/utils/performance';

export default abstract class PerformanceBaseController extends Controller {
  queryParams = ['tab'];

  @tracked tab!: Subtab;

  abstract type: Tab;

  @action
  changeTab(tab: Subtab) {
    if (this.tab !== tab) {
      this.tab = tab;
    }
  }

  setupDefaultTab() {
    if (!this.tab) {
      this.tab = tabConfig
        .filter(({ category }) => category === this.type)
        .sort((a, b) => a.order - b.order)[0]!.name;
    }
  }
}
