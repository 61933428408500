import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import Card from 'ember-smily-base/components/card';
import Form from 'ember-smily-base/components/form';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import TopbarActions from 'ember-smily-base/components/topbar/actions';
import generateQuery from 'ember-smily-base/helpers/generate-query';
import { createChangeset } from 'ember-smily-base/utils/model';
import eq from 'ember-truth-helpers/helpers/eq';
import TasksTemplateFormTaskSections from 'smily-admin-ui/components/tasks/template/form-task-sections';
import type TaskManagementTaskModel from 'smily-admin-ui/models/task-management-task';
import type CacheService from 'smily-admin-ui/services/cache';

const FormSubmitButton: typeof LoadingButton<true> = LoadingButton;

const WHERE_TO_APPLY_OPTIONS = ['booking', 'rental', 'general'] as const;
type WhereToApplyOption = (typeof WHERE_TO_APPLY_OPTIONS)[number];

interface TasksTaskNewFormSignature {
  Args: {
    task: TaskManagementTaskModel;
  };
}

export default class TasksTaskNewForm extends Component<TasksTaskNewFormSignature> {
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;

  @tracked _whereToApply!: WhereToApplyOption;

  changeset = createChangeset(this.args.task);
  whereToApplyOptions = WHERE_TO_APPLY_OPTIONS.map((type) => ({
    value: type,
    label: this.intl.t(`tasks.where_to_apply_options.${type}`),
  }));

  get whereToApply() {
    return this._whereToApply;
  }

  set whereToApply(value: WhereToApplyOption) {
    this._whereToApply = value;
    this.changeset.set('eventSource', undefined);
  }

  save = task({ drop: true }, async () => {
    await this.changeset.save();

    this.cache.taskCount = (this.cache.taskCount ?? 0) + 1;
    this.router.transitionTo('tasks.task.index', this.changeset.id as string);
    this.args.task.unloadRecord();
    this.notify.success(this.intl.t('tasks.task_form.task_created'));
  });

  <template>
    <TopbarActions>
      <FormSubmitButton
        @label={{t 'common.save'}}
        @isFormSubmit={{true}}
        @isSubmittingForm={{this.save.isRunning}}
        form='task-form'
      />
    </TopbarActions>

    <Form
      @model={{this.changeset}}
      @onSubmit={{perform this.save}}
      id='task-form'
      class='p-3'
      as |F|
    >
      <div class='gap-3 d-flex flex-column flex-lg-row'>
        <div class='flex-7 d-flex flex-column gap-3'>
          <Card>
            <div class='card-body'>
              <List @large={{true}} @sticky={{true}} @style='light' as |Item|>
                <TasksTemplateFormTaskSections
                  @form={{F}}
                  @item={{Item}}
                  @changeset={{this.changeset}}
                />
              </List>
            </div>
          </Card>
        </div>

        <div class='flex-5 d-flex flex-column gap-3'>
          <Card>
            <div class='card-body'>
              <List
                @large={{true}}
                @sticky={{true}}
                @style='light'
                as |OuterItem|
              >
                <OuterItem class='pb-0'>
                  <F.Section
                    @icon='cog'
                    @title={{t 'tasks.task_form.where_to_apply'}}
                    @vertical={{true}}
                  >
                    <F.Radio
                      @model={{this}}
                      @property='whereToApply'
                      @label={{t 'tasks.task_form.where_to_apply'}}
                      @options={{this.whereToApplyOptions}}
                      @required={{true}}
                      as |Radio|
                    >
                      <List
                        @large={{true}}
                        @sticky={{true}}
                        @style='light'
                        as |Item|
                      >
                        <Item class='pt-2'>
                          <div class='d-flex flex-column gap-3'>
                            <Radio @index={{0}} />

                            {{#if (eq this.whereToApply 'booking')}}
                              <F.SelectInfinite
                                @property='eventSource'
                                @label={{t 'bookings.booking_reference'}}
                                @modelName='booking'
                                @query={{generateQuery
                                  'booking'
                                  'taskEventSource'
                                }}
                                @searchField='reference'
                                @loose={{true}}
                                @helperText={{t
                                  'tasks.task_form.apply_to_booking_helper'
                                }}
                                class='ps-3'
                              />
                            {{/if}}
                          </div>
                        </Item>

                        <Item>
                          <div class='d-flex flex-column gap-3'>
                            <Radio @index={{1}} />

                            {{#if (eq this.whereToApply 'rental')}}
                              <F.SelectInfinite
                                @property='eventSource'
                                @label={{t 'internals.models.rental'}}
                                @modelName='rental'
                                @query={{generateQuery
                                  'rental'
                                  'taskEventSource'
                                }}
                                @searchField='name'
                                @loose={{true}}
                                @helperText={{t
                                  'tasks.task_form.apply_to_rental_helper'
                                }}
                                class='ps-3'
                              />
                            {{/if}}
                          </div>
                        </Item>

                        <Item>
                          <Radio @index={{2}} />
                        </Item>
                      </List>
                    </F.Radio>
                  </F.Section>
                </OuterItem>

                <OuterItem>
                  <F.Section
                    @icon='clock'
                    @title={{t 'tasks.template_form.due_date_title'}}
                    @subtitle={{t 'tasks.template_form.due_date_subtitle'}}
                    @vertical={{true}}
                    @contentClasses='gap-0'
                  >
                    <F.Date
                      @property='dueAt'
                      @label={{t 'tasks.task_form.due_date'}}
                      @datetime={{true}}
                    />

                    <F.Time
                      @property='dueAt'
                      @label={{t 'tasks.task_form.due_time'}}
                      @datetime={{true}}
                    />
                  </F.Section>
                </OuterItem>
              </List>
            </div>
          </Card>
        </div>
      </div>
    </Form>
  </template>
}
