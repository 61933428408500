import type { Snapshot } from '@ember-data/store';
import type StoreService from 'ember-smily-base/services/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import { isNew } from 'ember-smily-base/utils/serializer';
import type { TaskManagementAttachmentFile } from 'smily-admin-ui/models/task-management-attachment';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';
import { DEFAULT_TASK_AUTOMATION } from 'smily-admin-ui/models/task-management-template';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class TaskManagementTemplateSerializer extends ApplicationSerializer<'task-management-template'> {
  serialize(snapshot: Snapshot, options?: {}) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;
    const attributes = json.data.attributes!;

    if (isNew(snapshot, options)) {
      attributes['attachments'] = (
        snapshot.attr('newAttachmentFiles') as TaskManagementAttachmentFile[]
      ).map(({ signedId }) => ({ file: signedId }));
    } else {
      delete attributes['apply-to-existing-bookings'];
    }

    if (attributes['status'] === 'manual') {
      delete attributes['task-due-at-automation'];
    }

    return json;
  }

  normalizeSingleResponse(
    store: StoreService,
    primaryModelClass: TaskManagementTemplateModel,
    payload: ResponseWithData<ResourceObject>,
    id: string,
    requestType: string,
  ) {
    const attributes = payload.data.attributes;
    if (attributes?.['status'] === 'manual') {
      attributes['task-due-at-automation'] = DEFAULT_TASK_AUTOMATION;
    }

    return super.normalizeSingleResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType,
    );
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'task-management-template': TaskManagementTemplateSerializer;
  }
}
