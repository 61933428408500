import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OnboardingPaymentAccountVerificationRoute extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  breadcrumb = null;

  beforeModel() {
    if (!this.session.onboarding!.isAwaitingSirenSetup) {
      this.router.transitionTo('onboarding.index');
    }
  }
}
