// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import type { ModelName } from 'ember-smily-base/utils/store';
import type { AjaxSettings } from 'smily-admin-ui/adapters/application';
import ApplicationAdapter from 'smily-admin-ui/adapters/application';

export default class AccountChannelSignupAdapter extends ApplicationAdapter {
  ajaxOptions(
    url: string,
    method: string,
    options: AjaxSettings,
  ): AjaxSettings {
    const origOptions = super.ajaxOptions(url, method, options);

    if (url.endsWith('api/v4/complete-channel-signup')) {
      origOptions.credentials = 'include';
    }

    return origOptions;
  }

  urlForCustomAction(
    _: ModelName,
    _2: string,
    _3: DS.Snapshot,
    actionId: string,
  ): string {
    if (actionId === 'completeChannelSignup') {
      return `${this.urlPrefix()}/complete-channel-signup`;
    }

    return '';
  }
}
