import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type CoreLinksService from 'smily-admin-ui/services/core-links';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OldBookingsRoute extends Route {
  @service coreLinks!: CoreLinksService;
  @service session!: SessionService;

  beforeModel(): void {
    this.coreLinks.redirect('bookings');
  }
}
