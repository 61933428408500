import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import BsTooltip from 'ember-bootstrap/components/bs-tooltip';
import cannot from 'ember-can/helpers/cannot';
import t from 'ember-intl/helpers/t';

const SettingsEditButton: TOC<{
  Element: HTMLAnchorElement;
  Args: {
    route: string;
  };
}> = <template>
  {{#let (cannot 'access edit settings route') as |isDisabled|}}
    <LinkTo
      @route={{@route}}
      @disabled={{isDisabled}}
      class='btn btn-secondary btn-sm {{if isDisabled "disabled has-tooltip"}}'
      aria-disabled={{if isDisabled 'true'}}
      ...attributes
    >
      {{t 'common.edit'}}

      {{#if isDisabled}}
        <BsTooltip>
          {{t 'onboarding.settings_edit_locked_tooltip'}}
        </BsTooltip>
      {{/if}}
    </LinkTo>
  {{/let}}
</template>;

export default SettingsEditButton;
