import { attr, belongsTo } from '@ember-data/model';
import type TaskManagementTaskModel from 'smily-admin-ui/models/task-management-task';
import { BaseModel } from 'smily-admin-ui/utils/model';

export default class TaskManagementTaskItemModel extends BaseModel {
  @attr name?: string;
  @attr('number') position?: number;
  @attr('date') checkedAt?: Date;

  @belongsTo('task-management-task', { async: false, inverse: null })
  task?: TaskManagementTaskModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'task-management-task-item': TaskManagementTaskItemModel;
  }
}
