import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { tracked } from '@glimmer/tracking';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import PlanningCalendar from 'ember-planning/components/planning-calendar';
import RouteTemplate from 'ember-route-template';
import LoadingState from 'ember-smily-base/components/loading-state';
import { parseDateString } from 'ember-smily-base/utils/date';
import { removeElement } from 'ember-smily-base/utils/dom';
import { nextRender } from 'ember-smily-base/utils/runloop';
import CalendarsBookingDialog from 'smily-admin-ui/components/calendars/booking-dialog';
import CalendarsLayout from 'smily-admin-ui/components/calendars/layout';
import CalendarsPlanningBookingPillContent from 'smily-admin-ui/components/calendars/planning-booking-pill-content';
import { CALENDAR_SHARED_FILTERS } from 'smily-admin-ui/controllers/calendar';
import type CalendarPlanningController from 'smily-admin-ui/controllers/calendar/planning';
import type CalendarPlanningRoute from 'smily-admin-ui/routes/calendar/planning';
import TemplatesCalendarBase from 'smily-admin-ui/templates/calendar/-base';

class TemplatesCalendarPlanning extends TemplatesCalendarBase<
  CalendarPlanningRoute,
  CalendarPlanningController
> {
  get bookingQuery() {
    return this.store.generateQuery('booking', 'calendarPlanning');
  }

  get availabilityRange() {
    if (!this.args.controller.availableOn) {
      return undefined;
    }

    return Object.fromEntries(
      this.args.controller.availableOn
        .split(',')
        .map((dateStr, index) => [
          index ? 'end' : 'start',
          parseDateString(dateStr),
        ]),
    );
  }

  @action
  resetFilters(): void {
    CALENDAR_SHARED_FILTERS.forEach((key) =>
      this.args.controller.changeQueryParam(key, ''),
    );
  }

  @tracked showCalendar = true;

  @action
  async reload() {
    this.showCalendar = false;
    await nextRender();
    this.showCalendar = true;
  }

  <template>
    <div
      {{didInsert removeElement}}
      {{didUpdate
        this.refreshRoute
        @controller.rental
        @controller.destination
        @controller.availableOn
        @controller.sleeps
        @controller.bedrooms
        @controller.rentalTag
        @controller.rentalType
        @controller.amenities
      }}
      {{didUpdate this.reload @model.rentals.options}}
    >
    </div>

    {{#if this.showCalendar}}
      <PlanningCalendar
        @date={{this.date}}
        @rentals={{@model.rentals.options}}
        @minDate={{this.minDate}}
        @maxDate={{@model.maxDate}}
        @rentalDisplayField='name'
        {{! @glint-expect-error TODO unify Range types across apps }}
        @highlightedDayRange={{this.availabilityRange}}
        @bookingPillContentComponent={{CalendarsPlanningBookingPillContent}}
        @getBookingPillClass={{this.getBookingPillClass}}
        @allBookings={{this.allBookings}}
        @findBooking={{this.findBooking}}
        @onBookingClick={{perform this.showBookingDetails}}
        @onSelect={{this.transitionToNewBooking}}
        @onChange={{this.updateCalendarState}}
        as |Calendar publicAPI|
      >
        <CalendarsLayout
          {{didInsert (fn this.setupAPI publicAPI)}}
          @rentals={{@model.rentals.options}}
          @date={{@controller.date}}
          @months={{this.months}}
          @rental={{@controller.rental}}
          @destination={{@controller.destination}}
          @availableOn={{@controller.availableOn}}
          @sleeps={{@controller.sleeps}}
          @bedrooms={{@controller.bedrooms}}
          @rentalTag={{@controller.rentalTag}}
          @rentalType={{@controller.rentalType}}
          @amenities={{@controller.amenities}}
          @scrollToDate={{fn this.scrollToDate publicAPI.scrollToDate}}
          @changeQueryParam={{@controller.changeQueryParam}}
        >
          <:calendar>
            {{#if @model.rentals.options.length}}
              <Calendar class='h-100 bg-body' />
            {{else}}
              <div
                class='h-100 d-flex align-items-center justify-content-center bg-body'
              >
                <div class='d-flex flex-column align-items-center'>
                  <div>
                    {{t 'rentals.no_rentals_found'}}
                  </div>

                  <a
                    {{on 'click' this.resetFilters}}
                    href='#'
                    class='btn btn-link p-0'
                  >
                    {{t 'common.reset_filters'}}
                  </a>
                </div>
              </div>
            {{/if}}
          </:calendar>
        </CalendarsLayout>
      </PlanningCalendar>
    {{else}}
      <LoadingState @isLoading={{true}} class='h-100' />
    {{/if}}

    <CalendarsBookingDialog
      @booking={{this.booking}}
      @isOpen={{this.isModalOpen}}
      @isLoading={{this.showBookingDetails.isRunning}}
      @decoratePaymentBars={{true}}
      @closeModal={{fn (mut this.isModalOpen) false}}
      @transitionToBooking={{this.transitionToBooking}}
    />
  </template>
}

export default RouteTemplate(TemplatesCalendarPlanning);
