import type { TaskDueAtAutomation } from 'smily-admin-ui/models/task-management-template';
import type { BeforeOrAfter, TimeUnit } from 'smily-admin-ui/utils/tasks';

export default function getAutomationFields(automation: TaskDueAtAutomation) {
  const beforeOrAfter: BeforeOrAfter =
    automation.delayInMinutes < 0 ? 'before' : 'after';
  const event = automation.event;
  const minutes = Math.abs(automation.delayInMinutes);

  let value: string;
  let unit: TimeUnit;

  if (!(minutes % 1440)) {
    value = String(minutes / 1440);
    unit = 'day';
  } else if (!(minutes % 60)) {
    value = String(minutes / 60);
    unit = 'hour';
  } else {
    value = String(minutes);
    unit = 'minute';
  }

  return {
    value,
    unit,
    beforeOrAfter,
    event,
  };
}
