import type { TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import StarsRating from 'ember-smily-base/components/stars-rating';
import CounterText from 'smily-admin-ui/components/counter-text';
import type ClientModel from 'smily-admin-ui/models/client';

const ClientsListItemReview: TOC<{
  Args: {
    client: ClientModel;
    counterAlignment?: string;
  };
}> = <template>
  <div class='flex-1 d-flex flex-column {{@counterAlignment}}'>
    <CounterText
      @counter={{@client.reviewsCount}}
      @text={{t 'guests.reviews_left' counter=@client.reviewsCount}}
    />
  </div>

  <div class='flex-1 d-flex flex-column gap-3 align-items-end'>
    <StarsRating @value={{@client.reviewsRate}} @disabled={{true}} />
  </div>
</template>;

export default ClientsListItemReview;
