import { fn } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import type { PromiseObject } from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import Form from 'ember-smily-base/components/form';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import ensure from 'ember-smily-base/helpers/ensure';
import type StoreService from 'ember-smily-base/services/store';
import { removeElement } from 'ember-smily-base/utils/dom';
import LegalTerms from 'smily-admin-ui/components/legal/terms';
import config from 'smily-admin-ui/config/environment';
import type LegalTermModel from 'smily-admin-ui/models/legal-term';
import type SessionService from 'smily-admin-ui/services/session-service';
import { createCustomActionProxy } from 'smily-admin-ui/utils/model';

class CheckboxModel {
  @tracked isSelected = false;
}

interface AcceptanceModalsSignature {
  Args: {
    returnPath?: string;
  };
}

export default class AcceptanceModals extends Component<AcceptanceModalsSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service session!: SessionService;
  @service store!: StoreService;

  @tracked declare termsAndConditions: PromiseObject<LegalTermModel>;
  @tracked declare privacyPolicy: PromiseObject<LegalTermModel>;
  tcModel = new CheckboxModel();
  ppModel = new CheckboxModel();
  toggleTCModal?: () => void;
  togglePPModal?: () => void;

  get canDisregardTC() {
    return this.session.termsAndConditionsAccepted || !this.session.owner;
  }

  @action
  setupTCModal(toggleModal: () => void): void {
    if (this.canDisregardTC) {
      return;
    }

    this.termsAndConditions = createCustomActionProxy(
      'legal-term',
      'termsAndConditions',
      this.store,
    );

    next(this, toggleModal);

    this.toggleTCModal = toggleModal;
  }

  @action
  setupPPModal(toggleModal: () => void): void {
    if (this.session.privacyPolicyAccepted) {
      return;
    }

    this.privacyPolicy = createCustomActionProxy(
      'legal-term',
      'privacyPolicy',
      this.store,
    );
    this.togglePPModal = toggleModal;

    if (this.canDisregardTC) {
      next(this, toggleModal);
    }
  }

  @action
  async onTCSubmit(): Promise<void> {
    await this.acceptTC();

    this.toggleTCModal!();

    if (this.togglePPModal) {
      this.togglePPModal();
    } else {
      this.maybeRedirect();
    }
  }

  @action
  async onPPSubmit(): Promise<void> {
    await this.acceptPP();

    this.togglePPModal!();
    this.maybeRedirect();
  }

  async acceptTC(): Promise<void> {
    await createCustomActionProxy(
      'legal-term',
      'acceptTermsAndConditions',
      this.store,
    );
    await this.session.account!.reload();
  }

  async acceptPP(): Promise<void> {
    await createCustomActionProxy(
      'legal-term',
      'acceptPrivacyPolicy',
      this.store,
    );
    await this.session.user!.reload();
  }

  maybeRedirect(): void {
    if (this.args.returnPath) {
      window.location.href = `${config.API.HOST}${this.args.returnPath}`;
    }
  }

  <template>
    <ModalDialog @preventClose={{true}} as |dialog|>
      <div
        {{didInsert removeElement}}
        {{didInsert (fn this.setupTCModal dialog.toggleModal)}}
      >
      </div>

      <dialog.modal class='full-screen-modal' as |modal|>
        <modal.header>
          <h4>
            {{t 'user.legal.tos.title'}}
          </h4>
        </modal.header>

        <modal.body class='d-flex flex-column gap-3 h-0 flex-1 overflow-hidden'>
          <div>
            {{t
              'user.legal.tos.subtitle'
              date=(formatDate
                (ensure this.termsAndConditions.content.publishedAt)
                format='long'
              )
            }}
          </div>

          <LegalTerms
            @legalTerm={{this.termsAndConditions}}
            class='pe-0 pe-lg-2'
          />
        </modal.body>

        <modal.footer>
          <Form
            @model={{this.tcModel}}
            @onSubmit={{this.onTCSubmit}}
            class='w-100 flex-md-row align-items-center justify-content-md-between'
            as |F|
          >
            <F.Checkbox
              @label={{t 'user.legal.tos.accept_checkbox'}}
              @property='isSelected'
              @required={{true}}
            />

            <F.Submit
              @label={{t 'common.continue'}}
              disabled={{this.termsAndConditions.isPending}}
            />
          </Form>

        </modal.footer>
      </dialog.modal>
    </ModalDialog>

    <ModalDialog @preventClose={{true}} as |dialog|>
      <div
        {{didInsert removeElement}}
        {{didInsert (fn this.setupPPModal dialog.toggleModal)}}
      >
      </div>

      <dialog.modal class='full-screen-modal' as |modal|>
        <modal.header>
          <h4>
            {{t 'user.legal.pp.title'}}
          </h4>
        </modal.header>

        <modal.body class='d-flex flex-column gap-3 h-0 flex-1 overflow-hidden'>
          <div>
            {{t
              'user.legal.pp.subtitle'
              date=(formatDate
                (ensure this.privacyPolicy.content.publishedAt) format='long'
              )
            }}
          </div>

          <LegalTerms @legalTerm={{this.privacyPolicy}} class='pe-0 pe-lg-2' />
        </modal.body>

        <modal.footer>
          <Form
            @model={{this.ppModel}}
            @onSubmit={{this.onPPSubmit}}
            class='w-100 flex-md-row align-items-center justify-content-md-between'
            as |F|
          >
            <F.Checkbox
              @label={{t 'user.legal.pp.accept_checkbox'}}
              @property='isSelected'
              @required={{true}}
            />

            <F.Submit @label={{t 'common.continue'}} />
          </Form>

        </modal.footer>
      </dialog.modal>
    </ModalDialog>
  </template>
}
