import type { Snapshot } from '@ember-data/store';
import type {
  NewResourceObject,
  Request,
  ResourceObject,
} from 'ember-smily-base/utils/json-api';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class UserSerializer extends ApplicationSerializer<'user'> {
  attrs = {
    firstName: 'firstname',
    lastName: 'lastname',
  };

  formAttrs = {
    profilePicture: 'profilePictureFile',
  };

  serialize(snapshot: Snapshot, options: object) {
    const json = super.serialize(
      snapshot,
      options,
    ) as Request<NewResourceObject>;
    const applicationMetadata = snapshot.belongsTo(
      'currentApplicationMetadata',
    );

    if (!applicationMetadata) {
      return json;
    }

    json.data.relationships = {
      ...(json.data.relationships ?? {}),
      'current-application-metadata': applicationMetadata.serialize(
        {},
      ) as ResourceObject,
    };

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    user: UserSerializer;
  }
}
