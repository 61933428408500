import Component from '@glimmer/component';
import LoadingState from 'ember-smily-base/components/loading-state';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import PerformanceChart from 'smily-admin-ui/components/performance/chart';
import PerformanceTab from 'smily-admin-ui/components/performance/tab';
import type PerformanceBaseController from 'smily-admin-ui/controllers/performance/-base';
import type PerformanceBaseRoute from 'smily-admin-ui/routes/performance/-base';
import type { Tab } from 'smily-admin-ui/utils/performance';
import { tabConfig, TabHelper } from 'smily-admin-ui/utils/performance';

interface TemplatesPerformanceBaseSignature {
  Args: {
    model: ModelFor<PerformanceBaseRoute>;
    controller: PerformanceBaseController;
  };
}

export default abstract class TemplatesPerformanceBase extends Component<TemplatesPerformanceBaseSignature> {
  abstract tab: Tab;
  abstract description: string;

  get tabs() {
    return tabConfig
      .filter((tab) => tab.category === this.tab)
      .sort((a, b) => a.order - b.order)
      .map(({ name }) => {
        const { base, compare } = this.args.model[name];
        return new TabHelper(name, base, compare);
      });
  }

  get activeTab() {
    return this.tabs.find((tab) => tab.name === this.args.controller.tab)!;
  }

  <template>
    <div class='h-100 d-flex flex-column gap-3'>
      <div class='performance-description'>
        {{this.description}}
      </div>

      <div class='h-0 flex-1 d-flex'>
        <div class='card-list'>
          {{#each this.tabs as |tab|}}
            <PerformanceTab
              @tab={{tab}}
              @activeTab={{@controller.tab}}
              @changeTab={{@controller.changeTab}}
            />
          {{/each}}
        </div>

        <div class='flex-1 performance-chart p-3'>
          {{#if this.activeTab.isLoading}}
            <div
              class='w-100 h-100 d-flex align-items-center justify-content-center'
            >
              <LoadingState isLoading={{true}} />
            </div>
          {{else}}
            <PerformanceChart @tab={{this.activeTab}} />
          {{/if}}
        </div>
      </div>
    </div>
  </template>
}
