import { attr, belongsTo } from '@ember-data/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import { BaseModel, euro } from 'smily-admin-ui/utils/model';

type ComponentName =
  | 'payout'
  | 'smily_gateway_fees'
  | 'owner_payout'
  | 'property_manager_deductions';
interface BookingPayoutComponent {
  name: ComponentName;
  position: number;
  'amount-in-cents': number;
  currency: string;
}

export function getComponent(
  name: ComponentName,
  bookingPayout: BookingPayoutModel,
) {
  return bookingPayout.components.find((component) => component.name === name);
}

export function getAmountForComponent(
  name: ComponentName,
  bookingPayout: BookingPayoutModel,
) {
  return getComponent(name, bookingPayout)!['amount-in-cents'] / 100;
}

export function getCurrencyForComponent(
  name: ComponentName,
  bookingPayout: BookingPayoutModel,
) {
  return getComponent(name, bookingPayout)!.currency;
}

export default class BookingPayoutModel extends BaseModel {
  @attr('number') paymentsBalanceInCents!: number;
  @euro('paymentsBalanceInCents') paymentsBalance!: number;
  @attr paymentsBalanceCurrency!: string;
  @attr('array') components!: BookingPayoutComponent[];

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-payout': BookingPayoutModel;
  }
}
