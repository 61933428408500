import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import can from 'ember-can/helpers/can';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import UserAvatar from 'ember-smily-base/components/user-avatar';
import not from 'ember-truth-helpers/helpers/not';
import AccountSwitcher from 'smily-admin-ui/components/account-switcher';
import SidebarFooter from 'smily-admin-ui/components/sidebar/footer';
import SidebarFooterHeadway from 'smily-admin-ui/components/sidebar/footer/headway';
import SidebarMobileMenuSwitchAccountTrigger from 'smily-admin-ui/components/sidebar/mobile-menu/switch-account-trigger';
import type CacheService from 'smily-admin-ui/services/cache';

export default class SidebarMobileMenuFooter extends SidebarFooter {
  @service cache!: CacheService;

  @tracked isUserMenuOpen = false;

  get mobileFooterRoutes() {
    return this.session.account?.isSmily
      ? ['profile', 'settings', 'auth.logout']
      : ['auth.logout'];
  }

  <template>
    <h4 class='px-3 py-3'>
      {{t 'common.account'}}
    </h4>

    <ul class='nav ps-1'>
      {{#if (can 'show profile route')}}
        <li class='nav-item'>
          <LinkTo @route='profile' class='nav-link'>
            <div class='nav-icon flex-shrink-0'>
              <UserAvatar
                @name={{this.user.fullName}}
                @image={{this.user.profilePicture}}
              />
            </div>

            <div class='nav-title text-truncate'>
              {{t 'breadcrumbs.profile'}}
            </div>
          </LinkTo>
        </li>
      {{/if}}

      {{#if (can 'show settings route')}}
        <li class='nav-item'>
          <LinkTo
            @route='settings'
            class='nav-link dropdown-item'
            @disabled={{not (can 'access settings route')}}
          >
            <Icon @icon='cog' @fixedWidth={{true}} class='nav-icon' />

            <div class='nav-title'>
              {{t 'breadcrumbs.settings.index'}}
            </div>

            {{#if (not (can 'access settings route'))}}
              <div class='flex-1 pe-3 d-flex justify-content-end'>
                <Icon @icon='lock' />
              </div>
            {{/if}}
          </LinkTo>
        </li>
      {{/if}}

      <SidebarFooterHeadway />

      {{#if this.cache.hasMultipleAccounts}}
        <AccountSwitcher
          @renderInPlace={{true}}
          @triggerClass='border-0 bg-white'
          @triggerComponent={{SidebarMobileMenuSwitchAccountTrigger}}
          as |account|
        >
          <div class='ps-2 text-body text-truncate'>
            {{account.businessName}}
          </div>
        </AccountSwitcher>
      {{/if}}

      <LinkTo @route='auth.logout' class='ps-2 ms-1 mt-2'>
        {{t 'breadcrumbs.auth.logout'}}
      </LinkTo>
    </ul>
  </template>
}
