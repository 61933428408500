import type { TOC } from '@ember/component/template-only';
import Icon from 'ember-smily-base/components/icon';

const IconsSmily: TOC<{
  Element: SVGElement;
}> = <template>
  <Icon
    @icon='smily'
    @style='fak'
    @fixedWidth={{true}}
    class='text-primary'
    ...attributes
  />
</template>;

export default IconsSmily;
