import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import RentalsPrimaryPhoto from 'smily-admin-ui/components/rentals/primary-photo';
import RentalsProgress from 'smily-admin-ui/components/rentals/progress';
import RentalsRoomsAndGuests from 'smily-admin-ui/components/rentals/rooms-and-guests';
import RentalsStatus from 'smily-admin-ui/components/rentals/status';
import type RentalModel from 'smily-admin-ui/models/rental';

const RentalsRentalItemDesktop: TOC<{
  Element: HTMLDivElement;
  Args: {
    model: RentalModel;
  };
}> = <template>
  <div class='d-flex align-items-center gap-3 gap-lg-5' ...attributes>
    <div class='flex-4 d-flex align-items-center gap-3 gap-lg-5'>
      <RentalsPrimaryPhoto @rental={{@model}} />

      <div>
        <div class='h4'>
          {{@model.name}}
        </div>

        <RentalsRoomsAndGuests @rental={{@model}} />
      </div>
    </div>

    <div class='flex-1'>
      <RentalsStatus @rental={{@model}} />
    </div>

    <div class='flex-1 flex-lg-4 d-flex align-items-center gap-5'>
      <RentalsProgress @rental={{@model}} />
    </div>

    <LinkTo
      @route='rentals.rental'
      @model={{@model.id}}
      class='flex-1 btn btn-sm
        {{if @model.isListed "btn-secondary" "btn-primary"}}'
    >
      {{t (if @model.isListed 'rentals.show_listing' 'rentals.finish_listing')}}
    </LinkTo>
  </div>
</template>;

export default RentalsRentalItemDesktop;
