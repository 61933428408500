import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import findBy from 'ember-composable-helpers/helpers/find-by';
import type IntlService from 'ember-intl/services/intl';
import Breadcrumbs from 'ember-smily-base/components/breadcrumbs';
import Notifications from 'ember-smily-base/components/notifications';
import Select from 'ember-smily-base/components/select/simple';
import SmilyLayoutRouteIcon from 'ember-smily-base/components/smily-layout/route-icon';
import { getLanguages } from 'ember-smily-base/utils/intl';
import type {
  AvailableLocale,
  AvailableLocaleLong,
} from 'ember-smily-base/utils/intl/dictionary';
import type { SelectOption } from 'ember-smily-base/utils/select';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';

type LocaleOption = SelectOption<AvailableLocaleLong> & { flag: string };

const LOCALE_MAP: {
  locale: AvailableLocaleLong;
  language: AvailableLocale;
}[] = [
  { locale: 'en-us', language: 'EN' },
  { locale: 'fr-fr', language: 'FR' },
];

interface UnauthenticatedScreenSignature<ExtraArgs> {
  Args: {
    extraTopbarInfo?: string;
    hideLocaleSelect?: boolean;
  } & ExtraArgs;
  Blocks: { default: [] };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default class UnauthenticatedScreen<ExtraArgs = {}> extends Component<
  UnauthenticatedScreenSignature<ExtraArgs>
> {
  @service intl!: IntlService;

  get languages(): LocaleOption[] {
    const languages = getLanguages(this.intl.primaryLocale);

    return LOCALE_MAP.map(({ locale, language }) => ({
      label: languages.find(({ value }) => value === language)!.label,
      value: locale,
      flag: language.toLowerCase(),
    }));
  }

  @action
  changeLocale({ value }: LocaleOption): void {
    this.intl.setLocale(value);
  }

  <template>
    <div class='smily-topbar'>
      <div class='container-xl d-flex justify-content-between'>
        <div class='d-flex align-items-center'>
          <div class='smily-topbar-icon'>
            <SmilyLayoutRouteIcon />
          </div>

          <Breadcrumbs class='flex-1' />
        </div>

        {{#if (or @extraTopbarInfo (not @hideLocaleSelect))}}
          <div class='d-flex align-items-center'>
            {{#if @extraTopbarInfo}}
              <h3>
                {{@extraTopbarInfo}}
              </h3>
            {{/if}}

            {{#unless @hideLocaleSelect}}
              <div class='d-flex align-items-center'>
                <Select
                  @value={{findBy
                    'value'
                    this.intl.primaryLocale
                    this.languages
                  }}
                  @options={{this.languages}}
                  @triggerClass='btn btn-link link-unstyled btn-sm with-smily-select-icon'
                  @onChange={{this.changeLocale}}
                  as |item|
                >
                  <div class='d-flex align-items-center gap-1'>
                    <div class='smily-flag {{item.flag}}'></div>

                    <div>{{item.label}}</div>
                  </div>
                </Select>
              </div>
            {{/unless}}
          </div>
        {{/if}}
      </div>
    </div>

    {{yield}}

    <Notifications />
  </template>
}
