import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class OnboardingRentalRoute extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  breadcrumb = null;

  redirect(): void {
    const hasAnyRental = this.session.hasAnyRental;
    const onlyRentalId = this.session.onboarding?.onlyRentalId;

    if (!hasAnyRental) {
      this.router.transitionTo('rentals.new');
    } else if (onlyRentalId) {
      this.router.transitionTo('rentals.rental', onlyRentalId);
    } else {
      this.router.transitionTo('rentals');
    }
  }
}
