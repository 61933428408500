import { concat, fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import pick from 'ember-composable-helpers/helpers/pick';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import type MediaService from 'ember-responsive';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import MultilineText from 'ember-smily-base/components/multiline-text';
import StarsRating from 'ember-smily-base/components/stars-rating';
import Textarea from 'ember-smily-base/components/textarea';
import UserAvatar from 'ember-smily-base/components/user-avatar';
import ensure from 'ember-smily-base/helpers/ensure';
import timestamp from 'ember-smily-base/helpers/timestamp';
import { nextRender } from 'ember-smily-base/utils/runloop';
import and from 'ember-truth-helpers/helpers/and';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';
import MobileMessageComposer from 'smily-admin-ui/components/mobile/message-composer';
import SourceText from 'smily-admin-ui/components/source-text';
import type HostReviewModel from 'smily-admin-ui/models/host-review';
import type ReviewModel from 'smily-admin-ui/models/review';
import { CHARACTER_LIMIT } from 'smily-admin-ui/models/review-reply';
import type SessionService from 'smily-admin-ui/services/session-service';
import {
  HOST_REVIEW_CRITERIA,
  REVIEW_CRITERIA,
} from 'smily-admin-ui/utils/model/review-criteria';
import { isError, queryAI } from 'smily-admin-ui/utils/promise';

function isGuestRecommended(review: HostReviewModel | ReviewModel) {
  return (review as HostReviewModel).isGuestRecommended;
}

function isGuestRecommendedEmpty(review: HostReviewModel | ReviewModel) {
  return (
    isGuestRecommended(review) === null || isGuestRecommended === undefined
  );
}

function getReviewTitle(review: HostReviewModel | ReviewModel) {
  return (review as ReviewModel).title;
}

interface ReviewsShowSignature<IsHostReview extends boolean> {
  Args: {
    review: IsHostReview extends true ? HostReviewModel : ReviewModel;
    isHostReview: IsHostReview;
  };
}

export default class ReviewsShow<
  IsHostReview extends boolean,
> extends Component<ReviewsShowSignature<IsHostReview>> {
  @service intl!: IntlService;
  @service media!: MediaService;
  @service notify!: NotifyService;
  @service session!: SessionService;

  get criterionNames(): typeof HOST_REVIEW_CRITERIA | typeof REVIEW_CRITERIA {
    return this.args.isHostReview ? HOST_REVIEW_CRITERIA : REVIEW_CRITERIA;
  }

  get replyGuid() {
    return guidFor(this.args.review.reviewReply);
  }

  get isMobileComposerShown() {
    const reviewReply = this.args.review.reviewReply;

    return (
      !this.args.isHostReview &&
      this.media.isSmallScreen &&
      (reviewReply?.isNew as boolean)
    );
  }

  get aiGenerationPayload() {
    return {
      data: {
        type: 'review-replies',
        relationships: {
          review: {
            data: {
              type: 'reviews',
              id: this.args.review.id,
            },
          },
        },
      },
    };
  }

  saveReply = task({ drop: true }, async () => {
    const reply = this.args.review.reviewReply;
    reply.submittedAt = new Date();
    await reply.save();
  });

  draftWithAI = task({ drop: true }, async () => {
    const response = await queryAI(
      this,
      'review-replies/suggest',
      this.aiGenerationPayload,
    );

    if (isError(response)) {
      return;
    }

    this.args.review.reviewReply.message = response.data.attributes!
      .message as string;

    if (this.isMobileComposerShown) {
      this.afterPayloadGeneration();
    }
  });

  @action
  handleMobileComposerInput(value: string) {
    this.args.review.reviewReply.message = value;
    this.resizeComposer();
  }

  async afterPayloadGeneration() {
    await nextRender();
    this.resizeComposer();
    await nextRender();
    this.focusEndOfComposer();
    this.scrollComposerToBottom();
  }

  /* MOBILE COMPOSER UTILS START */

  get textarea() {
    return document.querySelector(
      '[data-mobile-composer] textarea',
    ) as HTMLTextAreaElement;
  }

  get mainContent() {
    return document.querySelector('.main-content') as HTMLDivElement;
  }

  get comment() {
    return document.querySelector('[data-review-comment]') as HTMLDivElement;
  }

  resizeComposer() {
    this.textarea.style.height = '';
    this.textarea.style.height = `${this.textarea.scrollHeight}px`;
    this.textarea.style.maxHeight = '';

    if (
      this.comment.clientHeight + this.textarea.clientHeight >
      this.mainContent.clientHeight
    ) {
      this.textarea.style.maxHeight = `${
        this.mainContent.clientHeight -
        Math.min(this.comment.clientHeight, this.mainContent.clientHeight / 2)
      }px`;
    }
  }

  focusEndOfComposer() {
    this.textarea.setSelectionRange(
      this.textarea.value.length,
      this.textarea.value.length,
    );
    this.textarea.focus();
  }

  scrollComposerToBottom() {
    const marginsHeight = 30;
    this.mainContent.scrollTop =
      this.textarea.offsetTop -
      (this.mainContent.clientHeight -
        this.textarea.clientHeight -
        marginsHeight);
  }

  /* MOBILE COMPOSER UTILS END */

  <template>
    <div class='card-body px-0 py-2'>
      <List @padded={{true}} @justified={{true}} as |Item|>
        {{#unless @isHostReview}}
          <Item>
            <div class='h2 text-22'>
              {{getReviewTitle @review}}
            </div>

            <StarsRating
              @value={{@review.rating}}
              @disabled={{true}}
              class='text-22'
            />
          </Item>

          <Item class='border-top-0'>
            <div class='fw-semibold'>
              {{t 'internals.models.source'}}
            </div>

            <SourceText @source={{@review.source}} @directIfEmpty={{true}} />
          </Item>
        {{/unless}}

        {{#each this.criterionNames as |criterionName|}}
          {{#let (get @review.criteria criterionName) as |criterion|}}
            <Item class='flex-column align-items-start'>
              <div class='d-flex w-100 justify-content-between'>
                <div class='fw-semibold'>
                  {{t (concat 'reviews.criteria.' criterionName)}}
                </div>

                {{#if criterion.rating}}
                  <StarsRating @value={{criterion.rating}} @disabled={{true}} />
                {{else}}
                  {{t 'reviews.guest_left_no_rating'}}
                {{/if}}
              </div>

              {{#if criterion.comment}}
                <div>
                  {{criterion.comment}}
                </div>
              {{/if}}
            </Item>
          {{/let}}
        {{/each}}

        {{#if @isHostReview}}
          <Item>
            <div class='fw-semibold'>
              {{t 'reviews.is_guest_recommended'}}
            </div>

            <div>
              {{#if (isGuestRecommendedEmpty @review)}}
                {{t 'reviews.guest_left_no_rating'}}
              {{else}}
                {{t
                  (concat 'common.yes_no_options.' (isGuestRecommended @review))
                }}
              {{/if}}
            </div>
          </Item>
        {{/if}}

        <Item class='border-top-0' data-review-comment>
          <div class='messenger w-100'>
            <div class='message {{if @isHostReview "owned"}}'>
              <div class='message-avatar'>
                <UserAvatar
                  @name={{if
                    @isHostReview
                    (ensure this.session.user.fullName)
                    (ensure @review.booking.client.fullName)
                  }}
                  @image={{if @isHostReview this.session.user.profilePicture}}
                  @small={{true}}
                  @color={{if @isHostReview 'teal-50' 'white'}}
                />
              </div>

              <div class='message-content'>
                <div class='message-bubble'>
                  <MultilineText @text={{@review.comment}} />

                  {{#if @review.privateComment}}
                    <div class='fw-semibold mt-3'>
                      {{t 'internals.fields.privateComment'}}
                    </div>

                    <MultilineText @text={{@review.privateComment}} />
                  {{/if}}
                </div>

                <ul
                  class='list-inline dotted message-meta text-12'
                  data-test-message-meta
                >
                  {{#if @review.submittedAt}}
                    <li>
                      {{timestamp @review.submittedAt}}
                    </li>
                  {{/if}}

                  <li>
                    <SourceText
                      @source={{@review.source}}
                      @directIfEmpty={{true}}
                      @gap={{1}}
                    />
                  </li>
                </ul>
              </div>
            </div>

            {{#unless this.isMobileComposerShown}}
              {{#if (or (not @isHostReview) @review.reviewReply)}}
                <div class='message {{if @isHostReview "" "owned"}}'>
                  <div class='message-avatar'>
                    <UserAvatar
                      @name={{ensure this.session.user.fullName}}
                      @image={{this.session.user.profilePicture}}
                      @small={{true}}
                      @color='teal-50'
                    />
                  </div>

                  {{#if @review.reviewReply.isNew}}
                    <div
                      class='message-content form-group smily-form-field smily-form-textarea w-100'
                      data-smily-form-object={{this.replyGuid}}
                      data-smily-form-field='base'
                    >
                      <Textarea
                        {{on
                          'input'
                          (pick
                            'target.value'
                            (fn (mut @review.reviewReply.message))
                          )
                        }}
                        @containerClass='w-100 mb-3'
                        @characterLimit={{CHARACTER_LIMIT}}
                        value={{@review.reviewReply.message}}
                        placeholder={{t 'reviews.write_your_reply'}}
                        class='form-control'
                        aria-label={{t 'common.reply'}}
                      />
                    </div>
                  {{else}}
                    <div class='message-content'>
                      <MultilineText
                        @text={{@review.reviewReply.message}}
                        class='message-bubble'
                      />

                      <ul
                        class='list-inline dotted message-meta text-12'
                        data-test-message-meta
                      >
                        <li>
                          {{timestamp
                            (or
                              @review.reviewReply.submittedAt
                              @review.reviewReply.createdAt
                            )
                          }}
                        </li>

                        <li>
                          {{! TODO fix source when new source concept is figured out }}
                          <SourceText
                            @source={{@review.source}}
                            @directIfEmpty={{true}}
                            @gap={{1}}
                          />
                        </li>
                      </ul>
                    </div>
                  {{/if}}
                </div>
              {{/if}}

              {{#if
                (and @review.reviewReply.isNew (not this.isMobileComposerShown))
              }}
                <div class='message'>
                  <div
                    class='message-content flex-row justify-content-end gap-3'
                  >
                    <LoadingButton
                      @action={{perform this.draftWithAI}}
                      class='btn btn-secondary'
                    >
                      <div class='d-flex gap-1'></div>

                      <IconText
                        @text={{t 'reviews.draft_with_ai'}}
                        @icon='sparkles'
                        @iconClass='text-purple'
                        @gap={{1}}
                      />
                    </LoadingButton>

                    <LoadingButton
                      @action={{perform this.saveReply}}
                      type='button'
                      class='btn btn-primary align-self-end'
                      disabled={{not @review.reviewReply.message}}
                      data-test-submit-reply
                    >
                      {{t 'common.reply'}}
                    </LoadingButton>
                  </div>
                </div>
              {{/if}}
            {{/unless}}
          </div>
        </Item>
      </List>

      {{#if this.isMobileComposerShown}}
        <MobileMessageComposer
          @value={{@review.reviewReply.message}}
          @label={{t 'common.reply'}}
          @characterLimit={{CHARACTER_LIMIT}}
          @onChange={{this.handleMobileComposerInput}}
          @send={{perform this.saveReply}}
          class='border-top p-3 pb-0'
          data-mobile-composer
        >
          <div>
            <LoadingButton
              @label={{t 'reviews.draft_with_ai'}}
              @iconOnly={{true}}
              @action={{perform this.draftWithAI}}
              class='btn btn-secondary px-2'
            >
              <Icon @icon='sparkles' class='text-purple text-22' />
            </LoadingButton>
          </div>
        </MobileMessageComposer>
      {{/if}}
    </div>
  </template>
}
