import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import IconText from 'ember-smily-base/components/icon-text';
import LoadingButton from 'ember-smily-base/components/loading-button';
import type StoreService from 'ember-smily-base/services/store';
import type TaskManagementTemplateModel from 'smily-admin-ui/models/task-management-template';

interface TasksDeleteTemplateSignature {
  Args: {
    template: TaskManagementTemplateModel;
  };
}

export default class TasksDeleteTemplate extends Component<TasksDeleteTemplateSignature> {
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;
  @service store!: StoreService;

  @action
  async deleteTemplate() {
    await this.args.template.destroyRecord();

    this.router.transitionTo('tasks.templates.index');
    this.notify.success(this.intl.t('tasks.template_form.template_deleted'));
  }

  <template>
    <LoadingButton
      @action={{this.deleteTemplate}}
      @needsConfirmation={{true}}
      class='btn btn-danger'
    >
      <IconText
        @icon='trash-alt'
        @iconStyle='far'
        @text={{t 'tasks.template_form.delete_template'}}
      />
    </LoadingButton>
  </template>
}
