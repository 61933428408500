import PerformanceBaseController from 'smily-admin-ui/controllers/performance/-base';

export default class PerformanceCalendarOccupancyController extends PerformanceBaseController {
  type = 'calendarOccupancy' as const;
}

declare module '@ember/controller' {
  interface Registry {
    'performance.calendar-occupancy': PerformanceCalendarOccupancyController;
  }
}
