import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import can from 'ember-can/helpers/can';
import MarkdownToHtml from 'ember-cli-showdown/components/markdown-to-html';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import Form from 'ember-smily-base/components/form';
import Icon from 'ember-smily-base/components/icon';
import Link from 'ember-smily-base/components/link';
import type StoreService from 'ember-smily-base/services/store';
import { nextRender } from 'ember-smily-base/utils/runloop';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type SmilyCopilotConversationModel from 'smily-admin-ui/models/smily-copilot-conversation';
import type {
  Message,
  SmilyCopilotMessagePayload,
} from 'smily-admin-ui/models/smily-copilot-conversation';
import { TranscriptRole } from 'smily-admin-ui/models/smily-copilot-conversation';

export default class SmilyCopilotComponent extends Component {
  @service private store!: StoreService;

  @tracked private systemMessage =
    "Hello I'm Smily Copilot, how can I help you?";
  @tracked private transcript: Message[] = [];
  @tracked private message = '';
  @tracked private isMinimized = false;

  private conversationId?: string;
  private conversation?: SmilyCopilotConversationModel;

  private get displayableTranscript() {
    return this.transcript.filter(
      (item) =>
        Object.values(TranscriptRole).includes(item.role) &&
        item.content.trim().length,
    );
  }

  private handleInput = (event: Event) => {
    this.message = (event.target as HTMLInputElement).value;
  };

  private sendMessage = task({ drop: true }, async () => {
    if (!this.message.trim()) {
      return;
    }

    const payload: SmilyCopilotMessagePayload = {
      data: {
        attributes: {
          message: this.message,
        },
      },
    };

    if (this.conversationId) {
      payload.data.attributes['conversation-id'] = this.conversationId;
    } else {
      const conversation = this.store.createRecord(
        'smily-copilot-conversation',
      );
      this.conversation = conversation;
    }

    const conversationFromResponse =
      await this.conversation!.createMessage(payload);

    this.conversationId = conversationFromResponse.data.id;
    this.transcript = conversationFromResponse.data.attributes!
      .transcript as Message[];
    this.message = '';

    await nextRender();

    const messagesContainer = document.querySelector(
      '[data-smily-copilot-messages]',
    );

    messagesContainer?.scrollTo({
      top: messagesContainer.scrollHeight,
      behavior: 'smooth',
    });
  });

  private toggleMinimize = () => {
    this.isMinimized = !this.isMinimized;
  };

  <template>
    {{#if (can 'access smily copilot feature')}}
      <div
        class='smily-copilot-container
          {{if this.isMinimized "minimized"}}
          {{if this.sendMessage.isRunning "loading"}}'
      >
        <div class='smily-copilot-header'>
          <div>
            {{t 'inbox.smily_copilot.title'}}
          </div>

          <Link
            @action={{this.toggleMinimize}}
            class='smily-copilot-minimize-button'
          >
            {{#if this.isMinimized}}
              <Icon @icon='chevron-up' @style='far' />
            {{else}}
              <Icon @icon='chevron-down' @style='far' />
            {{/if}}
          </Link>
        </div>

        <BsCollapse @collapsed={{this.isMinimized}}>
          <section
            class='smily-copilot-messages'
            tabindex='0'
            aria-label={{t 'inbox.smily_copilot.conversation_label'}}
            aria-live='polite'
            data-smily-copilot-messages
          >
            <section
              class='smily-copilot-message bot'
              aria-label={{t
                'inbox.smily_copilot.message_label_options.assistant'
              }}
            >
              {{this.systemMessage}}
            </section>

            {{#each this.displayableTranscript as |message|}}
              <section
                class='smily-copilot-message {{getMessageClass message}}'
                aria-label={{optionLabel
                  message.role
                  'inbox.smily_copilot.message_label_options'
                }}
              >
                <MarkdownToHtml @markdown={{message.content}} />
              </section>
            {{/each}}
          </section>

          <Form
            @model={{(hash)}}
            @onSubmit={{perform this.sendMessage}}
            class='border-top p-2 d-flex flex-row gap-2'
            as |F|
          >
            <input
              {{on 'input' this.handleInput}}
              type='text'
              value={{this.message}}
              placeholder={{t 'inbox.type_a_message'}}
              aria-label={{t 'inbox.type_a_message'}}
              class='form-control form-control-sm'
              disabled={{this.sendMessage.isRunning}}
            />

            <F.Submit class='btn-sm'>
              {{t 'common.send'}}
            </F.Submit>
          </Form>
        </BsCollapse>
      </div>
    {{/if}}
  </template>
}

function getMessageClass(message: Message) {
  if (message.role === TranscriptRole.User) {
    return 'user';
  } else if (message.role === TranscriptRole.Assistant) {
    return 'bot';
  } else {
    return '';
  }
}
