import { camelize, underscore } from '@ember/string';
import Transform from '@ember-data/serializer/transform';

export default class UnderscoreTransform extends Transform {
  deserialize(serialized?: string | null): string | null | undefined {
    return serialized ? camelize(serialized) : serialized;
  }

  serialize(deserialized?: string | null): string | null | undefined {
    return deserialized ? underscore(deserialized) : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    underscore: string;
  }
}
