import type { TOC } from '@ember/component/template-only';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import List from 'ember-smily-base/components/list';
import StarsRating from 'ember-smily-base/components/stars-rating';
import ensure from 'ember-smily-base/helpers/ensure';
import type HostReviewModel from 'smily-admin-ui/models/host-review';
import type ReviewModel from 'smily-admin-ui/models/review';

const ReviewsListItemCompact: TOC<{
  Args: {
    review: ReviewModel | HostReviewModel;
  };
}> = <template>
  <List @padded={{true}} class='text-nowrap bt-1' as |Item|>
    <Item class='d-flex align-items-center gap-3'>
      <div class='flex-1 d-flex gap-3'>
        <div class='flex-1 d-flex flex-column gap-1'>
          <div class='h4'>
            {{@review.booking.rentalName}}
          </div>

          <div class='d-flex justify-content-start gap-3'>
            <div class='position-relative'>
              <StarsRating @value={{@review.rating}} @disabled={{true}} />

              {{#if @review.isDismissed}}
                <div class='strike-through'></div>
              {{/if}}
            </div>

            {{#if @review.isDismissed}}
              <IconText
                @icon='exclamation-circle'
                @iconStyle='fal'
                @text={{t 'reviews.retracted'}}
                @gap={{1}}
              />
            {{/if}}
          </div>
        </div>

        <div class='flex-1 d-flex gap-1 align-items-center justify-content-end'>
          <div class='d-flex align-items-center gap-2'>
            <div>
              {{formatDate
                (ensure @review.booking.startAt.date)
                format='longWithWeek'
              }}
            </div>

            <Icon
              @icon='long-arrow-alt-right'
              @style='far'
              class='stretch-2x text-10'
            />

            <div>
              {{formatDate
                (ensure @review.booking.endAt.date)
                format='longWithWeek'
              }}
            </div>
          </div>
        </div>
      </div>
    </Item>

    {{#if @review.comment}}
      <Item>
        <div class='d-flex'>
          <div class='flex-1 text-truncate'>
            {{#if @review.isDismissed}}
              <del>
                {{@review.comment}}
              </del>
            {{else}}
              {{@review.comment}}
            {{/if}}
          </div>
        </div>
      </Item>
    {{/if}}
  </List>
</template>;

export default ReviewsListItemCompact;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reviews::ListItemCompact': typeof ReviewsListItemCompact;
  }
}
