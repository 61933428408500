import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import dec from 'ember-composable-helpers/helpers/dec';
import t from 'ember-intl/helpers/t';
import type { AnyComponent } from 'ember-smily-base/utils/glimmer';
import OnboardingStepIcon from 'smily-admin-ui/components/onboarding/step-icon';
import OnboardingStepTitle from 'smily-admin-ui/components/onboarding/step-title';
import type { Task } from 'smily-admin-ui/utils/onboarding';

interface OnboardingStepSignature {
  Element: HTMLDivElement;
  Args: {
    item: AnyComponent;
    step: 1 | 2 | 3 | 4;
    currentStep: 1 | 2 | 3 | 4;
    tasks: Task[];
  };
}

export default class OnboardingStep extends Component<OnboardingStepSignature> {
  @service router!: RouterService;

  get isCompleted(): boolean {
    return this.args.step < this.args.currentStep;
  }

  get isDisabled(): boolean {
    return this.args.step > this.args.currentStep;
  }

  get isActive(): boolean {
    return !this.isCompleted && !this.isDisabled;
  }

  get state(): string {
    if (this.isCompleted) {
      return 'completed';
    } else if (this.isDisabled) {
      return 'disabled';
    } else {
      return 'active';
    }
  }

  @action
  transitionToNextTask(): void {
    if (!this.isActive) {
      return;
    }

    const nextTask = this.args.tasks.find(
      (task) => !task.isComplete && !task.isHidden && !task.isLocked,
    );
    const route = nextTask ? `onboarding.${nextTask.route}` : 'index';

    this.router.transitionTo(route);
  }

  <template>
    <@item
      {{on 'click' this.transitionToNextTask}}
      class='onboarding-step {{this.state}}'
      role={{if this.isActive 'button'}}
      ...attributes
    >
      <div class='d-flex'>
        <div class='flex-1 d-flex align-items-center'>
          <OnboardingStepTitle @step={{@step}} />
        </div>

        <div
          class='onboarding-step-status flex-shrink-0 d-flex flex-column align-items-center gap-2'
        >
          <div class='flex-1'>
            <OnboardingStepIcon @isCompleted={{this.isCompleted}} />
          </div>

          <div class='onboarding-step-status-text text-12 fw-semibold'>
            {{t (concat 'onboarding.step_states.' this.state) step=(dec @step)}}
          </div>
        </div>
      </div>
    </@item>
  </template>
}
