import Service from '@ember/service';
import { service } from '@ember/service';
import { isAfter } from 'date-fns';
import type MediaService from 'ember-responsive';
import type SidebarService from 'ember-smily-base/services/sidebar';
import type StoreService from 'ember-smily-base/services/store';
import { isMobile } from 'ember-smily-base/utils/application';
import { parseDateString } from 'ember-smily-base/utils/date';
import type { InfiniteQuery, QueryResult } from 'ember-smily-base/utils/store';
import { hash } from 'rsvp';
import type DestinationModel from 'smily-admin-ui/models/destination';
import type InboxAttachmentModel from 'smily-admin-ui/models/inbox-attachment';
import type PreferencesPaymentModel from 'smily-admin-ui/models/preferences-payment';
import type SessionService from 'smily-admin-ui/services/session-service';
import type { FeatureName } from 'smily-admin-ui/utils/config';
import { featureDiscoveryConfig } from 'smily-admin-ui/utils/config';
import type { TrackedArray } from 'tracked-built-ins';
import { tracked } from 'tracked-built-ins';

export type InboxConversationStatus = 'all' | 'closed' | 'open' | 'spam';
export type InboxConversationBookingLabel =
  | 'inquiries'
  | 'reserved'
  | 'checkin_today'
  | 'at_property'
  | 'checkout_today'
  | 'post_stay'
  | 'canceled';

interface InboxCounterMeta {
  [index: string]: unknown;
  pagination: { current_page: number; pages: number; total: number };
  counters: Record<InboxConversationStatus, number>;
  'booking-label-counters': Record<InboxConversationBookingLabel, number>;
}

interface InboxCacheEntry {
  messages: InfiniteQuery<'inbox-message'>;
  external?: InboxMessageContentCacheEntry;
  internal?: InboxMessageContentCacheEntry;
}

interface InboxMessageContentCacheEntry {
  content: string;
  attachments: InboxAttachmentModel[];
}

export type InboxCounterProperty =
  | 'inboxOpenCounter'
  | 'inboxInquiriesCounter'
  | 'inboxReservedCounter'
  | 'inboxCheckinTodayCounter'
  | 'inboxAtPropertyCounter'
  | 'inboxCheckoutTodayCounter'
  | 'inboxPostStayCounter'
  | 'inboxCanceledCounter'
  | 'inboxArchivedCounter'
  | 'inboxSpamCounter';

export default class CacheService extends Service {
  @service media!: MediaService;
  @service session!: SessionService;
  @service sidebar!: SidebarService;
  @service store!: StoreService;

  inboxCache: Map<string, InboxCacheEntry> = new Map();
  @tracked taskCount?: number;
  @tracked firstBookingDate?: Date;
  @tracked maximumBedroomsCount?: number;
  @tracked maximumSleepsCount?: number;
  @tracked destinations!: QueryResult<DestinationModel>;
  @tracked preferencesPayment!: PreferencesPaymentModel;
  @tracked hasMultipleAccounts?: boolean;
  newFeatureSessionCounts: TrackedArray<[FeatureName, number]> = tracked([]);

  updateOnboardingCompletionPercentage(): void {
    this.sidebar.setBadge(
      'onboarding',
      `${this.session.onboarding!.completionPercentage}%`,
    );
  }

  async updateInboxCounter(): Promise<void> {
    if (!isMobile(this.media)) {
      return;
    }

    const conversations = await this.store.queryRecords(
      'inbox-conversation',
      this.store.generateQuery('inbox-conversation', 'unreadCounter'),
    );

    this.sidebar.setBadge('inbox', conversations.meta.pagination.total);
  }

  // inbox counters
  private isInboxCounterRequested = false;
  @tracked inboxOpenCounter?: number;
  @tracked inboxInquiriesCounter?: number;
  @tracked inboxReservedCounter?: number;
  @tracked inboxCheckinTodayCounter?: number;
  @tracked inboxAtPropertyCounter?: number;
  @tracked inboxCheckoutTodayCounter?: number;
  @tracked inboxPostStayCounter?: number;
  @tracked inboxCanceledCounter?: number;
  @tracked inboxArchivedCounter?: number;
  @tracked inboxSpamCounter?: number;

  async updateInboxCounters(): Promise<void> {
    if (isMobile(this.media) || this.isInboxCounterRequested) {
      return;
    }

    const countersPromise = this.store.queryRecords(
      'inbox-conversation',
      this.store.generateQuery('inbox-conversation', 'counters'),
    );
    const bookingLabelCountersPromise = this.store.queryRecords(
      'inbox-conversation',
      this.store.generateQuery('inbox-conversation', 'bookingLabelCounters'),
    );

    const responses = await hash({
      counters: countersPromise,
      bookingLabelCounters: bookingLabelCountersPromise,
    });

    const countersMeta = responses.counters.meta as InboxCounterMeta;
    const bookingLabelCountersMeta = responses.bookingLabelCounters
      .meta as InboxCounterMeta;

    this.inboxOpenCounter = countersMeta.counters.open;
    this.inboxArchivedCounter = countersMeta.counters.closed;
    this.inboxSpamCounter = countersMeta.counters.spam;
    this.inboxInquiriesCounter =
      bookingLabelCountersMeta['booking-label-counters'].inquiries;
    this.inboxReservedCounter =
      bookingLabelCountersMeta['booking-label-counters'].reserved;
    this.inboxCheckinTodayCounter =
      bookingLabelCountersMeta['booking-label-counters'].checkin_today;
    this.inboxAtPropertyCounter =
      bookingLabelCountersMeta['booking-label-counters'].at_property;
    this.inboxCheckoutTodayCounter =
      bookingLabelCountersMeta['booking-label-counters'].checkout_today;
    this.inboxPostStayCounter =
      bookingLabelCountersMeta['booking-label-counters'].post_stay;
    this.inboxCanceledCounter =
      bookingLabelCountersMeta['booking-label-counters'].canceled;

    this.isInboxCounterRequested = true;
  }

  async updateTasksCount() {
    const tasks = await this.store.queryRecords(
      'task-management-task',
      this.store.generateQuery('task-management-task', 'meta'),
    );

    this.taskCount = tasks.meta.pagination.total;
  }

  async updateSleepsBedroomsCount() {
    const calendars = await this.store.queryRecords(
      'calendar',
      this.store.generateQuery('calendar', 'meta'),
    );

    this.maximumBedroomsCount = calendars.meta['maximum-bedrooms-count'] as
      | number
      | undefined;
    this.maximumSleepsCount = calendars.meta['maximum-sleeps-count'] as
      | number
      | undefined;
  }

  async loadFirstBooking() {
    const firstBooking = await this.store.queryRecords('booking', {
      ...this.store.generateQuery('booking', 'calendar'),
      sort: 'startAt',
      page: { number: 1, size: 1 },
    });

    this.firstBookingDate = firstBooking.at(0)?.startAt.date;
  }

  loadDestinations() {
    if (this.session.account?.isSmily) {
      return;
    }

    this.destinations = this.store.queryRecords(
      'destination',
      this.store.generateQuery('destination'),
    );
  }

  async getPreferencesPayment() {
    if (!this.preferencesPayment) {
      const account = await this.store.findRecord(
        'account',
        this.session.account!.id,
        {
          ...this.store.generateQuery('account', 'preferencesPayment'),
          reload: true,
        },
      );

      this.preferencesPayment = account.preferencesPayment;
    }

    return this.preferencesPayment;
  }

  async queryAccounts() {
    const accounts = await this.store.queryRecords(
      'account',
      this.store.generateQuery('account', 'meta'),
    );

    this.hasMultipleAccounts = accounts.meta.pagination.total > 1;
  }

  async setupFeatureDiscovery() {
    const featureDiscovery = this._getFeatureDiscoveryMeta();
    const relevantFeatures = featureDiscoveryConfig
      .filter((entry) => {
        return isAfter(parseDateString(entry.until), new Date());
      })
      .map((entry) => entry.name);

    relevantFeatures.forEach((featureName) => {
      featureDiscovery[featureName] = {
        ...(featureDiscovery[featureName] ?? {}),
        sessions: featureDiscovery[featureName]?.sessions ?? 0,
      };
      const featureMeta = featureDiscovery[featureName]!;

      if (featureMeta.sessions >= 3) {
        return;
      }

      featureMeta.sessions += 1;
      this.newFeatureSessionCounts.push([featureName, featureMeta.sessions]);
    });

    await this.session.user!.save();
  }

  private _getFeatureDiscoveryMeta() {
    const user = this.session.user!;
    const applicationMetadata =
      user.currentApplicationMetadata ??
      this.store.createRecord('application-metadatum', {
        payload: {
          feature_discovery: {},
        },
        user,
      });

    if (!applicationMetadata.payload.feature_discovery) {
      applicationMetadata.payload.feature_discovery = {};
    }

    return applicationMetadata.payload.feature_discovery;
  }
}
